
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../Sidebar";
import { AiFillDelete, AiFillEdit, AiOutlineExclamationCircle } from "react-icons/ai";
import { axiosAuth } from "../../../lib/axiosInstance";
import Skeleton from "react-loading-skeleton";

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteModalRef = useRef(null);
  const [editingCompany, setEditingCompany] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(true);

  const itemsPerPage = 10;

  const fetchData = async () => {
    try {
      const response = await axiosAuth.get("/get-companies", {
        params: { page: currentPage, perPage: itemsPerPage },
      });
      setCompanies(response?.data.data); // Access the paginated data
      setLastPage(response?.data.last_page);
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false);
    }
  };

  const nextPage = () => {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handleClickOutside = (event) => {
    if (deleteModalRef.current && !deleteModalRef.current.contains(event.target)) {
      closeDeleteModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditingCompany(null);
    setCompanyName("");
    fetchData();
  };

  const addCompany = async (newCompany) => {
    if (editingCompany) {
      try {
        await axiosAuth.put(`/update-company/${editingCompany.id}`, newCompany);
        setCompanies(companies.map((company) =>
          company.id === editingCompany.id ? { ...editingCompany, ...newCompany } : company
        ));
      } catch (error) {
        console.error("Error updating company:", error);
      }
    } else {
      try {
        await axiosAuth.post("/create-company", newCompany);
        fetchData();
      } catch (error) {
        console.error("Error adding company:", error);
      }
    }
    closeModal();
  };

  const confirmRemoveCompany = (companyId) => {
    setShowDeleteModal(true);
    setCurrentCompany(companyId);
  };

  const removeCompany = async () => {
    setCompanies(companies.filter((company) => company.id !== currentCompany));
    try {
      await axiosAuth.delete(`delete-company/${currentCompany}`);
    } catch (error) {
      console.error("Error deleting company:", error);
    }
    closeDeleteModal();
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const editCompany = (company) => {
    setEditingCompany(company);
    setCompanyName(company.name);
    openModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newCompany = { name: companyName };
    addCompany(newCompany);
  };

  return (
    <Sidebar>
      <div className="p-4 mt-16 lg:mt-0 md:p-8 bg-gray-100 h-full">
        <h1 className="text-2xl md:text-3xl font-bold text-slate-800 mb-4 md:mb-6">Companies</h1>

        <button
          onClick={openModal}
          className="bg-[#36154e] text-white font-semibold py-2 px-4 rounded-lg mb-4 md:mb-6 hover:bg-[#2a133b] transition-colors"
        >
          Add Company
        </button>

        {showModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
            <div className="bg-white rounded-lg shadow-lg p-8 z-20 max-w-md w-full">
              <h2 className="text-2xl font-semibold mb-4">{editingCompany ? "Edit Company" : "Add Company"}</h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                </div>
                <div className="flex justify-end space-x-4">
                  <button type="button" onClick={closeModal} className="bg-gray-500 text-white px-4 py-2 rounded">
                    Cancel
                  </button>
                  <button type="submit" className="bg-[#36154e] text-white px-4 py-2 rounded">
                    {editingCompany ? "Update" : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {showDeleteModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
            <div
              ref={deleteModalRef}
              className="bg-white rounded-lg shadow-lg p-8 z-20 max-w-md w-full transform transition-transform duration-300 scale-95"
            >
              <div className="grid gap-6">
                <div className="flex items-center justify-center text-red-500">
                  <AiOutlineExclamationCircle className="w-12 h-12" />
                </div>
                <div className="text-center">
                  <h2 className="text-2xl font-semibold mb-2">Confirm Deletion</h2>
                  <p className="text-gray-700">Are you sure you want to delete this company?</p>
                  <p className="text-gray-500 mt-1">This action cannot be undone.</p>
                </div>
                <div className="flex justify-end text-white gap-4 mt-4">
                  <button
                    onClick={closeDeleteModal}
                    className="w-24 py-2 rounded bg-gray-400 hover:bg-gray-600 transition-colors duration-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={removeCompany}
                    className="w-24 py-2 rounded bg-red-500 hover:bg-red-700 transition-colors duration-300"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="bg-white rounded-lg shadow-md overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-[#36154e] text-white">
                <th className="px-4 md:px-6 py-3 text-left">ID</th>
                <th className="px-4 md:px-6 py-3 text-left">Name</th>
                <th className="px-4 md:px-6 py-3 text-left">No of User</th>
                <th className="px-4 md:px-6 py-3 text-left">Created At</th>
                <th className="px-4 md:px-6 py-3"></th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                {[...Array(10)].map((_, index) => (
                  <tr key={index}>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : companies.length > 0 ? (
              <tbody className="divide-y divide-blue-200 text-nowrap">
                {companies.map((company) => (
                  <tr key={company.id}>
                    <td className="px-4 md:px-6 py-3">{company.id}</td>
                    <td className="px-4 md:px-6 py-3">{company.name}</td>
                    <td className="px-4 md:px-6 py-3">{company.user_count}</td>
                    <td className="px-4 md:px-6 py-3">{new Date(company.created_at).toLocaleDateString()}</td>
                    <td className="px-4 md:px-6 py-3 flex space-x-2">
                      <button
                        onClick={() => editCompany(company)}
                        className="text-blue-500 hover:text-blue-700 font-semibold"
                      >
                        <AiFillEdit />
                      </button>
                      <button
                        onClick={() => confirmRemoveCompany(company.id)}
                        className="text-red-500 hover:text-red-700 font-semibold"
                      >
                        <AiFillDelete />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody className="divide-blue-200 w-full border-2">
                <tr>
                  <td
                    colSpan={6}
                    className="p-3 text-center italic text-gray-400 font-medium text-xl"
                  >
                    No results found
                  </td>
                </tr>
              </tbody>
            )}
          </table>

          <div className="flex justify-center gap-4 py-2">
            <button
              className={`py-1 w-[5rem] text-slate-50 bg-[#36154e] rounded ${currentPage === 1 ? "opacity-20" : ""}`}
              disabled={currentPage === 1}
              onClick={prevPage}
            >
              Previous
            </button>
            <button
              className={`py-1 w-[5rem] text-slate-50 bg-[#36154e] rounded ${currentPage === lastPage ? "opacity-20" : ""}`}
              disabled={currentPage === lastPage}
              onClick={nextPage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};


export default Companies;
