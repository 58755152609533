import React, { useState, useEffect } from "react";
import {
  utils_data_for_form,
  convert_array_to_object_for_dropdown_data_for_dynamically_rendered_component,
  convert_array_to_object_for_dropdown_data_not_for_llama_form,
} from "../../lib/utils";
import Select from "react-select";
import "../ComponentTwo.css";
import { Player } from "@lottiefiles/react-lottie-player";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {
  StringContentForDoubleDropdownMulti,
  tool_tip_for_multi_single,
} from "../../lib/toolTipFunction";
import { Edit } from "iconsax-react";
import loader from "./loader.json";

const DropdownMultiSingle = ({
  id,
  label,
  type,
  options,
  response,
  setPreviousQuestion,
  previousQuestion,
  setInputResponse,
  setPresentQuestion,
  inputResponse,
  presentQuestion,
  option_groups,
  optionsChosenForCountry,
  setOptionChosenForCountry,
  last_question_for_part_one,
  loading,
  setLoading,
  setLastResponse,
  lastResponse,
}) => {
  const { dropdown, second_values } = option_groups;
  const [dropDownResponse, setDropDownResponse] = useState();
  const [className, setClassName] = useState();
  const [main, setMain] = useState();
  const [ok, setOk] = useState(false);
  const customStyles = utils_data_for_form.dropdown_style;

  const drop_down_option_main =
    convert_array_to_object_for_dropdown_data_not_for_llama_form(dropdown);
  const drop_down_option_secondary =
    convert_array_to_object_for_dropdown_data_not_for_llama_form(second_values);

  const handle_change_main = (e) => {
    let arr = inputResponse;
    arr = arr.map((val) => {
      if (val.id == id) {
        let arr = e.map((val) => val.label);
        if (e.label === "No") {
          val.response = { main: arr, done: true };
        } else {
          val.response = { main: arr, done: false };
        }
        return val;
      } else {
        return val;
      }
    });

    if (e.label == "No") {
      setPresentQuestion({
        ...previousQuestion[
          previousQuestion.indexOf(
            previousQuestion.find((val) => val.id == id)
          ) + 1
        ],
        response: null,
      });
    }

    setInputResponse([...arr]);
  };

  const handle_change_secondary = (e) => {
    let arr = inputResponse;

    arr = arr.map((val) => {
      if (val.id == id) {
        val.response = { ...val.response, done: true, secondary: e.label };
        return val;
      } else {
        return val;
      }
    });
    setPresentQuestion({
      ...previousQuestion[
        previousQuestion.indexOf(previousQuestion.find((val) => val.id == id)) +
          1
      ],
      response: null,
    });

    setInputResponse([...arr]);
  };

  const handleReset = () => {
    let arr = inputResponse;
    let index_search = arr.indexOf(arr.find((val) => val.id == className));
    arr = arr
      .map((val, index) => {
        if (index == index_search) {
          val.response = null;
          return val;
        } else if (index < index_search) {
          return val;
        }
      })
      .filter((val) => val !== undefined);
    setInputResponse([...arr]);
    setMain("Yes");
    setOk(false);
    setLastResponse(false);
  };

  return (
    <div className="A1-dropdown ">
      {(response && response.done) || lastResponse ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: 5,
          }}
        >
          {
            <div
              className={`A1-select-double-dropdown ${id}`}
              onMouseEnter={(e) => {
                setClassName(e.target.classList[1]);
              }}
            >
              <Tippy
                content="Do you want to edit the data? Doing so, you will lose all your stored data."
                onClick={handleReset}
              >
                <Edit
                  size="20"
                  color="#2ccce4"
                  onClick={handleReset}
                  style={{ padding: 4 }}
                />
              </Tippy>
              <Select
                options={drop_down_option_main}
                onChange={handle_change_main}
                styles={customStyles}
                value={{
                  value: String(response.main).toLowerCase(),
                  label: response.main,
                }}
                isDisabled={true}
              />
              {response && response.secondary && (
                <Select
                  options={drop_down_option_secondary}
                  onChange={handle_change_secondary}
                  styles={customStyles}
                  value={{
                    value: String(response.secondary).toLowerCase(),
                    label: response.secondary,
                  }}
                  isDisabled={true}
                />
              )}
            </div>
          }
        </div>
      ) : (
        <>
          {loading && (
            <div style={{ height: "4rem", width: "4rem" }}>
              <Player
                src={loader}
                className="player"
                loop
                autoplay
              />
            </div>
          )}
          {!loading && (
            <div className="A1-select-double-dropdown">
              <Tippy
                interactive={true}
                content={tool_tip_for_multi_single(setPresentQuestion,previousQuestion,setOk, ok,id,setLastResponse)}
              >
                <div>
                  <Select
                    options={drop_down_option_main}
                    onChange={handle_change_main}
                    styles={customStyles}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
              </Tippy>
              <Select
                options={drop_down_option_secondary}
                onChange={handle_change_secondary}
                styles={customStyles}
              />

            

            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DropdownMultiSingle;
