// ForgotPassword.js
import React, { useState } from "react";
import "../ComponentTwo.css";
import { axiosNoAuth } from "../../lib/axiosInstance";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Import toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for react-toastify
import { IoIosArrowBack } from "react-icons/io"; // Import IoIosArrowBack icon from react-icons/io
import logo from "../../Logo/ripley-logo.png";

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [loadingText, setLoadingText] = useState(false);

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            setLoadingText(true);
            await axiosNoAuth.post("/reset-password", { email });
            toast.success("Password reset link sent successfully!"); // Show success toast
            setLoadingText(false);
        } catch (error) {
            console.error("Error sending reset link:", error);
            toast.error("Failed to send password reset link.");
            setLoadingText(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen font-sans border">
            <img src={logo} alt="Ripley" width="180px" className="mb-4" />

            <div className="border border-solid md:p-5 p-5 rounded-md shadow-sm lg:w-[31%] md:w-[50%] w-[80%] bg-white">
                <button
                    className="flex items-center text-blue-500  py-2 border-none rounded-sm cursor-pointer text-base "
                    onClick={() => navigate(-1)} // Go back to previous page
                >
                    <IoIosArrowBack size={20} className="" /> Back
                </button>
                <form className="flex flex-col" onSubmit={handleForgotPassword}>
                    <label className="mb-[0.3rem]" htmlFor="email">
                        Email:
                    </label>
                    <input
                        placeholder="example@example.com"
                        className="mb-[0.8rem] p-[0.3rem] border border-solid border-gray-300 rounded-sm text-base"
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <button
                        className="px-[10px] py-2 bg-[#724CF9] text-white border-none rounded-sm  text-base mt-4"
                        type="submit"
                        disabled={email == '' || loadingText}
                    >
                      {loadingText?("Sending..."):("Send Reset Link")}
                    </button>
                </form>

            </div>
        </div>
    );
};

export default ForgotPassword;
