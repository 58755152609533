import React, { useState } from "react";
import Radio from "./Radio";
import Dropdown from "./Dropdown";
import Input from "./Input";
import CheckBox from "./CheckBox";
import DoubleDropdown from "./DoubleDropdown";
import DropdownMultiSingle from "./DropdownMultiSingle";
import DoubleDropdownMulti from "./DoubleDropdownMulti";
import DropdownMultiMulti from "./DropdownMultiMulti";
import DropdownMultiMultiMulti from "./DropdownMultiMultiMulti";
import DropdownQuantityAfterhours from "./DropdownQuantityAfterhours";
import DropdownQuantityDays from "./DropdownQuantityDays";
import DatePickerComponent from "./DatePickerComponent";

const Index = ({
  id,
  type,
  options,
  response,
  setPreviousQuestion,
  previousQuestion,
  label,
  setInputResponse,
  setPresentQuestion,
  inputResponse,
  presentQuestion,
  option_groups,
  optionsChosenForCountry,
  setOptionChosenForCountry,
  last_question_for_part_one,
  loading,
  setLoading,
  // setOutPutPage,
  outPutPage,
  setShow,
  setLastResponse,
  lastResponse,
  setTextInputId,
  setTextArea,
  textArea,
}) => {
  switch (type) {
    case "radio": {
      return (
        <Radio
          id={id}
          label={label}
          type={type}
          options={options}
          response={response}
          setPreviousQuestion={setPreviousQuestion}
          previousQuestion={previousQuestion}
          setInputResponse={setInputResponse}
          setPresentQuestion={setPresentQuestion}
          inputResponse={inputResponse}
          presentQuestion={presentQuestion}
          loading={loading}
          setLoading={setLoading}
          setLastResponse={setLastResponse}
          lastResponse={lastResponse}
        />
      );
    }

    case "dropdown": {
      return (
        <Dropdown
          id={id}
          label={label}
          type={type}
          options={options}
          response={response}
          setPreviousQuestion={setPreviousQuestion}
          previousQuestion={previousQuestion}
          setInputResponse={setInputResponse}
          setPresentQuestion={setPresentQuestion}
          inputResponse={inputResponse}
          presentQuestion={presentQuestion}
          option_groups={option_groups}
          optionsChosenForCountry={optionsChosenForCountry}
          setOptionChosenForCountry={setOptionChosenForCountry}
          last_question_for_part_one={last_question_for_part_one}
          loading={loading}
          setLoading={setLoading}
          setLastResponse={setLastResponse}
          lastResponse={lastResponse}
          setTextArea={setTextArea}
          textArea={textArea}
        />
      );
    }

    case "text": {
      return (
        <Input
          id={id}
          label={label}
          type={type}
          options={options}
          response={response}
          setPreviousQuestion={setPreviousQuestion}
          previousQuestion={previousQuestion}
          setInputResponse={setInputResponse}
          setPresentQuestion={setPresentQuestion}
          inputResponse={inputResponse}
          presentQuestion={presentQuestion}
          loading={loading}
          setLoading={setLoading}
          setLastResponse={setLastResponse}
          setTextInputId={setTextInputId}
          setTextArea={setTextArea}
        />
      );
    }

    case "checkbox": {
      return (
        <CheckBox
          id={id}
          label={label}
          type={type}
          options={options}
          response={response}
          setPreviousQuestion={setPreviousQuestion}
          previousQuestion={previousQuestion}
        />
      );
    }
    case "dropdown_and_quantity": {
      return (
        <DoubleDropdown
          id={id}
          label={label}
          type={type}
          options={options}
          response={response}
          setPreviousQuestion={setPreviousQuestion}
          previousQuestion={previousQuestion}
          setInputResponse={setInputResponse}
          setPresentQuestion={setPresentQuestion}
          inputResponse={inputResponse}
          presentQuestion={presentQuestion}
          option_groups={option_groups}
          optionsChosenForCountry={optionsChosenForCountry}
          setOptionChosenForCountry={setOptionChosenForCountry}
          last_question_for_part_one={last_question_for_part_one}
          loading={loading}
          setLoading={setLoading}
          setLastResponse={setLastResponse}
          lastResponse={lastResponse}
        />
      );
    }
    case "dropdown_single_value_and_single_value": {
      return (
        <DoubleDropdown
          id={id}
          label={label}
          type={type}
          options={options}
          response={response}
          setPreviousQuestion={setPreviousQuestion}
          previousQuestion={previousQuestion}
          setInputResponse={setInputResponse}
          setPresentQuestion={setPresentQuestion}
          inputResponse={inputResponse}
          presentQuestion={presentQuestion}
          option_groups={option_groups}
          optionsChosenForCountry={optionsChosenForCountry}
          setOptionChosenForCountry={setOptionChosenForCountry}
          last_question_for_part_one={last_question_for_part_one}
          loading={loading}
          setLoading={setLoading}
          setLastResponse={setLastResponse}
          lastResponse={lastResponse}
        />
      );
    }

    case "dropdown_single_value_and_multi_value": {
      return (
        <DoubleDropdownMulti
          id={id}
          label={label}
          type={type}
          options={options}
          response={response}
          setPreviousQuestion={setPreviousQuestion}
          previousQuestion={previousQuestion}
          setInputResponse={setInputResponse}
          setPresentQuestion={setPresentQuestion}
          inputResponse={inputResponse}
          presentQuestion={presentQuestion}
          option_groups={option_groups}
          optionsChosenForCountry={optionsChosenForCountry}
          setOptionChosenForCountry={setOptionChosenForCountry}
          last_question_for_part_one={last_question_for_part_one}
          loading={loading}
          setLoading={setLoading}
          setLastResponse={setLastResponse}
          lastResponse={lastResponse}
        />
      );
    }

    case "dropdown_multi_value_and_single_value": {
      return (
        <DropdownMultiSingle
          id={id}
          label={label}
          type={type}
          options={options}
          response={response}
          setPreviousQuestion={setPreviousQuestion}
          previousQuestion={previousQuestion}
          setInputResponse={setInputResponse}
          setPresentQuestion={setPresentQuestion}
          inputResponse={inputResponse}
          presentQuestion={presentQuestion}
          option_groups={option_groups}
          optionsChosenForCountry={optionsChosenForCountry}
          setOptionChosenForCountry={setOptionChosenForCountry}
          last_question_for_part_one={last_question_for_part_one}
          loading={loading}
          setLoading={setLoading}
          setLastResponse={setLastResponse}
          lastResponse={lastResponse}
        />
      );
    }

    case "dropdown_multi_value_and_multi_value": {
      return (
        <DropdownMultiMulti
          id={id}
          label={label}
          type={type}
          options={options}
          response={response}
          setPreviousQuestion={setPreviousQuestion}
          previousQuestion={previousQuestion}
          setInputResponse={setInputResponse}
          setPresentQuestion={setPresentQuestion}
          inputResponse={inputResponse}
          presentQuestion={presentQuestion}
          option_groups={option_groups}
          optionsChosenForCountry={optionsChosenForCountry}
          setOptionChosenForCountry={setOptionChosenForCountry}
          last_question_for_part_one={last_question_for_part_one}
          loading={loading}
          setLoading={setLoading}
          // setOutPutPage={setOutPutPage}
          // outPutPage={outPutPage}
          setShow={setShow}
          setLastResponse={setLastResponse}
          lastResponse={lastResponse}
        />
      );
    }
    case "dropdown_and_quantity_and_days": {
      return (
        <DropdownQuantityDays
          id={id}
          label={label}
          type={type}
          options={options}
          response={response}
          setPreviousQuestion={setPreviousQuestion}
          previousQuestion={previousQuestion}
          setInputResponse={setInputResponse}
          setPresentQuestion={setPresentQuestion}
          inputResponse={inputResponse}
          presentQuestion={presentQuestion}
          option_groups={option_groups}
          optionsChosenForCountry={optionsChosenForCountry}
          setOptionChosenForCountry={setOptionChosenForCountry}
          last_question_for_part_one={last_question_for_part_one}
          loading={loading}
          setLoading={setLoading}
          // setOutPutPage={setOutPutPage}
          // outPutPage={outPutPage}
          setShow={setShow}
          setLastResponse={setLastResponse}
          lastResponse={lastResponse}
        />
      );
    }
    case "dropdown_and_quantity_and_afterhours": {
      return (
        <DropdownQuantityAfterhours
          id={id}
          label={label}
          type={type}
          options={options}
          response={response}
          setPreviousQuestion={setPreviousQuestion}
          previousQuestion={previousQuestion}
          setInputResponse={setInputResponse}
          setPresentQuestion={setPresentQuestion}
          inputResponse={inputResponse}
          presentQuestion={presentQuestion}
          option_groups={option_groups}
          optionsChosenForCountry={optionsChosenForCountry}
          setOptionChosenForCountry={setOptionChosenForCountry}
          last_question_for_part_one={last_question_for_part_one}
          loading={loading}
          setLoading={setLoading}
          // setOutPutPage={setOutPutPage}
          // outPutPage={outPutPage}
          setShow={setShow}
          setLastResponse={setLastResponse}
          lastResponse={lastResponse}
        />
      );
    }
    case "date_time": {
      return (
        <DatePickerComponent
          id={id}
          label={label}
          type={type}
          options={options}
          response={response}
          setPreviousQuestion={setPreviousQuestion}
          previousQuestion={previousQuestion}
          setInputResponse={setInputResponse}
          setPresentQuestion={setPresentQuestion}
          inputResponse={inputResponse}
          presentQuestion={presentQuestion}
          option_groups={option_groups}
          optionsChosenForCountry={optionsChosenForCountry}
          setOptionChosenForCountry={setOptionChosenForCountry}
          last_question_for_part_one={last_question_for_part_one}
          loading={loading}
          setLoading={setLoading}
          setLastResponse={setLastResponse}
          lastResponse={lastResponse}
        />
      );
    }
    default:
      break;
  }
};

export default Index;
