import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Home2, Document, Profile, LogoutCurve, PresentionChart, Profile2User, MenuBoard } from "iconsax-react";
import { TbBuildingSkyscraper } from "react-icons/tb";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCookies from "../customHooks/useCookies";
import { icon } from "@fortawesome/fontawesome-svg-core";

const Sidebar = ({ children, setIsLoggedIn }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true); // Add state for large screens

  const navbarRef = useRef(null);
  const { getCookie, deleteCookie } = useCookies();
  const userRole = getCookie('user_role');

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const toggleSidebarLargeScreen = () => {

    setIsSidebarVisible(!isSidebarVisible);

    // Toggle for large screens
  };
  const handleButtonClick = (event) => {
    event.stopPropagation();
  };
  // handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setShowSidebar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleLogout = () => {
    localStorage.removeItem("authenticated");
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_in");
    deleteCookie('user_role');
    window.location.reload();
  };

  // Define navigation links based on user role
  const userNavigationLinks = [
    { path: "/home", icon: <Home2 size="16" color="#ffffff" />, iconlg: < Home2 size="24" color="#ffffff" />, text: "Home" },
    { path: "/form", icon: <Document size="16" color="#ffffff" />, iconlg: < Document size="24" color="#ffffff" />, text: "Smart Scope" },
  ];

  const adminNavigationLinks = [
    { path: "/admin/dashboard", icon: <PresentionChart size="16" color="#ffffff" />, iconlg: <PresentionChart size="24" color="#ffffff" />, text: "Dashboard" },
    { path: "/admin/companies", icon: <TbBuildingSkyscraper size="16" color="#ffffff" />, iconlg: <TbBuildingSkyscraper size="24" color="#ffffff" />, text: "Companies" },
    { path: "/admin/users", icon: <Profile2User size="16" color="#ffffff" />, iconlg: <Profile2User size="24" color="#ffffff" />, text: "Users" },
    { path: "/admin/editor", icon: <MenuBoard size="16" color="#ffffff" />, iconlg: <MenuBoard size="24" color="#ffffff" />, text: "Editor" },
    { path: "/admin/surveys", icon: <MenuBoard size="16" color="#ffffff" />, iconlg: <MenuBoard size="24" color="#ffffff" />, text: "Macros" },
  ]

  const companyAdminNavigationLinks = adminNavigationLinks.filter(link => link.path !== "/admin/companies" && link.path !== "/admin/editor");

  const navigationLinks = userRole === 'admin'
    ? adminNavigationLinks
    : userRole === 'company-admin'
      ? companyAdminNavigationLinks
      : userNavigationLinks;

  return (
    <div className="flex h-screen">
      <div
        className={`lg:hidden top-0 right-0 z-[300] w-[100vw] pt-[2rem] pb-[1rem] flex items-center justify-between b-[5rem] md:pl-[4rem] sm:pl-[3rem] xs:pl-[3rem] pl-[2rem] xs:pr-[3.7rem] md:pr-[4.5rem] sm:pr-[4rem] pr-[2rem] ${showSidebar ? "fixed  bg-[#ffffff00]" : "absolute  bg-[#ffffff]"
          }`}
      >
        <div
          className={`text-2xl font-semibold sm:mr-[2rem] sm:pl-[1.4rem] md:pl-[0rem] ${showSidebar ? "text-[#f000]" : "text-[#1B2559]"
            } text-wrap mr-[1rem]`}
        >
          <NavLink to="/home">Ripley

          </NavLink>
        </div>
        <FontAwesomeIcon
          icon={faBars}
          onClick={toggleSidebar}
          onMouseDown={handleButtonClick}
          className={`cursor-pointer text-[1.5rem] pt-[4px] ${showSidebar ? "text-[#fff]" : "text-[#1B2559]"
            }`}
        />
      </div>

      {/* Sidebar (visible on small and medium screens) */}
      <nav
        ref={navbarRef}
        className={`lg:hidden z-[200] fixed top-0 left-0 w-full ${showSidebar
          ? "transform translate-y-0"
          : "transform -translate-y-full"
          } transition-transform duration-[.6s] bg-red-300`}
      >
        <div className=" md:h-[10rem]  bg-gray-900  pl-[1.4rem] pt-[2rem] z-[100]">
          <div className="text-2xl font-semibold   md:mb-10 text-[#fff] hover:text-[#724CF9] text-wrap ">
            <span className="">Ripley</span>
            {userRole == 'admin' && (
              <span className=" bg-[#BE7438] text-[10px] text-white px-2 py-1 rounded-md ml-2">Admin 🔒 </span>
            )}
          </div>
          <ul className="flex flex-wrap flex-col items-start justify-start md:justify-around  md:flex-row pl-[0.7rem] py-2">
            {navigationLinks.map((link, index) => (
              <li key={index}>
                <NavLink
                  to={link.path}
                  className={({ isActive }) =>
                    `flex items-center gap-2 p-2 text-sm hover:text-[#724CF9] no-underline ${isActive ? "text-[#724CF9]" : "text-[#fff]"}`
                  }
                  onClick={toggleSidebar}
                >
                  {link.icon}
                  {link.text}
                </NavLink>
              </li>
            ))}
            <NavLink
              to={'/profile'}
              className={({ isActive }) =>
                `flex items-center gap-2 p-2 text-sm hover:text-[#724CF9] no-underline ${isActive ? "text-[#724CF9]" : "text-[#fff]"}`
              }
            >
              <Profile size="16" color="#ffffff" />
              Profile
            </NavLink>
            <li>

              <div
                className="flex  items-center p-2 text-sm cursor-pointer hover:text-[#724CF9] text-[#fff] no-underline"
                onClick={() => {
                  handleLogout();
                  toggleSidebar();
                }}
              >
                <LogoutCurve size="16" color="#ffffff" className="mr-[9px]" />
                Logout
              </div>
            </li>
          </ul>
        </div>
      </nav>
      {/* Sidebar (visible on large screens) */}
      <nav className={`hidden lg:block ${isSidebarVisible ? "w-[14rem]" : "w-[7rem]"} transition-all duration-500 bg-gray-900 p-4 relative`}>
        <div className={`text-2xl font-semibold mb-10 text-[#fff] text-wrap flex ${!isSidebarVisible ? "justify-center" : "gap-2 items-center"}`}>
          <FontAwesomeIcon
            icon={faBars}
            onClick={() => toggleSidebarLargeScreen()} // Only this button toggles the sidebar
            className="cursor-pointer text-[1.5rem] pt-[4px] text-[#fff] hover:text-[#724CF9]"
          />
          {isSidebarVisible && <span className="hover:text-[#724CF9]">Ripley</span>}
          {userRole === 'admin' && (
            <span className=" bg-[#BE7438] text-[10px] text-white px-2 py-1 rounded-md ml-2">{isSidebarVisible ? "Admin 🔒" : "🔒"} </span>
          )}
        </div>
        {isSidebarVisible ? (
          <>
            {/* Full Sidebar Content */}
            <ul>
              {navigationLinks.map((link, index) => (
                <li key={index}>
                  <NavLink
                    to={link.path}
                    className={({ isActive }) =>
                      `flex items-center gap-2 p-2 text-sm hover:text-[#724CF9] no-underline mb-6 transition-all duration-200 ${isActive ? "text-[#724CF9]" : "text-[#fff]"}`
                    }
                  >
                    {link.icon}
                    {link.text}
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className="fixed bottom-4 transition-all duration-200">
              <NavLink
                to={'/profile'}
                className={({ isActive }) =>
                  `flex items-center gap-2 p-2 text-sm hover:text-[#724CF9] no-underline mb-6 ${isActive ? "text-[#724CF9]" : "text-[#fff]"}`
                }
              >
                <Profile size="16" color="#ffffff" />
                Profile
              </NavLink>
              <div
                className="flex items-center p-2 text-sm cursor-pointer hover:text-[#724CF9] text-[#fff] no-underline"
                onClick={handleLogout}
              >
                <LogoutCurve size="16" color="#ffffff" className="mr-[9px]" />
                Logout
              </div>
            </div>
          </>
        ) : (
          <>
            <ul>
              {navigationLinks.map((link, index) => (
                <li key={index}>
                  <NavLink
                    to={link.path}
                    className={({ isActive }) =>
                      `flex p-2 text-sm hover:text-[#724CF9] no-underline mb-6 ${isActive ? "text-[#724CF9]" : "text-[#fff]"} ${!isSidebarVisible ? "justify-center" : "items-center gap-2"}`
                    }
                  >
                    {link?.iconlg}
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className="fixed bottom-4">
              <NavLink
                to={'/profile'}
                className={`flex items-center gap-2 p-2 text-sm hover:text-[#724CF9] no-underline mb-6`}
              >
                <Profile size="24" color="#ffffff" />
              </NavLink>
              <div
                className={({ isActive }) => `"flex items-center p-2 text-sm cursor-pointer hover:text-[#724CF9] text-[#fff] no-underline ${isActive ? "text-[#724CF9]" : "text-[#fff]"}`}
                onClick={handleLogout}
              >
                <LogoutCurve size="24" color="#ffffff" className="mr-[9px]" />
              </div>
            </div>
          </>

        )}
      </nav>
      {/* Content */}
      <div className={`flex-1 max-h-[100vh] w-[100%] overflow-y-scroll transition-margin duration-500`}>
        {children}
      </div>
    </div >
  );
};

export default Sidebar;
