import React, { useEffect, useState } from "react";
import "./ComponentTwo.css";
import Sidebar from "./Sidebar";
import FormClaim from "./FormClaim";
import FormGeneral from "./FormGeneral";
import ProgressBar from "./OtherComponents/ProgressBar";

const FormTwo = ({ setIsLoggedIn }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [claimId, setClaimId] = useState(null);

  useEffect(() => {
    if (claimId) {
      setCurrentStep(2); // Auto-switch to General Info after submitting claim
    }
  }, [claimId]);

  return (
    <Sidebar setIsLoggedIn={setIsLoggedIn}>
      {/* Progress Bar */}
      <ProgressBar currentStep={currentStep} totalSteps={2} setCurrentStep={setCurrentStep} />

      {/* Show Claim Form when step is 1 */}
      {currentStep === 1 && <FormClaim setClaimId={setClaimId} setCurrentStep={setCurrentStep} />}

      {/* Show General Form when step is 2 */}
      {currentStep === 2 && <FormGeneral claimId={claimId} setCurrentStep={setCurrentStep} />}
    </Sidebar>
  );
};

export default FormTwo;
