import React, { useState, useEffect } from "react";
import "../ComponentTwo.css";
import { Player } from "@lottiefiles/react-lottie-player";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Edit } from "iconsax-react";

const Radio = ({
  id,
  label,
  type,
  options,
  response,
  setPreviousQuestion,
  previousQuestion,
  setInputResponse,
  setPresentQuestion,
  inputResponse,
  presentQuestion,
  loading,
  setLoading,
  setLastResponse,
  lastResponse,
}) => {
  const [className, setClassName] = useState();

  const [disable, setDisable] = useState(false);

  const handle_radio_change_handler = (e) => {
    if (disable) {
      return;
    }

    setDisable(true);
    let arr = inputResponse;

    arr = arr.map((val) => {
      if (val.id == id) {
        val.response = e.target.value;
        return val;
      } else {
        return val;
      }
    });

    setInputResponse([...arr]);

    setPresentQuestion({
      ...previousQuestion[
        previousQuestion.indexOf(previousQuestion.find((val) => val.id == id)) +
          1
      ],
      response: null,
    });
  };

  const StringContent = () => {
    return (
      <div id="confirmation-div">
        <p>Do you want to edit the data?</p>
        <p>Doing so, you will lose all your stored data.</p>
        <button
          id="yes-button"
          onClick={() => {
            let arr = inputResponse;
            let index_search = arr.indexOf(
              arr.find((val) => val.id == className)
            );
            arr = arr
              .map((val, index) => {
                if (index == index_search) {
                  val.response = null;
                  return val;
                } else if (index < index_search) {
                  return val;
                }
              })
              .filter((val) => val !== undefined);
            setInputResponse([...arr]);
          }}
        >
          Yes
        </button>
      </div>
    );
  };

  const handleReset = () => {
    setDisable(false);
    let arr = inputResponse;
    let index_search = arr.indexOf(arr.find((val) => val.id == id));
    arr = arr
      .map((val, index) => {
        if (index == index_search) {
          val.response = null;
          return val;
        } else if (index < index_search) {
          return val;
        }
      })
      .filter((val) => val !== undefined);
    setInputResponse([...arr]);
    setLastResponse(false);
  };

  return (
    <>
      {options &&
        options.length &&
        options.map((option) => {
          const { key, value } = option;

          return (
            <div key={key}>
              {response || lastResponse ? (
                <div
                  className={`A1-dropdown-input ${id}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 5,
                  }}
                  onClick={(e) => {
                    setClassName(e.target.classList[1]);
                  }}
                >
                  <Tippy
                    content="Do you want to edit the data? Doing so, you will lose all your stored data."
                    onClick={handleReset}
                  >
                    <Edit
                      size="25"
                      color="#2ccce4"
                      onClick={handleReset}
                      style={{ padding: 4, cursor: "pointer" }}
                    />
                  </Tippy>
                  <label className="radio-container" htmlFor={key}>
                    <input
                      checked={key == response}
                      type="radio"
                      id={key}
                      name="radio-option"
                      className="radio-input"
                      onChange={(e) => handle_radio_change_handler(e, key)}
                    />
                    <div className="radio-box"></div>
                    <span className="radio-label">{value}</span>
                  </label>
                </div>
              ) : (
                <>
                  {loading && (
                    <div style={{ height: "4rem", width: "4rem" }}>
                      <Player
                        src="https://lottie.host/16487e6f-6e86-4956-af5a-f89515804257/3EVqJvUh3i.json"
                        className="player"
                        loop
                        autoplay
                      />
                    </div>
                  )}
                  {!loading && (
                    <div className="A1-dropdown-input">
                      <label className="radio-container" htmlFor={key}>
                        <input
                          type="radio"
                          id={key}
                          name="radio-option"
                          className="radio-input"
                          value={key}
                          onChange={handle_radio_change_handler}
                        />
                        <div className="radio-box"></div>
                        <span className="radio-label">{value}</span>
                      </label>
                    </div>
                  )}
                </>
              )}
            </div>
          );
        })}
    </>
  );
};

export default Radio;
