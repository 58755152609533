import { useState } from 'react';
import Cookies from 'js-cookie';

const useCookies = () => {
  const [cookies, setCookiesState] = useState(() => {
    const allCookies = Cookies.get();
    return allCookies;
  });

  const setCookie = (name, value, days) => {
    Cookies.set(name, value, { expires: days });
    setCookiesState(Cookies.get());
  };

  const getCookie = (name) => {
    return Cookies.get(name);
  };

  const deleteCookie = (name) => {
    Cookies.remove(name);
    setCookiesState(Cookies.get());
  };

  return { cookies, setCookie, getCookie, deleteCookie };
};

export default useCookies;
