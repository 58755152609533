import React, { useRef, useState } from "react";
import chat_icon from "./chaticon.png";
import { useEffect } from "react";
import { useForm } from "../customHooks/useForm";
import Index from "./inputComponent/Index";
import { CloudFog, ProfileCircle, Send } from "iconsax-react";
import { useNavigate } from "react-router";
import ProgressBar from "./OtherComponents/ProgressBar";
// import {create_general} from "../customHooks/useForm";
// import Input from "./inputComponent/Input";
import { Player } from "@lottiefiles/react-lottie-player";
import { utils_data_for_dynamic_form } from "../lib/utilsDynamic";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { FormClaim } from "./FormClaim";
import { general_payload_object_maker } from "../lib/utilsDynamic";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosAuth } from "../lib/axiosInstance";

const FormGeneral = ({ claimId, setCurrentStep }) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [lastResponse, setLastResponse] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [textAreaResponse, setTextAreaResponse] = useState();
  const [textArea, setTextArea] = useState(true);
  const [textInputId, setTextInputId] = useState();
  const [questionType, setQuestionType] = useState();
  const navigate = useNavigate();
  const messagesContainerRef = useRef(null);
  const { create_general, create_claim, fetchQuestion } = useForm();
  const [dropdownVisibility, setDropdownVisibility] = useState({});
  const [formAnswers, setFormAnswers] = useState([]);
  const [formQuestions, setFormQuestions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [editedOptions, setEditedOptions] = useState([]);
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});
  const [isButtonClickable, setIsButtonClickable] = useState(true);
  const topRef = useRef(null);

  // Function to update visibility for a specific question
  const updateVisibility = (questionId, isVisible) => {
    console.log(questionId)
    console.log(isVisible)
    setDropdownVisibility((prevVisibility) => ({
      ...prevVisibility,
      [questionId]: isVisible,
    }));
  };

  const [questions, setQuestions] = useState();

  const [q7Value, setQ7Value] = useState(""); // State to manage the value of q7

  const handle_final_submit = async (payload) => {
    try {
      // Assuming create_general returns a Promise
      const response = await create_general(claimId, payload);
      setCurrentStep(2);

      // Check if the API response indicates success
      if (response.success === "true") {
        toast.success(response.message);
        // Add code here to handle successful response, if needed
      } else {
        // toast.error('Failed to submit claim. Please try again.');
        // Add code here to handle unsuccessful response, if needed
      }
    } catch (error) {
      // Handle any errors that may occur during the submission
      console.error("Error submitting claim:", error);
      // Add code here to handle the error, such as displaying an error message to the user
      toast.error("An error occurred. Please try again later.");
    }
  };

  const [answers, setAnswers] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
    q8: "",
    q9: "",
    q10: "",
    q11: "",
    q12: "",
    q13: "",
    q14: "",
    q15: "",
    q16: "",
    q17: "",
    q18: "",
    q19: "",
    q20: "",
    q21: "",
  });

  useEffect(() => {
    setTimeout(() => {
      if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: "instant" });
      }
    }, 700);
  }, []);

  useEffect(() => {
    fetchQuestion(claimId)
      .then((response) => {
        setQuestions(response);

      })

      .catch((error) => {
        console.error("Error fetching questions:", error);
      });


  }, [claimId]);


  const fetchData = async () => {
    try {
      const {
        data: { generalInformation },
      } = await axiosAuth.get("/user-details-general");
      const formData = [];
      generalInformation.forEach(
        ({ general_information_question_id, user_entered_answer }) => {
          const answer_1 = user_entered_answer.answer_1 || "";
          const answer_2 = user_entered_answer.answer_2 || "";

          formData.push({
            question_id: general_information_question_id,
            answer: {
              answer_1,
              answer_2,
            },
          });
        }
      );
      setFormAnswers(formData);
      formData.forEach(({ question_id, answer: { answer_1, answer_2 } }) => {
        if (Array.isArray(answer_2)) {
          const updatedSelectedOptions = answer_2.flat(Infinity);

          setSelectedOptionsMap((prevState) => ({
            ...prevState,
            [question_id]: updatedSelectedOptions,
          }));
        }

        const answerSelect = document.querySelectorAll(
          `select[name="${question_id}"]`
        );
        if (answerSelect.length > 0) {
          answerSelect.forEach((select, index) => {
            if (index === 0) {
              select.value = answer_1;
              updateVisibility(question_id, answer_1 === "Yes" || answer_1 == "Yes, Regular Hours" || answer_1 == "Yes, After Hours" || answer_1 == "Yes - N-95" || answer_1 == "Yes - Half Face" || answer_1 == "Yes - Full Face" || answer_1 == "Yes -  Powered");
            } else if (index === 1) {
              select.value = answer_2;
            }
          });
        }
      });

      // Get the button element
      const button = document.getElementById("auto-fill-btn");

      // Trigger a click on the button
      if (isButtonClickable) {
        button?.click();
        setIsButtonClickable(false);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      toast.error(error?.response?.data?.error || "Something went wrong!");
    }
  };

  // fetchData();

  const handleInputChange = (questionId, value, key) => {

    const existingAnswerIndex = formAnswers.findIndex(
      (answer) => answer.question_id === questionId

    );
    console.log(existingAnswerIndex);

    setSelectedOptionsMap((prevMap) => {

      const updatedMap = {
        ...prevMap,
        [questionId]:
          key === "answer_2" ? [...(prevMap[questionId] || []), ...value] : [],
      };

      // Update the formAnswers within the same callback to ensure consistency
      const updatedAnswer = {
        question_id: questionId,
        answer: {
          ...(existingAnswerIndex !== -1
            ? formAnswers[existingAnswerIndex].answer
            : {}),
          [key]: Array.isArray(value)
            ? value.map((val, i) => ({ [i]: updatedMap[questionId] }))
            : value,
        },
      };
      console.log(updatedAnswer)
      if (existingAnswerIndex !== -1) {
        const updatedFormAnswers = [...formAnswers];
        updatedFormAnswers[existingAnswerIndex] = updatedAnswer;
        setFormAnswers(updatedFormAnswers);
      } else {
        setFormAnswers((prevFormAnswers) => [
          ...prevFormAnswers,
          updatedAnswer,
        ]);
      }

      return updatedMap;
    });
  };
   console.log(selectedOptionsMap)
  const handleRemoveSelectedOption = (questionId, optionToRemove) => {
    // Remove the selected option from the array
    const updatedSelectedOptions = selectedOptionsMap[questionId].filter(
      (option) => option !== optionToRemove
    );

    // Update the selected options map
    setSelectedOptionsMap((prevMap) => ({
      ...prevMap,
      [questionId]: updatedSelectedOptions,
    }));
  };

  const question_mapper = {
    q1: "company_name_or_location",
    q2: "requester_name",
    q3: "full_name",
    q4: "phone",
    q5: "email",
    q6: "address",
    q8: "city",
    q9: "region",
    q10: "postal_code",
    q11: "renderable_type_of_loss",
    q12: "carrier_name",
    q13: "claim_adjuster",
    q14: "claim_number",
    q15: "policy_number",
    q16: "renderable_type_of_request",
    q17: "is_outside_of_service_area",
    q18: "emergency_profit",
    q19: "emergency_overhead",
    q20: "reconstruction_profit",
    q21: "reconstruction_overhead",
  };

  const handleSubmit = () => {
    const payload = {
      form_answers: formAnswers,
    };

    // Send the payload to your backend API endpoint
    handle_final_submit(payload);
    // console.log(JSON.stringify(payload));
  };

  const validOptions = [
    "Yes",
    "Yes, Labor & Material",
    "Yes, Material Only",
    "Yes, Regular Hours",
    "Yes, After Hours",
    "Yes - N-95",
    "Yes - Half Face",
    "Yes - Full Face",
    "Yes -  Powered",
    "Pick Up Truck",
    "Dump Trailer",
    "Dump Truck",
    "Dump 12Yrd",
    "Dump 20Yrd",
    "Dump 30Yrd",
    "Dump 40Yrd",
    "Yes, ALL attached",
    "Yes, SOME attached",
    /* add more options */
  ];

  const handleConfirmSelection = (questionId, selectedOptions) => {
    setSelectedOptionsMap((prevMap) => ({
      ...prevMap,
      [questionId]: selectedOptions,
    }));

    setEditedOptions(selectedOptions);
    setSelectedOptions(selectedOptions);
    updateVisibility(questionId, false); // Close the second dropdown
  };

  const handleEditSelection = () => {
    setDropdownOpen(true);
  };
  const [options, setOptions] = useState();
  useEffect(() => {
    const question = questions?.find(q => q?.id == 57)
    if (question) {
      setOptions("No")
    }
 
  }, [questions])
  return (
    <div className="bg-gray-100" ref={topRef}>
      <div className="pt-5 flex items-center justify-center p-5 bg-gray-100">
        <div
          className="col-span-full md:col-span-2 gap-5 lg:mt-14 mt-20 xl:mt-5 lg:col-span-3 bg-white sm:p-6 py-10 pt-0 rounded-lg
                w-full xl:w-1/2 2xl:w-1/2"
        >
          <ProgressBar currentStep={2} totalSteps={2} />
        </div>
      </div>
      <div className="flex items-center justify-center bg-gray-100">
        <div
          className="col-span-full md:col-span-2 lg:col-span-3 pt-0 rounded-lg
                w-full xl:w-1/2 2xl:w-1/2"
        >
          <h4 className="text-xl font-semibold mb-4 text-center">
            General Information Form
          </h4>
        </div>
      </div>
      <div className="min-h-screen flex items-center pt-5 justify-center bg-gray-100 p-5">
        <div
          className="col-span-full md:col-span-2 lg:col-span-3 bg-white p-6  pt-5 rounded-lg shadow-xl 
                w-full xl:w-1/2 2xl:w-1/2"
        >
          {isButtonClickable && (
            <div className="flex justify-end">
              <button
                className="flex items-center p-2  font-medium  text-white  rounded-[7px] justify-end  shadow-md  bg-[#9780FF] hover:bg-[#785eea] "
                onClick={fetchData}
                id="auto-fill-btn"
              >
                Auto Fill
              </button>
            </div>
          )}
          {questions &&
            Array.isArray(questions) &&

            questions.map((question, index) => (

              <div key={index} className="mb-4">
                <p className="mb-1 text-gray-800">
                  {question.title} {question.required ? "*" : ""}
                </p>
                {question.renderable_type ===
                  "dropdown_single_value_and_single_value" && (
                    <div
                      key={question.id}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <select
                        className="w-full p-2 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300 "
                        key={`${question.id}_options`}
                        name={question.id}
                        // value={options}
                        defaultValue={
                          ["ATP Testing (Amount)", "Asbestos/Lead Sample(s) - (Amount)", "Emergency Call Out Fee", "Subtrades used on Emergency?"].includes(question.title)
                            ? "No"
                            : ""
                        }
                        id={question.id}
                        onChange={(e) => {
                          handleInputChange(question.id, e.target.value, "answer_1");
                          updateVisibility(
                            question.id,
                            e.target.value === "Yes" ||
                            e.target.value === "Yes, Regular Hours" ||
                            e.target.value === "Yes, After Hours" ||
                            e.target.value === "Yes - N-95" ||
                            e.target.value === "Yes - Half Face" ||
                            e.target.value === "Yes - Full Face" ||
                            e.target.value === "Yes -  Powered"
                          );
                        }}
                      >
                        <option value="">Select</option>
                        {question.possible_answers &&
                          question.possible_answers.dropdown.map((option, optionIndex) => (
                            <option key={optionIndex} value={option} className="py-2 bg-gray-200 rounded-md">
                              {option}
                            </option>
                          ))}
                      </select>

                      {dropdownVisibility[question.id] && (
                        <div className="w-full md:w-1/2 pl-2">
                          <select
                            className="w-full p-2 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300"
                            key={`${question.id}`}
                            name={question.id}
                            id={question.id}
                            onChange={(e) => {
                              handleInputChange(
                                question.id,
                                e.target.value,
                                "answer_2"
                              );
                            }}
                          >
                            <option value="">Select</option>
                            {question.possible_answers.second_values &&
                              question.possible_answers.second_values.map(
                                (secondOption, secondOptionIndex) => (
                                  <option
                                    key={secondOptionIndex}
                                    value={secondOption}
                                    className="py-2 bg-gray-200 rounded-md"
                                  >
                                    {secondOption}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                      )}
                    </div>


                  )}



                {/* //DropDown single value and amount  */}
                {question.renderable_type ===
                  "dropdown_single_value_and_amount" && (
                    <div
                      key={question.id}
                      style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}
                    >

                      <select
                        className="w-full p-2 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300"
                        name={question.id}
                        defaultValue="No"
                        id={question.id}
                        onChange={(e) => {
                          if (e.target.value !== "No" && e.target.value !== "0") {
                            handleInputChange(question.id, 'Yes', "answer_1");
                          }
                          else {
                            handleInputChange(question.id, e.target.value, "answer_1");
                          }
                        }}


                      >
                        <option value="No">No</option>
                        {question.possible_answers.second_values.map((option, optionIndex) => (
                          <option key={optionIndex} value={option} className="py-2 bg-gray-200 rounded-md">
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>


                  )}

                   {/* //DropDown */}
                {question.renderable_type ===
                  "dropdown" && (
                    <div key={question.id} style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                    
                    <label htmlFor={`${question.id}_regular`}>Regular Hours:</label>
                    <select
                        className="w-full p-2 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300"
                        name={`${question.id}_regular`}
                        defaultValue={question.possible_answers.dropdown[0]}
                        id={`${question.id}_regular`}
                        onChange={(e) => {
                            if (e.target.value !== "No" && e.target.value !== "0") {
                                handleInputChange(question.id, 'Yes', "answer_1");
                            } else {
                                handleInputChange(question.id, e.target.value, "answer_1");
                            }
                        }}
                    >
                        
                        {question.possible_answers.dropdown.map((option, optionIndex) => (
                            <option key={optionIndex} value={option} className="py-2 bg-gray-200 rounded-md">
                                {option}
                            </option>
                        ))}
                    </select>

                    <label htmlFor={`${question.id}_after`}>After Hours:</label>
                    <select
                        className="w-full p-2 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300"
                        name={`${question.id}_after`}
                        defaultValue={question.possible_answers.dropdown2[0]}
                        id={`${question.id}_after`}
                        onChange={(e) => {
                            if (e.target.value !== "No" && e.target.value !== "0") {
                                handleInputChange(question.id, 'Yes', "answer_1");
                            } else {
                                handleInputChange(question.id, e.target.value, "answer_1");
                            }
                        }}
                    >
                        
                        {question.possible_answers.dropdown2.map((option, optionIndex) => (
                            <option key={optionIndex} value={option} className="py-2 bg-gray-200 rounded-md">
                                {option} 
                            </option>
                        ))}
                    </select>
                </div>


                  )}


                {/* dropdown_single_value_and_multi_value */}
                {question.renderable_type ===
                  "dropdown_single_value_and_multi_value" && (
                    <div key={question.id} className="flex flex-row">
                      <div className="flex items-center flex-col">
                        {/* First dropdown */}
                        <select
                          className="w-full p-2 border border-gray-300 rounded-md mr-2 appearance-none focus:outline-none focus:ring focus:border-blue-300"
                          key={`${question.id}_options`}
                          name={question.id}
                          id={question.id}
                          defaultValue={
                            ["Subtrades used on Emergency?"].includes(question.title)
                              ? "No"
                              : ""
                          }
                          onChange={(e) => {
                            handleInputChange(
                              question.id,
                              e.target.value,
                              "answer_1"
                            );
                            updateVisibility(
                              question.id,
                              validOptions.includes(e.target.value)
                            );
                          }}
                        >
                          <option value="">Select</option>
                          {question.possible_answers &&
                            question.possible_answers.dropdown.map(
                              (option, optionIndex) => (
                                <option
                                  key={optionIndex}
                                  value={option}
                                  className="py-2 bg-gray-200 rounded-md"
                                >
                                  {option}
                                </option>
                              )
                            )}
                        </select>

                        {/* Dashed Divider */}
                        <div className="border-l border-dashed h-full mx-4"></div>

                        {/* Second dropdown for selected options */}
                        <div className="flex flex-col">
                          {dropdownVisibility[question.id] && (
                            <div className="w-full md:w-full py-2 pr-2 flex-row">
                              <div className="flex flex-row flex-wrap gap-2 item-start">
                                {question.possible_answers.second_values &&
                                  question.possible_answers.second_values.map(
                                    (secondOption, secondOptionIndex) => (
                                      <div
                                        key={secondOptionIndex}
                                        className="mb-2 flex"
                                      >
                                        <button
                                          className={`cursor-pointer ${selectedOptionsMap[question.id]?.includes(secondOption)
                                            ? "bg-gray-500 text-white"
                                            : "bg-gray-200"
                                            } px-2 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300`}
                                          onClick={() =>
                                            handleInputChange(
                                              question.id,
                                              [secondOption],
                                              "answer_2"
                                            )
                                          }
                                          disabled={selectedOptionsMap[question.id]?.includes(secondOption)}
                                        >
                                          {secondOption}
                                        </button>
                                      </div>
                                    )
                                  )}
                                {/* Add a button to confirm the selection */}
                                <button
                                  onClick={() =>
                                    handleConfirmSelection(
                                      question.id,
                                      selectedOptionsMap[question.id]
                                    )
                                  }
                                  className="bg-green-500 text-white px-4 py-2 rounded-md flex items-center space-x-2 hover:bg-green-600 focus:outline-none focus:ring focus:border-blue-300"
                                >
                                  <span className="fa fa-check"></span>
                                  Confirm Selection
                                </button>
                              </div>
                            </div>
                          )}

                          {/* Display selected options when the second dropdown is not visible */}
                          {selectedOptionsMap[question.id] &&
                            selectedOptionsMap[question.id].length > 0 && (
                              <div className="w-full md:w-[100%]">
                                <p className="text-gray-800">Selected Options:</p>
                                <div className="flex flex-wrap w-[100%]">
                                  {selectedOptionsMap[question.id].map(
                                    (option, optionIndex) => (
                                      <div
                                        key={optionIndex}
                                        className="rounded-md bg-gray-200 p-2 m-1 flex items-center"
                                      >
                                        <span>{option}</span>
                                        <button
                                          className="ml-2 text-red-500 font-light"
                                          onClick={() =>
                                            handleRemoveSelectedOption(
                                              question.id,
                                              option
                                            )
                                          }
                                        >
                                          &#10006;
                                        </button>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                        </div>

                      </div>
                    </div>
                  )}

                {question.type === "text" && (
                  <input
                    type="text"
                    name={question.id}
                    id={question.id}
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    onChange={(e) =>
                      handleInputChange(question.id, e.target.value, "answer_1")
                    }
                  />
                )}
              </div>
            ))}
          <button
            onClick={handleSubmit}
            className="bg-[#9780FF] font-medium hover:bg-[#785eea] text-white px-4 py-2 rounded-md   "
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormGeneral;
