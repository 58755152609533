import React from 'react'
import { useLocation } from 'react-router-dom'
import Sidebar from '../Sidebar'

const QAPairs = ({ data }) => {

  const formatAnswer = (response) => {
      if (!response) return '';

      if (response.main) {
          if (response.main === 'No' || response.main === 'None') {
              return response.main;
          }

          let firstPart = Array.isArray(response.main) ? response.main.join(", ") : response.main;
          let secondPart = Array.isArray(response.secondary) ? response.secondary.join(", ") : response.secondary;

          return `First: ${firstPart}, Second: ${secondPart ? secondPart : ""}`;
      }

      return response;
  };

  return (
      <div>
          {data.map((item, index) => {
              const { id, label, response } = item;
              const answer = formatAnswer(response);

              return (
                  <div key={id}>
                      <p><b>{index + 1}. Question:</b> {label}</p>
                      <p><b>Response:</b> {answer}</p>
                      {index < data.length - 1 && <hr />} {/* Horizontal line between Q&A pairs */}
                  </div>
              );
          })}
      </div>
  );
};



const FormOutput = () => {
  const location = useLocation();
  const { data, surveyId } = location.state || {};;
  return (
      <Sidebar>
          <div className='flex flex-col rounded-[5px] shadow-[0_1px_15px_-5px_rgba(0,0,0,0.4)] bg-[#fff] p-[2rem] mb-[1rem] text-[#1B2559] md:mr-[1rem]'>
              <div className='survey-output-header'>
                  <h1>Your Survey Response</h1>
                  {surveyId && <p><strong>Survey ID:</strong> {surveyId}</p>}
              </div>
              <QAPairs data={data} />
          </div>
      </Sidebar>
  )
}

export default FormOutput




