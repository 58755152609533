import React, { useEffect, useState } from "react";
import "./ComponentTwo.css";
import Sidebar from "./Sidebar";
import { CSSTransition } from "react-transition-group";
import FormClaim from "./FormClaim";
import FormGeneral from "./FormGeneral";
import ProgressBar from "./OtherComponents/ProgressBar";
import {
  utils_data_for_dynamic_form,
  payload_object_maker,
  room_payload_maker,
  general_payload_object_maker,
} from "../lib/utilsDynamic";
import { useForm } from "../customHooks/useForm";
import {ErrorModal} from './OtherComponents/ErrorComponent';

const FormTwo = ({ setIsLoggedIn, setOutPutPage, outPutPage }) => {
  const [totalQuestions, setTotalQuestions] = useState(0); 
  
  const [previousQuestion, setPreviousQuestion] = useState(
    utils_data_for_dynamic_form.question_part_one
  );

  const [presentQuestion, setPresentQuestion] = useState({
    ...previousQuestion[0],
    response: null,
  }); 

  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = 2; 

  const [inputResponse, setInputResponse] = useState([]);
  const [endOfQuestionFlag, setEndOfQuestionFlag] = useState(false);
  const [optionsChosenForCountry, setOptionChosenForCountry] = useState();
  const [fetchedQuestionData, setFetchedQuestionData] = useState();
  const [claimId, setClaimId] = useState();
  const [payload, setPayload] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    data,
    isLoading,
    error,
    fetchQuestion,
    fetchRoomQuestions,
    create_claim,
    create_room,
    create_general,
    endGenQuestion,
    
  } = useForm();


  const incrementStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  // Define a state variable to track if claimId is available
  const [isClaimIdAvailable, setIsClaimIdAvailable] = useState(false);

  // Effect to check if claimId is available and set the state accordingly
  useEffect(() => {
    if (claimId) {
      // console.log("Claim ID is available:", claimId);
      setIsClaimIdAvailable(true);
    }
  }, [claimId]);

  return (
    <Sidebar setIsLoggedIn={setIsLoggedIn}
    >
      <CSSTransition
        in={!isClaimIdAvailable}
        timeout={900}
        classNames="fade"
        unmountOnExit
      >
        <FormClaim
          previousQuestion={previousQuestion}
          setPreviousQuestion={setPreviousQuestion}
          setInputResponse={setInputResponse}
          presentQuestion={presentQuestion}
          setEndOfQuestionFlag={setEndOfQuestionFlag}
          endOfQuestionFlag={endOfQuestionFlag}
          inputResponse={inputResponse}
          optionsChosenForCountry={optionsChosenForCountry}
          setOptionChosenForCountry={setOptionChosenForCountry}
          payload={payload}
          endGenQuestionFlag={endGenQuestion}
          claimId={claimId}
          setClaimId={setClaimId}
          setCurrentStep={setCurrentStep}
        />
      </CSSTransition>

      <CSSTransition
        in={isClaimIdAvailable}
        timeout={900}
        classNames="fade"
        unmountOnExit
      >
        <FormGeneral 
          claimId={claimId}
          setCurrentStep={setCurrentStep}
        />
      </CSSTransition>
    </Sidebar>
  );
};

export default FormTwo;
