import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { axiosAuth } from '../../../../lib/axiosInstance';
import Sidebar from '../../../Sidebar';
import { LiaEyeSolid } from 'react-icons/lia';
import Skeleton from 'react-loading-skeleton';


const Editor = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);


    const fetchItems = async () => {
        try {
            const response = await axiosAuth.get('/get-alldata');
            setItems(response.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchItems();
    }, []);


    return (
        <div className="flex flex-col min-h-screen">
            <Sidebar >

                <div className="flex-1 p-8 bg-gray-100 overflow-auto">
                    <h1 className="text-2xl md:text-3xl font-bold text-slate-800 mb-4 md:mb-6">Editor</h1>
                    <div className="bg-white shadow-md rounded-lg p-6">

                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white border border-gray-200">
                                <thead>
                                    <tr className="bg-[#36154e] text-white">
                                        <th className="px-4 md:px-6 py-3 text-left">Type of Request</th>
                                        <th className="px-4 md:px-6 py-3 text-left">Code</th>
                                        <th className="px-4 md:px-6 py-3 text-left">Macros</th>
                                        <th className="px-4 md:px-6 py-3"></th>
                                    </tr>
                                </thead>
                                {loading ? (
                                    <tbody>
                                        {[...Array(24)].map((_, index) => (
                                            <tr key={index}>
                                                <td className="px-4 md:px-6 py-3">
                                                    <Skeleton />
                                                </td>
                                                <td className="px-4 md:px-6 py-3">
                                                    <Skeleton />
                                                </td>
                                                <td className="px-4 md:px-6 py-3">
                                                    <Skeleton />
                                                </td>
                                                <td className="px-4 md:px-6 py-3">
                                                    <Skeleton />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : items.length > 0 ? (
                                    <tbody className="divide-y divide-gray-200">
                                        {items.map((typeOfReq) => (
                                            <tr key={typeOfReq.id} className="hover:bg-gray-50">
                                                <td className="px-4 md:px-6 py-3">{typeOfReq?.title}</td>
                                                <td className="px-4 md:px-6 py-3">{typeOfReq?.code}</td>
                                                <td className="px-4 md:px-6 py-3">
                                                    {typeOfReq.related_room_information_codes ?? "-"}
                                                </td>
                                                <td className="px-4 md:px-6 py-3 flex space-x-2">
                                                    <Link
                                                        to={`/admin/question-list/${typeOfReq.related_room_information_codes}`}
                                                        className="text-blue-500 hover:text-blue-700 font-semibold"
                                                    >
                                                        <LiaEyeSolid size={'20px'} color="grey" />
                                                    </Link>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody className=" divide-blue-200  w-full border-2">
                                        <tr>
                                            <td
                                                colSpan={7}
                                                className="p-3 text-center italic text-gray-400 font-medium  text-xl"
                                            >
                                                No results found
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </Sidebar >

        </div >
    );
};

export default Editor;
