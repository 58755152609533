// ProgressBar.js
import React from "react";
import "./ProgressBar.css";

const ProgressBar = ({ currentStep, totalSteps }) => {
  const stepLabels = ["Claim Information Form", "General Information Form" /* add more labels as needed */];

  const calculateProgress = () => {
    return (currentStep / totalSteps) * 100;
  };

  return (
<div className="progress-bar-container p-5 " style={{ width: '100%'}}>
      <div className="step-labels">
        {stepLabels.map((label, index) => (
          <div
            key={index}
            className={`step-label ${currentStep === index + 1 ? "active" : ""}`}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
