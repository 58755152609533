// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ProgressBar.css */
.progress-bar-container {
    width: 100%;
    height: 20px;
    background-color: #ff070700;
    margin-bottom: 20px;
  }

  .step-labels {
    display: flex;
    justify-content: space-between;
    position: relative;
    left: 0;
    right: 0;
    gap: 0.75rem;
    
  }
  
  .step-label {
    flex: 1 1;
    text-align: center;
    font-size: 14px;
    color: #c4c4c4;
    padding:3px;
  }
  
  .step-label.active {
    color: #ffffff;
    font-display: bold;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/component/OtherComponents/ProgressBar.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,OAAO;IACP,QAAQ;IAIR,YAAW;;EAFb;;EAKA;IACE,SAAO;IACP,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,WAAW;EACb;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB","sourcesContent":["/* ProgressBar.css */\n.progress-bar-container {\n    width: 100%;\n    height: 20px;\n    background-color: #ff070700;\n    margin-bottom: 20px;\n  }\n\n  .step-labels {\n    display: flex;\n    justify-content: space-between;\n    position: relative;\n    left: 0;\n    right: 0;\n    \n  }\n  .step-labels{\n    @apply gap-3\n  }\n  \n  .step-label {\n    flex: 1;\n    text-align: center;\n    font-size: 14px;\n    color: #c4c4c4;\n    padding:3px;\n  }\n  \n  .step-label.active {\n    color: #ffffff;\n    font-display: bold;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
