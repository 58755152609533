import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Player } from "@lottiefiles/react-lottie-player";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {
  StringContentForDoubleDropdownMulti,
  tool_tip_multi_multi,
} from "../../lib/toolTipFunction";
import { Edit, TickCircle } from "iconsax-react";
import {
  utils_data_for_form,
  convert_array_to_object_for_dropdown_data_not_for_llama_form,
} from "../../lib/utils";
import loader from "./loader.json";

const DropdownMultiMultiMulti = ({
  id,
  label,
  type,
  options,
  response,
  setPreviousQuestion,
  previousQuestion,
  setInputResponse,
  setPresentQuestion,
  inputResponse,
  presentQuestion,
  option_groups,
  optionsChosenForCountry,
  setOptionChosenForCountry,
  last_question_for_part_one,
  loading,
  setLoading,
  setShow,
  setLastResponse,
  lastResponse,
}) => {
  const [rendered, setRendered, isRe] = useState(false);
  const { dropdown, second_values, third_values } = option_groups;
  const [main, setMain] = useState("Yes"); // Default value set to "Yes"
  const [className, setClassName] = useState();
  const [ok, setOk] = useState(false);

  const customStyles = utils_data_for_form.dropdown_style;

  const drop_down_option_main =
    convert_array_to_object_for_dropdown_data_not_for_llama_form(dropdown);
  const drop_down_option_secondary =
    convert_array_to_object_for_dropdown_data_not_for_llama_form(second_values);
  const drop_down_option_tertiary =
    convert_array_to_object_for_dropdown_data_not_for_llama_form(third_values);

  const handle_change_main = (e) => {
    let arr = inputResponse;
    let resp_arr = Array.isArray(e) ? e.map((val) => val.label) : [e.label];
    let value_no_boolean = Array.isArray(e) ? e.some((val) => val.label === "No") : e.label === "No";

    setMain(value_no_boolean ? "No" : "Yes");

    arr = arr.map((val) =>
      val.id === id
        ? { ...val, response: { main: resp_arr, done: value_no_boolean } }
        : val
    );

    if (value_no_boolean) {
      if (id !== "q71") {
        setPresentQuestion({
          ...previousQuestion[
          previousQuestion.indexOf(
            previousQuestion.find((val) => val.id === id)
          ) + 1
          ],
          response: null,
        });
      } else {
        if (isRe) setShow(true);
      }
    }

    setInputResponse([...arr]);
  };
  const handle_change_secondary = (e) => {

    let arr = inputResponse;
    let value_no_boolean = Array.isArray(e) ? e.some((val) => val.label === "No") : e.label === "No";

    arr = arr.map((val) =>
      val.id === id
        ? {
          ...val,
          response: {
            ...val.response,
            done: value_no_boolean,
            secondary: Array.isArray(e) ? e.map((val) => val.label) : [e.label],
          },
        }
        : val
    );

    setMain(value_no_boolean ? "No" : "Yes");

    setInputResponse([...arr]);
  };

  const handle_change_tertiary = (e) => {

    let arr = inputResponse;

    arr = arr.map((val) => {
      if (val.id === id) {
        let arr = Array.isArray(e) ? e.map((val) => val.label) : [e.label];
        val.response = { ...val.response, done: true, tertiary: arr };
        return val;
      } else {
        return val;
      }
    });

    if (ok) {
      setInputResponse([...arr]);
    }
  };

  const handleReset = () => {
    let arr = inputResponse;
    let index_search = arr.findIndex((val) => val.id === id);

    arr = arr
      .map((val, index) => {
        if (index === index_search) {
          val.response = {
            main: null,
            done: false,
            secondary: null,
            tertiary: null,
          };
          return val;
        } else if (index < index_search) {
          return val;
        }
      })
      .filter((val) => val !== undefined);

    setInputResponse([...arr]);
    setMain("Yes");
    setOk(!ok);
    setLastResponse(false);
  };

  useEffect(() => {
    if (id === "q70") {
      setRendered(true);
    }
  }, [id]);

  useEffect(() => {
    if (rendered) {
      // Execute your logic after q70 is rendered
    }
  }, [rendered]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="A1-dropdown ">
          {(response && response.done) || lastResponse ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: 5,
              }}
            >
              {
                <div
                  className={`A1-select-double-dropdown ${id}`}
                  onMouseEnter={(e) => {
                    setClassName(e.target.classList[1]);
                  }}
                >
                  <Tippy
                    content="Do you want to edit the data? Doing so, you will lose all your stored data."
                  // onClick={handleReset}
                  >
                    <Edit
                      size="20"
                      color="#2ccce4"
                      // onClick={handleReset}
                      style={{ padding: 4 }}
                    />
                  </Tippy>
                  <Select
                    options={drop_down_option_main}
                    onChange={handle_change_main}
                    styles={customStyles}
                    value={{
                      value: String(response.main).toLowerCase(),
                      label: response.main,
                    }}
                    isDisabled={true}
                  />
                  {response && response.secondary && (
                    <Select
                      options={drop_down_option_secondary}
                      onChange={handle_change_secondary}
                      styles={customStyles}
                      value={{
                        value: String(response.secondary).toLowerCase(),
                        label: response.secondary,
                      }}
                      isDisabled={true}
                    />
                  )}
                  {response && response.secondary && response.tertiary && (
                    <Select
                      options={drop_down_option_tertiary}
                      onChange={handle_change_tertiary}
                      styles={customStyles}
                      value={{
                        value: String(response.tertiary).toLowerCase(),
                        label: response.tertiary,
                      }}
                      isDisabled={true}
                    />
                  )}
                </div>
              }
            </div>
          ) : (
            <>
              {loading && (
                <div style={{ height: "4rem", width: "4rem" }}>
                  <Player
                    src={loader}
                    className="player"
                    loop
                    autoplay
                  />
                </div>
              )}
              {!loading && (
                <div className="A1-select-double-dropdown">
                  <Select
                    options={drop_down_option_main}
                    onChange={handle_change_main}
                    styles={customStyles}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {main && main !== "No" && (
                    <Select
                      options={drop_down_option_secondary}
                      onChange={handle_change_secondary}
                      styles={customStyles}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  )}
                  {main && main !== "No" && (
                    <Select
                      options={drop_down_option_tertiary}
                      onChange={handle_change_tertiary}
                      styles={customStyles}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  )}
                  <TickCircle size="32" color="#00cc00" onClick={() => {
                    setPresentQuestion({ ...previousQuestion[previousQuestion.indexOf(previousQuestion.find((val) => val.id == id)) + 1], response: null })
                  }} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DropdownMultiMultiMulti;
