import React,{useState,useEffect} from 'react'
import Select, { components } from "react-select";
import { utils_data_for_form, convert_array_to_object_for_dropdown_data_for_dynamically_rendered_component } from '../../lib/utils';
import '../ComponentTwo.css'



const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const CheckBox = ({ id, label, type, options, response, setPreviousQuestion, previousQuestion }) => {
  const [dropDownResponses, setDropDownResponses] = useState([])
  const [dropdown, setDropdown] = useState([])

  const customStyles = utils_data_for_form.dropdown_style;
  const drop_down_option = convert_array_to_object_for_dropdown_data_for_dynamically_rendered_component(options)


  useEffect(() => {
    if (dropDownResponses && dropDownResponses.length) {
      
      setPreviousQuestion((prev) => ([
        {
          id: id,
          label: label,
          type: type,
          options: options,
          response: dropDownResponses
        },
        ...prev,
      ]))
    }
  }, [dropDownResponses,dropDownResponses.length])

  useEffect(() => {
    let arr = []
    if(previousQuestion.length > 1){

      arr = previousQuestion[0]?.response
    }

    setDropdown([...arr])
  },[previousQuestion])

  const handle_change = (e) => {
    setDropDownResponses([...e])
  }

  return (
    <div className='A1-dropdown'>
      {
        response && response.length > 2 ? (
          <div className='A1-select'>
            <Select options={drop_down_option} onChange={handle_change} styles={customStyles} value={dropdown} isDisabled={true} isMulti />
          </div>
        ) : (
          <div className='A1-select'>
            <Select options={drop_down_option} onChange={handle_change} styles={customStyles} components={{
              Option
            }}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        )
      }
    </div>
  )
}

export default CheckBox
