import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { useHome } from "../../customHooks/useHome";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import companyLogo from "../../Logo/ripley-logo.png";
import { useAuth } from "../../customHooks/useAuth";

const HomePage = () => {
  const { data, loading } = useHome();
  const navigate = useNavigate();
  const { getUserDetails } = useAuth();
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const [showSpinner, setShowSpinner] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [userResponses, setUserResponse] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  useEffect(() => {
    let loadingTimeout; // Define loadingTimeout outside of the fetchData function

    const fetchData = async () => {
      try {
        loadingTimeout = setTimeout(() => {
          setShowSpinner(true);
        }, 1000);

        const token = localStorage.getItem("access_token");

        const userResponse = await getUserDetails(token);
        setUserResponse(userResponse);

        clearTimeout(loadingTimeout);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Cleanup function
    return () => clearTimeout(loadingTimeout);
  }, []);


  // console.log(userResponses);

  return (
    <Sidebar>
      <div className="border-2">
      </div>

      <div className="home-page-logo">
        <img
          src={companyLogo}
          alt="Company Logo"
          className="mx-auto mb-2"
          style={{ maxWidth: "180px", maxHeight: "150px" }}
        />
      </div>

      <div className="home-new-smart-scope-container">
        {data.length > 0 && <div
          className="cursor-pointer text-xl font-bold text-indigo-800"
          onClick={() => {
            navigate("/form");
          }}
        >
          <p className="home-new-smart-scope-btn">
            New Smart Scope
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#4c0f78"
              color="white"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-10 h-10 ml-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </p>
        </div>}
      </div>
      <div className="flex gap-4 justify-center items-center flex-wrap p-3 m-2">

        {loading ? (
          // Skeleton loader when data is loading

          /* Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="home-file-card">
                <Skeleton height={100} />
              </div>
            )) */
          <div className="flex justify-center items-center h-screen">
            <HashLoader
              size={40}
              color="#1B2559"
              loading={true}
              css="margin: 0 auto;"
            />
          </div>
        ) : (
          data.length > 0 ? (
            data.map((item, i) => (

              <div
                key={i}
                className="home-file-card my-3 mx-13  h-fit"
                onClick={() => {
                  navigate(`/individual-file/${item.id}`);
                }}
              >
                <div className="whitespace-nowrap text-[0.75rem] p-[5px] w-[80%] ">
                  {/* <p className="mb-0">
                  <strong className="mr-[0.3rem]">File Number:</strong>
                  <span>{item.file_number}</span>
                </p> */}
                  <p className="mb-0">
                    <strong className="mr-[0.3rem]">Full Name:</strong>
                    <span>{item.full_name}</span>
                  </p>
                  <p className="mb-0 flex">
                    <strong className="mr-[0.3rem]">Address:</strong>
                    <span className="inline-block w-[12rem] truncate">
                      <span>{item.address}</span>
                    </span>
                  </p>
                  {/* <p className="mb-0">
                    <strong className="mr-[0.3rem]">City:</strong> {item.city}
                  </p> */}
                  <p className="mb-0">
                    <strong className="mr-[0.3rem] ">Date:</strong>
                    <span>{formatDate(item.created_at)}</span>
                  </p>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faLayerGroup}
                    className="text-[#4c0f78]"
                  />
                </div>
              </div>
            ))
          ) :
            <div
              className=" flex justify-center w-full h-full items-center text-2xl font-bold text-indigo-800"

            >
              <div className="home-new-smart-scope-btn flex flex-col  justify-center cursor-pointer" onClick={() => {
                navigate("/form");
              }}>
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#724CF9"
                    color="white"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-10 h-10 ml-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  New Smart Scope
                </div>

                <div className="italic text-gray-500 text-sm">
                  No Macros found
                </div>
              </div>
            </div>
        )}
      </div>
    </Sidebar>
  );
};

export default HomePage;
