import { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar";
import { axiosAuth } from "../../../../lib/axiosInstance";
import Skeleton from "react-loading-skeleton";
import Modal from "react-modal";
import { AiFillEdit } from "react-icons/ai";

Modal.setAppElement("#root");

const ClaimInfo = () => {
  const [claimFields, setClaimFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [formData, setFormData] = useState({
    label: "",
    type: "",
    options: [],
    optionGroups: [],
    required: false,
  });
  const [newOption, setNewOption] = useState("");
  const [newGroupCondition, setNewGroupCondition] = useState("");
  const [newGroupOptions, setNewGroupOptions] = useState([""]);

  useEffect(() => {
    fetchClaimFields();
  }, []);

  const fetchClaimFields = async () => {
    try {
      const response = await axiosAuth.get("/get-claim-info-data");
      const formattedFields = response.data.fields.map((field) => {
        let options = [];
        let optionGroups = [];
        try {
          const parsed = field.options ? JSON.parse(field.options) : {};
          if (Array.isArray(parsed.options)) options = parsed.options;
          if (Array.isArray(parsed.option_groups)) optionGroups = parsed.option_groups;
        } catch {}
        return {
          ...field,
          options,
          optionGroups,
        };
      });
      setClaimFields(formattedFields);
    } catch (error) {
      console.error("Error fetching claim fields:", error);
    } finally {
      setLoading(false);
    }
  };

  const openModal = (field) => {
    setSelectedField(field);
    setFormData({
      label: field.label,
      type: field.type,
      options: field.options || [],
      optionGroups: field.optionGroups || [],
      required: !!field.required,
    });
    setNewOption("");
    setNewGroupOptions([""]);
    setNewGroupCondition(field.optionGroups?.[0]?.conditions?.value || "");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedField(null);
    setFormData({ label: "", type: "", options: [], optionGroups: [], required: false });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const addOption = () => {
    if (newOption.trim()) {
      const optionObj = typeof newOption === 'string' ? { key: newOption.toLowerCase(), value: newOption } : newOption;
      setFormData({ ...formData, options: [...formData.options, optionObj] });
      setNewOption("");
    }
  };

  const removeOption = (index) => {
    const updated = [...formData.options];
    updated.splice(index, 1);
    setFormData({ ...formData, options: updated });
  };

  const handleGroupOptionChange = (index, value) => {
    const updated = [...newGroupOptions];
    updated[index] = value;
    setNewGroupOptions(updated);
  };

  const addGroupOption = () => {
    setNewGroupOptions([...newGroupOptions, ""]);
  };

  const removeGroupOption = (index) => {
    const updated = [...newGroupOptions];
    updated.splice(index, 1);
    setNewGroupOptions(updated);
  };

  const addOptionGroup = () => {
    const newOptions = newGroupOptions
      .filter((opt) => opt.trim())
      .map((opt) => ({ key: opt.toLowerCase(), value: opt }));

    const groupIndex = formData.optionGroups.findIndex(
      (g) => g.conditions?.value === newGroupCondition
    );

    if (groupIndex !== -1) {
      const updatedGroups = [...formData.optionGroups];
      const existingKeys = new Set(updatedGroups[groupIndex].options.map((o) => o.key));
      const mergedOptions = [
        ...updatedGroups[groupIndex].options,
        ...newOptions.filter((opt) => !existingKeys.has(opt.key)),
      ];
      updatedGroups[groupIndex].options = mergedOptions;
      setFormData({ ...formData, optionGroups: updatedGroups });
    }

    setNewGroupOptions([""]);
  };

  const handleSave = async () => {
    if (!selectedField) return;

    const formattedOptions = JSON.stringify({
      options: formData.options.length ? formData.options : undefined,
      option_groups: formData.optionGroups.length ? formData.optionGroups : undefined,
    });

    const updatedData = {
      label: formData.label,
      type: formData.type,
      options: formattedOptions,
      required: formData.required,
    };

    try {
      await axiosAuth.put(`/update-claim/${selectedField.id}`, updatedData);
      setClaimFields(
        claimFields.map((field) =>
          field.id === selectedField.id ? { ...field, ...updatedData } : field
        )
      );
      closeModal();
    } catch (err) {
      console.error("Error updating:", err);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Sidebar>
        <div className="flex-1 p-8 bg-gray-100 max-h-screen overflow-scroll">
          <div className="bg-white shadow-md rounded-lg p-6">
            <div className="text-2xl font-bold mb-4">Claim Field Editor</div>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border">
                <thead>
                  <tr className="bg-[#36154e] text-white text-left">
                    <th className="px-4 py-2">Field</th>
                    <th className="px-4 py-2">Type</th>
                    <th className="px-4 py-2">Required</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="5">
                        <Skeleton count={5} />
                      </td>
                    </tr>
                  ) : (
                    claimFields.map((field) => (
                      <tr key={field.id} className="hover:bg-gray-50">
                        <td className="px-4 py-3">{field.label}</td>
                        <td className="px-4 py-3">{field.type}</td>
                        <td className="px-4 py-3">{field.required ? "Yes" : "No"}</td>
                        <td className="px-4 py-3">
                          <button
                            onClick={() => openModal(field)}
                            className="text-green-500 hover:text-green-700 font-semibold"
                          >
                            <AiFillEdit />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Sidebar>

      {isModalOpen && selectedField && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded shadow-md w-full max-w-3xl overflow-y-auto max-h-[90vh]">
            <h2 className="text-xl font-bold mb-4">Edit Field: {selectedField.label}</h2>

            <label className="block text-gray-700">Label</label>
            <input
              type="text"
              name="label"
              value={formData.label}
              onChange={handleInputChange}
              className="w-full border p-2 rounded mb-4"
            />

            <label className="block text-gray-700">Type</label>
            <select
              name="type"
              value={formData.type}
              onChange={handleInputChange}
              className="w-full border p-2 rounded mb-4"
            >
              <option value="text">Text</option>
              <option value="radio">Radio</option>
              <option value="checkbox">Checkbox</option>
              <option value="dropdown">Dropdown</option>
              <option value="date">Date</option>
            </select>

            {formData.options.length > 0 && (
              <>
                <label className="block text-gray-700">Flat Options</label>
                {formData.options.map((opt, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <span className="mr-2">{typeof opt === 'string' ? opt : opt.value}</span>
                    <button onClick={() => removeOption(index)} className="text-red-500">❌</button>
                  </div>
                ))}
                <input
                  value={newOption}
                  onChange={(e) => setNewOption(e.target.value)}
                  className="w-full border p-2 rounded mb-2"
                  placeholder="Add option"
                />
                <button onClick={addOption} className="bg-green-500 text-white px-4 py-2 rounded mb-4">
                  Add Option
                </button>
              </>
            )}

            {formData.optionGroups.length > 0 && (
              <>
                <label className="block text-gray-700">Select Option Group Condition</label>
                <select
                  className="w-full border p-2 rounded mb-2"
                  value={newGroupCondition}
                  onChange={(e) => setNewGroupCondition(e.target.value)}
                >
                  {formData.optionGroups.map((group, idx) => (
                    <option key={idx} value={group.conditions.value}>{group.conditions.value}</option>
                  ))}
                </select>

                {newGroupOptions.map((opt, idx) => (
                  <div key={idx} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={opt}
                      onChange={(e) => handleGroupOptionChange(idx, e.target.value)}
                      className="w-full border p-2 rounded"
                      placeholder={`Option ${idx + 1}`}
                    />
                    <button onClick={() => removeGroupOption(idx)} className="ml-2 text-red-500">❌</button>
                  </div>
                ))}
                <button onClick={addGroupOption} className="bg-blue-500 text-white px-4 py-1 rounded mr-2">
                  + Add Group Option
                </button>
                <button onClick={addOptionGroup} className="bg-purple-500 text-white px-4 py-1 rounded">
                  ✅ Add to Group
                </button>
              </>
            )}

            {formData.optionGroups.map((group, index) => (
              <div key={index} className="border p-2 my-2 rounded bg-gray-50">
                <div className="text-sm font-semibold mb-1">
                  Condition: <span className="italic">{group.conditions.value}</span>
                </div>
                {group.options.map((opt, idx) => (
                  <div key={idx} className="text-sm">• {opt.value}</div>
                ))}
              </div>
            ))}

            <div className="mt-6">
              <button onClick={closeModal} className="bg-gray-300 px-4 py-2 rounded mr-2">
                Cancel
              </button>
              <button onClick={handleSave} className="bg-[#4c0f78] text-white px-4 py-2 rounded">
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClaimInfo;
