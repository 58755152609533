import React, { useState, useEffect } from "react";
import { AiOutlineUser } from "react-icons/ai";
import Sidebar from "../Sidebar";
import { axiosAuth } from "../../lib/axiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { Edit, UserEdit } from "iconsax-react";

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isError, setErrorMsg] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState(" ");

  const [companies, setCompanies] = useState([]);

  const fetchCompaniesDetails = async () => {
    try {
      const {data} = await axiosAuth.get('/companies');
   console.log(data.companies);
      setCompanies(data.companies);
    } catch (error) {
      console.error(error);
      setCompanies([]); // Ensure companies is an array even if the fetch fails
    }
  };

  useEffect(() => {
    fetchCompaniesDetails();
  }, []);
  const [formData, setFormData] = useState({
    id: null,
    name: "",
    email: "",
    date_of_birth: "",
    companies_id: "",
    location: "",
    contact_number: "",
    address: "",
    profile_photo: null,
  });

  const fetchUser = async () => {
    try {
      const response = await axiosAuth.get("/user");
      setFormData(response.data.user);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "profile_photo") {
      setFormData({
        ...formData,
        profile_photo: files[0], // Update profile photo separately
      });
      // Update image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          profile_photo: files[0],
        });
      };
      if (files[0]) {
        reader.readAsDataURL(files[0]);
      }
    } else {
      if (name == "contact_number") {
        const isContact = /^\+?[0-9 -]*$/.test(value);
        setErrorMsg(!isContact);
        setErrorMsgName("contact");
      }

      if (name == "name") {
        const isValid = /^[a-zA-Z ]+$/.test(value);
        setErrorMsg(!isValid);
        setErrorMsgName("name");
      }

      setFormData({
        ...formData,
        [name]: value, // Update other fields
      });
    }
  };
  const cancelEditing=(e)=>{
    e.preventDefault();
    setIsEditing(false)
  }
  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditing(!isEditing);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataUpload = new FormData();
      if (formData["confirm_password"] != formData["password"]) {
        toast.error("Password and Confirm Password doesn't match");
        return;
      }
      for (const key in formData) {
        // Only append non-null values to FormData
        if (formData[key] !== null) {
          formDataUpload.append(key, formData[key]);
        }
      }
      const res = await axiosAuth.post(
        `/user/${formData?.id}`,
        formDataUpload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status == 200) {
        toast.success("Profile Successfully Updated");
        setIsEditing(false);
        fetchUser();
      } else {
        toast.error("Something Went Wrong!");
      }
    } catch (error) {
      toast.error(
        error.response.data.error
          ? error.response.data.error
          : error.response.data?.message
      );
    }
  };

  const formatISODate = (isoDateString) => {
    if (!isoDateString) return ""; // Return empty string if isoDateString is null or undefined

    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <Sidebar>
      <div className="bg-gray-100 min-h-screen flex justify-center items-center">
        <div
          className="md:max-w-lg mt-24 lg:mt-0 max-w-full w-full bg-white shadow-md rounded-lg overflow-hidden mx-4 p-6"
          style={{ position: "relative" }}
        >
          {!isEditing && (
            <div className="w-full justify-end flex">
              <button
                className="text-white  font-bold rounded mr-2 focus:outline-none transition duration-300 ease-in-out transform hover:-translate-y-1"
                onClick={handleEdit}
              >
                <Edit size="24" color="#000000" />
              </button>
            </div>
          )}
          <div className="flex items-center justify-center mb-6">
            <div className="relative">
              <img
                src={`https://ui-avatars.com/api/?name=${formData?.name}&background=0D8ABC&color=fff&rounded=true&bold=true`}
                alt="Profile"
                className="w-16 h-16 rounded-full"
              />
            </div>
          </div>
          <div className="text-center mb-6">
            {!isEditing && (
              <h2 className="text-3xl font-semibold text-gray-800">
                {formData?.name}
              </h2>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="block sm:grid sm:grid-cols-2 gap-4 w-full">
              {isEditing && (
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    className={`input outline-none ${
                      isEditing
                        ? "border rounded-md px-3 py-2 md:w-fit w-full"
                        : "read-only"
                    }`}
                    id="name"
                    name="name"
                    type="text"
                    placeholder="John Doe"
                    value={formData?.name}
                    onChange={handleChange}
                    readOnly={!isEditing}
                    pattern="^[a-zA-Z ]+$"
                    title="Only Alphabet Letters"
                  />
                  {errorMsgName === "name" ? (
                    <span className="text-red-700">
                      {isError ? "Invalid name" : " "}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className={`input outline-none ${
                    isEditing
                      ? "border rounded-md px-3 py-2 md:w-fit w-full"
                      : "read-only"
                  }`}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="example@example.com"
                  value={formData?.email}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div>
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="date_of_birth"
                >
                  Date of Birth
                </label>
                <input
                  className={`input outline-none ${
                    isEditing
                      ? "border rounded-md px-3 py-2  w-full"
                      : "read-only"
                  }`}
                  id="date_of_birth"
                  name="date_of_birth"
                  type="date"
                  value={formatISODate(formData?.date_of_birth)}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div>
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="location"
                >
                  Company Name/Location
                </label>
                {console.log(formData)}
                {isEditing ? (
                  <select
                    className="input outline-none border rounded-md px-3 py-2 md:w-fit w-full"
                    id=""
                    name="companies_id"
                    onChange={handleChange}
                    value={formData.companies_id}
                  >
                    <option value="">Select Company</option>
                    {companies.length > 0 ? (
                      companies.map((company) => (
                        <option key={company.id} value={company.id}>
                          {company.name}
                        </option>
                      ))
                    ) : (
                      <option>Loading companies...</option>
                    )}
                  </select>
                ) : (
                  <input
                    className="input outline-none read-only"
                    id="company_name"
                    name="company_name"
                    type="text"
                    value={formData?.company_name}
                    readOnly={true}
                  />
                )}
              </div>
              {/* <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="location"
                >
                  Location
                </label>
                <input
                  className={`input outline-none ${
                    isEditing
                      ? "border rounded-md px-3 py-2 md:w-fit w-full"
                      : "read-only"
                  }`}
                  id="location"
                  name="location"
                  type="text"
                  placeholder="City, Country"
                  value={formData?.location}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div> */}
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="contact"
                >
                  Contact Number
                </label>

                <input
                  className={`input outline-none ${
                    isEditing
                      ? "border rounded-md px-3 py-2 md:w-fit w-full"
                      : "read-only"
                  }`}
                  id="contact"
                  name="contact_number"
                  type="tel"
                  placeholder="123-456-7890"
                  maxLength="13"
                  value={formData?.contact_number}
                  onChange={handleChange}
                  readOnly={!isEditing}
                  pattern="^\+?[0-9 -]*$"
                  title="Please enter only numbers and the plus symbol (+)"
                />
                {errorMsgName === "contact" ? (
                  <span className="text-red-700">
                    {isError ? "Invalid Contact" : " "}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="col-span-2">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="address"
                >
                  Address
                </label>
                <input
                  className={`input w-full outline-none ${
                    isEditing
                      ? "border rounded-md px-3 py-2  w-full"
                      : "read-only"
                  }`}
                  id="address"
                  name="address"
                  placeholder="123 Street, City, Country"
                  value={formData?.address}
                  onChange={handleChange}
                  readOnly={!isEditing}
                />
              </div>

              {/* Password section */}

              {isEditing && (
                <div className=" w-full text-xl font-medium pt-3  pb-1">
                  Update Password
                </div>
              )}
              {isEditing ? (
                <div className="col-span-2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="oldpassword"
                  >
                    Old Password
                  </label>
                  <input
                    type="password"
                    className={
                      "input w-full outline-none border rounded-md px-3 py-2"
                    }
                    id="old_password"
                    name="old_password"
                    placeholder="Old Password"
                    onChange={handleChange}
                  />
                </div>
              ) : (
                ""
              )}
              {isEditing ? (
                <div className="col-span-2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="old_password"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    className={
                      "input w-full outline-none border rounded-md px-3 py-2"
                    }
                    id="password"
                    name="password"
                    placeholder="New Password"
                    onChange={handleChange}
                  />
                </div>
              ) : (
                ""
              )}

              {isEditing ? (
                <div className="col-span-2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="old_password"
                  >
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    className={
                      "input w-full outline-none border rounded-md px-3 py-2"
                    }
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="New Password"
                    onChange={handleChange}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <div className="flex justify-end mt-6">
              {isEditing && (
                <button
                  className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded mr-2 focus:outline-none transition duration-300 ease-in-out transform hover:-translate-y-1"
                  type="submit"
                >
                  Submit
                </button>
              )}
            </div> */}

            <div className="flex justify-between mt-6">
              {isEditing && (
                <>
                  <button
                    className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none transition duration-300 ease-in-out transform hover:-translate-y-1"
                    type="button"
                    onClick={cancelEditing}
                  >
                    Cancel
                  </button>

                  <button
                    className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded mr-2 focus:outline-none transition duration-300 ease-in-out transform hover:-translate-y-1"
                    type="submit"
                  >
                    Submit
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </Sidebar>
  );
};

export default Profile;
