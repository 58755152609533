import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHome } from "../../customHooks/useHome";
import { AddCircle, Refresh, Clock } from "iconsax-react";
import Sidebar from "../Sidebar";
import ResultComponent from "../ResultComponent";
import { HashLoader } from "react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTools, faBoxes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import { axiosAuth } from "../../lib/axiosInstance";

import throttle from 'lodash/throttle';
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import  Oval  from 'react-loader-spinner'; 
import 'react-toastify/dist/ReactToastify.css';
const AccordionSection = ({ title, content, defaultExpanded }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };




  return (
    <div className="border border-solid rounded-lg overflow-hidden mb-1 w-full mx-[1rem] ">
      <input type="checkbox" id={`accordion-${title}`} className="hidden" />
      <label
        htmlFor={`accordion-${title}`}
        className="accordion-header individual-file-accordion"
        onClick={toggleAccordion}
      >
        <span>{title}</span>
        <span
          className={`accordion-toggle w-6 h-6 flex items-center justify-center transform transition-transform duration-[.4s] ${isOpen ? "rotate-180" : ""
            }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </span>
      </label>
      <div
        className={`accordion-body bg-[#ffffff] overflow-hidden  w-full transition-all duration-[.4s] ${isOpen ? "max-h-[500px]  overflow-y-auto" : "max-h-0"
          }`}
        aria-hidden={!isOpen}
      >
        <div className="p-[1rem]">{content}</div>
      </div>
    </div>
  );
};
const RoomCard = ({ room, onRoomClick }) => {
  const getTimeDifferenceLabel = (timestamp) => {
    const creationDate = moment(timestamp).tz('UTC').format('MM/DD/YYYY');
    return creationDate;
    const currentDate = new Date();
  };

  return (
    <div
      onClick={onRoomClick}
      className="relative w-full bg-gray-100 rounded-lg overflow-hidden shadow-md hover:shadow-xl transition duration-300  md:w-[17rem] xl:w-80 cursor-pointer"
    >
      <div className="p-10 h-lg">
        <h5 className="text-[#7e84a3] text-lg font-semibold mb-2 text-center break-words">{room.room_name}</h5>

        <div className="text-gray-800 flex gap-1 text-xs text-center absolute bottom-0 right-0 mt-1 -mr-3  font-medium p-1 px-2 pr-4  rounded-tl-xl  ">
          {getTimeDifferenceLabel(room.created_at)}
        </div>
        {room.status == 'pending' && <div className="text-slate-100 font-medium capitalize italic flex gap-1 text-xs text-center absolute top-0 left-0  -mr-3 bg-[#4c0f78] p-1 px-2 pr-4  rounded-br-xl  ">
          {room.status}
        </div>}
      </div>
    </div>
  );
};
const IndivisualFile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showResultModal, setShowResultModal] = useState(false);
  const { loading, indivisualData, fetchIndivisualClaim } = useHome();
  const [roomId, setRoomId] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const [roomName, setRoomName] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [loader, setLoader] = useState(false);
  const [roomStatus, setRoomStatus]=useState()

  
  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setShowSpinner(false);
    }, 1000);

    return () => clearTimeout(loadingTimeout);
  }, []);

  useEffect(() => {
    fetchIndivisualClaim(id);
  }, []);

  const renderIcon = (title) => {

    if (title.includes("Emergency") && title.includes("Rebuild") && title.includes("Contents")) {
      return <FontAwesomeIcon icon={faExclamationTriangle} />;
    } else if (title.includes("Emergency") && title.includes("Contents")) {
      return <FontAwesomeIcon icon={faBoxes} />;
    } else if (title.includes("Emergency") && title.includes("Rebuild")) {
      return <FontAwesomeIcon icon={faTools} />;
    } else if (title.includes("Rebuild")) {
      return <FontAwesomeIcon icon={faTools} />;
    } else if (title.includes("Emergency")) {
      return <FontAwesomeIcon icon={faExclamationCircle} />;
    } else {
      return null; // No icon matched
    }
  };

  const getBgColor = (title) => {
    if (title.includes("Emergency") && title.includes("Rebuild") && title.includes("Contents")) {
      return "bg-gradient-to-r from-red-200 to-green-200";
    } else if (title.includes("Emergency") && title.includes("Contents")) {
      return "bg-green-200";
    } else if (title.includes("Emergency") && title.includes("Rebuild")) {
      return "bg-yellow-200";
    } else if (title.includes("Rebuild")) {
      return "bg-yellow-200";
    } else if (title.includes("Emergency")) {
      return "bg-red-200";
    } else {
      return ""; // No background color matched
    }
  };

  const handleEmailSend = async () => {
    
    try {
      setLoader(true);
      const roomIds = Object.keys(indivisualData.room_information).map(key => indivisualData.room_information[key].id);
      console.log('Room IDs:', roomIds);


      const { data } = await axiosAuth.post('/send-room-emails', { roomIds: roomIds });
    
      toast.success('Email sent successfully!');
    } catch (error) {

      toast.error('Failed to send emails.');
    }
    finally {
      setLoader(false); 
    }
  };

  const throttledHandleEmailSend = useCallback(throttle(handleEmailSend, 2000, { leading: true, trailing: false }), [indivisualData]);


  return (
    <Sidebar>
      {showResultModal && (
        <div
          onClick={() => setShowResultModal(false)}
          className="block w-[100vw] h-[100vh] fixed top-0 left-0 z-[1]"
        >
          <div className="individual-file-room-modal">
            <ResultComponent
              setShowResultModal={setShowResultModal}
              roomId={roomId}
              roomStatus={roomStatus}
              roomName={roomName}
              requestType={requestType}
              pending={isPending}
            />
          </div>
        </div>
      )}
      {showSpinner && (
        <div className="flex justify-center items-center h-screen">
          <HashLoader
            size={40}
            color="#1B2559"
            loading={showSpinner}
            css="margin: 0 auto;"
          />
        </div>
      )}
      <div className="flex flex-col items-start">
        {indivisualData && (
          <div className="individual-file-container">
            <div className="flex flex-col items-center flex-wrap justify-between">
              <div className="w-full flex flex-col justify-between gap-[2rem] items-center rounded-[5px]">
                <AccordionSection
                  title="Property and Owner Details"
                  defaultExpanded={true} // Always keep it in the open state
                  content={
                    <>
                      <div className="text-xs flex flex-wrap gap-1">
                        <strong>Client Name:</strong>
                        <span>{indivisualData.claim_information.full_name}</span>
                      </div>
                      <div className="text-xs flex flex-wrap gap-1">
                        <strong>Email:</strong>
                        <span>{indivisualData.claim_information.email}</span>
                      </div>
                      <div className="text-xs flex flex-wrap gap-1">
                        <strong>Loss Address:</strong>
                        <span>{indivisualData.claim_information.address}</span>
                      </div>
                      <div className="text-xs flex flex-wrap gap-1">
                        <strong>Postal Code:</strong>
                        <span>{indivisualData.claim_information.postal_code}</span>
                      </div>
                      <div className="text-xs flex flex-wrap gap-1">
                        <strong>City:</strong>
                        <span>{indivisualData.claim_information.city}</span>
                      </div>
                      <div className="text-xs flex flex-wrap gap-1">
                        <strong>State/Region:</strong>
                        <span>{indivisualData.claim_information.region}</span>
                      </div>
                    </>
                  }
                />

                <AccordionSection
                  title="Claim Information"
                  defaultExpanded={true} // Always keep it in the open state
                  content={
                    <>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Carrier Name:</strong>
                        <span>{indivisualData.claim_information.carrier_name}</span>
                      </div>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Carrier Adjuster:</strong>
                        <span>{indivisualData.claim_information.claim_adjuster}</span>
                      </div>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Claim Number:</strong>
                        <span>{indivisualData.claim_information.claim_number}</span>
                      </div>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Policy Number:</strong>
                        <span>{indivisualData.claim_information.policy_number}</span>
                      </div>
                    </>
                  }
                />

                <AccordionSection
                  title="General Information"
                  defaultExpanded={true} // Always keep it in the open state
                  content={
                    <>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Type of Request:</strong>
                        <span>{indivisualData.claim_information.type_of_request}</span>
                      </div>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Outside of Service Area:</strong>
                        <span>
                          {indivisualData.claim_information.is_outside_of_service_area
                            ? "Yes"
                            : "No"}
                        </span>
                      </div>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Emergency Profit:</strong>
                        <span>{indivisualData.claim_information.emergency_profit}</span>
                      </div>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Emergency Overhead:</strong>
                        <span>{indivisualData.claim_information.emergency_overhead}</span>
                      </div>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Reconstruction Profit:</strong>
                        <span>{indivisualData.claim_information.reconstruction_profit}</span>
                      </div>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Reconstruction Overhead:</strong>
                        <span>{indivisualData.claim_information.reconstruction_overhead}</span>
                      </div>
                    </>
                  }
                />
              </div>
            </div>
            <hr className="mt-8" />
            <header className="flex flex-wrap sm:flex-row justify-between items-center mt-[20px]">
              <p className="font-medium lg:text-[1.3rem] md:text-[1.2rem] text-[1.1rem] text-[#1B2559] mr-[1rem]">
                Macros
              </p>
              <div>
            <button
                onClick={throttledHandleEmailSend}
                className="relative flex items-center justify-center w-32 h-12 px-4 py-2 bg-[#4c0f78] text-white font-medium rounded-md"
                disabled={loading}
            >
                {loader ? (
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="w-6 h-6 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
                    </div>
                ) : (
                    'Send Mail'
                )}
            </button>
        </div>
    
              {/* <ToastContainer /> */}
              <div
                key={id}
                className="cursor-pointer py-2 "
                onClick={() => {
                  navigate(`/individual-room/${id}?type_of_request=${encodeURIComponent(indivisualData.claim_information.type_of_request)}`);
                }}
              >

                <div className="flex items-center text-sm font-medium">
                  Add a Room/Area
                  <AddCircle
                    size="32"
                    color="#4c0f78"
                    variant="Bold"
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              </div>
            </header>
            <div className="grid">


              <div className="grid 2xl:grid-cols-3 md:grid-cols-2  gap-4 bg-white-400 p-4  rounded-md ">

                {Object.keys(indivisualData.room_information).map((item, index) => (
                  <div>
                  
                    <RoomCard
                      key={index}
                      room={indivisualData.room_information[item]}
                      onRoomClick={() => {
                        setShowResultModal(true);
                        setRoomId(indivisualData.room_information[item].id);
                        setRoomName(indivisualData.room_information[item].room_name);
                        setRoomStatus(indivisualData.room_information[item].status)
                        setRequestType(indivisualData.room_information[item].request_type);
                        setIsPending(indivisualData.room_information[item].status === 'pending');
                      }}

                    />
                  </div>
                ))}

              </div>
            </div>

          </div>
        )}
      </div>
    </Sidebar>
  );
};

export default IndivisualFile;
