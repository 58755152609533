import "./App.css";
import FormTwo from "./component/FormTwo";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import NotFound from "./component/pages/NotFound";
import Login from "./component/pages/Login";
import PrivateRoute from "./component/loginRouteComponent/PrivateRoute";
import { useState, useEffect } from "react";
import FormOutput from "./component/pages/FormOutput";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./component/pages/HomePage";
import IndivisualFile from "./component/pages/IndivisualFile";
import IndivisualRooms from "./component/pages/IndivisualRooms";
import EditIndividualRoom from "./component/pages/EditIndividualRoom";
import Profile from "./component/pages/Profile";
import Register from "./component/pages/Register";
import ForgotPassword from "./component/pages/ForgotPassword";
import Dashboard from "./component/pages/admin/Dashboard";
import Users from "./component/pages/admin/Users";
import Surveys from "./component/pages/admin/Surveys";
import useCookies from "./customHooks/useCookies";
import Companies from "./component/pages/admin/Companies";
import Editor from "./component/pages/admin/Editor/Editor";
import QuestionList from "./component/pages/admin/Editor/QuestionList";
import DropdownList from "./component/pages/admin/Editor/DropdownList";
import GeneralInfo from "./component/pages/admin/generalAndClaimInfo/GeneralInfo";
import ClaimInfo from "./component/pages/admin/generalAndClaimInfo/ClaimInfo";

function App() {
  const navigate = useNavigate();
  const { getCookie } = useCookies();
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem("authenticated"))
  );
  const [outPutPage, setOutPutPage] = useState(false);
  const location = useLocation();
  const currentYear = new Date().getFullYear();
  const userRole = getCookie("user_role");
  useEffect(() => {
    if (
      !isLoggedIn &&
      !["/login", "/register", "/forgot-password"].includes(location.pathname)
    ) {
      navigate("/login"); // Redirect to login page if not logged in and trying to access other pages
    }
  }, [isLoggedIn, navigate, location.pathname]);

  return (
    <div className="App relative">
      <ToastContainer />
      <Routes>
        <Route
          path="/form"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <FormTwo
                setIsLoggedIn={setIsLoggedIn}
                setOutPutPage={setOutPutPage}
                outPutPage={outPutPage}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/output"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <FormOutput />
            </PrivateRoute>
          }
        />
        <Route
          path="/login"
          element={
            !isLoggedIn ? (
              <Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
            ) : (
              <Navigate to="/home" />
            )
          }
        />
        {/* <Route path="/register" element={!isLoggedIn ? <Register /> : <Navigate to="/home" />} /> */}
        <Route
          path="/forgot-password"
          element={!isLoggedIn ? <ForgotPassword /> : <Navigate to="/home" />}
        />
        <Route
          path="/home"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/dashboard"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn} requiredRole={['admin', 'company-admin']}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn} requiredRole={['admin', 'company-admin']}>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/companies"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn} requiredRole={['admin']}>
              <Companies />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/surveys"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn} requiredRole={['admin', 'company-admin']}>
              <Surveys />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/editor"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn} requiredRole={['admin']}>
              <Editor />
            </PrivateRoute>
          }
        />
         <Route
          path="/admin/general-info-editor"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn} requiredRole={['admin']}>
              <GeneralInfo />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/claim-info-editor"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn} requiredRole={['admin']}>
              <ClaimInfo />
            </PrivateRoute>
          }
        />
        <Route
          path="admin/question-list/:code"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn} requiredRole={['admin']}>
              <QuestionList />
            </PrivateRoute>
          }
        />
        <Route
          path="admin/dropdown-list/:code"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn} requiredRole={['admin']}>
              <DropdownList />
            </PrivateRoute>
          }
        />
        {/* /question-list/${typeOfReq.code} */}


        <Route
          path="/profile"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/individual-file/:id"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <IndivisualFile />
            </PrivateRoute>
          }
        />
        <Route
          path="/individual-room/:id"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <IndivisualRooms
                setIsLoggedIn={setIsLoggedIn}
                setOutPutPage={setOutPutPage}
                outPutPage={outPutPage}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/individual-room/edit/:id"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <EditIndividualRoom
                setIsLoggedIn={setIsLoggedIn}
                setOutPutPage={setOutPutPage}
                outPutPage={outPutPage}
              />
            </PrivateRoute>
          }
        />
        {/* Redirect logged-in users from the root URL to home */}
        <Route
          path="/"
          element={
            isLoggedIn ? (
              userRole == "admin" || userRole == "company-admin" ? (
                <Navigate to="/admin/dashboard" />
              ) : (
                <Navigate to="/home" />
              )
            ) : (
              <Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
            )
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
