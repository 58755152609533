import React from "react";
import "./ProgressBar.css";

const ProgressBar = ({ currentStep, totalSteps, setCurrentStep }) => {
  const stepLabels = ["Claim Information Form", "General Information Form"];

  return (
    <div className="pt-5 flex items-center justify-center p-5 bg-gray-100 sticky top-0 z-50">
        <div
          className="col-span-full md:col-span-2 gap-5 lg:mt-14 mt-20 xl:mt-5 lg:col-span-3 bg-white sm:p-6 py-10 pt-0 rounded-lg
                w-full xl:w-1/2 2xl:w-1/2"
        >
          <div className="step-labels flex justify-around">
        {stepLabels.map((label, index) => (
          <div
            key={index}
            className={`step-label cursor-pointer ${currentStep === index + 1 ? "active" : ""}`}
            onClick={() => {
              if (index === 0) setCurrentStep(1); // Go to Claim Information Form when clicked
            }}
          >
            {label}
          </div>
        ))}
      </div>
    
         
        </div>
      </div>
      
  );
};

export default ProgressBar;
