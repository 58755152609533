export const convert_array_to_object_for_dropdown_data = (input_array) => {
    const result_obj = {}
    input_array.forEach(element => {
        result_obj[element] = element.split(" ").join("-").toLowerCase()
    })

    return result_obj
}

export const convert_array_to_object_for_dropdown_data_not_for_llama_form = (input_array) => {
  const result_array = [];

  // Check if the input is an array with key-value pairs
  // if (Array.isArray(input_array) && input_array.length > 1 && input_array.length % 2 === 0) {

  //   for (let i = 0; i < input_array.length; i += 2) {
  //     const key = input_array[i];
  //     const value = input_array[i + 1];

  //     result_array.push({ value: String(key), label: String(value) });
  //   }

  //   return result_array;
  // }

  // Check if the input is an array with objects having 'id' and 'value' properties
  if (Array.isArray(input_array) && input_array.length > 0) {  // Check if the first element is an object with 'id' and 'value' properties

    const firstElement = input_array[0];
    if (typeof firstElement === 'object' && firstElement.id !== undefined && firstElement.value !== undefined) {
      // Case where input_array has 'id' and 'value' properties
      input_array && input_array.forEach(element => {
        result_array.push({ value: element.id, label: element.value });
      });

      return result_array;
    }
  }

  // Fallback to the original logic

  input_array && input_array.forEach(element => {
    result_array.push({ value: String(element).split(" ").join("-").toLowerCase(), label: element });
  });

  return result_array;
}



export const convert_array_to_object_for_dropdown_data_for_dynamically_rendered_component = (input) => {
  const result_array = [];

  if (Array.isArray(input)) {
    // Case where input is an array, assuming the original structure

    input && input.forEach(element => {
      result_array.push({ value: element.key, label: element.value });
    });
  } else if (input && input.dropdown && Array.isArray(input.dropdown)) {
    // Case where input is an object with a 'dropdown' property, assuming the new structure

    input.dropdown.forEach(element => {
      if (element.id && element.value) {
        result_array.push({ value: element.id, label: element.value });
      }
    });
  } else {
    // console.error('Invalid input structure:', input);
  }

  return result_array;
}


export const utils_data_for_form = {
    dropdown_style: {
        // control: (provided) => ({
        //     ...provided,
        //     // Set the minimum height of the control
        //     height: '40px',
        //     minHeight: '40px'    // Set the height of the control
        // }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'white',          // Set the color of the arrow icon
            backgroundColor: '#5957FF', // Set the background color of the arrow icon
            padding: '2px',
            marginRight: '5px',
            marginBottom: '3px',
            borderRadius: '10%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }),
        menu: (provided) => ({
            ...provided,
            textAlign: 'left',  // Align the text to the left within the dropdown menu
            // maxHeight: '150px', // Set the maximum height for the dropdown menu
            overflow: 'hidden', // Hide the scrollbar
        }),
        singleValue: (provided) => ({
            ...provided,
            textAlign: 'left',
            marginBottom: '5px'
        }),
    },
    company_name_or_location: [
        "Belfor Abbotsford",
        "Belfor Brockville",
        "Belfor Calgary",
        "Belfor Chatham",
        "Belfor Collingwood",
        "Belfor Corner Brook",
        "Belfor Courtenay",
        "Belfor Dartmouth",
        "Belfor Dieppe",
        "Belfor Duncan",
        "Belfor Edmonton",
        "Belfor Gander",
        "Belfor Halifax",
        "Belfor Hamilton",
        "Belfor Kelowna",
        "Belfor Kingston",
        "Belfor Kitchener",
        "Belfor Labrador",
        "Belfor Lanark",
        "Belfor Lindsay",
        "Belfor London",
        "Belfor Markham",
        "Belfor Mississauga",
        "Belfor Moncton",
        "Belfor Montreal",
        "Belfor Mt. Tremblant",
        "Belfor Nanaimo",
        "Belfor Ottawa",
        "Belfor Owen Sound",
        "Belfor Paradise",
        "Belfor Perth",
        "Belfor Prince George",
        "Belfor Quispamsis",
        "Belfor Red Deer",
        "Belfor Sarnia",
        "Belfor St. John",
        "Belfor St. John's",
        "Belfor Stratford",
        "Belfor Surrey",
        "Belfor Toronto",
        "Belfor Vancouver",
        "Belfor Victoria",
        "Belfor Windsor",
        "Belfor Winnipeg",
        "DANAR - Montreal",
        "DANAR - Northshore"
    ],

    requester_name: [
        "BELFOR",
        "Brett MacKenzie ",
        "Emily Lees",
        "Kamala Brown",
        "Kristy Persson",
        "Ryan O'Neill",
        "Tracey Paris",
        "FIRST GENERAL",
        "PDS",
        "PUROCLEAN",
        "SERVICEMASTER",
        "WINMAR"
    ],

    countries: ['United States', 'Canada'],

    canada_cites: [
        "Calgary",
        "Edmonton",
        "Fort McMurray",
        "Grand Prairie",
        "Vancouver",
        "Prince George",
        "Vancouver Island",
        "Kelowna",
        "Winnipeg",
        "Brandon",
        "Saint John",
        "Moncton",
        "Fredericton",
        "St. John's",
        "Corner Brook",
        "Halifax",
        "Sydney",
        "Barrie",
        "Burlington",
        "Kitchener",
        "Kingston",
        "London",
        "Ottawa",
        "Sudbury",
        "St. Catharines",
        "Thunder Bay",
        "Toronto",
        "Windsor",
        "Charlottetown",
        "Quebec",
        "Montreal",
        "Saguenay",
        "Outaouais",
        "Abitibi",
        "Cantons-de-l'Est",
        "Regina",
        "Saskatoon"
    ],

    us_cities: [
        "Andalusia",
        "Anniston",
        "Birmingham",
        "Decatur",
        "Dothan",
        "Florence",
        "Gadsden",
        "Guntersville",
        "Huntsville",
        "Jasper",
        "Mobile",
        "Montgomery",
        "Opelika",
        "Scottsboro",
        "Tuscaloosa",
        "Anchorage",
        "Fairbanks",
        "Juneau",
        "Batesville",
        "El Dorado",
        "Fayetteville",
        "Fort Smith",
        "Jonesboro",
        "Little Rock",
        "West Memphis",
        "Flagstaff",
        "Lake Havasu",
        "Phoenix",
        "Prescott",
        "Tucson",
        "Yuma",
        "Bakersfield",
        "San Benardino",
        "Bishop",
        "Santa Barbara",
        "East Bay",
        "Eureka",
        "Fresno",
        "San Fernando",
        "Los Angeles",
        "Lancaster",
        "Merced",
        "Modesto",
        "Santa Maria",
        "Orange",
        "SF Peninula",
        "Palm Springs",
        "Ridgecrest",
        "Redding",
        "Sacramento",
        "South Bay",
        "San Diego",
        "Salinas",
        "San Luis Obispo",
        "Santa Rosa",
        "San Rafael",
        "S. Lake Tahoe",
        "Santa Cruz",
        "Tuolumne",
        "Truckee",
        "Ukiah",
        "Ventura",
        "Victorville",
        "Visalia",
        "Simi Valley",
        "Boulder",
        "Colorado Springs",
        "Denver",
        "Durango",
        "Fort Collins",
        "Grand Junction",
        "Glenwood Springs",
        "Pueblo",
        "Resort",
        "Hartford",
        "New Haven",
        "Stamfort",
        "Washington",
        "Dover",
        "Wilimngton",
        "Delray Beach",
        "Daytona Beach",
        "Fort Lauderdale",
        "Fort Myers",
        "Fort Pierce",
        "Gainesville",
        "Jacksonville",
        "Jupiter",
        "Key West",
        "Leesburg",
        "Melbourne",
        "Miami",
        "Naples",
        "Ocala",
        "Orlando",
        "Panama City",
        "Pensacola",
        "Sebring",
        "Spring Hill",
        "St. Petersburg",
        "Sarasota",
        "Tampa",
        "Tallahassee",
        "West Palm Beach",
        "Win Haven",
        "Athens",
        "Atlanta",
        "Augusta",
        "Brunswick",
        "Carrollton",
        "Columbus",
        "Macon",
        "Rome",
        "Savannah",
        "Valdosta",
        "Honolulu",
        "Hawaii Outer Island",
        "Wailuku",
        "Burlington",
        "Council Bluffs",
        "Cedar Rapids",
        "Davenport",
        "Des Moines",
        "Dubuque",
        "Fort Dodge",
        "Mason City",
        "Sioux City",
        "Waterloo",
        "Boise",
        "Coeur D'Alene",
        "Idaho Falls",
        "Lewiston",
        "Pocatello",
        "Sun Valley Resort",
        "Twin Falls",
        "Bloomington",
        "Chicago",
        "Champaign",
        "Decatur",
        "East St. Louis",
        "Marion",
        "Moline",
        "Mattoon",
        "Mount Vernon",
        "Peoria",
        "Peru",
        "Rockford",
        "Springfield",
        "Evansville",
        "Fort Wayne",
        "Gary",
        "Indianapolis",
        "Michigan City",
        "Muncie",
        "New Albany",
        "South Bend",
        "Terre Haute",
        "West Lafayette",
        "Hutchinson",
        "Kansas City",
        "Kansas State",
        "Topeka",
        "Wichita",
        "Ashland",
        "Bowling Green",
        "Covington",
        "London",
        "Louisville",
        "Lexington",
        "Owensboro",
        "Paducah",
        "Alexandria",
        "Baton Rouge",
        "Houma",
        "Lafayette",
        "Lake Charles",
        "Mandeville",
        "Monroe",
        "New Orleans",
        "Shreveport",
        "Boston",
        "Eastern Massachusetts",
        "Islands",
        "Springfield",
        "Baltimore",
        "Cumberland",
        "Frederick",
        "Rockville",
        "Salisbury",
        "St. Charles",
        "Bangor",
        "Portland",
        "Ann Arbor",
        "Battle Creek",
        "Detroit",
        "Flint",
        "Gaylord",
        "Grand Rapids",
        "Kalamazoo",
        "Lansing",
        "Marquette",
        "Mount Pleasant",
        "Muskegon",
        "Saginaw",
        "Traverse City",
        "Bemidji",
        "Brainerd",
        "Detr-Lakes",
        "Duluth",
        "Hibbing",
        "Marshall",
        "Mankato",
        "Minneapolis",
        "Rochester",
        "St. Cloud",
        "Thief River Falls",
        "Cape Girardeau",
        "Chillichothe",
        "Columbia",
        "Joplin",
        "Kansas City",
        "Kirksville",
        "Osage Beach",
        "Rolla",
        "Sedalia",
        "St. Joseph",
        "St. Louis",
        "Springfield",
        "Greenville",
        "Gulfport",
        "Hattiesburg",
        "Jackson",
        "Meridian",
        "Southaven",
        "Tupelo",
        "Billings",
        "Bozeman",
        "Butte",
        "Great Falls",
        "Helena",
        "Kalispell",
        "Missoula",
        "Sidney",
        "Asheville",
        "Charlotte",
        "Chapel Hill",
        "Elizabeth City",
        "Fayetteville",
        "Greensboro",
        "Hickory",
        "High Point",
        "Kinston",
        "Raleigh",
        "Wilmington",
        "Winston-Salem",
        "Bismark",
        "Fargo",
        "Grand Forks",
        "Minot",
        "Grand Island",
        "Lincoln",
        "Norfolk",
        "North Platte",
        "Omaha",
        "Scottsbluff",
        "Manchester",
        "Atlantic City",
        "Camden",
        "Newark",
        "Paterson",
        "Toms River",
        "Alburquerque",
        "Clovis",
        "Farmington",
        "Hobbs",
        "Las Cruces",
        "Roswell",
        "Santa Fe",
        "Las Vegas",
        "Elko",
        "Reno",
        "Albany ",
        "Binghamton",
        "Buffalo",
        "Long Island",
        "Manhattan",
        "Outer Boroughs",
        "Rochester",
        "Syracuse",
        "Utica",
        "White Plains",
        "Akron",
        "Canton",
        "Cincinnati",
        "Cleveland",
        "Columbus",
        "Dayton",
        "Lima",
        "Mansfield",
        "Newark",
        "Portsmouth",
        "Toledo",
        "Youngstown",
        "Ada",
        "Enid",
        "Lawton",
        "Muskogee",
        "Oklahoma City",
        "Tulsa",
        "Bend",
        "Corvallis",
        "Eugene",
        "Medford",
        "Pendleton",
        "Portland",
        "Roseburg",
        "Salem",
        "Allentown",
        "Altoona",
        "Erie",
        "Harrisburg",
        "Johnstown",
        "Lancaster",
        "Pittsburgh",
        "Philadelphia",
        "Reading",
        "Scranton",
        "West Chester",
        "Willamsport",
        "San Juan",
        "Providence",
        "Aiken",
        "Beaufort",
        "Charleston",
        "Columbia",
        "Florence",
        "Greenwood",
        "Greenville",
        "Hilton Head Island",
        "Myrtle Beach",
        "Rock Hill",
        "Aberdeen",
        "Rapid City",
        "Sioux Falls",
        "Chattanooga",
        "Cookeville",
        "Jackson",
        "Johnson City",
        "Knoxville",
        "Memphis",
        "Murfreesboro",
        "Nashville",
        "Abilene",
        "Amarillo",
        "Austin",
        "Bay City",
        "Beaumont",
        "Belton",
        "Corpus Christi",
        "College Station",
        "Dallas/Fort Worth",
        "El Paso",
        "Galveston",
        "Houston",
        "Lubbock",
        "McAllen",
        "Midland",
        "San Antonio",
        "Sherman",
        "San Angelo",
        "Texarkana",
        "The Woodlands",
        "Tyler",
        "Victoria",
        "Waco",
        "Wichita Falls",
        "Bountiful",
        "Cedar City",
        "Logan",
        "Ogden",
        "Orem",
        "St. George",
        "Salt Lake City",
        "Park City",
        "Arlington",
        "Blacksburg",
        "Bristol",
        "Charlottesville",
        "Danville",
        "Dale City",
        "Frederickburg",
        "Lynchburg",
        "Norfolk",
        "Richmond",
        "Roanoke",
        "Winchester",
        "Burlington",
        "Rutland",
        "Bellingham",
        "Everett",
        "Kennewick",
        "Olympia",
        "Seattle",
        "Spokane",
        "Tacoma",
        "Vancouver",
        "Wenatchee",
        "Yakima",
        "Appleton",
        "Eau Claire",
        "Green Bay",
        "La Crosse",
        "Madison",
        "Milwaukee",
        "Racine",
        "Wausau",
        "Beckley",
        "Bluefield",
        "Charleston",
        "Fairmont",
        "Huntington",
        "Logan",
        "Parkersburg",
        "Wheeling",
        "Casper",
        "Cheyenne",
        "Cody",
        "Gillette",
        "Riverton",
        "Rock Springs",
        "Sheridan"
    ],

    canada_provinces: [
        "AB",
        "BC",
        "MB",
        "NB",
        "NL",
        "NS",
        "NT",
        "NU",
        "ON",
        "PE",
        "QC",
        "SK",
        "YT"
    ],

    type_of_loss: [
        "CAT 1 - Flood",
        "CAT 3 - Sewer Back Up",
        "Collapse",
        "Earthquake",
        "Fire",
        "Hail",
        "Hurricane",
        "Lightning",
        "Smoke",
        "Theft",
        "Tornado",
        "Vandalism"
    ],

    carrier_name: [
        "Allstate",
        "AMA",
        "Aviva",
        "BCAA",
        "Chubb",
        "Contractor Connection",
        "Cooperators",
        "DGIG",
        "Economical",
        "Farmers",
        "Gore",
        "Heartland",
        "Intact",
        "Liberty Mutual",
        "RSA",
        "State Farm",
        "TDMM",
        "Travelers",
        "USAA",
        "Wawanessa",
        "Other"
    ],

    type_of_request: [
        "E - Interior Only",
        "E - Exterior Only",
        "E - Separate Contents Only",
        "E - Interior & Exterior",
        "E & C - Interior & Separate Contents",
        "E & C - Exterior & Separate Contents",
        "E & C - Interior, Exterior & Separate Contents",
        "R - Interior Only",
        "R - Exterior Only",
        "R - Interior & Exterior",
        "R & C - Interior & Separate Contents",
        "R & C - Exterior & Separate Contents",
        "R & C - Interior, Exterior & Separate Contents",
        "E & R - Interior Only",
        "E & R - Exterior Only",
        "E & R - Interior & Exterior",
        "E & R & C - Interior Only & Separate Contents",
        "E & R & C - Exterior Only & Separate Contents",
        "E & R & C - Interior, Exterior & Separate Contents "
    ],

    outside_service_area: ["Yes", "No"],

    percentages: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20"
    ],

    answers1: [
        "No Mould Remediation or General Containment",
        "Containment",
        "Zipper",
        "Tension Posts - (each x#of days)",
        "Negative Air Fan / Scrubber - (each x#of days)",
        "Flexible Duct Work",
        "Apply Mould Remover",
        "HEPA Vacuum - Detailed",
        "PPE(Suits) Amount (Total used)",
        "Yes - Half Face - Respirator (each X#of days)",
        "Yes - Full Face - Respirator (each X#of days)",
        "Yes - Powered - Respirator (each X#of days)",
        "Temporary Shower (each X#of days)"
    ],

    answers2: [
        'No Equipment Added',
        'Air Mover - (Per 24 Hours)',
        'Air Mover - Axial (Per 24 Hours)',
        'Dehumidifier - Regular (Per 24 Hours)',
        'Dehumidifier - Large - (Per 24 Hours)',
        'Dehumidifier - X-Large - (Per 24 Hours)',
        'Dehumidifier - XX-Large - (Per 24 Hours)',
        'Dehumidifier - Small-Desiccant - (Per 24 Hours)',
        'Dehumidifier - Large-Desiccant - (Per 24 Hours)',
        'Dehumidifier - Desiccant 3000-4000 CFM- (Per 24 Hours)',
        'Dehumidifier - Desiccant 4500-7500 CFM- (Per 24 Hours)',
        'Dehumidifier - Desiccant 8500-15000 CFM- (Per 24 Hours)',
        'Negative Air Scrubber - Regular - NOT for Hazardous Materials - (Per 24 hours)',
        'Negative Air Scrubber - Large - NOT for Hazardous Materials - (Per 24 hours)',
        'Negative Air Scrubber - X-Large NOT for Hazardous Materials - (Per 24 hours)',
        'Hydroxyl Generator - 2 Optics (Per 24 Hours)',
        'Hydroxyl Generator - 3 Optics (Per 24 Hours)',
        'Power Distribution Box (Per 24 Hours)',
        'Pump Standing Water - Truck Mount (Per Hour)',
        'Submersible Pump with Hose (Per hour)',
        'Temporary Heater - Electric (Per 24 Hours)',
        'Temporary Heater - Electric (Per Week)',
        'Trailer-Mount Dehumidifier (Per 24 Hours)',
        'Wall/Ceiling Cavity Injection Drying (Per 24 Hours)',
        'Wood Floor Extraction - Mat & Blower (Per 24 Hours)',
        'Wood Floor Extraction - Additional Mat (Per 24 Hours)',
        'Scaffolding - per Section (per Day)',
        'Scaffolding - per Section (per Week)',
        'Scaffolding - per Section (per Month)',
        'Scaffolding - Labor to Set up/Take Down (per Section)',
      ],

      answers3: [
        'No Content Manipulation',
          'Small Room - Move Out & Reset',
          'Small Room - Move Out & Reset & Block',
          'Small Room - Move Out & Reset & Protect',
          'Small Room - Move Out & Reset',
        ' Block & ProtectSmall Room - Move Out & Reset',
        ' Block & Protect - After Hours',
          'Regular Room - Move Out & Reset',
          'Regular Room - Move Out & Reset & Block',
          'Regular Room - Move Out & Reset & Protect',
          'Regular Room - Move Out & Reset',
        ' Block & ProtectRegular Room - Move Out & Reset',
        ' Block & Protect - After Hours',
          'Large Room - Move Out & Reset',
          'Large Room - Move Out & Reset & Block',
          'Large Room - Move Out & Reset & Protect',
          'Large Room - Move Out & Reset',
        ' Block & ProtectLarge Room - Move Out & Reset',
        ' Block & Protect - After Hours',
          'X-Large Room - Move Out & Reset',
          'X-Large Room - Move Out & Reset & Block',
          'X-Large Room - Move Out & Reset & Protect',
          'X-Large Room - Move Out & Reset',
        ' Block & ProtectX-Large Room - Move Out & Reset',
        ' Block & Protect - After Hours',
          'Block Only - Small Amount',
          'Block Only - Regular Amount',
          'Block Only - Large Amount',
          'Block Only - Small Amount - After Hours',
          'Block Only - Regular Amount - After Hours',
          'Block Only - Large Amount - After Hours',
          'Protect Contents Only - per Sq Ft',
          'Fridge - Detach',
          'Fridge - Detach - After Hours',
          'Fridge - Detach & Reset',
          'Fridge - Remove & Dispose',
          'Fridge - Water Line',
          'Range - Electric - Detach',
          'Range - Electric - Detach - After Hours',
          'Range - Electric - Detach & Reset',
          'Range - Electric - Remove & Dispose',
          'Range - Gas - Detach',
          'Range - Gas - Detach - After Hours',
          'Range - Gas - Detach & Reset',
          'Range - Gas - Remove & Dispose',
          'Dishwasher - Detach',
          'Dishwasher - Detach - After Hours',
          'Dishwasher - Detach & Reset',
          'Dishwasher - Remove & Dispose',
          'Washer - Detach',
          'Washer - Detach - After Hours',
          'Washer - Detach & Reset',
          'Washer - Remove & Dispose',
          'Dryer - Detach',
          'Dryer - Detach - After Hours',
          'Dryer - Detach & Reset',
          'Dryer - Remove & Dispose',
          'Built-in Oven - Detach',
          'Built-in Oven - Remove & Dispose',
          'Built-in Double Oven - Detach',
          'Built-in Double Oven - Remove & Dispose',
          'Cooktop - Electric - Detach',
          'Cooktop - Electric - Remove & Dispose',
          'Cooktop - Gas - Detach',
          'Cooktop - Gas - Remove & Dispose',
          'Freezer - Detach & Reset',
          'Garbage Disposer - Detach',
          'Garbage Disposer - Remove & Dispose',
          'Microwave - Over Range - Detach',
          'Microwave - Over Range - Remove & Dispose',
          'Range Hood - Detach',
          'Range Hood - Remove & Dispose',
          'BID ITEM - Used Subtrade for Appliance Removal'
      ] ,

      answers4: [
        'Flooring Unaffected',
          'Protect Floor Only',
          'Hard Surface',
          'Carpet',
          'Carpet - G.D',
          'Cork - Floating',
          'Cork - G.D Wood',
          'Cork - G.D Concrete',
          'Cork - Tile',
          'Laminate',
          'Tile',
          'Vinyl Plank',
          'Vinyl Plank - G.D'
      ],

      category: [
        'No Extraction, Clean Floor, Antimic - NO Flooring Removal',
        'Extraction, Clean Floor, Antimic - NO Flooring Removal',
        'Extraction, Clean Floor, Antimic - "Painted" Floor - Damaged'
      ],

      answers5: ['Walls Unaffected',
      'No Wall Materials Removed - Seal & Paint Walls on Rebuild Only',
      'Drill Holes for Wall Cavity Drying (each)',
      '1/2" DRYWALL - CAT 1',
    ' Tear Out up to 4 Inches (per LnFt)1/2" DRYWALL - CAT 1',
    ' Tear Out up to 2 Feet (per LnFt)1/2" DRYWALL - CAT 1',
    ' Tear Out up to 4 Feet (per LnFt)1/2" DRYWALL - CAT 1',
    ' Tear Out (per SqFt)1/2" DRYWALL - CAT 3',
    ' Tear Out up to 4 Inches',
    ' Antimic (per LnFt)1/2" DRYWALL - CAT 3',
    ' Tear Out up to 2 Feet',
    ' Antimic (per LnFt)1/2" DRYWALL - CAT 3',
    ' Tear Out up to 4 Feet',
    ' Antimic (per LnFt)1/2" DRYWALL - CAT 3',
    ' Tear Out',
    ' Antmic (per SqFt)',
      '1/2" DRYWALL - NON-WATER - Tear Out Drywall (per Sq Ft)',
      '1/2" DRYWALL - NON-WATER - Tear Out Drywall - Seal Stud Wall for Odor Control (per SqFt)',
      '5/8" DRYWALL - CAT 1',
    ' Tear Out up to 4 Inches (per LnFt)5/8" DRYWALL - CAT 1',
    ' Tear Out up to 2 Feet (per LnFt)5/8" DRYWALL - CAT 1',
    ' Tear Out up to 4 Feet (per LnFt)5/8" DRYWALL - CAT 1',
    ' Tear Out (per SqFt)5/8" DRYWALL - CAT 3',
    ' Tear Out up to 4 Inches',
    ' Antimic (per LnFt)5/8" DRYWALL - CAT 3',
    ' Tear Out up to 2 Feet',
    ' Antimic (per LnFt)5/8" DRYWALL - CAT 3',
    ' Tear Out up to 4 Feet',
    ' Antimic (per LnFt)5/8" DRYWALL - CAT 3',
    ' Tear Out',
    ' Antmic (per LnFt)',
      '5/8" DRYWALL - NON-WATER - Tear Out Drywall (per SqFt)',
      '5/8" DRYWALL - NON-WATER - Tear Out Drywall - Seal Stud Wall for Odor Control (per SqFt)',
      'INSULATION & VAPOR BARRIER - 4" - CAT 1 - Tear Out (per SqFt)',
      'INSULATION & VAPOR BARRIER - 4" - CAT 3 - Tear Out (per SqFt)',
      'INSULATION & VAPOR BARRIER - 4" - NON-WATER - Tear Out (per SqFt)',
      'INSULATION & VAPOR BARRIER - 6" - CAT 1 - Tear Out (per SqFt)',
      'INSULATION & VAPOR BARRIER - 6" - CAT 3 - Tear Out (per SqFt)',
      'INSULATION & VAPOR BARRIER - 6" - NON-WATER - Tear Out (per SqFt)',
      'INSULATION - FIRE RATED/ACOUSTICAL - 4" - CAT 1 - Tear Out (per SqFt)',
      'INSULATION - FIRE RATED/ACOUSTICAL - 4" - CAT 3 - Tear Out (per SqFt)',
      'INSULATION - FIRE RATED/ACOUSTICAL - 4" - NON-WATER - Tear Out (per SqFt)',
      'INSULATION - FIRE RATED/ACOUSTICAL - 6" - CAT 1 - Tear Out (per SqFt)',
      'INSULATION - FIRE RATED/ACOUSTICAL - 6" - CAT 3 - Tear Out (per SqFt)',
      'INSULATION - FIRE RATED/ACOUSTICAL - 6" - NON-WATER - Tear Out (per SqFt)',
      'INSULATION - PAPER FACED - 4" - CAT 1 - Tear Out (per SqFt)',
      'INSULATION - PAPER FACED - 4" - CAT 3 - Tear Out (per SqFt)',
      'INSULATION - PAPER FACED - 4" - NON-WATER - Tear Out (per SqFt)',
      'INSULATION - PAPER FACED - 6" - CAT 1 - Tear Out (per SqFt)',
      'INSULATION - PAPER FACED - 6" - CAT 3 - Tear Out (per SqFt)',
      'INSULATION - PAPER FACED - 6" - NON-WATER - Tear Out (per SqFt)',
      'INSULATION - RIGID FOAM - 1" - CAT 1 - Tear Out (per SqFt)',
      'INSULATION - RIGID FOAM - 1" - CAT 3 - Tear Out (per SqFt)',
      'INSULATION - RIGID FOAM - 1" - NON-WATER - Tear Out (per SqFt)',
      'INSULATION - RIGID FOAM - 1.5" - CAT 1 - Tear Out (per SqFt)',
      'INSULATION - RIGID FOAM - 1.5" - CAT 3 - Tear Out (per SqFt)',
      'INSULATION - RIGID FOAM - 1.5" - NON-WATER - Tear Out (per SqFt)',
      'INSULATION - RIGID FOAM - 2" - CAT 1 - Tear Out (per SqFt)',
      'INSULATION - RIGID FOAM - 2" - CAT 3 - Tear Out (per SqFt)',
      'INSULATION - RIGID FOAM - 2" - NON-WATER - Tear Out (per SqFt)',
      'INSULATION - SPRAY FOAM - 4" - CAT 1 - Tear Out (per SqFt)',
      'INSULATION - SPRAY FOAM - 4" - CAT 3 - Tear Out (per SqFt)',
      'INSULATION - SPRAY FOAM - 4" - NON-WATER - Tear Out (per SqFt)',
      'INSULATION - SPRAY FOAM - 6" - CAT 1 - Tear Out (per SqFt)',
      'INSULATION - SPRAY FOAM - 6" - CAT 3 - Tear Out (per SqFt)',
      'INSULATION - SPRAY FOAM - 6" - NON-WATER - Tear Out (per SqFt)',
      'PANELING - SHEET - Paint Grade - Detach (per SqFt)',
      'PANELING - SHEET - Paint Grade - CAT 1',
    ' Tear Out (per SqFt)PANELING - SHEET - Paint Grade - CAT 3',
    ' Tear Out (per SqFt)',
      'PANELING - SHEET - Paint Grade - NON-WATER - Tear Out Paneling (per SqFt)',
      'PANELING - SHEET - Paint Grade - NON-WATER - Tear Out - Seal Stud Wall for Odor Control (per SqFt)',
      'PANELING - SHEET - Stain Grade - Detach (per SqFt)',
      'PANELING - SHEET - Stain Grade - CAT 1',
    ' Tear Out (per SqFt)PANELING - SHEET - Stain Grade - CAT 3',
    ' Tear Out (per SqFt)',
      'PANELING - SHEET - Stain Grade - NON-WATER - Tear Out Paneling (per SqFt)',
      'PANELING - SHEET - Stain Grade - NON-WATER - Tear Out - Seal Stud Wall for Odor Control (per SqFt)',
      'PANELING - SHEET W/ CEILING & CORNER TRIM - Paint Grade - Detach',
      'PANELING - SHEET W/ CEILING & CORNER TRIM - Paint Grade - CAT 1',
    ' Tear OutPANELING - SHEET W/ CEILING & CORNER TRIM - Paint Grade - CAT 3',
    ' Tear Out',
    ' Antimic',
      'PANELING - SHEET W/ CELIING & CORNER TRIM - Paint Grade - NON-WATER - Tear Out Paneling',
      'PANELING - SHEET W/ CEILING & CORNER TRIM - Paint Grade - NON-WATER - Tear Out - Seal Stud Wall',
      'PANELING - SHEET W/ CEILING & CORNER TRIM - Stain Grade - Detach',
      'PANELING - SHEET W/ CEILING & CORNER TRIM - Stain Grade - CAT 1',
    ' Tear OutPANELING - SHEET W/ CEILING & CORNER TRIM - Stain Grade - CAT 3',
    ' Tear Out',
    ' Antimic',
      'PANELING - SHEET W/ CELIING & CORNER TRIM - Stain Grade - NON-WATER - Tear Out Paneling',
      'PANELING - SHEET W/ CEILING & CORNER TRIM - Stain Grade - NON-WATER - Tear Out - Seal Stud Wall',
      'PANELING - BEAD BOARD/WAINSCOTTING - Paint Grade - Detach (per SqFt)',
      'PANELING - BEAD BOARD/WAINSCOTTING - Paint Grade - CAT 1',
    ' Tear Out (per SqFt)',
      'PANELING - BEAD BOARD/WAINSCOTTING - Paint Grade - CAT 3',
    ' Tear Out',
    ' Antimic (per SqFt)',
      'PANELING - BEAD BOARD/WAINSCOTTING - Paint Grade - NON-WATER - Tear Out Paneling (per SqFt)',
      'PANELING - BEAD BOARD/WAINSCOTTING - Paint Grade - NON-WATER - Tear Out - Seal Stud Wall',
      'PANELING - BEAD BOARD/WAINSCOTTING - Stain Grade - Detach (per SqFt)',
      'PANELING - BEAD BOARD/WAINSCOTTING - Stain Grade - CAT 1',
    ' Tear Out (per SqFt)',
      'PANELING - BEAD BOARD/WAINSCOTTING - Stain Grade - CAT 3',
    ' Tear Out',
    ' Antimic (per SqFt)',
      'PANELING - BEAD BOARD/WAINSCOTTING - Stain Grade - NON-WATER - Tear Out Paneling (per SqFt)',
      'PANELING - BEAD BOARD/WAINSCOTTING - Stain Grade - NON-WATER - Tear Out - Seal Stud Wall',
      'PANELING - T&G - Paint Grade - Detach (per SqFt)',
      'PANELING - T&G - Paint Grade - CAT 1',
    ' Tear Out (per SqFt)PANELING - T&G - Paint Grade - CAT 3',
    ' Tear Out',
    ' Antimic (per SqFt)',
      'PANELING - T&G - Paint Grade - NON-WATER - Tear Out Paneling (per SqFt)',
      'PANELING - T&G - Paint Grade - NON-WATER - Tear Out - Seal Stud Wall for Odor Control (per SqFt)',
      'PANELING - T&G - Stain Grade - Detach (per SqFt)',
      'PANELING - T&G - Stain Grade - CAT 1',
    ' Tear Out (per SqFt)PANELING - T&G - Stain Grade - CAT 3',
    ' Tear Out',
    ' Antimic (per SqFt)',
      'PANELING - T&G - Stain Grade - NON-WATER - Tear Out Paneling (per SqFt)',
      'PANELING - T&G - Stain Grade - NON-WATER - Tear Out - Seal Stud Wall for Odor Control (per SqFt)',
      'PANELING - JUDGES W/ MOULDING - Paint Grade - Detach (per SqFt)',
      'PANELING - JUDGES W/ MOULDING - Paint Grade - CAT 1',
    ' Tear Out (per SqFt)PANELING - JUDGES W/ MOULDING - Paint Grade - CAT 3',
    ' Tear Out',
    ' Antimic (per SqFt)',
      'PANELING - JUDGES W/ MOULDING - Paint Grade - NON-WATER - Tear Out Paneling (per SqFt)',
      'PANELING - JUDGES W/ MOULDING - Paint Grade - NON-WATER - Tear Out - Seal Stud Wall (per SqFt)',
      'PANELING - JUDGES W/ MOULDING - Stain Grade - Detach (per SqFt)',
      'PANELING - JUDGES W/ MOULDING - Stain Grade - CAT 1',
    ' Tear Out (per SqFt)PANELING - JUDGES W/ MOULDING - Stain Grade - CAT 3',
    ' Tear Out',
    ' Antimic (per SqFt)',
      'PANELING - JUDGES W/ MOULDING - Stain Grade - NON-WATER - Tear Out Paneling (per SqFt)',
      'PANELING - JUDGES W/ MOULDING - Stain Grade - NON-WATER - Tear Out - Seal Stud Wall (per SqFt)',
      'PLASTER OVER 1/2" Blue Board - CAT 1',
    ' Tear Out (per SqFt)PLASTER OVER 1/2" Blue Board - CAT 3',
    ' Tear Out',
    ' Antimic (per SqFt)',
      'PLASTER OVER 1/2" Blue Board - NON-WATER - Tear Out (per SqFt)',
      'PLASTER OVER 1/2" Blue Board - NON-WATER - Tear Out - Seal Stud Wall for Odor Control (per SqFt)',
      'PLASTER OVER 5/8" Blue Board - CAT 1',
    ' Tear Out (per SqFt)PLASTER OVER 5/8" Blue Board - CAT 3',
    ' Tear Out',
    ' Antimic (per SqFt)',
      'PLASTER OVER 5/8" Blue Board - NON-WATER - Tear Out (per SqFt)',
      'PLASTER OVER 5/8" Blue Board - NON-WATER - Tear Out - Seal Stud Wall for Odor Control (per SqFt)',
      'PLASTER OVER LATH - CAT 1',
    ' Tear Out (per SqFt)PLASTER OVER LATH - CAT 3',
    ' Tear Out',
    ' Antimic (per SqFt)',
      'PLASTER OVER LATH - NON-WATER - Tear Out (per SqFt)',
      'PLASTER OVER LATH - NON-WATER - Tear Out - Seal Stud Wall for Odor Control (per SqFt)',
      'BID ITEM / Used Subtrade for Wall Removal (can be partial or full)'
    ],

    answers6: [
        'Ceiling Unaffected',
          'No Ceiling Materials Removed - FLAT CEILING - Seal & Paint on Rebuild Only',
          'No Ceiling Materials Removed - POPCORN CEILING - Seal & Paint on Rebuild Only',
          'No Ceiling Materials Removed - TEXTURED CEILING - Seal & Paint on Rebuild Only',
          '1/2" DRYWALL - CAT 1',
        ' Tear Out per Square Foot1/2" DRYWALL - CAT 3',
        ' Tear Out per Square Foot',
        ' Antmic',
          '1/2" DRYWALL - NON-WATER - Tear Out Drywall',
          '1/2" DRYWALL - NON-WATER - Tear Out Drywall - Seal Ceiling Joists for Odor Control',
          '1/2" DRYWALL - NON-WATER - Tear Out Drywall - Seal Trusses for Odor Control - up to 5/12',
          '1/2" DRYWALL - NON-WATER - Tear Out Drywall - Seal Trusses for Odor Control - 6 to 8/12',
          '1/2" DRYWALL - NON-WATER - Tear Out Drywall - Seal Trusses for Odor Control - 9 to 12/12',
          '5/8" DRYWALL - CAT 1',
        ' Tear Out per Square Foot5/8" DRYWALL - CAT 3',
        ' Tear Out per Square Foot',
        ' Antmic',
          '5/8" DRYWALL - NON-WATER - Tear Out Drywall',
          '5/8" DRYWALL - NON-WATER - Tear Out Drywall - Seal Ceiling Joists for Odor Control',
          '5/8" DRYWALL - NON-WATER - Tear Out Drywall - Seal Trusses for Odor Control - up to 5/12',
          '5/8" DRYWALL - NON-WATER - Tear Out Drywall - Seal Trusses for Odor Control - 6 to 8/12',
          '5/8" DRYWALL - NON-WATER - Tear Out Drywall - Seal Trusses for Odor Control - 9 to 12/12',
          'INSULATION & VAPOR BARRIER - 8" - CAT 1 - Tear Out',
          'INSULATION & VAPOR BARRIER - 8" - CAT 3 - Tear Out',
          'INSULATION & VAPOR BARRIER - 8" - NON-WATER - Tear Out',
          'INSULATION & VAPOR BARRIER - 10" - CAT 1 - Tear Out',
          'INSULATION & VAPOR BARRIER - 10" - CAT 3 - Tear Out',
          'INSULATION & VAPOR BARRIER - 10" - NON-WATER - Tear Out',
          'INSULATION & VAPOR BARRIER - 12" - CAT 1 - Tear Out',
          'INSULATION & VAPOR BARRIER - 12" - CAT 3 - Tear Out',
          'INSULATION & VAPOR BARRIER - 12" - NON-WATER - Tear Out',
          'INSULATION - PAPER FACED - 10" - CAT 1 - Tear Out',
          'INSULATION - PAPER FACED - 10" - CAT 3 - Tear Out',
          'INSULATION - PAPER FACED - 10" - NON-WATER - Tear Out',
          'INSULATION - PAPER FACED - 12" - CAT 1 - Tear Out',
          'INSULATION - PAPER FACED - 12" - CAT 3 - Tear Out',
          'INSULATION - PAPER FACED - 12" - NON-WATER - Tear Out',
          'INSULATION - BLOWN-IN - 10" Depth - CAT 1 - Tear Out',
          'INSULATION - BLOWN-IN - 10" Depth - CAT 3 - Tear Out',
          'INSULATION - BLOWN-IN - 10" - NON-WATER - Tear Out',
          'INSULATION - BLOWN-IN - 12" Depth - CAT 1 - Tear Out',
          'INSULATION - BLOWN-IN - 12" Depth - CAT 3 - Tear Out',
          'INSULATION - BLOWN-IN - 12" - NON-WATER - Tear Out',
          'INSULATION - BLOWN-IN - 14" Depth - CAT 1 - Tear Out',
          'INSULATION - BLOWN-IN - 14" Depth - CAT 3 - Tear Out',
          'INSULATION - BLOWN-IN - 14" - NON-WATER - Tear Out',
          'INSULATION - BLOWN-IN - 16" Depth - CAT 1 - Tear Out',
          'INSULATION - BLOWN-IN - 16" Depth - CAT 3 - Tear Out',
          'INSULATION - BLOWN-IN - 16" - NON-WATER - Tear Out',
          'INSULATION - BLOWN-IN - 20" Depth - CAT 1 - Tear Out',
          'INSULATION - BLOWN-IN - 20" Depth - CAT 3 - Tear Out',
          'INSULATION - BLOWN-IN - 20" - NON-WATER - Tear Out',
          'INSULATION - BLOWN-IN - 24" Depth - CAT 1 - Tear Out',
          'INSULATION - BLOWN-IN - 24" Depth - CAT 3 - Tear Out',
          'INSULATION - BLOWN-IN - 24" - NON-WATER - Tear Out',
          'ACOUSTIC CEILING TILE - Not Painted - CAT 1',
        ' Tear OutACOUSTIC CEILING TILE - Not Painted - CAT 3',
        ' Tear Out',
        ' Antimic',
          'ACOUSTIC CEILING TILE - Not Painted - NON-WATER - Tear Out',
          'ACOUSTIC CEILING TILE - Not Painted - NON-WATER - Tear Out - Seal Ceiling Joists',
          'ACOUSTIC CEILING TILE - Painted - CAT 1',
        ' Tear OutACOUSTIC CEILING TILE - Painted - CAT 3',
        ' Tear Out',
        ' Antimic',
          'ACOUSTIC CEILING TILE - Painted - NON-WATER - Tear Out',
          'ACOUSTIC CEILING TILE - Painted - NON-WATER - Tear Out - Seal Ceiling Joists',
          'SUSPENDED CEILING TILE - 2x2 - Not Painted - Detach',
          'SUSPENDED CEILING TILE - 2x2 - Not Painted - CAT 1',
        ' Tear Out - MATCHABLESUSPENDED CEILING TILE - 2x2 - Not Painted - CAT 1',
        ' Tear Out - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x2 - Not Painted - CAT 3',
        ' Tear Out',
        ' Antimic - MATCHABLESUSPENDED CEILING TILE - 2x2 - Not Painted - CAT 3',
        ' Tear Out',
        ' Antimic - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x2 - Not Painted - NON-WATER - Tear Out',
          'SUSPENDED CEILING TILE - 2x2 - Not Painted - NON-WATER - Tear Out - Seal Ceiling Joists',
          'SUSPENDED CEILING TILE - 2x2 - Painted - Detach',
          'SUSPENDED CEILING TILE - 2x2 - Painted - CAT 1',
        ' Tear Out - MATCHABLESUSPENDED CEILING TILE - 2x2 - Painted - CAT 1',
        ' Tear Out - NON-MATCHABLESUSPENDED CEILING TILE - 2x2 - Painted - CAT 3',
        ' Tear Out',
        ' Antimic - MATCHABLESUSPENDED CEILING TILE - 2x2 - Painted - CAT 3',
        ' Tear Out',
        ' Antimic - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x2 - Painted - NON-WATER - Tear Out',
          'SUSPENDED CEILING TILE - 2x2 - Painted - NON-WATER - Tear Out - Seal Ceiling Joists',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Not Painted - Detach',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Not Painted - CAT 1',
        ' Tear Out - MATCHABLE',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Not Painted - CAT 1',
        ' Tear Out - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Not Painted - CAT 3',
        ' Tear Out',
        ' Antimic - MATCHABLE',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Not Painted - CAT 3',
        ' Tear Out',
        ' Antimic - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Not Painted - NON-WATER - Tear Out',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Not Painted - NON-WATER - Tear Out - Seal Ceiling Joists',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Painted - Detach',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Painted - CAT 1',
        ' Tear Out - MATCHABLE',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Painted - CAT 1',
        ' Tear Out - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Painted - CAT 3',
        ' Tear Out',
        ' Antimic - MATCHABLESUSPENDED CEILING TILE - 2x2 & GRID - Painted - CAT 3',
        ' Tear Out',
        ' Antimic - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Painted - NON-WATER - Tear Out',
          'SUSPENDED CEILING TILE - 2x2 & GRID - Painted - NON-WATER - Tear Out - Seal Ceiling Joists',
          'SUSPENDED CEILING TILE - 2x4 - Not Painted - Detach',
          'SUSPENDED CEILING TILE - 2x4 - Not Painted - CAT 1',
        ' Tear Out - MATCHABLESUSPENDED CEILING TILE - 2x4 - Not Painted - CAT 1',
        ' Tear Out - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x4 - Not Painted - CAT 3',
        ' Tear Out',
        ' Antimic - MATCHABLESUSPENDED CEILING TILE - 2x4 - Not Painted - CAT 3',
        ' Tear Out',
        ' Antimic - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x4 - Not Painted - NON-WATER - Tear Out',
          'SUSPENDED CEILING TILE - 2x4 - Not Painted - NON-WATER - Tear Out - Seal Ceiling Joists',
          'SUSPENDED CEILING TILE - 2x4 - Painted - Detach',
          'SUSPENDED CEILING TILE - 2x4 - Painted - CAT 1',
        ' Tear Out - MATCHABLESUSPENDED CEILING TILE - 2x4 - Painted - CAT 1',
        ' Tear Out - NON-MATCHABLESUSPENDED CEILING TILE - 2x4 - Painted - CAT 3',
        ' Tear Out',
        ' Antimic - MATCHABLESUSPENDED CEILING TILE - 2x4 - Painted - CAT 3',
        ' Tear Out',
        ' Antimic - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x4 - Painted - NON-WATER - Tear Out',
          'SUSPENDED CEILING TILE - 2x4 - Painted - NON-WATER - Tear Out - Seal Ceiling Joists',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Not Painted - Detach',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Not Painted - CAT 1',
        ' Tear Out - MATCHABLE',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Not Painted - CAT 1',
        ' Tear Out - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Not Painted - CAT 3',
        ' Tear Out',
        ' Antimic - MATCHABLE',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Not Painted - CAT 3',
        ' Tear Out',
        ' Antimic - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Not Painted - NON-WATER - Tear Out',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Not Painted - NON-WATER - Tear Out - Seal Ceiling Joists',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Painted - Detach',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Painted - CAT 1',
        ' Tear Out - MATCHABLE',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Painted - CAT 1',
        ' Tear Out - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Painted - CAT 3',
        ' Tear Out',
        ' Antimic - MATCHABLESUSPENDED CEILING TILE - 2x4 & GRID - Painted - CAT 3',
        ' Tear Out',
        ' Antimic - NON-MATCHABLE',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Painted - NON-WATER - Tear Out',
          'SUSPENDED CEILING TILE - 2x4 & GRID - Painted - NON-WATER - Tear Out - Seal Ceiling Joists',
          'PLASTER OVER 1/2" Blue Board - CAT 1',
        ' Tear OutPLASTER OVER 1/2" Blue Board - CAT 3',
        ' Tear Out',
        ' Antimic',
          'PLASTER OVER 1/2" Blue Board - NON-WATER - Tear Out',
          'PLASTER OVER 1/2" Blue Board - NON-WATER - Tear Out - Seal Ceiling Joists for Odor Control',
          'PLASTER OVER 5/8" Blue Board - CAT 1',
        ' Tear OutPLASTER OVER 5/8" Blue Board - CAT 3',
        ' Tear Out',
        ' Antimic',
          'PLASTER OVER 5/8" Blue Board - NON-WATER - Tear Out',
          'PLASTER OVER 5/8" Blue Board - NON-WATER - Tear Out - Seal Ceiling Joists for Odor Control',
          'PLASTER OVER LATH - CAT 1',
        ' Tear OutPLASTER OVER LATH - CAT 3',
        ' Tear Out',
        ' Antimic',
          'PLASTER OVER LATH - NON-WATER - Tear Out',
          'PLASTER OVER LATH - NON-WATER - Tear Out - Seal Ceiling Joists for Odor Control',
          'BID ITEM / Used Subtrade for Ceiling Removal (can be partial or full)'
      ],

      answers7: [
        'Door Casings/Jambs/Doors Unaffected',
          'Door Casing - 2 1/4" - Paint Grade - Detach on Emergency (per LnFt)',
          'Door Casing - 2 1/4" - Paint Grade - Detach & Reset on Rebuild (per LnFt)',
          'Door Casing - 2 1/4" - Paint Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
          'Door Casing - 2 1/4" - Paint Grade - Tear Out - NON-WATER (per LnFt)',
          'Door Casing - 2 1/4" - Paint Grade - Remove & Replace on Rebuild (per LnFt)',
          'Door Casing - 2 1/4" - Stain Grade - Detach on Emergency (per LnFt)',
          'Door Casing - 2 1/4" - Stain Grade - Detach & Reset on Rebuild (per LnFt)',
          'Door Casing - 2 1/4" - Stain Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
          'Door Casing - 2 1/4" - Stain Grade - Tear Out - NON-WATER (per LnFt)',
          'Door Casing - 2 1/4" - Stain Grade - Remove & Replace on Rebuild (per LnFt)',
          'Door Casing - 3 1/4" - Paint Grade - Detach on Emergency (per LnFt)',
          'Door Casing - 3 1/4" - Paint Grade - Detach & Reset on Rebuild (per LnFt)',
          'Door Casing - 3 1/4" - Paint Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
          'Door Casing - 3 1/4" - Paint Grade - Tear Out - NON-WATER (per LnFt)',
          'Door Casing - 3 1/4" - Paint Grade - Remove & Replace on Rebuild (per LnFt)',
          'Door Casing - 3 1/4" - Stain Grade - Detach on Emergency (per LnFt)',
          'Door Casing - 3 1/4" - Stain Grade - Detach & Reset on Rebuild (per LnFt)',
          'Door Casing - 3 1/4" - Stain Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
          'Door Casing - 3 1/4" - Stain Grade - Tear Out - NON-WATER (per LnFt)',
          'Door Casing - 3 1/4" - Stain Grade - Remove & Replace on Rebuild (per LnFt)',
          'Door Casing - 4" - Paint Grade - Detach on Emergency (per LnFt)',
          'Door Casing - 4" - Paint Grade - Detach & Reset on Rebuild (per LnFt)',
          'Door Casing - 4" - Paint Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
          'Door Casing - 4" - Paint Grade - Tear Out - NON-WATER (per LnFt)',
          'Door Casing - 4" - Paint Grade - Remove & Replace on Rebuild (per LnFt)',
          'Door Casing - 4" - Stain Grade - Detach on Emergency (per LnFt)',
          'Door Casing - 4" - Stain Grade - Detach & Reset on Rebuild (per LnFt)',
          'Door Casing - 4" - Stain Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
          'Door Casing - 4" - Stain Grade - Tear Out - NON-WATER (per LnFt)',
          'Door Casing - 4" - Stain Grade - Remove & Replace on Rebuild (per LnFt)',
          'Door Jamb - Paint Grade - Detach on Emergency (per LnFt)',
          'Door Jamb - Paint Grade - Detach & Reset on Rebuild (per LnFt)',
          'Door Jamb - Paint Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
          'Door Jamb - Paint Grade - Tear Out - NON-WATER',
          'Door Jamb - Paint Grade - Remove & Replace on Rebuild (per LnFt)',
          'Door Jamb - Stain Grade - Detach on Emergency (per LnFt)',
          'Door Jamb - Stain Grade - Detach & Reset on Rebuild (per LnFt)',
          'Door Jamb - Stain Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
          'Door Jamb - Stain Grade - Tear Out - NON-WATER (per LnFt)',
          'Door Jamb - Stain Grade - Remove & Replace on Rebuild Only (per LnFt)',
          'Interior Door Slab Only - Paint Grade - Detach on Emergency (each)',
          'Interior Door Slab Only - Paint Grade - Detach & Reset on Rebuild (each)',
          'Interior Door Slab Only - Paint Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Interior Door Slab Only - Paint Grade - Tear Out - NON-WATER (each)',
          'Interior Door Slab Only - Paint Grade - Remove & Replace on Rebuild (each)',
          'Interior Door Slab Only - Stain Grade - Detach on Emergency (each)',
          'Interior Door Slab Only - Stain Grade - Detach & Reset on Rebuild (each)',
          'Interior Door Slab Only - Stain Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Interior Door Slab Only - Stain Grade - Tear Out - NON-WATER (each)',
          'Interior Door Slab Only - Stain Grade - Remove & Replace on Rebuild (each)',
          'Interior Double Door Slab Only - Paint Grade - Detach on Emergency (each)',
          'Interior Double Door Slab Only - Paint Grade - Detach & Reset on Rebuild (each)',
          'Interior Double Door Slab Only - Paint Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Interior Double Door Slab Only - Paint Grade - Tear Out - NON-WATER (each)',
          'Interior Double Door Slab Only - Paint Grade - Remove & Replace on Rebuild (each)',
          'Interior Double Door Slab Only - Stain Grade - Detach on Emergency (each)',
          'Interior Double Door Slab Only - Stain Grade - Detach & Reset on Rebuild (each)',
          'Interior Double Door Slab Only - Stain Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Interior Double Door Slab Only - Stain Grade - Tear Out - NON-WATER (each)',
          'Interior Double Door Slab Only - Stain Grade - Remove & Replace on Rebuild (each)',
          'Interior French Door Slab Only - Paint Grade - Detach on Emergency (each)',
          'Interior French Door Slab Only - Paint Grade - Detach & Reset on Rebuild (each)',
          'Interior French Door Slab Only - Paint Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Interior French Door Slab Only - Paint Grade - Tear Out - NON-WATER (each)',
          'Interior French Door Slab Only - Paint Grade - Remove & Replace on Rebuild (each)',
          'Interior French Door Slab Only - Stain Grade - Detach on Emergency (each)',
          'Interior French Door Slab Only - Stain Grade - Detach & Reset on Rebuild (each)',
          'Interior French Door Slab Only - Stain Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Interior French Door Slab Only - Stain Grade - Tear Out - NON-WATER (each)',
          'Interior French Door Slab Only - Stain Grade - Remove & Replace on Rebuild (each)',
          'Interior Double French Door Slab Only - Paint Grade - Detach on Emergency (each)',
          'Interior Double French Door Slab Only - Paint Grade - Detach & Reset on Rebuild (each)',
          'Interior Double French Door Slab Only - Paint Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Interior Double French Door Slab Only - Paint Grade - Tear Out - NON-WATER (each)',
          'Interior Double French Door Slab Only - Paint Grade - Remove & Replace on Rebuild (each)',
          'Interior Double French Door Slab Only - Stain Grade - Detach on Emergency (each)',
          'Interior Double French Door Slab Only - Stain Grade - Detach & Reset on Rebuild (each)',
          'Interior Double French Door Slab Only - Stain Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Interior Double French Door Slab Only - Stain Grade - Tear Out - NON-WATER (each)',
          'Interior Double French Door Slab Only - Stain Grade - Remove & Replace on Rebuild (each)',
          'Bifold Door Only- Single - Paint Grade - Detach on Emergency (each)',
          'Bifold Door Only- Single - Paint Grade - Detach & Reset on Rebuild (each)',
          'Bifold Door Only- Single - Paint Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Bifold Door Only- Single - Paint Grade - Tear Out - NON-WATER (each)',
          'Bifold Door Only- Single - Paint Grade - Remove & Replace on Rebuild (each)',
          'Bifold Door Only- Single - Stain Grade - Detach on Emergency (each)',
          'Bifold Door Only- Single - Stain Grade - Detach & Reset on Rebuild (each)',
          'Bifold Door Only- Single - Stain Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Bifold Door Only- Single - Stain Grade - Tear Out - NON-WATER (each)',
          'Bifold Door Only- Single - Stain Grade - Remove & Replace on Rebuild (each)',
          'Bifold Door Only - Double - Paint Grade - Detach on Emergency (each)',
          'Bifold Door Only - Double - Paint Grade - Detach & Reset on Rebuild (each)',
          'Bifold Door Only - Double - Paint Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Bifold Door Only - Double - Paint Grade - Tear Out - NON-WATER (each)',
          'Bifold Door Only - Double - Paint Grade - Remove & Replace on Rebuild (each)',
          'Bifold Door Only - Double - Stain Grade - Detach on Emergency (each)',
          'Bifold Door Only - Double - Stain Grade - Detach & Reset on Rebuild (each)',
          'Bifold Door Only - Double - Stain Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Bifold Door Only - Double - Stain Grade - Tear Out - NON-WATER (each)',
          'Bifold Door Only - Double - Stain Grade - Remove & Replace on Rebuild (each)',
          'Bypass Door Only - Paint Grade - Detach on Emergency (each)',
          'Bypass Door Only - Paint Grade - Detach & Reset on Rebuild (each)',
          'Bypass Door Only - Paint Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Bypass Door Only - Paint Grade - Tear Out - NON-WATER (each)',
          'Bypass Door Only - Paint Grade - Remove & Replace on Rebuild (each)',
          'Bypass Door Only - Stain Grade - Detach on Emergency (each)',
          'Bypass Door Only - Stain Grade - Detach & Reset on Rebuild (each)',
          'Bypass Door Only - Stain Grade - Tear Out - CAT 1 or CAT 3 (each)',
          'Bypass Door Only - Stain Grade - Tear Out - NON-WATER (each)',
          'Bypass Door Only - Stain Grade - Remove & Replace on Rebuild (each)',
          'Bypass Door Only - Mirrored - Detach on Emergency (each)',
          'Bypass Door Only - Mirrored - Detach & Reset on Rebuild (each)',
          'Bypass Door Only - Mirrored - Tear Out - CAT 1 or CAT 3 (each)',
          'Bypass Door Only - Mirrored - Tear Out - NON-WATER (each)',
          'Bypass Door Only - Mirrored - Remove & Replace on Rebuild (each)',
          'Exterior Door Slab Only - Single - Paint Grade - Remove & Replace on Emergency (each)',
          'Exterior Door Slab Only - Single - Paint Grade - Remove & Replace on Rebuild Only (each)',
          'Exterior Door Slab Only - Single - Paint Grade - Paint on Rebuid Only (each)',
          'Exterior Door Slab Only - Single - Stain Grade - Remove & Replace on Emergency (each)',
          'Exterior Door Slab Only - Single - Stain Grade - Remove & Replace on Rebuild Only (each)',
          'Exterior Door Slab Only - Single - Stain Grade - Refinish on Rebuid Only (each)',
          'Window Casings/Jambs/Windows Unaffected',
    'Window Casing - 2 1/4" - Paint Grade - Detach on Emergency (per LnFt)',
    'Window Casing - 2 1/4" - Paint Grade - Detach & Reset on Rebuild (per LnFt)',
    'Window Casing - 2 1/4" - Paint Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
    'Window Casing - 2 1/4" - Paint Grade - Tear Out - NON-WATER (per LnFt)',
    'Window Casing - 2 1/4" - Paint Grade - Remove & Replace on Rebuild (per LnFt)',
    'Window Casing - 2 1/4" - Stain Grade - Detach on Emergency (per LnFt)',
    'Window Casing - 2 1/4" - Stain Grade - Detach & Reset on Rebuild (per LnFt)',
    'Window Casing - 2 1/4" - Stain Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
    'Window Casing - 2 1/4" - Stain Grade - Tear Out - NON-WATER (per LnFt)',
    'Window Casing - 2 1/4" - Stain Grade - Remove & Replace on Rebuild (per LnFt)',
    'Window Casing - 3 1/4" - Paint Grade - Detach on Emergency (per LnFt)',
    'Window Casing - 3 1/4" - Paint Grade - Detach & Reset on Rebuild (per LnFt)',
    'Window Casing - 3 1/4" - Paint Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
    'Window Casing - 3 1/4" - Paint Grade - Tear Out - NON-WATER (per LnFt)',
    'Window Casing - 3 1/4" - Paint Grade - Remove & Replace on Rebuild (per LnFt)',
    'Window Casing - 3 1/4" - Stain Grade - Detach on Emergency (per LnFt)',
    'Window Casing - 3 1/4" - Stain Grade - Detach & Reset on Rebuild (per LnFt)',
    'Window Casing - 3 1/4" - Stain Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
    'Window Casing - 3 1/4" - Stain Grade - Tear Out - NON-WATER (per LnFt)',
    'Window Casing - 3 1/4" - Stain Grade - Remove & Replace on Rebuild (per LnFt)',
    'Window Casing - 4" - Paint Grade - Detach on Emergency (per LnFt)',
    'Window Casing - 4" - Paint Grade - Detach & Reset on Rebuild (per LnFt)',
    'Window Casing - 4" - Paint Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
    'Window Casing - 4" - Paint Grade - Tear Out - NON-WATER (per LnFt)',
    'Window Casing - 4" - Paint Grade - Remove & Replace on Rebuild (per LnFt)',
    'Window Casing - 4" - Stain Grade - Detach on Emergency (per LnFt)',
    'Window Casing - 4" - Stain Grade - Detach & Reset on Rebuild (per LnFt)',
    'Window Casing - 4" - Stain Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
    'Window Casing - 4" - Stain Grade - Tear Out - NON-WATER (per LnFt)',
    'Window Casing - 4" - Stain Grade - Remove & Replace on Rebuild (per LnFt)',
    'Window Sill - Paint Grade - Detach on Emergency (per LnFt)',
    'Window Sill - Paint Grade - Detach & Reset on Rebuild (per LnFt)',
    'Window Sill - Paint Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
    'Window Sill - Paint Grade - Tear Out - NON-WATER (per LnFt)',
    'Window Sill - Paint Grade - Remove & Replace on Rebuild (per LnFt)',
    'Window Sill - Stain Grade - Detach on Emergency (per LnFt)',
    'Window Sill - Stain Grade - Detach & Reset on Rebuild (per LnFt)',
    'Window Sill - Stain Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
    'Window Sill - Stain Grade - Tear Out - NON-WATER (per LnFt)',
    'Window Sill - Stain Grade - Remove & Replace on Rebuild (per LnFt)',
    'Window Stool & Apron - Paint Grade - Detach on Emergency (per LnFt)',
    'Window Stool & Apron - Paint Grade - Detach & Reset on Rebuild (per LnFt)',
    'Window Stool & Apron - Paint Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
    'Window Stool & Apron - Paint Grade - Tear Out - NON-WATER (per LnFt)',
    'Window Stool & Apron - Paint Grade - Remove & Replace on Rebuild (per LnFt)',
    'Window Stool & Apron - Stain Grade - Detach on Emergency (per LnFt)',
    'Window Stool & Apron - Stain Grade - Detach & Reset on Rebuild (per LnFt)',
    'Window Stool & Apron - Stain Grade - Tear Out - CAT 1 or CAT 3 (per LnFt)',
    'Window Stool & Apron - Stain Grade - Tear Out - NON-WATER (per LnFt)',
    'Window Stool & Apron - Stain Grade - Remove & Replace on Rebuild (per LnFt)',
    'Window - Vinyl - Casement - 3-5 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Casement - 6-8 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Casement - 9-13 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Double Hung - 4-8 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Double Hung - 9-12 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Double Hung - 13-19 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Doulbe Hung - 20-28 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Horizontal Sliding - 3-11 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Horizontal Sliding - 12-23 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Horizontal Sliding - 24-32 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Horizontal Sliding - 33-40 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Picture - 3-11 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Picture - 12-23 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Picture - 24-32 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Vinyl - Picture - 33-40 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Aluminum - Casement - 3-5 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Aluminum - Casement - 6-8 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Aluminum - Casement - 9-13 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Aluminum - Horizontal Sliding - 3-11 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Aluminum - Horizontal Sliding - 12-23 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Aluminum - Horizontal Sliding - 24-32 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Aluminum - Horizontal Sliding - 33-40 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Aluminum - Picture - 3-11 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Aluminum - Picture - 12-23 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Aluminum - Picture - 24-32 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Aluminum - Picture - 33-40 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Casement - 3-5 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Casement - 6-8 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Casement - 9-13 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Double Hung - 4-8 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Double Hung - 9-12 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Double Hung - 12-19 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Doulbe Hung - 20-28 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Horizontal Sliding - 3-11 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Horizontal Sliding - 12-23 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Horizontal Sliding - 24-32 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Horizontal Sliding - 33-40 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Picture - 3-11 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Picture - 12-23 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Picture - 24-32 Square Feet - Remove & Replace on Rebuild (each)',
    'Window - Wood - Picture - 33-40 Square Feet - Remove & Replace on Rebuild (each)',
    'BID ITEM / Used Subtrade for Windows (can be partial or full)'
      ],

    answers8: [
        'Cabinets and Shelving Unaffected',
          'TOE KICK ONLY- Paint Grade - Detach on Emergency (per LnFt)',
          'TOE KICK ONLY- Paint Grade - Detach & Reset on Rebuild (per LnFt)',
          'TOE KICK ONLY- Paint Grade - Tear Out on Emergency - CAT 1 (per LnFt)',
          'TOE KICK ONLY- Paint Grade - Tear Out on Emergency - CAT 3 (per LnFt)',
          'TOE KICK ONLY- Paint Grade - Remove & Replace on Rebuild (per LnFt)',
          'TOE KICK ONLY- Stain Grade - Detach on Emergency (per LnFt)',
          'TOE KICK ONLY- Stain Grade - Detach & Reset on Rebuild (per LnFt)',
          'TOE KICK ONLY- Stain Grade - Tear Out on Emergency - CAT 1 (per LnFt)',
          'TOE KICK ONLY- Stain Grade - Tear Out on Emergency - CAT 3 (per LnFt)',
          'TOE KICK ONLY- Stain Grade - Remove & Replace on Rebuild (per LnFt)',
          'CABINETS - Lower (Base) Unit & Flat Laminate C. Top - Detach BOTH on Emergency (per LnFt)',
          'CABINETS - Lower (Base) Unit & Flat Laminate C. Top - Detach & Reset BOTH on Rebuild (per LnFt)',
          'CABINETS - Lower (Base) Unit & Flat Laminate C. Top - Tear Out on Emergency / SAVE C. Top (per LnFt)',
          'CABINETS - Lower (Base) Unit & Flat Laminate C. Top - Tear Out BOTH on Emergency (per LnFt)',
          'CABINETS - Lower (Base) Unit & Flat Laminate C. Top - Rem. & Rep. on Rebuild / Save C. Top (per LnFt)',
          'CABINETS - Lower (Base) Unit & Flat Laminate C. Top - Remove & Replace BOTH on Rebuild (per LnFt)',
          'CABINETS - Lower (Base) Unit & Post Form C. Top - Detach BOTH on Emergency (per LnFt)',
          'CABINETS - Lower (Base) Unit & Post Form C. Top - Detach & Reset BOTH on Rebuild (per LnFt)',
          'CABINETS - Lower (Base) Unit & Post Form C. Top - Tear Out on Emergency / SAVE C. Top (per LnFt)',
          'CABINETS - Lower (Base) Unit & Post Form C. Top - Tear Out BOTH on Emergency (per LnFt)',
          'CABINETS - Lower (Base) Unit & Post Form C. Top - Rem. & Rep. on Rebuild / Save C. Top (per LnFt)',
          'CABINETS - Lower (Base) Unit & Post Form C. Top - Remove & Replace BOTH on Rebuild (per LnFt)',
          'CABINETS - Lower (Base) Unit & Solid Surface C. Top - Detach on BOTH Emergency (per LnFt)',
          'CABINETS - Lower (Base) Unit & Solid Surface C. Top - Detach & Reset BOTH on Rebuild (per LnFt)',
          'CABINETS - Lower (Base) Unit & Solid Surface C. Top - Tear Out on Emergency / SAVE C. Top (per LnFt)',
          'CABINETS - Lower (Base) Unit & Solid Surface C. Top - Tear Out BOTH on Emergency (per LnFt)',
          'CABINETS - Lower (Base) Unit & Solid Surface C. Top - Rem. & Rep. on Rebuild / Save C. Top (per LnFt)',
          'CABINETS - Lower (Base) Unit & Solid Surface C. Top - Remove & Replace BOTH on Rebuild (per LnFt)',
          'CABINETS - Lower (Base) Unit & Tile C. Top - Tear Out BOTH on Emergency (per LnFt)',
          'CABINETS - Lower (Base) Unit & Tile C. Top - Remove & Replace BOTH on Rebuild (per LnFt)',
          'CABINETS - Lower (Base) Unit & Butcher Block C. Top - Detach BOTH on Emergency (per LnFt)',
          'CABINETS - Lower (Base) Unit & Butcher Block C. Top - Detach & Reset BOTH on Rebuild (per LnFt)',
          'CABINETS - Lower (Base) Unit & Butcher Block C. Top - Tear Out on Emergency / SAVE C. Top (per LnFt)',
          'CABINETS - Lower (Base) Unit & Butcher Block C. Top - Tear Out BOTH on Emergency (per LnFt)',
          'CABINETS - Lower (Base) Unit & Butcher Block C. Top - Rem. & Rep. on Rebuild / Save C. Top (per LnFt)',
          'CABINETS - Lower (Base) Unit & Butcher Block C. Top - Remove & Replace BOTH on Rebuild (per LnFt)',
          'CABINETS - Lower (Base) Unit & Concrete C. Top - Detach on BOTH Emergency (per LnFt)',
          'CABINETS - Lower (Base) Unit & Concrete C. Top - Detach & Reset BOTH on Rebuild (per LnFt)',
          'CABINETS - Lower (Base) Unit & Concrete C. Top - Tear Out on Emergency / SAVE C. Top (per LnFt)',
          'CABINETS - Lower (Base) Unit & Concrete C. Top - Tear Out BOTH on Emergency (per LnFt)',
          'CABINETS - Lower (Base) Unit & Concrete C. Top - Rem. & Rep. on Rebuild / Save C. Top (per LnFt)',
          'CABINETS - Lower (Base) Unit & Concrete C. Top - Remove & Replace BOTH on Rebuild (per LnFt)',
          'CABINETS - Upper (Wall) Unit - Detach on Emergency (per LnFt)',
          'CABINETS - Upper (Wall) Unit - Detach & Reset on Rebuild (per LnFt)',
          'CABINETS - Upper (Wall) Unit - Tear Out on Emergency (per LnFt)',
          'CABINETS - Upper (Wall) Unit - Remove & Replace on Rebuild (per LnFt)',
          'CABINETS - Full Height Unit - Detach on Emergency (per LnFt)',
          'CABINETS - Full Height Unit - Detach & Reset on Rebuild (per LnFt)',
          'CABINETS - Full Height Unit - Tear Out on Emergency (per LnFt)',
          'CABINETS - Full Height Unit - Remove & Replace on Rebuild (per LnFt)',
          'CABINETS - Panels - Side/End/Back - Detach on Emergency (per SqFt)',
          'CABINETS - Panels - Side/End/Back - Detach & Reset on Rebuild (per SqFt)',
          'CABINETS - Panels - Side/End/Back - Tear Out on Emergency (per SqFt)',
          'CABINETS - Panels - Side/End/Back - Remove & Replace on Rebuild (per SqFt)',
          'CABINETS - Vanity Unit & Flat Laminate C. Top - Detach BOTH on Emergency (per LnFt)',
          'CABINETS - Vanity Unit & Flat Laminate C. Top - Detach & Reset BOTH on Rebuild (per LnFt)',
          'CABINETS - Vanity Unit & Flat Laminate C. Top - Tear Out on Emergency / SAVE C. Top (per LnFt)',
          'CABINETS - Vanity Unit & Flat Laminate C. Top - Tear Out BOTH on Emergency (per LnFt)',
          'CABINETS - Vanity Unit & Flat Laminate C. Top - Rem. & Rep. on Rebuild / Save C. Top (per LnFt)',
          'CABINETS - Vanity Unit & Flat Laminate C. Top - Remove & Replace BOTH on Rebuild (per LnFt)',
          'CABINETS - Vanity Unit & Post Form C. Top - Detach BOTH on Emergency (per LnFt)',
          'CABINETS - Vanity Unit & Post Form C. Top - Detach & Reset BOTH on Rebuild (per LnFt)',
          'CABINETS - Vanity Unit & Post Form C. Top - Tear Out on Emergency / SAVE C. Top (per LnFt)',
          'CABINETS - Vanity Unit & Post Form C. Top - Tear Out BOTH on Emergency (per LnFt)',
          'CABINETS - Vanity Unit & Post Form C. Top - Rem. & Rep. on Rebuild / Save C. Top (per LnFt)',
          'CABINETS - Vanity Unit & Post Form C. Top - Remove & Replace BOTH on Rebuild (per LnFt)',
          'CABINETS - Vanity Unit & Solid Surface C. Top - Detach on BOTH Emergency (per LnFt)',
          'CABINETS - Vanity Unit & Solid Surface C. Top - Detach & Reset BOTH on Rebuild (per LnFt)',
          'CABINETS - Vanity Unit & Solid Surface C. Top - Tear Out on Emergency / SAVE C. Top (per LnFt)',
          'CABINETS - Vanity Unit & Solid Surface C. Top - Tear Out BOTH on Emergency (per LnFt)',
          'CABINETS - Vanity Unit & Solid Surface C. Top - Rem. & Rep. on Rebuild / Save C. Top (per LnFt)',
          'CABINETS - Vanity Unit & Solid Surface C. Top - Remove & Replace BOTH on Rebuild (per LnFt)',
          'CABINETS - Vanity Unit & Tile C. Top - Tear Out BOTH on Emergency (per LnFt)',
          'CABINETS - Vanity Unit & Tile C. Top - Remove & Replace BOTH on Rebuild (per LnFt)',
          'CABINETS - Vanity Unit & Cultured Marble One Sink - Detach BOTH on Emergency (per LnFt)',
          'CABINETS - Vanity Unit & Cultured Marble One Sink - Detach & Reset BOTH on Rebuild (per LnFt)',
          'CABINETS - Vanity Unit & Cultured Marble One Sink - Tear Out on Emergency / SAVE C. Top (per LnFt)',
          'CABINETS - Vanity Unit & Cultured Marble One Sink - Tear Out BOTH on Emergency (per LnFt)',
          'CABINETS - Vanity Unit & Cultured Marble One Sink - Rem. & Rep. on Rebuild / Save C. Top (per LnFt)',
          'CABINETS - Vanity Unit & Cultured Marble One Sink - Remove & Replace BOTH on Rebuild (per LnFt)',
          'CABINETS - Vanity Unit & Cultured Marble Two Sinks - Detach on BOTH Emergency (per LnFt)',
          'CABINETS - Vanity Unit & Cultured Marble Two Sinks - Detach & Reset BOTH on Rebuild (per LnFt)',
          'CABINETS - Vanity Unit & Cultured Marble Two Sinks - Tear Out on Emergency / SAVE C. Top (per LnFt)',
          'CABINETS - Vanity Unit & Cultured Marble Two Sinks - Tear Out BOTH on Emergency (per LnFt)',
          'CABINETS - Vanity Unit & Cultured Marble Two Sinks - Rem. & Rep. on Rebuild / Save C. Top (per LnFt)',
          'CABINETS - Vanity Unit & Cultured Marble Two Sinks - Remove & Replace BOTH on Rebuild (per LnFt)',
          'CABINETS - Small Bathroom Wall Cabinet - Detach on Emergency (each)',
          'CABINETS - Small Bathroom Wall Cabinet - Detach & Reset on Rebuild (each)',
          'CABINETS - Small Bathroom Wall Cabinet - Tear Out on Emergency (each)',
          'CABINETS - Small Bathroom Wall Cabinet - Remove & Replace on Rebuild (each)',
          'CABINETS - Medicine Cabinet - Detach on Emergency (each)',
          'CABINETS - Medicine Cabinet - Detach & Reset on Rebuild (each)',
          'CABINETS - Medicine Cabinet - Tear Out on Emergency (each)',
          'CABINETS - Medicine Cabinet - Remove & Replace on Rebuild (each)',
          'COUNTER TOP ONLY- Flat Laminate - Detach on Emergency (per LnFt)',
          'COUNTER TOP ONLY- Flat Laminate - Detach & Reset on Rebuild (per LnFt)',
          'COUNTER TOP ONLY- Flat Laminate - Tear Out on Emergency (per LnFt)',
          'COUNTER TOP ONLY- Flat Laminate - Remove & Replace on Rebuild (per LnFt)',
          'COUNTER TOP ONLY- Post Form - Detach on Emergency (per LnFt)',
          'COUNTER TOP ONLY- Post Form - Detach & Reset on Rebuild (per LnFt)',
          'COUNTER TOP ONLY- Post Form - Tear Out on Emergency (per LnFt)',
          'COUNTER TOP ONLY- Post Form - Remove & Replace on Rebuild (per LnFt)',
          'COUNTER TOP ONLY- Solid Surface - Reset (per SqFt)',
          'COUNTER TOP ONLY- Solid Surface - Detach & Reset (per SqFt)',
          'COUNTER TOP ONLY- Solid Surface - Reset (per SqFt)',
          'COUNTER TOP ONLY- Solid Surface - Remove & Replace (per SqFt)',
    'COUNTER TOP ONLY- Tile - Tear Out on Emergency (per SqFt)',
    'COUNTER TOP ONLY- Tile - Remove & Replace on Rebuild (per SqFt)',
    'COUNTER TOP ONLY- Butcher Block - Detach on Emergency (per SqFt)',
    'COUNTER TOP ONLY- Butcher Block - Detach & Reset on Rebuild (per SqFt)',
    'COUNTER TOP ONLY- Butcher Block - Tear Out on Emergency (per SqFt)',
    'COUNTER TOP ONLY- Butcher Block- Remove & Replace on Rebuild (per SqFt)',
    'COUNTER TOP ONLY- Concrete - Detach on Emergency (per SqFt)',
    'COUNTER TOP ONLY- Concrete - Detach & Reset on Rebuild (per SqFt)',
    'COUNTER TOP ONLY- Concrete - Tear Out on Emergency (per SqFt)',
    'COUNTER TOP ONLY- Concrete - Remove & Replace on Rebuild (per SqFt)',
    'COUNTER TOP ONLY- Cultured Marble One Sink - Detach on Emergency (per LnFt)',
    'COUNTER TOP ONLY- Cultured Marble One Sink - Detach & Reset on Rebuild (per LnFt)',
    'COUNTER TOP ONLY- Cultured Marble One Sink - Tear Out on Emergency (per LnFt)',
    'COUNTER TOP ONLY- Cultured Marble One Sink - Remove & Replace on Rebuild (per LnFt)',
    'COUNTER TOP ONLY- Cultured Marble Two Sinks - Detach on Emergency (per LnFt)',
    'COUNTER TOP ONLY- Cultured Marble Two Sinks - Detach & Reset on Rebuild (per LnFt)',
    'COUNTER TOP ONLY- Cultured Marble Two Sinks - Tear Out on Emergency (per LnFt)',
    'COUNTER TOP ONLY- Cultured Marble Two Sinks - Remove & Replace on Rebuild (per LnFt)',     
],

answers9: [
    'Items Unaffected',
      'Toilet - Detach on Emergency (each)',
      'Toilet - Detach & Reset on Rebuild (each)',
      'Toilet - Tear Out on Emergency (each)',
      'Toilet - Remove & Replace on Rebuild (each)',
      'Sink - Bathroom - Single with Faucet - Detach on Emergency (each)',
      'Sink - Bathroom - Single with Faucet - Detach & Reset on Rebuild (each)',
      'Sink - Bathroom - Single with Faucet - Tear Out Emergency (each)',
      'Sink - Bathroom - Single with Faucet - Remove & Replace on Rebuild (each)',
      'Sink - Bathroom - Pedestal with Faucet - Detach on Emergency (each)',
      'Sink - Bathroom - Pedestal with Faucet - Detach & Reset on Rebuild (each)',
      'Sink - Bathroom - Pedestal with Faucet - Tear Out Emergency (each)',
      'Sink - Bathroom - Pedestal with Faucet - Remove & Replace on Rebuild (each)',
      'Sink - Kitchen - Single with Faucet - Detach on Emergency (each)',
      'Sink - Kitchen - Single with Faucet - Detach & Reset on Rebuild (each)',
      'Sink - Kitchen - Single with Faucet - Tear Out Emergency (each)',
      'Sink - Kitchen - Single with Faucet - Remove & Replace on Rebuild (each)',
      'Sink - Kitchen - Double with Faucet - Detach on Emergency (each)',
      'Sink - Kitchen - Double with Faucet - Detach & Reset on Rebuild (each)',
      'Sink - Kitchen - Double with Faucet - Tear Out Emergency (each)',
      'Sink - Kitchen - Double with Faucet - Remove & Replace on Rebuild (each)',
      'Sink - Kitchen - Undermount with NO Faucet - Detach on Emergency (each)',
      'Sink - Kitchen - Undermount with NO Faucet - Detach & Reset on Rebuild (each)',
      'Sink - Kitchen - Undermount with Faucet - Tear Out Emergency (each)',
      'Sink - Kitchen - Undermount with Faucet - Remove & Replace on Rebuild (each)',
      'Sink - Laundry Tub with Faucet - Detach on Emergency (each)',
      'Sink - Laundry Tub with Faucet - Detach & Reset on Rebuild (each)',
      'Sink - Laundry Tub with Faucet - Tear Out Emergency (each)',
      'Sink - Laundry Tub with Faucet - Remove & Replace on Rebuild (each)',
      'Faucet Only - Bathroom - Detach on Emergency (each)',
      'Faucet Only - Bathroom - Single - Detach & Reset on Rebuild (each)',
      'Faucet Only - Bathroom - Single - Tear Out Emergency (each)',
      'Faucet Only - Bathroom - Single - Remove & Replace on Rebuild (each)',
      'Faucet Only - Kitchen - Detach on Emergency (each)',
      'Faucet Only - Kitchen - Detach & Reset on Rebuild (each)',
      'Faucet Only - Kitchen - Tear Out Emergency (each)',
      'Faucet Only - Kitchen - Remove & Replace on Rebuild (each)',
      'Faucet Only - Bathtub - Regular - TRIM ONLY - Detach on Emergency (each)',
      'Faucet Only - Bathtub - Regular - TRIM ONLY - Detach & Reset on Rebuild (each)',
      'Faucet Only - Bathtub - Regular - Detach on Emergency (each)',
      'Faucet Only - Bathtub - Regular - Detach & Reset on Rebuild (each)',
      'Faucet Only - Bathtub - Regular - Tear Out Emergency (each)',
      'Faucet Only - Bathtub - Regluar - Remove & Replace on Rebuild (each)',
      'Faucet Only - Bathtub - Built-in/Jetted - TRIM ONLY - Detach on Emergency (each)',
      'Faucet Only - Bathtub - Built in/Jetted - TRIM ONLY - Detach & Reset on Rebuild (each)',
      'Faucet Only - Bathtub - Built-in/Jetted - Detach on Emergency (each)',
      'Faucet Only - Bathtub - Built in/Jetted - Detach & Reset on Rebuild (each)',
      'Faucet Only - Bathtub - Built in/Jetted - Tear Out Emergency (each)',
      'Faucet Only - Bathtub - Built in/Jetted - Remove & Replace on Rebuild (each)',
      'Faucet Only - Bathtub - Stand Alone/Clawfoot - TRIM ONLY - Detach on Emergency (each)',
      'Faucet Only - Bathtub - Stand Alone/Clawfoot - TRIM ONLY - Detach & Reset on Rebuild (each)',
      'Faucet Only - Bathtub - Stand Alone/Clawfoot - Detach on Emergency (each)',
      'Faucet Only - Bathtub - Stand Alone/Clawfoot - Detach & Reset on Rebuild (each)',
      'Faucet Only - Bathtub - Stand Alone/Clawfoot - Tear Out Emergency (each)',
      'Faucet Only - Bathtub - Stand Alone/Clawfoot - Remove & Replace on Rebuild (each)',
      'Faucet Only - Shower - TRIM ONLY - Detach on Emergency (each)',
      'Faucet Only - Shower - Single - TRIM ONLY - Detach & Reset on Rebuild (each)',
      'Faucet Only - Shower - Single - Detach on Emergency (each)',
      'Faucet Only - Shower - Single - Detach & Reset on Rebuild (each)',
      'Faucet Only - Shower - Single - Tear Out Emergency (each)',
      'Faucet Only - Shower - Single - Remove & Replace on Rebuild (each)',
      'Bathtub - Regular - Detach on Emergency (each)',
      'Bathtub - Regular - Detach & Reset on Rebuild (each)',
      'Bathtub - Regular - Tear Out Emergency (each)',
      'Bathtub - Regular - Remove & Replace on Rebuild (each)',
      'Bathtub - Built-In/Jetted Tub - Detach on Emergency (each)',
      'Bathtub - Built-In/Jetted Tub - Detach & Reset on Rebuild (each)',
      'Bathtub - Built-In/Jetted Tub - Tear Out Emergency (each)',
      'Bathtub - Built-in/Jetted Tub - Remove & Replace on Rebuild (each)',
      'Bathtub - Stand Alone/Clawfoot - Detach on Emergency (each)',
      'Bathtub - Stand Alone/Clawfoot - Detach & Reset on Rebuild (each)',
      'Bathtub - Stand Alone/Clawfoot - Tear Out Emergency (each)',
      'Bathtub - Stand Alone/Clawfoot- Remove & Replace on Rebuild (each)',
      'Bathtub - Fibreglass Surround Only - Tear out on Emergency (each)',
      'Bathtub - Fiberglass Surround Only - Remove & Replace on Rebuild (Each)',
      'Bathtub - Fiberglass Surround & Cement/Greenboard - Tear Out Emergency (Each)',
      'Bathtub - Fibreglass Surround & Cement/Greenboard - Remove & Replace on Rebuild (Each)',
      'Tile Bathtub Surround Only - Tear Out Emergency (per SqFt)',
      'Tile Bathtub Surround Only - Remove & Replace on Rebuild (per SqFt)',
      'Tile Bathtub Surround & Cement/Greenboard (<60 SqFt) - Tear Out Emergency',
      'Tile Bathtub Surround & Cement/Greenboard (<60 SqFt) - Remove & Replace on Rebuild',
      'Tile Shower Surround Only (<60 SqFt) - Tear Out Emergency (per SqFt)',
      'Tile Shower Surround Only (<60 SqFt) - Remove & Replace on Rebuild (per SqFt)',
      'Tile Shower Surround & Cement/Greenboard (<60 SqFt) - Tear Out Emergency (per SqFt)',
      'Tile Shower Surround & Cement/Greenboard (<60 SqFt) - Remove & Replace on Rebuild (per SqFt)',
      'Tile Shower Surround Only (60-100 SqFt) - Tear Out Emergency (per SqFt)',
      'Tile Shower Surround Only (60-100 SqFt) - Remove & Replace on Rebuild (per SqFt)',
      'Tile Shower Surround & Cement/Greenboard (60-100 SqFt) - Tear Out Emergency (per SqFt)',
      'Tile Shower Surround & Cement/Greenboard (60-100 SqFt) - Remove & Replace on Rebuild (per SqFt)',
      'Tile Shower Surround Only (101-120 SqFt) - Tear Out Emergency (per SqFt)',
      'Tile Shower Surround Only (101-120 SqFt) - Remove & Replace on Rebuild (per SqFt)',
      'Tile Shower Surround & Cement/Greenboard (101-120 SqFt) - Tear Out Emergency (per SqFt)',
      'Tile Shower Surround & Cement/Greenboard (101-120 SqFt) - Remove & Replace on Rebuild (per SqFt)',
      'Tile Shower Surround Only (121-150 SqFt) - Tear Out Emergency (per SqFt)',
      'Tile Shower Surround Only (121-150 SqFt) - Remove & Replace on Rebuild (per SqFt)',
      'Tile Shower Surround & Cement/Greenboard (121-150 SqFt) - Tear Out Emergency (per SqFt)',
      'Tile Shower Surround & Cement/Greenboard (121-150 SqFt) - Remove & Replace on Rebuild (per SqFt)',
      'Tile - Wall Only (outside of Shower/Tub Area) - Tear Out Emergency (per SqFt)',
      'Tile - Wall Only (outside of Shower/Tub Area) - Remove & Replace on Rebuild (per SqFt)',
      'Tile - Platform Area(s) & Wood Platform Underlay- Tear Out Emergency (per SqFt)',
      'Tile - Platform Area(s) & Wood Platform Underlay - Remove & Replace on Rebuild (per SqFt)', 
    ],

    answers10: [
        'Items Unaffected',
          'Floor - Door Stop - Detach on Emergency (each)',
          'Floor - Door Stop - Detach & Reset on Emergency (each)',
          'Floor - Door Stop - Tear Out on Emergency (each)',
          'Floor - Door Stop - Detach & Reset on Rebuild (each)',
          'Floor - Door Stop - Remove & Replace on Rebuild (each)',
          'Floor - Register - Detach on Emergency (each)',
          'Floor - Register - Detach & Reset on Emergency (each)',
          'Floor - Register - Tear Out on Emergency (each)',
          'Floor - Register - Detach & Reset on Rebuild (each)',
          'Floor - Register - Remove & Replace on Rebuild (each)',
          'Floor - Register - Wood - Detach on Emergency (each)',
          'Floor - Register - Wood - Detach & Reset on Emergency (each)',
          'Floor - Register - Wood - Tear Out on Emergency (each)',
          'Floor - Register - Wood - Detach & Reset on Rebuild (each)',
          'Floor - Register - Wood - Remove & Replace on Rebuild (each)',
          'Floor - Register - Wood - Detach on Emergency (each)',
          'Floor - Register - Wood - Detach & Reset on Emergency (each)',
          'Floor - Register - Wood - Tear Out on Emergency (each)',
          'Floor - Register - Wood - Detach & Reset on Rebuild (each)',
          'Floor - Register - Wood - Remove & Replace on Rebuild (each)',
          'Floor - Cold Air Return - Small - Detach on Emergency (each)',
          'Floor - Cold Air Return - Small - Detach & Reset on Emergency (each)',
          'Floor - Cold Air Return - Small - Tear Out on Emergency (each)',
          'Floor - Cold Air Return - Small - Detach & Reset on Rebuild (each)',
          'Floor - Cold Air Return - Small - Remove & Replace on Rebuild (each)',
          'Floor - Cold Air Return - Large - Detach on Emergency (each)',
          'Floor - Cold Air Return - Large - Detach & Reset on Emergency (each)',
          'Floor - Cold Air Return - Large - Tear Out on Emergency (each)',
          'Floor - Cold Air Return - Large - Detach & Reset on Rebuild (each)',
          'Floor - Cold Air Return - Large - Remove & Replace on Rebuild (each)',
          'Floor - Cold Air Return - Extra Large - Detach on Emergency (each)',
          'Floor - Cold Air Return - Extra Large - Detach & Reset on Emergency (each)',
          'Floor - Cold Air Return - Extra Large - Tear Out on Emergency (each)',
          'Floor - Cold Air Return - Extra Large - Detach & Reset on Rebuild (each)',
          'Floor - Cold Air Return - Extra Large - Remove & Replace on Rebuild (each)',
          'Floor - Baseboard Heat Cover - 12" - 36" Long - Detach on Emergency (each)',
          'Floor - Baseboard Heat Cover - 12" - 36" Long - Detach & Reset on Emergency (each)',
          'Floor - Baseboard Heat Cover - 12" - 36" Long - Tear Out on Emergency (each)',
          'Floor - Baseboard Heat Cover - 12" - 36" Long - Detach & Reset on Rebuild (each)',
          'Floor - Baseboard Heat Cover - 12" - 36" Long - Remove & Replace on Rebuild (each)',
          'Floor - Baseboard Heat Cover - 37" - 60" Long - Detach on Emergency (each)',
          'Floor - Baseboard Heat Cover - 37" - 60" Long - Detach & Reset on Emergency (each)',
          'Floor - Baseboard Heat Cover - 37" - 60" Long - Tear Out on Emergency (each)',
          'Floor - Baseboard Heat Cover - 37" - 60" Long - Detach & Reset on Rebuild (each)',
          'Floor - Baseboard Heat Cover - 37" - 60" Long - Remove & Replace on Rebuild (each)',
          'Floor - Baseboard Heat Cover - 61" - 84" Long - Detach on Emergency (each)',
          'Floor - Baseboard Heat Cover - 61" - 84" Long - Detach & Reset on Emergency (each)',
          'Floor - Baseboard Heat Cover - 61" - 84" Long - Tear Out on Emergency (each)',
          'Floor - Baseboard Heat Cover - 61" - 84" Long - Detach & Reset on Rebuild (each)',
          'Floor - Baseboard Heat Cover - 61" - 84" Long - Remove & Replace on Rebuild (each)',
          'Floor - Baseboard Heat Cover - over 85" Long - Detach on Emergency (each)',
          'Floor - Baseboard Heat Cover - over 85" Long - Detach & Reset on Emergency (each)',
          'Floor - Baseboard Heat Cover - over 85" Long - Tear Out on Emergency (each)',
          'Floor - Baseboard Heat Cover - over 85" Long - Detach & Reset on Rebuild (each)',
          'Floor - Baseboard Heat Cover - over 85" Long - Remove & Replace on Rebuild (each)',
          'Floor - Baseboard Heat - Steam/Hot Water - Detach on Emergency (per LnFt)',
          'Floor - Baseboard Heat - Steam/Hot Water - Detach & Reset on Emergency (per LnFt)',
          'Floor - Baseboard Heat - Steam/Hot Water - Tear Out on Emergency (per LnFt)',
          'Floor - Baseboard Heat - Steam/Hot Water - Detach & Reset on Rebuild (per LnFt)',
          'Floor - Baseboard Heat - Steam/Hot Water - Remove & Replace on Rebuild (per LnFt)',
          'Floor - Baseboard Heater - Electric - Cover & Ends Only - Detach on Emergency (per LnFt)',
          'Floor - Baseboard Heater - Electric - Cover & Ends Only - Detach & Reset on Emergency (per LnFt)',
          'Floor - Baseboard Heater - Electric - Cover & Ends Only - Tear Out on Emergency (per LnFt)',
          'Floor - Baseboard Heater - Electric - Cover & Ends Only - Detach & Reset on Rebuild (per LnFt)',
          'Floor - Baseboard Heater - Electric - Cover & Ends Only - Remove & Replace on Rebuild (per LnFt)',
          "Floor - Baseboard Heater - Electric - 2' - Detach on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 2' - Detach & Reset on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 2' - Tear Out on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 2' - Detach & Reset on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 2' - Remove & Replace on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 3' - Detach on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 3' - Detach & Reset on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 3' - Tear Out on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 3' - Detach & Reset on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 3' - Remove & Replace on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 4' - Detach on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 4' - Detach & Reset on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 4' - Tear Out on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 4' - Detach & Reset on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 4' - Remove & Replace on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 5' - Detach on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 5' - Detach & Reset on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 5' - Tear Out on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 5' - Detach & Reset on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 5' - Remove & Replace on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 6' - Detach on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 6' - Detach & Reset on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 6' - Tear Out on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 6' - Detach & Reset on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 6' - Remove & Replace on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 7' - Detach on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 7' - Detach & Reset on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 7' - Tear Out on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 7' - Detach & Reset on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 7' - Remove & Replace on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 8' - Detach on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 8' - Detach & Reset on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 8' - Tear Out on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 8' - Detach & Reset on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 8' - Remove & Replace on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 10' - Detach on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 10' - Detach & Reset on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 10' - Tear Out on Emergency (each)",
          "Floor - Baseboard Heater - Electric - 10' - Detach & Reset on Rebuild (each)",
          "Floor - Baseboard Heater - Electric - 10' - Remove & Replace on Rebuild (each)",
          'Floor - Hot Water Heater - Detach & Reset on Emergency (each)',
          'Floor - Hot Water Heater - Detach & Reset on Rebuild (each)',
          'Floor - Hot Water Heater - Detach & Reset on BOTH Emergency & Rebuild (each)',
          'BID ITEM / Used Subtrade for Smaller Items - Floor (can be partial or full)',     ]
}