import { useEffect, useState } from "react";
import Sidebar from "../../../Sidebar";
import { axiosAuth } from "../../../../lib/axiosInstance";
import Skeleton from "react-loading-skeleton";
import { MdEdit, MdDelete } from "react-icons/md";
import Modal from "react-modal";
import { AiFillEdit } from "react-icons/ai";

Modal.setAppElement("#root");

const GeneralInfo = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        code: "",
        renderable_type: "dropdown",
        dropdown: [],
        second_values: [],
    });
    const [newDropdownValue, setNewDropdownValue] = useState("");
    const [newSecondValue, setNewSecondValue] = useState("");

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axiosAuth.get("/get-general-info-data");
                setItems(response.data);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };
        fetchItems();
    }, []);

    const openModal = (item) => {
        setSelectedItem(item);
        setFormData({
            title: item?.title || "",
            code: item?.code || "",
            renderable_type: item?.renderable_type || "dropdown",
            dropdown: item?.possible_answers?.dropdown || [],
            second_values: item?.possible_answers?.second_values || [],
        });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null);
        setFormData({
            title: "",
            code: "",
            renderable_type: "dropdown",
            dropdown: [],
            second_values: [],
        });
        setNewDropdownValue("");
        setNewSecondValue("");
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Handling Dropdown Values
    const addDropdownValue = () => {
        if (newDropdownValue.trim() !== "") {
            setFormData({ ...formData, dropdown: [...formData.dropdown, newDropdownValue] });
            setNewDropdownValue("");
        }
    };

    const removeDropdownValue = (index) => {
        setFormData({ ...formData, dropdown: formData.dropdown.filter((_, i) => i !== index) });
    };

    // Handling Second Values
    const addSecondValue = () => {
        if (newSecondValue.trim() !== "") {
            setFormData({ ...formData, second_values: [...formData.second_values, newSecondValue] });
            setNewSecondValue("");
        }
    };

    const removeSecondValue = (index) => {
        setFormData({ ...formData, second_values: formData.second_values.filter((_, i) => i !== index) });
    };

    const handleEdit = async () => {
        try {
            await axiosAuth.put(`/update-general-info/${selectedItem.id}`, {
                ...formData,
                possible_answers: {
                    dropdown: formData.dropdown,
                    second_values: formData.second_values,
                },
            });
            setItems(items.map((item) => (item.id === selectedItem.id ? { ...item, ...formData } : item)));
            closeModal();
        } catch (error) {
            console.error("Error updating item:", error);
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Sidebar>
                <div className="flex-1 p-8 bg-gray-100 max-h-screen overflow-scroll">
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <div className="text-2xl font-bold mb-4">General Information List</div>
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white border">
                                <thead>
                                    <tr className="bg-[#36154e] text-white text-left">
                                        <th className="px-4 py-2">Title</th>
                                        <th className="px-4 py-2">Code</th>
                                        <th className="px-4 py-2">Renderable Type</th>
                                        <th className="px-4 py-2">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="4">
                                                <Skeleton count={5} />
                                            </td>
                                        </tr>
                                    ) : (
                                        items.map((item) => (
                                            <tr key={item.id} className="hover:bg-gray-50">
                                                <td className="px-4 py-3">{item.title}</td>
                                                <td className="px-4 py-3">{item.code}</td>
                                                <td className="px-4 py-3">{item.renderable_type ?? "-"}</td>
                                                <td className="px-4 py-3 flex space-x-3">
                                                    <button onClick={() => openModal(item)} className="text-green-500 hover:text-green-700 font-semibold">
                                                        <AiFillEdit />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Sidebar>

            {/* Modal for Editing */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded shadow-md w-full max-w-3xl">
                        <h2 className="text-xl font-bold mb-4">Edit General Info</h2>
                        <div className="flex gap-8">
                            <div>
                                <div className="mb-4">
                                    <label className="block text-gray-700">Title</label>
                                    <input type="text" name="title" value={formData.title} onChange={handleInputChange} className="w-full border p-2 rounded" />
                                </div>



                                {/* Dropdown Values */}
                                <div className="mb-4">
                                    <label className="block text-gray-700">Dropdown Values</label>
                                    {formData.dropdown.map((val, index) => (
                                        <div key={index} className="flex items-center space-x-2 mb-2">
                                            <span>{val}</span>
                                            <button onClick={() => removeDropdownValue(index)} className="text-red-500">❌</button>
                                        </div>
                                    ))}
                                    <input value={newDropdownValue} onChange={(e) => setNewDropdownValue(e.target.value)} placeholder="Add dropdown value" className="w-full border p-2 rounded mb-2" />
                                    <button onClick={addDropdownValue} className="bg-[#4c0f78] text-white px-4 py-2 rounded mb-3">Add</button>
                                </div>
                            </div>

                            {/* Second Values */}
                            <div>
                                <div className="mb-4">
                                    <label className="block text-gray-700">Code</label>
                                    <input type="text" name="code" value={formData.code} onChange={handleInputChange} className="w-full border p-2 rounded" />
                                </div>
                                <div className="overflow-y-auto border rounded-md p-4"
                                    style={{ maxHeight: "300px" }}>
                                    <label className="block text-gray-700">Second Values</label>
                                    {formData.second_values.map((val, index) => (
                                        <div key={index} className="flex items-center space-x-2 mb-2">
                                            <span>{val}</span>
                                            <button onClick={() => removeSecondValue(index)} className="text-red-500">❌</button>
                                        </div>
                                    ))}
                                    <input value={newSecondValue} onChange={(e) => setNewSecondValue(e.target.value)} placeholder="Add second value" className="w-full border p-2 rounded mb-2" />
                                    <button onClick={addSecondValue} className="bg-[#4c0f78] text-white px-4 py-2 rounded mb-3">Add</button>
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={closeModal} // ✅ This button will close the modal
                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                        >
                            Cancel
                        </button>
                        <button onClick={handleEdit} className="bg-[#4c0f78] text-white px-4 py-2 rounded">Save</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GeneralInfo;
