import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { axiosAuth } from "../../../../lib/axiosInstance";
import Sidebar from "../../../Sidebar";
import { LiaEyeSolid } from "react-icons/lia";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { CloseCircle } from "iconsax-react";

const QuestionList = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [valueData, setValueData] = useState([]);
  localStorage.setItem("Macros", code);
  useEffect(() => {
    fetchData();
  }, [code]);

  const fetchData = async () => {
    const response = await axiosAuth.post("/get-question-list", { code });
    setQuestions(response.data);
  };

  const deleteQuestion = async (id) => {
    if (window.confirm("Are you sure you want to delete this question?")) {
      try {
        await axiosAuth.post("/delete-question", { id });
        fetchData(); // Refresh the question list
      } catch (error) {
        console.error("Error deleting question:", error);
        alert("Failed to delete question. Please try again.");
      }
    }
  };

  const editQuestion = async (question) => {
    // Parse prerequisite if it is a string (coming from database)
    const response = await axiosAuth.post("/get-dropdown-list", {
      code: question.code,
    });
    setValueData(response.data.values); // Populate valueData for dropdown
    const parsedPrerequisite =
      typeof question.prerequisite === "string"
        ? JSON.parse(question.prerequisite)
        : question.prerequisite;

    // Ensure prerequisite and showConditions are properly initialized
    const updatedQuestion = {
      ...question,
      prerequisite: {
        ...parsedPrerequisite,
        showConditions: parsedPrerequisite?.showConditions || [],
        dataLoadInstruction: parsedPrerequisite?.dataLoadInstruction || [],
      },
      default_answer: question.default_answer || "", // Ensure default_answer is initialized
    };

    setCurrentQuestion(updatedQuestion); // Set the prepared question for editing
    setIsModalOpen(true); // Open the modal
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentQuestion((prev) => ({ ...prev, [name]: value }));
  };

  const saveChanges = async () => {
    try {
      const updatedQuestion = {
        ...currentQuestion,
        prerequisite: {
          ...currentQuestion.prerequisite,
          showConditions: currentQuestion.prerequisite?.showConditions || [],
        },
      };
      await axiosAuth.post("/edit-question", updatedQuestion);
      setIsModalOpen(false);
      fetchData(); // Refresh the question list
    } catch (error) {
      console.error("Error editing question:", error);
    }
  };
  const handleCloseModal = () => {
    navigate("/admin/editor");
  };

  const handleConditionChange = (e, index, field) => {
    const updatedConditions = [...currentQuestion.prerequisite.showConditions];
    updatedConditions[index][field] = e.target.value;
    setCurrentQuestion((prev) => ({
      ...prev,
      prerequisite: {
        ...prev.prerequisite,
        showConditions: updatedConditions,
      },
    }));
  };

  const handleAddCondition = () => {
    const newCondition = {
      inputCode: "",
      operation: "equalsTo",
      value: "",
      logic: "AND",
    };

    setCurrentQuestion((prev) => ({
      ...prev,
      prerequisite: {
        ...prev.prerequisite,
        showConditions: [
          ...(prev.prerequisite?.showConditions || []),
          newCondition,
        ],
      },
    }));
  };

  const handleRemoveCondition = (index) => {
    const updatedConditions = [...currentQuestion.prerequisite.showConditions];
    updatedConditions.splice(index, 1);
    setCurrentQuestion((prev) => ({
      ...prev,
      prerequisite: {
        ...prev.prerequisite,
        showConditions: updatedConditions,
      },
    }));
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 p-8 bg-gray-100 max-h-screen overflow-scroll">
        <div className="bg-white shadow-md rounded-lg p-6">
          <div className="flex justify-between">
            <div className=" flex text-2xl font-bold mb-4">
              Question List for Code: {code}
            </div>
            <div className="flex">
              <button
                className="bg-[#36154e] text-white px-3 py-1 rounded text-sm h-8"
                onClick={handleCloseModal}
              >
                Back
              </button>
            </div>
          </div>
          <div className="overflow-x-auto md:text-[17px] text-[14px]">
            <table className="min-w-full bg-white border">
              <thead>
                <tr className="bg-[#36154e] text-white text-left">
                  <th className="px-4 py-2">Question</th>
                  <th className="px-4 py-2">Code</th>
                  <th className="px-4 py-2">Type of Rendering</th>
                  <th className="px-4 py-2 text-nowrap">Depending On</th>
                  <th className="px-4 py-2"></th>
                </tr>
              </thead>
              <tbody>
                {questions.length > 0 ? (
                  questions.map((question) => (
                    <tr key={question.id} className="border-b">
                      <td className="px-4 py-2">{question.title}</td>
                      <td className="px-4 py-2">{question.code}</td>
                      <td className="px-4 py-2">{question.renderable_type}</td>
                      <td className="px-4 py-2">
                        {(() => {
                          // Parse the prerequisite field if it's a string
                          const prerequisite =
                            typeof question.prerequisite === "string"
                              ? JSON.parse(question.prerequisite)
                              : question.prerequisite;

                          // Check if showConditions exists and is an array
                          if (
                            Array.isArray(prerequisite?.showConditions) &&
                            prerequisite.showConditions.length > 0
                          ) {
                            return prerequisite.showConditions.map(
                              (condition, index) => (
                                <span key={index}>
                                  {Array.isArray(condition.inputCode)
                                    ? condition.inputCode.join(", ") // Join multiple inputCodes with commas
                                    : condition.inputCode}
                                  {index <
                                    prerequisite.showConditions.length - 1 &&
                                    ", "}
                                </span>
                              )
                            );
                          }

                          // If no conditions exist, show a placeholder
                          return "-";
                        })()}
                      </td>
                      <td className="px-4 md:px-6 py-3 flex space-x-2">
                        <Link
                          to={`/admin/dropdown-list/${question.code}`}
                          className="text-blue-500 hover:text-blue-700 font-semibold"
                        >
                          <LiaEyeSolid size={"20px"} color="grey" />
                        </Link>
                        <button
                          onClick={() => deleteQuestion(question.id)}
                          className="text-red-500 hover:text-red-700 font-semibold"
                        >
                          <AiFillDelete />
                        </button>
                        <button
                          onClick={() => editQuestion(question)}
                          className="text-green-500 hover:text-green-700 font-semibold"
                        >
                          <AiFillEdit />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center py-4">
                      No questions found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded shadow-md w-full max-w-4xl">
            <h2 className="text-xl font-bold mb-4">Edit Question</h2>
            <div className="flex gap-8">
              {/* Left Column: Question Fields */}
              <div className="flex-1">
                <div className="mb-4">
                  <label className="block text-gray-700">Title</label>
                  <input
                    type="text"
                    name="title"
                    value={currentQuestion.title}
                    onChange={handleChange}
                    className="w-full border p-2 rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Code</label>
                  <input
                    type="text"
                    name="code"
                    value={currentQuestion.code}
                    onChange={handleChange}
                    className="w-full border p-2 rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Type of Rendering
                  </label>
                  <select
                    name="renderable_type"
                    value={currentQuestion.renderable_type}
                    onChange={handleChange}
                    className="w-full border p-2 rounded"
                  >
                    <option value="">Select an option</option>
                    <option value="radio">Radio</option>
                    <option value="table">Table</option>
                    <option value="dropdown_and_quantity">Dropdown And Quantity</option>
                    <option value="dropdown_and_quantity_and_days">Dropdown And Quantity And Days</option>
                    <option value="dropdown_multi_value_and_multi_value">Dropdown Multi Value And Multi Value</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Default Answer</label>
                  <div className="flex items-center space-x-2">
                    <select
                      name="default_answer"
                      value={currentQuestion.default_answer?.value || ""}
                      onChange={(e) => {
                        const selectedOption = valueData.find(
                          (item) => item.value === e.target.value
                        );
                        setCurrentQuestion((prev) => ({
                          ...prev,
                          default_answer: {
                            id: selectedOption?.id || null,
                            value: selectedOption?.value || "",
                          },
                        }));
                      }}
                      className="w-full border p-2 rounded"
                    >
                      <option value="">Select a Default Answer</option>
                      {valueData.map((value) => (
                        <option key={value.id} value={value.value}>
                          {value.value}
                        </option>
                      ))}
                    </select>
                    <button
                      type="button"
                      className="text-red-500  rounded-full p-2"
                      onClick={() =>
                        setCurrentQuestion((prev) => ({
                          ...prev,
                          default_answer: { id: null, value: "" }, // Reset default answer
                        }))
                      }
                    >
                      ✖
                    </button>
                  </div>
                </div>
              </div>

              {/* Right Column: Depending On Field */}
              <div className="flex-1">
                <label className="block text-gray-700">Depending On</label>
                <div
                  className="overflow-y-auto border rounded-md p-4"
                  style={{ maxHeight: "300px" }}
                >
                  {currentQuestion.prerequisite?.showConditions?.map(
                    (condition, index) => (
                      <div key={index} className="mb-4 border-b pb-4">
                        <label className="block text-gray-700 mb-1">
                          Input Code
                        </label>
                        <input
                          type="text"
                          name={`prerequisite.showConditions[${index}].inputCode`}
                          value={condition.inputCode || ""}
                          onChange={(e) =>
                            handleConditionChange(e, index, "inputCode")
                          }
                          className="w-full border p-2 rounded mb-2"
                        />

                        <label className="block text-gray-700 mb-1">
                          Operation
                        </label>
                        <select
                          name={`prerequisite.showConditions[${index}].operation`}
                          value={condition.operation || ""}
                          onChange={(e) =>
                            handleConditionChange(e, index, "operation")
                          }
                          className="w-full border p-2 rounded mb-2"
                        >
                          <option value="equalsTo">Is Equal To</option>
                          <option value="notEqualsTo">Is Not Equal To</option>
                          <option value="contains">Contains</option>
                          <option value="doesNotContain">
                            Does Not Contain
                          </option>
                          <option value="startsWith">Starts With</option>
                          <option value="doesNotStartWith">
                            Does Not Start With
                          </option>
                          <option value="endsWith">Ends With</option>
                          <option value="doesNotEndWith">
                            Does Not End With
                          </option>
                          <option value="isEmpty">Is Empty</option>
                          <option value="isFilled">Is Filled</option>
                        </select>

                        <label className="block text-gray-700 mb-1">
                          Value
                        </label>
                        <input
                          type="text"
                          name={`prerequisite.showConditions[${index}].value`}
                          value={condition.value || ""}
                          onChange={(e) =>
                            handleConditionChange(e, index, "value")
                          }
                          className="w-full border p-2 rounded mb-2"
                        />

                        <label className="block text-gray-700 mb-1">
                          Logic
                        </label>
                        <select
                          name={`prerequisite.showConditions[${index}].logic`}
                          value={condition.logic || "AND"}
                          onChange={(e) =>
                            handleConditionChange(e, index, "logic")
                          }
                          className="w-full border p-2 rounded"
                        >
                          <option value=""></option>
                          <option value="AND">AND</option>
                          <option value="OR">OR</option>
                        </select>

                        <button
                          type="button"
                          className="text-red-500 mt-2"
                          onClick={() => handleRemoveCondition(index)}
                        >
                          Remove Condition
                        </button>
                      </div>
                    )
                  )}
                </div>

                <button
                  type="button"
                  className="bg-[#4c0f78] text-white px-4 py-2 rounded mt-4"
                  onClick={handleAddCondition}
                >
                  Add Condition
                </button>
              </div>
            </div>

            {/* Modal Actions */}
            <div className="flex justify-end mt-6">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={saveChanges}
                className="bg-[#4c0f78] text-white px-4 py-2 rounded"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionList;
