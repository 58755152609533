export const utils_data_for_dynamic_form = {
  question_part_one: [
    {
      id: "q1",
      type: "dropdown",
      label: "Company Name/Location",
      options: [
        {
          key: "belfor_abbotsford",
          value: "Belfor Abbotsford",
        },
        {
          key: "belfor_mt_tremblant",
          value: "Belfor Mt. Tremblant",
        },
        {
          key: "belfor_brockville",
          value: "Belfor Brockville",
        },
        {
          key: "belfor_nanaimo",
          value: "Belfor Nanaimo",
        },
        {
          key: "belfor_calgary",
          value: "Belfor Calgary",
        },
        {
          key: "belfor_ottawa",
          value: "Belfor Ottawa",
        },
        {
          key: "belfor_chatham",
          value: "Belfor Chatham",
        },
        {
          key: "belfor_owen_sound",
          value: "Belfor Owen Sound",
        },
        {
          key: "belfor_collingwood",
          value: "Belfor Collingwood",
        },
        {
          key: "belfor_paradise",
          value: "Belfor Paradise",
        },
        {
          key: "belfor_corner_brook",
          value: "Belfor Corner Brook",
        },
        {
          key: "belfor_perth",
          value: "Belfor Perth",
        },
        {
          key: "belfor_courtenay",
          value: "Belfor Courtenay",
        },
        {
          key: "belfor_prince_george",
          value: "Belfor Prince George",
        },
        {
          key: "belfor_dartmouth",
          value: "Belfor Dartmouth",
        },
        {
          key: "belfor_quispamsis",
          value: "Belfor Quispamsis",
        },
        {
          key: "belfor_dieppe",
          value: "Belfor Dieppe",
        },
        {
          key: "belfor_red_deer",
          value: "Belfor Red Deer",
        },
        {
          key: "belfor_duncan",
          value: "Belfor Duncan",
        },
        {
          key: "belfor_sarnia",
          value: "Belfor Sarnia",
        },
        {
          key: "belfor_edmonton",
          value: "Belfor Edmonton",
        },
        {
          key: "belfor_st_john",
          value: "Belfor St. John",
        },
        {
          key: "belfor_gander",
          value: "Belfor Gander",
        },
        {
          key: "belfor_st_john's",
          value: "Belfor St. John's",
        },
        {
          key: "belfor_halifax",
          value: "Belfor Halifax",
        },
        {
          key: "belfor_stratford",
          value: "Belfor Stratford",
        },
        {
          key: "belfor_hamilton",
          value: "Belfor Hamilton",
        },
        {
          key: "belfor_surrey",
          value: "Belfor Surrey",
        },
        {
          key: "belfor_kelowna",
          value: "Belfor Kelowna",
        },
        {
          key: "belfor_toronto",
          value: "Belfor Toronto",
        },
        {
          key: "belfor_kingston",
          value: "Belfor Kingston",
        },
        {
          key: "belfor_vancouver",
          value: "Belfor Vancouver",
        },
        {
          key: "belfor_kitchener",
          value: "Belfor Kitchener",
        },
        {
          key: "belfor_victoria",
          value: "Belfor Victoria",
        },
        {
          key: "belfor_labrador",
          value: "Belfor Labrador",
        },
        {
          key: "belfor_windsor",
          value: "Belfor Windsor",
        },
        {
          key: "belfor_lanark",
          value: "Belfor Lanark",
        },
        {
          key: "belfor_winnipeg",
          value: "Belfor Winnipeg",
        },
        {
          key: "belfor_lindsay",
          value: "Belfor Lindsay",
        },
        {
          key: "danar_montreal",
          value: "DANAR - Montreal",
        },
        {
          key: "belfor_london",
          value: "Belfor London",
        },
        {
          key: "danar_northshore",
          value: "DANAR - Northshore",
        },
        {
          key: "belfor_markham",
          value: "Belfor Markham",
        },
        {
          key: "belfor_moncton",
          value: "Belfor Moncton",
        },
        {
          key: "belfor_mississauga",
          value: "Belfor Mississauga",
        },
        {
          key: "belfor_montreal",
          value: "Belfor Montreal",
        },
      ],
      required: true,
    },
    {
      id: "q2",
      type: "dropdown",
      label: "Requester Name",
      options: [
        {
          key: "belfor",
          value: "BELFOR",
        },
        {
          key: "winmar",
          value: "WINMAR",
        },
        {
          key: "brett_mackenzie",
          value: "Brett MacKenzie",
        },
        {
          key: "first_general",
          value: "FIRST GENERAL",
        },
        {
          key: "emily_lees",
          value: "Emily Lees",
        },
        {
          key: "pds",
          value: "PDS",
        },
        {
          key: "kamala_brown ",
          value: "Kamala Brown ",
        },
        {
          key: "puroclean",
          value: "PUROCLEAN",
        },
        {
          key: "kristy_persson",
          value: "Kristy Persson",
        },
        {
          key: "servicemaster",
          value: "SERVICEMASTER",
        },
        {
          key: "ryan_o'neill",
          value: "Ryan O'Neill",
        },
        {
          key: "tracey_paris",
          value: "Tracey Paris",
        },
      ],
      required: true,
    },
    {
      id: "q0",
      type: "text",
      label: "Home Owner Name",
      required: false
    },

    {
      id: "q29",
      type: "date",
      label: "Date of Loss",
      required: false
    },

    {
      id: "q4",
      type: "text",
      label: "What is your phone number",
      required: false,
    },
    {
      id: "q5",
      type: "text",
      label: "What is your email address ?",
      required: true,
    },
    {
      id: "q3",
      type: "text",
      label: "What is the client's name?",
      required: true,
    },
    {
      id: "q6",
      type: "text",
      label: "Loss Address",
      required: true,
    },
    {
      id: "q7",
      type: "dropdown",
      label: "Country",
      options: [
        {
          key: "canada",
          value: "Canada",
        },
        {
          key: "us",
          value: "United States",
        },
      ],
      required: true,
    },
    {
      id: "q8",
      type: "dropdown",
      label: "City",
      option_groups: [
        {
          conditions: {
            type: "equals",
            questionId: "q7",
            value: "us",
          },
          options: [
            {
              key: "andalusia",
              value: "Andalusia",
            },
            {
              key: "anniston",
              value: "Anniston",
            },
            {
              key: "birmingham",
              value: "Birmingham",
            },
            {
              key: "decatur",
              value: "Decatur",
            },
            {
              key: "dothan",
              value: "Dothan",
            },
            {
              key: "florence",
              value: "Florence",
            },
            {
              key: "gadsden",
              value: "Gadsden",
            },
            {
              key: "guntersville",
              value: "Guntersville",
            },
            {
              key: "huntsville",
              value: "Huntsville",
            },
            {
              key: "jasper",
              value: "Jasper",
            },
            {
              key: "mobile",
              value: "Mobile",
            },
            {
              key: "montgomery",
              value: "Montgomery",
            },
            {
              key: "opelika",
              value: "Opelika",
            },
            {
              key: "scottsboro",
              value: "Scottsboro",
            },
            {
              key: "tuscaloosa",
              value: "Tuscaloosa",
            },
            {
              key: "anchorage",
              value: "Anchorage",
            },
            {
              key: "fairbanks",
              value: "Fairbanks",
            },
            {
              key: "juneau",
              value: "Juneau",
            },
            {
              key: "batesville",
              value: "Batesville",
            },
            {
              key: "el_dorado",
              value: "El Dorado",
            },
            {
              key: "fayetteville",
              value: "Fayetteville",
            },
            {
              key: "fort_smith",
              value: "Fort Smith",
            },
            {
              key: "jonesboro",
              value: "Jonesboro",
            },
            {
              key: "little_rock",
              value: "Little Rock",
            },
            {
              key: "west_memphis",
              value: "West Memphis",
            },
            {
              key: "flagstaff",
              value: "Flagstaff",
            },
            {
              key: "lake_havasu",
              value: "Lake Havasu",
            },
            {
              key: "phoenix",
              value: "Phoenix",
            },
            {
              key: "prescott",
              value: "Prescott",
            },
            {
              key: "tucson",
              value: "Tucson",
            },
            {
              key: "yuma",
              value: "Yuma",
            },
            {
              key: "bakersfield",
              value: "Bakersfield",
            },
            {
              key: "san_benardino",
              value: "San Benardino",
            },
            {
              key: "bishop",
              value: "Bishop",
            },
            {
              key: "santa_barbara",
              value: "Santa Barbara",
            },
            {
              key: "east_bay",
              value: "East Bay",
            },
            {
              key: "eureka",
              value: "Eureka",
            },
            {
              key: "fresno",
              value: "Fresno",
            },
            {
              key: "san_fernando",
              value: "San Fernando",
            },
            {
              key: "los_angeles",
              value: "Los Angeles",
            },
            {
              key: "lancaster",
              value: "Lancaster",
            },
            {
              key: "merced",
              value: "Merced",
            },
            {
              key: "modesto",
              value: "Modesto",
            },
            {
              key: "santa_maria",
              value: "Santa Maria",
            },
            {
              key: "orange",
              value: "Orange",
            },
            {
              key: "sf_peninula",
              value: "SF Peninula",
            },
            {
              key: "palm_springs",
              value: "Palm Springs",
            },
            {
              key: "ridgecrest",
              value: "Ridgecrest",
            },
            {
              key: "redding",
              value: "Redding",
            },
            {
              key: "sacramento",
              value: "Sacramento",
            },
            {
              key: "south_bay",
              value: "South Bay",
            },
            {
              key: "san_diego",
              value: "San Diego",
            },
            {
              key: "salinas",
              value: "Salinas",
            },
            {
              key: "san_luis_obispo",
              value: "San Luis Obispo",
            },
            {
              key: "santa_rosa",
              value: "Santa Rosa",
            },
            {
              key: "san_rafael",
              value: "San Rafael",
            },
            {
              key: "s._lake_tahoe",
              value: "S. Lake Tahoe",
            },
            {
              key: "santa_cruz",
              value: "Santa Cruz",
            },
            {
              key: "tuolumne",
              value: "Tuolumne",
            },
            {
              key: "truckee",
              value: "Truckee",
            },
            {
              key: "ukiah",
              value: "Ukiah",
            },
            {
              key: "ventura",
              value: "Ventura",
            },
            {
              key: "victorville",
              value: "Victorville",
            },
            {
              key: "visalia",
              value: "Visalia",
            },
            {
              key: "simi_valley",
              value: "Simi Valley",
            },
            {
              key: "boulder",
              value: "Boulder",
            },
            {
              key: "colorado_springs",
              value: "Colorado Springs",
            },
            {
              key: "denver",
              value: "Denver",
            },
            {
              key: "durango",
              value: "Durango",
            },
            {
              key: "fort_collins",
              value: "Fort Collins",
            },
            {
              key: "grand_junction",
              value: "Grand Junction",
            },
            {
              key: "glenwood_springs",
              value: "Glenwood Springs",
            },
            {
              key: "pueblo",
              value: "Pueblo",
            },
            {
              key: "resort",
              value: "Resort",
            },
            {
              key: "hartford",
              value: "Hartford",
            },
            {
              key: "new_haven",
              value: "New Haven",
            },
            {
              key: "stamfort",
              value: "Stamfort",
            },
            {
              key: "washington",
              value: "Washington",
            },
            {
              key: "dover",
              value: "Dover",
            },
            {
              key: "wilimngton",
              value: "Wilimngton",
            },
            {
              key: "delray_beach",
              value: "Delray Beach",
            },
            {
              key: "daytona_beach",
              value: "Daytona Beach",
            },
            {
              key: "fort_lauderdale",
              value: "Fort Lauderdale",
            },
            {
              key: "fort_myers",
              value: "Fort Myers",
            },
            {
              key: "fort_pierce",
              value: "Fort Pierce",
            },
            {
              key: "gainesville",
              value: "Gainesville",
            },
            {
              key: "jacksonville",
              value: "Jacksonville",
            },
            {
              key: "jupiter",
              value: "Jupiter",
            },
            {
              key: "key_west",
              value: "Key West",
            },
            {
              key: "leesburg",
              value: "Leesburg",
            },
            {
              key: "melbourne",
              value: "Melbourne",
            },
            {
              key: "miami",
              value: "Miami",
            },
            {
              key: "naples",
              value: "Naples",
            },
            {
              key: "ocala",
              value: "Ocala",
            },
            {
              key: "orlando",
              value: "Orlando",
            },
            {
              key: "panama_city",
              value: "Panama City",
            },
            {
              key: "pensacola",
              value: "Pensacola",
            },
            {
              key: "sebring",
              value: "Sebring",
            },
            {
              key: "spring_hill",
              value: "Spring Hill",
            },
            {
              key: "st._petersburg",
              value: "St. Petersburg",
            },
            {
              key: "sarasota",
              value: "Sarasota",
            },
            {
              key: "tampa",
              value: "Tampa",
            },
            {
              key: "tallahassee",
              value: "Tallahassee",
            },
            {
              key: "west_palm_beach",
              value: "West Palm Beach",
            },
            {
              key: "win_haven",
              value: "Win Haven",
            },
            {
              key: "athens",
              value: "Athens",
            },
            {
              key: "atlanta",
              value: "Atlanta",
            },
            {
              key: "augusta",
              value: "Augusta",
            },
            {
              key: "brunswick",
              value: "Brunswick",
            },
            {
              key: "carrollton",
              value: "Carrollton",
            },
            {
              key: "columbus",
              value: "Columbus",
            },
            {
              key: "macon",
              value: "Macon",
            },
            {
              key: "rome",
              value: "Rome",
            },
            {
              key: "savannah",
              value: "Savannah",
            },
            {
              key: "valdosta",
              value: "Valdosta",
            },
            {
              key: "honolulu",
              value: "Honolulu",
            },
            {
              key: "hawaii_outer_island",
              value: "Hawaii Outer Island",
            },
            {
              key: "wailuku",
              value: "Wailuku",
            },
            {
              key: "burlington",
              value: "Burlington",
            },
            {
              key: "council_bluffs",
              value: "Council Bluffs",
            },
            {
              key: "cedar_rapids",
              value: "Cedar Rapids",
            },
            {
              key: "davenport",
              value: "Davenport",
            },
            {
              key: "des_moines",
              value: "Des Moines",
            },
            {
              key: "dubuque",
              value: "Dubuque",
            },
            {
              key: "fort_dodge",
              value: "Fort Dodge",
            },
            {
              key: "mason_city",
              value: "Mason City",
            },
            {
              key: "sioux_city",
              value: "Sioux City",
            },
            {
              key: "waterloo",
              value: "Waterloo",
            },
            {
              key: "boise",
              value: "Boise",
            },
            {
              key: "coeur_d'alene",
              value: "Coeur D'Alene",
            },
            {
              key: "idaho_falls",
              value: "Idaho Falls",
            },
            {
              key: "lewiston",
              value: "Lewiston",
            },
            {
              key: "pocatello",
              value: "Pocatello",
            },
            {
              key: "sun_valley_resort",
              value: "Sun Valley Resort",
            },
            {
              key: "twin_falls",
              value: "Twin Falls",
            },
            {
              key: "bloomington",
              value: "Bloomington",
            },
            {
              key: "chicago",
              value: "Chicago",
            },
            {
              key: "champaign",
              value: "Champaign",
            },
            {
              key: "decatur",
              value: "Decatur",
            },
            {
              key: "east_st._louis",
              value: "East St. Louis",
            },
            {
              key: "marion",
              value: "Marion",
            },
            {
              key: "moline",
              value: "Moline",
            },
            {
              key: "mattoon",
              value: "Mattoon",
            },
            {
              key: "mount_vernon",
              value: "Mount Vernon",
            },
            {
              key: "peoria",
              value: "Peoria",
            },
            {
              key: "peru",
              value: "Peru",
            },
            {
              key: "rockford",
              value: "Rockford",
            },
            {
              key: "springfield",
              value: "Springfield",
            },
            {
              key: "evansville",
              value: "Evansville",
            },
            {
              key: "fort_wayne",
              value: "Fort Wayne",
            },
            {
              key: "gary",
              value: "Gary",
            },
            {
              key: "indianapolis",
              value: "Indianapolis",
            },
            {
              key: "michigan_city",
              value: "Michigan City",
            },
            {
              key: "muncie",
              value: "Muncie",
            },
            {
              key: "new_albany",
              value: "New Albany",
            },
            {
              key: "south_bend",
              value: "South Bend",
            },
            {
              key: "terre_haute",
              value: "Terre Haute",
            },
            {
              key: "west_lafayette",
              value: "West Lafayette",
            },
            {
              key: "hutchinson",
              value: "Hutchinson",
            },
            {
              key: "kansas_city",
              value: "Kansas City",
            },
            {
              key: "kansas_state",
              value: "Kansas State",
            },
            {
              key: "topeka",
              value: "Topeka",
            },
            {
              key: "wichita",
              value: "Wichita",
            },
            {
              key: "ashland",
              value: "Ashland",
            },
            {
              key: "bowling_green",
              value: "Bowling Green",
            },
            {
              key: "covington",
              value: "Covington",
            },
            {
              key: "london",
              value: "London",
            },
            {
              key: "louisville",
              value: "Louisville",
            },
            {
              key: "lexington",
              value: "Lexington",
            },
            {
              key: "owensboro",
              value: "Owensboro",
            },
            {
              key: "paducah",
              value: "Paducah",
            },
            {
              key: "alexandria",
              value: "Alexandria",
            },
            {
              key: "baton_rouge",
              value: "Baton Rouge",
            },
            {
              key: "houma",
              value: "Houma",
            },
            {
              key: "lafayette",
              value: "Lafayette",
            },
            {
              key: "lake_charles",
              value: "Lake Charles",
            },
            {
              key: "mandeville",
              value: "Mandeville",
            },
            {
              key: "monroe",
              value: "Monroe",
            },
            {
              key: "new_orleans",
              value: "New Orleans",
            },
            {
              key: "shreveport",
              value: "Shreveport",
            },
            {
              key: "boston",
              value: "Boston",
            },
            {
              key: "eastern_massachusetts",
              value: "Eastern Massachusetts",
            },
            {
              key: "islands",
              value: "Islands",
            },
            {
              key: "springfield",
              value: "Springfield",
            },
            {
              key: "baltimore",
              value: "Baltimore",
            },
            {
              key: "cumberland",
              value: "Cumberland",
            },
            {
              key: "frederick",
              value: "Frederick",
            },
            {
              key: "rockville",
              value: "Rockville",
            },
            {
              key: "salisbury",
              value: "Salisbury",
            },
            {
              key: "st._charles",
              value: "St. Charles",
            },
            {
              key: "bangor",
              value: "Bangor",
            },
            {
              key: "portland",
              value: "Portland",
            },
            {
              key: "ann_arbor",
              value: "Ann Arbor",
            },
            {
              key: "battle_creek",
              value: "Battle Creek",
            },
            {
              key: "detroit",
              value: "Detroit",
            },
            {
              key: "flint",
              value: "Flint",
            },
            {
              key: "gaylord",
              value: "Gaylord",
            },
            {
              key: "grand_rapids",
              value: "Grand Rapids",
            },
            {
              key: "kalamazoo",
              value: "Kalamazoo",
            },
            {
              key: "lansing",
              value: "Lansing",
            },
            {
              key: "marquette",
              value: "Marquette",
            },
            {
              key: "mount_pleasant",
              value: "Mount Pleasant",
            },
            {
              key: "muskegon",
              value: "Muskegon",
            },
            {
              key: "saginaw",
              value: "Saginaw",
            },
            {
              key: "traverse_city",
              value: "Traverse City",
            },
            {
              key: "bemidji",
              value: "Bemidji",
            },
            {
              key: "brainerd",
              value: "Brainerd",
            },
            {
              key: "detr-lakes",
              value: "Detr-Lakes",
            },
            {
              key: "duluth",
              value: "Duluth",
            },
            {
              key: "hibbing",
              value: "Hibbing",
            },
            {
              key: "marshall",
              value: "Marshall",
            },
            {
              key: "mankato",
              value: "Mankato",
            },
            {
              key: "minneapolis",
              value: "Minneapolis",
            },
            {
              key: "rochester",
              value: "Rochester",
            },
            {
              key: "st._cloud",
              value: "St. Cloud",
            },
            {
              key: "thief_river_falls",
              value: "Thief River Falls",
            },
            {
              key: "cape_girardeau",
              value: "Cape Girardeau",
            },
            {
              key: "chillichothe",
              value: "Chillichothe",
            },
            {
              key: "columbia",
              value: "Columbia",
            },
            {
              key: "joplin",
              value: "Joplin",
            },
            {
              key: "kansas_city",
              value: "Kansas City",
            },
            {
              key: "kirksville",
              value: "Kirksville",
            },
            {
              key: "osage_beach",
              value: "Osage Beach",
            },
            {
              key: "rolla",
              value: "Rolla",
            },
            {
              key: "sedalia",
              value: "Sedalia",
            },
            {
              key: "st._joseph",
              value: "St. Joseph",
            },
            {
              key: "st._louis",
              value: "St. Louis",
            },
            {
              key: "springfield",
              value: "Springfield",
            },
            {
              key: "greenville",
              value: "Greenville",
            },
            {
              key: "gulfport",
              value: "Gulfport",
            },
            {
              key: "hattiesburg",
              value: "Hattiesburg",
            },
            {
              key: "jackson",
              value: "Jackson",
            },
            {
              key: "meridian",
              value: "Meridian",
            },
            {
              key: "southaven",
              value: "Southaven",
            },
            {
              key: "tupelo",
              value: "Tupelo",
            },
            {
              key: "billings",
              value: "Billings",
            },
            {
              key: "bozeman",
              value: "Bozeman",
            },
            {
              key: "butte",
              value: "Butte",
            },
            {
              key: "great_falls",
              value: "Great Falls",
            },
            {
              key: "helena",
              value: "Helena",
            },
            {
              key: "kalispell",
              value: "Kalispell",
            },
            {
              key: "missoula",
              value: "Missoula",
            },
            {
              key: "sidney",
              value: "Sidney",
            },
            {
              key: "asheville",
              value: "Asheville",
            },
            {
              key: "charlotte",
              value: "Charlotte",
            },
            {
              key: "chapel_hill",
              value: "Chapel Hill",
            },
            {
              key: "elizabeth_city",
              value: "Elizabeth City",
            },
            {
              key: "fayetteville",
              value: "Fayetteville",
            },
            {
              key: "greensboro",
              value: "Greensboro",
            },
            {
              key: "hickory",
              value: "Hickory",
            },
            {
              key: "high_point",
              value: "High Point",
            },
            {
              key: "kinston",
              value: "Kinston",
            },
            {
              key: "raleigh",
              value: "Raleigh",
            },
            {
              key: "wilmington",
              value: "Wilmington",
            },
            {
              key: "winston-salem",
              value: "Winston-Salem",
            },
            {
              key: "bismark",
              value: "Bismark",
            },
            {
              key: "fargo",
              value: "Fargo",
            },
            {
              key: "grand_forks",
              value: "Grand Forks",
            },
            {
              key: "minot",
              value: "Minot",
            },
            {
              key: "grand_island",
              value: "Grand Island",
            },
            {
              key: "lincoln",
              value: "Lincoln",
            },
            {
              key: "norfolk",
              value: "Norfolk",
            },
            {
              key: "north_platte",
              value: "North Platte",
            },
            {
              key: "omaha",
              value: "Omaha",
            },
            {
              key: "scottsbluff",
              value: "Scottsbluff",
            },
            {
              key: "manchester",
              value: "Manchester",
            },
            {
              key: "atlantic_city",
              value: "Atlantic City",
            },
            {
              key: "camden",
              value: "Camden",
            },
            {
              key: "newark",
              value: "Newark",
            },
            {
              key: "paterson",
              value: "Paterson",
            },
            {
              key: "toms_river",
              value: "Toms River",
            },
            {
              key: "alburquerque",
              value: "Alburquerque",
            },
            {
              key: "clovis",
              value: "Clovis",
            },
            {
              key: "farmington",
              value: "Farmington",
            },
            {
              key: "hobbs",
              value: "Hobbs",
            },
            {
              key: "las_cruces",
              value: "Las Cruces",
            },
            {
              key: "roswell",
              value: "Roswell",
            },
            {
              key: "santa_fe",
              value: "Santa Fe",
            },
            {
              key: "las_vegas",
              value: "Las Vegas",
            },
            {
              key: "elko",
              value: "Elko",
            },
            {
              key: "reno",
              value: "Reno",
            },
            {
              key: "albany_",
              value: "Albany ",
            },
            {
              key: "binghamton",
              value: "Binghamton",
            },
            {
              key: "buffalo",
              value: "Buffalo",
            },
            {
              key: "long_island",
              value: "Long Island",
            },
            {
              key: "manhattan",
              value: "Manhattan",
            },
            {
              key: "outer_boroughs",
              value: "Outer Boroughs",
            },
            {
              key: "rochester",
              value: "Rochester",
            },
            {
              key: "syracuse",
              value: "Syracuse",
            },
            {
              key: "utica",
              value: "Utica",
            },
            {
              key: "white_plains",
              value: "White Plains",
            },
            {
              key: "akron",
              value: "Akron",
            },
            {
              key: "canton",
              value: "Canton",
            },
            {
              key: "cincinnati",
              value: "Cincinnati",
            },
            {
              key: "cleveland",
              value: "Cleveland",
            },
            {
              key: "columbus",
              value: "Columbus",
            },
            {
              key: "dayton",
              value: "Dayton",
            },
            {
              key: "lima",
              value: "Lima",
            },
            {
              key: "mansfield",
              value: "Mansfield",
            },
            {
              key: "newark",
              value: "Newark",
            },
            {
              key: "portsmouth",
              value: "Portsmouth",
            },
            {
              key: "toledo",
              value: "Toledo",
            },
            {
              key: "youngstown",
              value: "Youngstown",
            },
            {
              key: "ada",
              value: "Ada",
            },
            {
              key: "enid",
              value: "Enid",
            },
            {
              key: "lawton",
              value: "Lawton",
            },
            {
              key: "muskogee",
              value: "Muskogee",
            },
            {
              key: "oklahoma_city",
              value: "Oklahoma City",
            },
            {
              key: "tulsa",
              value: "Tulsa",
            },
            {
              key: "bend",
              value: "Bend",
            },
            {
              key: "corvallis",
              value: "Corvallis",
            },
            {
              key: "eugene",
              value: "Eugene",
            },
            {
              key: "medford",
              value: "Medford",
            },
            {
              key: "pendleton",
              value: "Pendleton",
            },
            {
              key: "portland",
              value: "Portland",
            },
            {
              key: "roseburg",
              value: "Roseburg",
            },
            {
              key: "salem",
              value: "Salem",
            },
            {
              key: "allentown",
              value: "Allentown",
            },
            {
              key: "altoona",
              value: "Altoona",
            },
            {
              key: "erie",
              value: "Erie",
            },
            {
              key: "harrisburg",
              value: "Harrisburg",
            },
            {
              key: "johnstown",
              value: "Johnstown",
            },
            {
              key: "lancaster",
              value: "Lancaster",
            },
            {
              key: "pittsburgh",
              value: "Pittsburgh",
            },
            {
              key: "philadelphia",
              value: "Philadelphia",
            },
            {
              key: "reading",
              value: "Reading",
            },
            {
              key: "scranton",
              value: "Scranton",
            },
            {
              key: "west_chester",
              value: "West Chester",
            },
            {
              key: "willamsport",
              value: "Willamsport",
            },
            {
              key: "san_juan",
              value: "San Juan",
            },
            {
              key: "providence",
              value: "Providence",
            },
            {
              key: "aiken",
              value: "Aiken",
            },
            {
              key: "beaufort",
              value: "Beaufort",
            },
            {
              key: "charleston",
              value: "Charleston",
            },
            {
              key: "columbia",
              value: "Columbia",
            },
            {
              key: "florence",
              value: "Florence",
            },
            {
              key: "greenwood",
              value: "Greenwood",
            },
            {
              key: "greenville",
              value: "Greenville",
            },
            {
              key: "hilton_head_island",
              value: "Hilton Head Island",
            },
            {
              key: "myrtle_beach",
              value: "Myrtle Beach",
            },
            {
              key: "rock_hill",
              value: "Rock Hill",
            },
            {
              key: "aberdeen",
              value: "Aberdeen",
            },
            {
              key: "rapid_city",
              value: "Rapid City",
            },
            {
              key: "sioux_falls",
              value: "Sioux Falls",
            },
            {
              key: "chattanooga",
              value: "Chattanooga",
            },
            {
              key: "cookeville",
              value: "Cookeville",
            },
            {
              key: "jackson",
              value: "Jackson",
            },
            {
              key: "johnson_city",
              value: "Johnson City",
            },
            {
              key: "knoxville",
              value: "Knoxville",
            },
            {
              key: "memphis",
              value: "Memphis",
            },
            {
              key: "murfreesboro",
              value: "Murfreesboro",
            },
            {
              key: "nashville",
              value: "Nashville",
            },
            {
              key: "abilene",
              value: "Abilene",
            },
            {
              key: "amarillo",
              value: "Amarillo",
            },
            {
              key: "austin",
              value: "Austin",
            },
            {
              key: "bay_city",
              value: "Bay City",
            },
            {
              key: "beaumont",
              value: "Beaumont",
            },
            {
              key: "belton",
              value: "Belton",
            },
            {
              key: "corpus_christi",
              value: "Corpus Christi",
            },
            {
              key: "college_station",
              value: "College Station",
            },
            {
              key: "dallas/fort_worth",
              value: "Dallas/Fort Worth",
            },
            {
              key: "el_paso",
              value: "El Paso",
            },
            {
              key: "galveston",
              value: "Galveston",
            },
            {
              key: "houston",
              value: "Houston",
            },
            {
              key: "lubbock",
              value: "Lubbock",
            },
            {
              key: "mcallen",
              value: "McAllen",
            },
            {
              key: "midland",
              value: "Midland",
            },
            {
              key: "san_antonio",
              value: "San Antonio",
            },
            {
              key: "sherman",
              value: "Sherman",
            },
            {
              key: "san_angelo",
              value: "San Angelo",
            },
            {
              key: "texarkana",
              value: "Texarkana",
            },
            {
              key: "the_woodlands",
              value: "The Woodlands",
            },
            {
              key: "tyler",
              value: "Tyler",
            },
            {
              key: "victoria",
              value: "Victoria",
            },
            {
              key: "waco",
              value: "Waco",
            },
            {
              key: "wichita_falls",
              value: "Wichita Falls",
            },
            {
              key: "bountiful",
              value: "Bountiful",
            },
            {
              key: "cedar_city",
              value: "Cedar City",
            },
            {
              key: "logan",
              value: "Logan",
            },
            {
              key: "ogden",
              value: "Ogden",
            },
            {
              key: "orem",
              value: "Orem",
            },
            {
              key: "st._george",
              value: "St. George",
            },
            {
              key: "salt_lake_city",
              value: "Salt Lake City",
            },
            {
              key: "park_city",
              value: "Park City",
            },
            {
              key: "arlington",
              value: "Arlington",
            },
            {
              key: "blacksburg",
              value: "Blacksburg",
            },
            {
              key: "bristol",
              value: "Bristol",
            },
            {
              key: "charlottesville",
              value: "Charlottesville",
            },
            {
              key: "danville",
              value: "Danville",
            },
            {
              key: "dale_city",
              value: "Dale City",
            },
            {
              key: "frederickburg",
              value: "Frederickburg",
            },
            {
              key: "lynchburg",
              value: "Lynchburg",
            },
            {
              key: "norfolk",
              value: "Norfolk",
            },
            {
              key: "richmond",
              value: "Richmond",
            },
            {
              key: "roanoke",
              value: "Roanoke",
            },
            {
              key: "winchester",
              value: "Winchester",
            },
            {
              key: "burlington",
              value: "Burlington",
            },
            {
              key: "rutland",
              value: "Rutland",
            },
            {
              key: "bellingham",
              value: "Bellingham",
            },
            {
              key: "everett",
              value: "Everett",
            },
            {
              key: "kennewick",
              value: "Kennewick",
            },
            {
              key: "olympia",
              value: "Olympia",
            },
            {
              key: "seattle",
              value: "Seattle",
            },
            {
              key: "spokane",
              value: "Spokane",
            },
            {
              key: "tacoma",
              value: "Tacoma",
            },
            {
              key: "vancouver",
              value: "Vancouver",
            },
            {
              key: "wenatchee",
              value: "Wenatchee",
            },
            {
              key: "yakima",
              value: "Yakima",
            },
            {
              key: "appleton",
              value: "Appleton",
            },
            {
              key: "eau_claire",
              value: "Eau Claire",
            },
            {
              key: "green_bay",
              value: "Green Bay",
            },
            {
              key: "la_crosse",
              value: "La Crosse",
            },
            {
              key: "madison",
              value: "Madison",
            },
            {
              key: "milwaukee",
              value: "Milwaukee",
            },
            {
              key: "racine",
              value: "Racine",
            },
            {
              key: "wausau",
              value: "Wausau",
            },
            {
              key: "beckley",
              value: "Beckley",
            },
            {
              key: "bluefield",
              value: "Bluefield",
            },
            {
              key: "charleston",
              value: "Charleston",
            },
            {
              key: "fairmont",
              value: "Fairmont",
            },
            {
              key: "huntington",
              value: "Huntington",
            },
            {
              key: "logan",
              value: "Logan",
            },
            {
              key: "parkersburg",
              value: "Parkersburg",
            },
            {
              key: "wheeling",
              value: "Wheeling",
            },
            {
              key: "casper",
              value: "Casper",
            },
            {
              key: "cheyenne",
              value: "Cheyenne",
            },
            {
              key: "cody",
              value: "Cody",
            },
            {
              key: "gillette",
              value: "Gillette",
            },
            {
              key: "riverton",
              value: "Riverton",
            },
            {
              key: "rock_springs",
              value: "Rock Springs",
            },
            {
              key: "sheridan",
              value: "Sheridan",
            },
          ],
        },
        {
          conditions: {
            type: "equals",
            questionId: "q7",
            value: "canada",
          },
          options: [
            {
              key: "calgary",
              value: "Calgary",
            },
            {
              key: "thunder_bay",
              value: "Thunder Bay",
            },
            {
              key: "edmonton",
              value: "Edmonton",
            },
            {
              key: "toronto",
              value: "Toronto",
            },
            {
              key: "fort_mcmurray",
              value: "Fort McMurray",
            },
            {
              key: "windsor",
              value: "Windsor",
            },
            {
              key: "grand_prairie",
              value: "Grand Prairie",
            },
            {
              key: "charlottetown",
              value: "Charlottetown",
            },
            {
              key: "vancouver",
              value: "Vancouver",
            },
            {
              key: "quebec",
              value: "Quebec",
            },
            {
              key: "prince_george",
              value: "Prince George",
            },
            {
              key: "montreal",
              value: "Montreal",
            },
            {
              key: "vancouver_island",
              value: "Vancouver Island",
            },
            {
              key: "saguenay",
              value: "Saguenay",
            },
            {
              key: "kelowna",
              value: "Kelowna",
            },
            {
              key: "outaouais",
              value: "Outaouais",
            },
            {
              key: "winnipeg",
              value: "Winnipeg",
            },
            {
              key: "abitibi",
              value: "Abitibi",
            },
            {
              key: "brandon",
              value: "Brandon",
            },
            {
              key: "cantons_de_l'est",
              value: "Cantons-de-l'Est",
            },
            {
              key: "saint_john",
              value: "Saint John",
            },
            {
              key: "regina",
              value: "Regina",
            },
            {
              key: "moncton",
              value: "Moncton",
            },
            {
              key: "saskatoon",
              value: "Saskatoon",
            },
            {
              key: "fredericton",
              value: "Fredericton",
            },
            {
              key: "burlington",
              value: "Burlington",
            },
            {
              key: "st_john's",
              value: "St. John's",
            },
            {
              key: "kitchener",
              value: "Kitchener",
            },
            {
              key: "corner_brook",
              value: "Corner Brook",
            },
            {
              key: "kingston",
              value: "Kingston",
            },
            {
              key: "halifax",
              value: "Halifax",
            },
            {
              key: "london",
              value: "London",
            },
            {
              key: "sydney",
              value: "Sydney",
            },
            {
              key: "ottawa",
              value: "Ottawa",
            },
            {
              key: "barrie",
              value: "Barrie",
            },
            {
              key: "sudbury",
              value: "Sudbury",
            },
            {
              key: "st_catharines",
              value: "St. Catharines",
            },
          ],
        },
      ],
      required: true,
    },
    {
      id: "q9",
      type: "dropdown",
      label: "State/Province",
      option_groups: [
        {
          conditions: {
            type: "equals",
            questionId: "q7",
            value: "us",
          },
          options: [
            { key: "al", value: "AL" },
            { key: "ak", value: "AK" },
            { key: "az", value: "AZ" },
            { key: "ar", value: "AR" },
            { key: "ca", value: "CA" },
            { key: "co", value: "CO" },
            { key: "ct", value: "CT" },
            { key: "de", value: "DE" },
            { key: "dc", value: "DC" },
            { key: "fl", value: "FL" },
            { key: "ga", value: "GA" },
            { key: "hi", value: "HI" },
            { key: "id", value: "ID" },
            { key: "il", value: "IL" },
            { key: "in", value: "IN" },
            { key: "ia", value: "IA" },
            { key: "ks", value: "KS" },
            { key: "ky", value: "KY" },
            { key: "la", value: "LA" },
            { key: "me", value: "ME" },
            { key: "md", value: "MD" },
            { key: "ma", value: "MA" },
            { key: "mi", value: "MI" },
            { key: "mn", value: "MN" },
            { key: "ms", value: "MS" },
            { key: "mo", value: "MO" },
            { key: "mt", value: "MT" },
            { key: "ne", value: "NE" },
            { key: "nv", value: "NV" },
            { key: "nh", value: "NH" },
            { key: "nj", value: "NJ" },
            { key: "nm", value: "NM" },
            { key: "ny", value: "NY" },
            { key: "nc", value: "NC" },
            { key: "nd", value: "ND" },
            { key: "oh", value: "OH" },
            { key: "ok", value: "OK" },
            { key: "or", value: "OR" },
            { key: "pa", value: "PA" },
            { key: "ri", value: "RI" },
            { key: "sc", value: "SC" },
            { key: "sd", value: "SD" },
            { key: "tn", value: "TN" },
            { key: "tx", value: "TX" },
            { key: "ut", value: "UT" },
            { key: "vt", value: "VT" },
            { key: "va", value: "VA" },
            { key: "wa", value: "WA" },
            { key: "wv", value: "WV" },
            { key: "wi", value: "WI" },
            { key: "wy", value: "WY" },
          ],
        },
        {
          conditions: {
            type: "equals",
            questionId: "q7",
            value: "canada",
          },
          options: [
            {
              key: "ab",
              value: "AB",
            },
            {
              key: "bc",
              value: "BC",
            },
            {
              key: "mb",
              value: "MB",
            },
            {
              key: "nb",
              value: "NB",
            },
            {
              key: "nl",
              value: "NL",
            },
            {
              key: "ns",
              value: "NS",
            },
            {
              key: "nt",
              value: "NT",
            },
            {
              key: "nu",
              value: "NU",
            },
            {
              key: "on",
              value: "ON",
            },
            {
              key: "pe",
              value: "PE",
            },
            {
              key: "qc",
              value: "QC",
            },
            {
              key: "sk",
              value: "SK",
            },
            {
              key: "yt",
              value: "YT",
            },
          ],
        },
      ],
      required: true,
    },
    {
      id: "q10",
      type: "text",
      label: "Zip Code/Postal Code",
      required: false,
    },
    {
      id: "q11",
      type: "dropdown",
      label: "Type of Loss",
      options: [
        {
          key: "cat_1_flood",
          value: "CAT 1 - Flood",
        },
        {
          key: "cat_3_sewer_back_up",
          value: "CAT 3 - Sewer Back Up",
        },
        {
          key: "collapse",
          value: "Collapse",
        },
        {
          key: "earthquake",
          value: "Earthquake",
        },
        {
          key: "fire",
          value: "Fire",
        },
        {
          key: "hail",
          value: "Hail",
        },
        {
          key: "hurricane",
          value: "Hurricane",
        },
        {
          key: "lightning",
          value: "Lightning",
        },
        {
          key: "smoke",
          value: "Smoke",
        },
        {
          key: "theft",
          value: "Theft",
        },
        {
          key: "tornado",
          value: "Tornado",
        },
        {
          key: "vandalism",
          value: "Vandalism",
        },
      ],
      required: true,
    },
    {
      id: "q12",
      type: "dropdown",
      label: "Carrier Name",
      options: [
        {
          key: "allstate",
          value: "Allstate",
        },
        {
          key: "farmers",
          value: "Farmers",
        },
        {
          key: "ama",
          value: "AMA",
        },
        {
          key: "gore",
          value: "Gore",
        },
        {
          key: "aviva",
          value: "Aviva",
        },
        {
          key: "heartland",
          value: "Heartland",
        },
        {
          key: "bcaa",
          value: "BCAA",
        },
        {
          key: "intact",
          value: "Intact",
        },
        {
          key: "chubb",
          value: "Chubb",
        },
        {
          key: "liberty_mutual",
          value: "Liberty Mutual",
        },
        {
          key: "contractor_connection",
          value: "Contractor Connection",
        },
        {
          key: "rsa",
          value: "RSA",
        },
        {
          key: "cooperators",
          value: "Cooperators",
        },
        {
          key: "state_farm",
          value: "State Farm",
        },
        {
          key: "dgig",
          value: "DGIG",
        },
        {
          key: "tdmm",
          value: "TDMM",
        },
        {
          key: "economical",
          value: "Economical",
        },
        {
          key: "travelers",
          value: "Travelers",
        },
        {
          key: "wawanessa",
          value: "Wawanessa",
        },
        {
          key: "usaa",
          value: "USAA",
        },
        {
          key: "other",
          value: "Other",
        },
      ],
      required: true,
    },
    {
      id: "q13",
      type: "text",
      label: "Claim Adjuster",
      required: false,
    },
    {
      id: "q14",
      type: "text",
      label: "Claim Number",
      required: false,
    },
    {
      id: "q15",
      type: "text",
      label: "Policy Number",
      required: false,
    },

    {
      id: "q16",
      type: "dropdown",
      label: "Type of Request",
      options: [
        {
          key: "(Emergency) Interior Only",
          value: "E - Interior Only",
          req: [1]
        },
        {
          key: "(Emergency) Exterior Only",
          value: "E - Exterior Only",
          req: [2]
        },
        {
          key: "(Emergency) Separate Contents Only",
          value: "E - Separate Contents Only",
          req: [3]
        },
        {
          key: "(Emergency) Interior & Exterior",
          value: "E - Interior & Exterior",
          req: [2, 1]
        },
        {
          key: "(Emergency) Interior & Separate Contents",
          value: "E - Interior & Separate Contents",
          req: [1, 3],
        },
        {
          key: "(Emergency) Exterior & Separate Contents",
          value: "E - Exterior & Separate Contents",
          req: [2, 3]
        },
        {
          key: "(Emergency) Interior, Exterior & Separate Contents",
          value: "E - Interior, Exterior & Separate Contents",
          req: [1, 2, 3]
        },
        {
          key: "(Rebuild) Interior Only",
          value: "R - Interior Only",
          req: [8]
        },
        {
          key: "(Rebuild) Exterior Only",
          value: "R - Exterior Only",
          req: [9]
        },
        {
          key: "(Rebuild) Interior & Exterior",
          value: "R - Interior & Exterior",
          req: [8, 9]
        },
        {
          key: "(Rebuild) Interior & Separate Contents",
          value: "R - Interior & Separate Contents",
          req: [8, 3]
        },
        {
          key: "(Rebuild) Exterior & Separate Contents",
          value: "R - Exterior & Separate Contents",
          req: [9, 3]
        },
        {
          key: "(Rebuild) Interior, Exterior & Separate Contents",
          value: "R - Interior, Exterior & Separate Contents",
          req: [8, 9, 3]
        },
        {
          key: "(Emergency & Rebuild) Interior Only",
          value: "E&R - Interior Only",
          req: [1, 8]
        },
        {
          key: "(Emergency & Rebuild) Exterior Only",
          value: "E&R - Exterior Only",
          req: [1, 9]
        },
        {
          key: "(Emergency & Rebuild) Interior & Exterior",
          value: "E&R - Interior & Exterior",
          req: [1, 2, 8, 9]
        },
        {
          key: "(Emergency & Rebuild) Interior Only & Separate Contents",
          value: "E&R - Interior Only & Separate Contents",
          req: [1, 2, 8, 9]
        },
        {
          key: "(Emergency & Contents) Exterior Only & Separate Contents",
          value: "E&C - Exterior Only & Separate Contents",
          req: [2, 3, 9]
        },
        {
          key: "(Emergency & Rebuild & Contents) Interior, Exterior & Separate Contents",
          value: "E&R&C - Interior, Exterior & Separate Contents",
          req: [1, 2, 3, 8, 9]
        },
      ],
      required: true,
    },
    {
      id: "q17",
      type: "radio",
      label: "Outside of Service Area?",
      options: [
        {
          key: "yes",
          value: "Yes",
        },
        {
          key: "no",
          value: "No",
        },
      ],
      required: true,
    },
    {
      id: "q18",
      type: "dropdown",
      label: "Emergency Profit %",
      options: [
        {
          key: "0",
          value: 0,
        },
        {
          key: "1",
          value: 1,
        },
        {
          key: "2",
          value: 2,
        },
        {
          key: "3",
          value: 3,
        },
        {
          key: "4",
          value: 4,
        },
        {
          key: "5",
          value: 5,
        },
        {
          key: "6",
          value: 6,
        },
        {
          key: "7",
          value: 7,
        },
        {
          key: "8",
          value: 8,
        },
        {
          key: "9",
          value: 9,
        },
        {
          key: "10",
          value: 10,
        },
        {
          key: "11",
          value: 11,
        },
        {
          key: "12",
          value: 12,
        },
        {
          key: "13",
          value: 13,
        },
        {
          key: "14",
          value: 14,
        },
        {
          key: "15",
          value: 15,
        },
        {
          key: "16",
          value: 16,
        },
        {
          key: "17",
          value: 17,
        },
        {
          key: "18",
          value: 18,
        },
        {
          key: "19",
          value: 19,
        },
        {
          key: "20",
          value: 20,
        },
      ],
      required: false,
    },
    {
      id: "q19",
      type: "dropdown",
      label: "Emergency Overhead %",
      options: [
        {
          key: "0",
          value: 0,
        },
        {
          key: "1",
          value: 1,
        },
        {
          key: "2",
          value: 2,
        },
        {
          key: "3",
          value: 3,
        },
        {
          key: "4",
          value: 4,
        },
        {
          key: "5",
          value: 5,
        },
        {
          key: "6",
          value: 6,
        },
        {
          key: "7",
          value: 7,
        },
        {
          key: "8",
          value: 8,
        },
        {
          key: "9",
          value: 9,
        },
        {
          key: "10",
          value: 10,
        },
        {
          key: "11",
          value: 11,
        },
        {
          key: "12",
          value: 12,
        },
        {
          key: "13",
          value: 13,
        },
        {
          key: "14",
          value: 14,
        },
        {
          key: "15",
          value: 15,
        },
        {
          key: "16",
          value: 16,
        },
        {
          key: "17",
          value: 17,
        },
        {
          key: "18",
          value: 18,
        },
        {
          key: "19",
          value: 19,
        },
        {
          key: "20",
          value: 20,
        },
      ],
      required: false,
    },
    {
      id: "q20",
      type: "dropdown",
      label: "Reconstruction Profit %",
      options: [
        {
          key: "0",
          value: 0,
        },
        {
          key: "1",
          value: 1,
        },
        {
          key: "2",
          value: 2,
        },
        {
          key: "3",
          value: 3,
        },
        {
          key: "4",
          value: 4,
        },
        {
          key: "5",
          value: 5,
        },
        {
          key: "6",
          value: 6,
        },
        {
          key: "7",
          value: 7,
        },
        {
          key: "8",
          value: 8,
        },
        {
          key: "9",
          value: 9,
        },
        {
          key: "10",
          value: 10,
        },
        {
          key: "11",
          value: 11,
        },
        {
          key: "12",
          value: 12,
        },
        {
          key: "13",
          value: 13,
        },
        {
          key: "14",
          value: 14,
        },
        {
          key: "15",
          value: 15,
        },
        {
          key: "16",
          value: 16,
        },
        {
          key: "17",
          value: 17,
        },
        {
          key: "18",
          value: 18,
        },
        {
          key: "19",
          value: 19,
        },
        {
          key: "20",
          value: 20,
        },
      ],
      required: false,
    },
    {
      id: "q21",
      type: "dropdown",
      label: "Reconstruction Overhead %",
      options: [
        {
          key: "0",
          value: 0,
        },
        {
          key: "1",
          value: 1,
        },
        {
          key: "2",
          value: 2,
        },
        {
          key: "3",
          value: 3,
        },
        {
          key: "4",
          value: 4,
        },
        {
          key: "5",
          value: 5,
        },
        {
          key: "6",
          value: 6,
        },
        {
          key: "7",
          value: 7,
        },
        {
          key: "8",
          value: 8,
        },
        {
          key: "9",
          value: 9,
        },
        {
          key: "10",
          value: 10,
        },
        {
          key: "11",
          value: 11,
        },
        {
          key: "12",
          value: 12,
        },
        {
          key: "13",
          value: 13,
        },
        {
          key: "14",
          value: 14,
        },
        {
          key: "15",
          value: 15,
        },
        {
          key: "16",
          value: 16,
        },
        {
          key: "17",
          value: 17,
        },
        {
          key: "18",
          value: 18,
        },
        {
          key: "19",
          value: 19,
        },
        {
          key: "20",
          value: 20,
        },
      ],
      required: false,
    },
    {
      id: "q22",
      type: "dropdown",
      label: "Total Number of KMs/Miles for ONE RETURN TRIP?",
      options: [
        {
          "key": "110",
          "value": 110
        },
        {
          "key": "120",
          "value": 120
        },
        {
          "key": "130",
          "value": 130
        },
        {
          "key": "140",
          "value": 140
        },
        {
          "key": "150",
          "value": 150
        },
        {
          "key": "160",
          "value": 160
        },
        {
          "key": "170",
          "value": 170
        },
        {
          "key": "180",
          "value": 180
        },
        {
          "key": "190",
          "value": 190
        },
        {
          "key": "200",
          "value": 200
        },
        {
          "key": "210",
          "value": 210
        },
        {
          "key": "220",
          "value": 220
        },
        {
          "key": "230",
          "value": 230
        },
        {
          "key": "240",
          "value": 240
        },
        {
          "key": "250",
          "value": 250
        }
      ],
      required: true,
    },
    {
      id: "q23",
      type: "dropdown",
      label: "Number of KMs/Miles included within your Service Area for ONE RETURN TRIP?",
      options: [
        {
          "key": "25",
          "value": 25
        },
        {
          "key": "50",
          "value": 50
        },
        {
          "key": "75",
          "value": 75
        },
        {
          "key": "100",
          "value": 100
        },
        {
          "key": "150",
          "value": 150
        },
        {
          "key": "200",
          "value": 200
        }
      ],
      required: true,
    },
    {
      id: "q24",
      type: "dropdown",
      last_question_for_part_one: true,
      label: "TRADE, LENGTH OF TRIP, NUMBER OF TRIPS (Select all that are applicable)",
      options: [
        {
          label: "TRADE & NUMBER OF TRIPS (Select all that are applicable) for  Emergency",
          values: [
            { key: "water_technician", text: "Water Tradesperson - Technician (per hour)" },
            { key: "water_technician_after_hours", text: "Water Tradesperson - Technician - AFTER HOURS (per hour)" },
            { key: "water_helper", text: "Water Tradesperson - HELPER (per hour)" },
            { key: "water_helper_after_hours", text: "Water Tradesperson - HELPER - AFTER HOURS (per hour)" },
            { key: "debris_technician", text: "Debris Removal/Truck Driver Tradesperson - Technician (per hour)" },
            { key: "debris_helper", text: "Debris Removal/Truck Driver Tradesperson - HELPER (per hour)" },
            { key: "content_technician", text: "Content Manipulation Tradesperson - Technician (per hour)" },
            { key: "content_helper", text: "Content Manipulation Tradesperson - HELPER (per hour)" },
            { key: "content_technician_after_hours", text: "Content Manipulation Tradesperson - Technician - AFTER HOURS (per hour)" },
            { key: "content_helper_after_hours", text: "Content Manipulation Tradesperson - HELPER - AFTER HOURS (per hour)" },
            { key: "inventory_technician", text: "Content Inventory Tradesperson - Technician (per hour)" },
            { key: "inventory_helper", text: "Content Inventory Tradesperson - HELPER (per hour)" },
            { key: "inventory_technician_after_hours", text: "Content Inventory Tradesperson - Technician - AFTER HOURS (per hour)" },
            { key: "inventory_helper_after_hours", text: "Content Inventory Tradesperson - HELPER - AFTER HOURS (per hour)" },
            { key: "repair_technician", text: "Temporary Repair Tradesperson - Technician (per hour)" },
            { key: "repair_helper", text: "Temporary Repair Tradesperson - HELPER (per hour)" },
            { key: "repair_technician_after_hours", text: "Temporary Repair Tradesperson - Technician - AFTER HOURS (per hour)" },
            { key: "repair_helper_after_hours", text: "Temporary Repair Tradesperson - HELPER - AFTER HOURS (per hour)" },
            { key: "hazardous_material_technician", text: "Hazardous Material Tradesperson - Technician (per hour)" },
            { key: "hazardous_material_technician_after_hours", text: "Hazardous Material Tradesperson - Technician - AFTER HOURS (per hour)" },
            { key: "plumber_technician", text: "Plumber Tradesperson - Technician (per hour)" },
            { key: "plumber_helper", text: "Plumber Tradesperson - Helper (per hour)" },
            { key: "roofing_technician", text: "Roofing Tradesperson - Technician (per hour)" },
            { key: "roofing_helper", text: "Roofing Tradesperson - Helper (per hour)" },
            { key: "framing_technician", text: "Framing Carpenter Tradesperson - Technician (per hour)" },
            { key: "framing_helper", text: "Framing Carpenter Tradesperson - Helper (per hour)" },
            { key: "project_manager_supervisor", text: "Project Manager/Supervisor (per hour)" }
          ],
          condition: "optionSet1Condition" // Add a condition for this set
        },
        {
          label: "TRADE & NUMBER OF TRIPS (Select all that are applicable) for Rebuild ",
          values: [
            { key: "debris_technician", text: "Debris Removal/Truck Driver Tradesperson - Technician (per hour)" },
            { key: "debris_helper", text: "Debris Removal/Truck Driver Tradesperson - HELPER (per hour)" },
            { key: "insulation_technician", text: "Insulation Tradesperson - Technician (per hour)" },
            { key: "insulation_helper", text: "Insulation Tradesperson - HELPER (per hour)" },
            { key: "drywall_technician", text: "Drywall Tradesperson - Technician (per hour)" },
            { key: "drywall_helper", text: "Drywall Tradesperson - HELPER (per hour)" },
            { key: "finishing_carpenter_technician", text: "Finishing Carpenter Tradesperson - Technician (per hour)" },
            { key: "finishing_carpenter_helper", text: "Finishing Carpenter Tradesperson - HELPER (per hour)" },
            { key: "painter_technician", text: "Painter Tradesperson - Technician (per hour)" },
            { key: "painter_helper", text: "Painter Tradesperson - HELPER (per hour)" },
            { key: "flooring_technician", text: "Flooring Tradesperson - Technician (per hour)" },
            { key: "flooring_helper", text: "Flooring Tradesperson - HELPER (per hour)" },
            { key: "acoustical_treatments_technician", text: "Acoustical Treatments Tradesperson - Technician (per hour)" },
            { key: "acoustical_treatments_helper", text: "Acoustical Treatments Tradesperson - HELPER (per hour)" },
            { key: "door_technician", text: "Door Tradesperson - Technician (per hour)" },
            { key: "door_helper", text: "Door Tradesperson - HELPER (per hour)" },
            { key: "window_technician", text: "Window Tradesperson - Technician (per hour)" },
            { key: "window_helper", text: "Window Tradesperson - HELPER (per hour)" },
            { key: "cleaning_carpet_technician", text: "Cleaning Technician - CARPET Clean (per hour)" },
            { key: "cleaning_carpet_helper", text: "Cleaning Technician - CARPET Clean - HELPER (per hour)" },
            { key: "cleaning_final_technician", text: "Cleaning Technician - FINAL Clean (per hour)" },
            { key: "cleaning_final_helper", text: "Cleaning Technician - FINAL Clean - HELPER (per hour)" },
            { key: "content_manipulation_technician", text: "Content Manipulation Tradesperson - Technician (per hour)" },
            { key: "content_manipulation_helper", text: "Content Manipulation Tradesperson - HELPER (per hour)" },
            { key: "content_inventory_technician", text: "Content Inventory Tradesperson - Technician (per hour)" },
            { key: "content_inventory_helper", text: "Content Inventory Tradesperson - HELPER (per hour)" },
            { key: "framing_technician", text: "Framing Carpenter Tradesperson - Technician (per hour)" },
            { key: "framing_helper", text: "Framing Carpenter Tradesperson - Helper (per hour)" },
            { key: "electrical_technician", text: "Electrical Tradesperson - Technician (per hour)" },
            { key: "electrical_helper", text: "Electrical Tradesperson - Helper (per hour)" },
            { key: "plumber_technician", text: "Plumber Tradesperson - Technician (per hour)" },
            { key: "plumber_helper", text: "Plumber Tradesperson - Helper (per hour)" },
            { key: "hvac_technician", text: "HVAC Tradesperson - Technician (per hour)" },
            { key: "hvac_helper", text: "HVAC Tradesperson - Helper (per hour)" },
            { key: "project_manager_supervisor", text: "Project Manager/Supervisor (per hour)" },
            { key: "roofing_technician", text: "Roofing Tradesperson - Technician (per hour)" },
            { key: "roofing_helper", text: "Roofing Tradesperson - Helper (per hour)" },
            { key: "soft_metals_technician", text: "Soft Metals Tradesperson - Technician (per hour)" },
            { key: "soft_metals_helper", text: "Soft Metals Tradesperson - Helper (per hour)" },
            { key: "siding_technician", text: "Siding Tradesperson - Technician (per hour)" },
            { key: "siding_helper", text: "Siding Tradesperson - Helper (per hour)" },
            { key: "fencing_technician", text: "Fencing Tradesperson - Technician (per hour)" },
            { key: "fencing_helper", text: "Fencing Tradesperson - Helper (per hour)" },
            { key: "exterior_structure_technician", text: "Exterior Structure Tradesperson - Technician (per hour)" },
            { key: "exterior_structure_helper", text: "Exterior Structure Tradesperson - Helper (per hour)" }
          ],
          condition: "optionSet2Condition" // Add a condition for this set
        },
        {
          label: "TRADE & NUMBER OF TRIPS (Select all that are applicable) for Contents",
          values: [
            { key: "content_manipulation_technician", text: "Content Manipulation Tradesperson - Technician (per hour)" },
            { key: "content_manipulation_helper", text: "Content Manipulation Tradesperson - HELPER (per hour)" },
            { key: "content_manipulation_after_hours_technician", text: "Content Manipulation Tradesperson - Technician - AFTER HOURS (per hour)" },
            { key: "content_manipulation_after_hours_helper", text: "Content Manipulation Tradesperson - HELPER - AFTER HOURS (per hour)" },
            { key: "content_inventory_technician", text: "Content Inventory Tradesperson - Technician (per hour)" },
            { key: "content_inventory_helper", text: "Content Inventory Tradesperson - HELPER (per hour)" },
            { key: "content_inventory_after_hours_technician", text: "Content Inventory Tradesperson - Technician - AFTER HOURS (per hour)" },
            { key: "content_inventory_after_hours_helper", text: "Content Inventory Tradesperson - HELPER - AFTER HOURS (per hour)" }
          ],
          condition: "optionSet3Condition" // Add a condition for this set
        },
        {
          "label": "Dropdown 2",
          "display": "Enter per Hour Amount",
          "values": [
            { "key": "0.25", "text": "0.25" },
            { "key": "0.50", "text": "0.50" },
            { "key": "0.75", "text": "0.75" },
            { "key": "1.00", "text": "1.00" },
            { "key": "1.25", "text": "1.25" },
            { "key": "1.50", "text": "1.50" },
            { "key": "1.75", "text": "1.75" },
            { "key": "2.00", "text": "2.00" },
            { "key": "2.25", "text": "2.25" },
            { "key": "2.50", "text": "2.50" },
            { "key": "2.75", "text": "2.75" },
            { "key": "3.00", "text": "3.00" },
            { "key": "3.25", "text": "3.25" },
            { "key": "3.50", "text": "3.50" },
            { "key": "3.75", "text": "3.75" },
            { "key": "4.00", "text": "4.00" }
          ]
        },
        {
          "label": "Dropdown 3",
          "display": "Enter Total # of Trips",
          "values": [
            { "key": "1", "text": "1" },
            { "key": "2", "text": "2" },
            { "key": "3", "text": "3" },
            { "key": "4", "text": "4" },
            { "key": "5", "text": "5" },
            { "key": "6", "text": "6" },
            { "key": "7", "text": "7" },
            { "key": "8", "text": "8" },
            { "key": "9", "text": "9" },
            { "key": "10", "text": "10" }
          ]
        }
      ],
      required: false,
    },
  ],

  q3: "^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$",
  q4: /^\d{3}\d{3}\d{4}$/,
  q5: /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
  // q11: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
  q15: /^(-?[0-9]+)$/,
  q16: /^(-?[0-9]+)$/,
  // q81: "^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$",
};

const question_mapper = {
  q1: "company_name_or_location",
  q2: "requester_name",
  q3: "full_name",
  q4: "phone",
  q5: "email",
  q6: "address",
  q8: "city",
  q9: "region",
  q10: "postal_code",
  q11: "type_of_loss",
  q12: "carrier_name",
  q13: "claim_adjuster",
  q14: "claim_number",
  q15: "policy_number",
  q16: "type_of_request",
  q17: "is_outside_of_service_area",
  q18: "emergency_profit",
  q19: "emergency_overhead",
  q20: "reconstruction_profit",
  q21: "reconstruction_overhead",
};

export const payload_object_maker = (fieldInputs) => {


  // console.log(fieldInputs)
  const formData = new FormData();
  formData.append("file_number", "123456");
  formData.append("content_profit", "2");
  formData.append("content_overhead", "2");

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const appendValuesBasedOnInput = (id, response) => {
    if (question_mapper[id]) {
      if (id === "q17") {
        let responseBoolean = response === "yes" ? 1 : 0;
        formData.append(question_mapper[id], responseBoolean);
      } else if (id === "q11" || id === "q16") {
        let options = utils_data_for_dynamic_form.question_part_one.find(
          (val) => val.id === id
        ).options;
        let index;

        if (response && response.answer) {
          // If response has an 'answer' property
          index = options.findIndex((val) => val.value == response.answer);
        } else {
          // If response is directly the answer
          index = options.findIndex((val) => val.value == response);
        }

        formData.append(question_mapper[id], String(index + 1));
      } else {
        if (response && response.answer) {
          // If response has an 'answer' property
          formData.append(question_mapper[id], response.answer);
        } else {
          // If response is directly the answer
          formData.append(question_mapper[id], response);
        }
      }
    }
  };

  // Append random values for room_information[0]
  //   appendRandomValues();

  // Append values based on fieldInputs
  fieldInputs.forEach((input) => {
    let { id, response } = input;
    appendValuesBasedOnInput(id, response);
  });

  return formData;
};
export const general_payload_object_maker = (fieldInputs) => {
  const payload = {
    form_answers: [],
  };

  fieldInputs.forEach((input) => {
    let { id, response, type } = input;
    const questionId = parseInt(id.slice(1));

    let answer_1 = null;
    let answer_2 = null;

    // Check for response and dynamic properties
    if (response) {
      if (response.answer !== undefined && response.quantity !== undefined && response.quantity_id !== undefined) {
        answer_1 = response.answer;
        answer_2 = response.quantity_id;
      } else if (response.done !== undefined && response.main !== undefined && response.secondary !== undefined) {
        answer_1 = response.main;
        answer_2 = response.secondary;
      }
      // Add more dynamic checks as needed for different response structures
    }

    // Define the structure for each form answer
    let formAnswer = {
      question_id: questionId,
      answer: {
        answer_1: answer_1,
        answer_2: answer_2,
      },
    };

    payload.form_answers.push(formAnswer);
  });

  return payload;
};



export const room_payload_maker = (fieldInputs) => {
  const formData = new FormData();
  formData.append("file_number", "123456");
  formData.append("content_profit", "2");
  formData.append("content_overhead", "2");

  // Fetch the first question's response and set it as the room name
  const firstQuestion = fieldInputs.find((input) => input.response);
  const roomName = firstQuestion ? firstQuestion.response || "default_room_name_value" : "default_room_name_value";
  formData.append("room_name", roomName);

  // Exclude the first question from field inputs
  const remainingFieldInputs = fieldInputs.filter((input) => input.id !== firstQuestion?.id);

  remainingFieldInputs.forEach((input) => {
    let { id, response, type } = input;
    // Convert question_id to integer
    const questionId = parseInt(id.slice(1));

    switch (type) {
      case "dropdown":
        // Only answer and answer_id will be sent
        const answerIdDropdown = response.answer_id || "default_answer_id";
        formData.append(`room_information[${id}][question_id]`, questionId);
        formData.append(`room_information[${id}][answer_id]`, answerIdDropdown);
        break;

      case "dropdown_multi_value_and_multi_value":
        // Multi-value case
        // Send answer_id, answer, question_id, and quantity_id
        const answerIdMultiValue = response.answer_id || "default_answer_id";
        const answerMultiValue = response.answer || "default_answer_value";
        const quantityIdMultiValue = response.quantity_id || null;

        formData.append(`room_information[${id}][question_id]`, questionId);
        formData.append(`room_information[${id}][answer_id]`, answerIdMultiValue);
        formData.append(`room_information[${id}][answer]`, answerMultiValue);
        formData.append(`room_information[${id}][quantity_id]`, quantityIdMultiValue);
        break;

      case "dropdown_and_quantity_and_days":
        // Send answer, answer_id, quantity, quantity_id, number_of_days, and number_of_days_id
        const answerIdQuantityDays = response.answer_id || "default_answer_id";
        const answerQuantityDays = response.answer || "default_answer_value";
        const quantityQuantityDays = response.quantity || null;
        const quantityIdQuantityDays = response.quantity_id || null;
        const numberOfDaysQuantityDays = response.number_of_days || null;
        const numberOfDaysIdQuantityDays = response.number_of_days_id || null;

        formData.append(`room_information[${id}][question_id]`, questionId);
        formData.append(`room_information[${id}][answer_id]`, answerIdQuantityDays);
        formData.append(`room_information[${id}][answer]`, answerQuantityDays);
        formData.append(`room_information[${id}][quantity]`, quantityQuantityDays);
        formData.append(`room_information[${id}][quantity_id]`, quantityIdQuantityDays);
        formData.append(`room_information[${id}][number_of_days]`, numberOfDaysQuantityDays);
        formData.append(`room_information[${id}][number_of_days_id]`, numberOfDaysIdQuantityDays);
        break;

      case "dropdown_and_quantity_and_afterhours":
        // Send answer_id, quantity, quantity_id, is_after_hours, and is_after_hours_id
        const answerIdAfterHours = response.answer_id || "default_answer_id";
        const quantityAfterHours = response.quantity || null;
        const quantityIdAfterHours = response.quantity_id || null;
        const isAfterHoursAfterHours = response.is_after_hours !== undefined
          ? response.is_after_hours === "true" ? 1 : (response.is_after_hours === "false" ? 0 : null)
          : null;
        const isAfterHoursIdAfterHours = response.is_after_hours_id || null;

        formData.append(`room_information[${id}][question_id]`, questionId);
        formData.append(`room_information[${id}][answer_id]`, answerIdAfterHours);
        formData.append(`room_information[${id}][quantity]`, quantityAfterHours);
        formData.append(`room_information[${id}][quantity_id]`, quantityIdAfterHours);
        formData.append(`room_information[${id}][is_after_hours]`, isAfterHoursAfterHours);
        formData.append(`room_information[${id}][is_after_hours_id]`, isAfterHoursIdAfterHours);
        break;

      case "date_time":
        // Send answer_id, quantity, quantity_id, is_after_hours, and is_after_hours_id
        const answerIdDate_time = response.answer_id || "default_answer_id";
        const quantityDate_time = response.quantity || null;
        const quantityIdDate_time = response.quantity_id || null;
        const isAfterHoursDate_time = response.is_after_hours !== undefined
          ? response.is_after_hours === "true" ? 1 : (response.is_after_hours === "false" ? 0 : null)
          : null;
        const isAfterHoursIdDate_time = response.is_after_hours_id || null;

        formData.append(`room_information[${id}][question_id]`, questionId);
        formData.append(`room_information[${id}][answer_id]`, answerIdDate_time);
        formData.append(`room_information[${id}][quantity]`, quantityDate_time);
        formData.append(`room_information[${id}][quantity_id]`, quantityIdDate_time);
        formData.append(`room_information[${id}][is_after_hours]`, isAfterHoursDate_time);
        formData.append(`room_information[${id}][is_after_hours_id]`, isAfterHoursIdDate_time);


        break;
      // Add additional cases if needed for other types

      default:
        // Default case (if type is not recognized)
        break;
    }
    // Set non-relevant values to null only if they don't exist already
    if (!formData.has(`room_information[${id}][quantity]`)) {
      formData.append(`room_information[${id}][quantity]`, null);
    }
    if (!formData.has(`room_information[${id}][quantity_id]`)) {
      formData.append(`room_information[${id}][quantity_id]`, null);
    }
    if (!formData.has(`room_information[${id}][number_of_days]`)) {
      formData.append(`room_information[${id}][number_of_days]`, null);
    }
    if (!formData.has(`room_information[${id}][number_of_days_id]`)) {
      formData.append(`room_information[${id}][number_of_days_id]`, null);
    }
    if (!formData.has(`room_information[${id}][is_after_hours]`)) {
      formData.append(`room_information[${id}][is_after_hours]`, null);
    }
    if (!formData.has(`room_information[${id}][is_after_hours_id]`)) {
      formData.append(`room_information[${id}][is_after_hours_id]`, null);
    }
  });

  return formData;
};