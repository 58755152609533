import React, { useEffect, useState } from "react";
import { CloseCircle, Edit } from "iconsax-react";
import { axiosAuth } from "../lib/axiosInstance";
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const SurveyResult = ({ dropdownData }) => {
  // First, organize the data by grouping items with the same question_title
  const groupedData = {};
  dropdownData?.room_information?.forEach(item => {
    if (!groupedData[item.question_title]) {
      groupedData[item.question_title] = [];
    }
    groupedData[item.question_title].push(item.answer?.value);
    if (item.addOn !== undefined && item.addOn !== null && item.addOn !== "") {
      // If 'addOn' is present, push it to the corresponding array in 'groupedData'
      groupedData[item.question_title].push(item.addOn);
    }
    if (item.date_time && item.date_time.trim() !== "" && groupedData[item.question_title]) {
      // Try to parse the date and check if it's valid
      const parsedDate = new Date(item.date_time);
      if (!isNaN(parsedDate)) { // Ensure the date is valid
        const dateTime = parsedDate.toLocaleString();
        groupedData[item.question_title] = [dateTime];
      }
    }
  });

  // Then, map over the grouped data and combine values into a single string separated by commas
  const groupedItems = Object.keys(groupedData).map(questionTitle => ({
    questionTitle,
    combinedValue: groupedData[questionTitle].join(", ")
  }));


  return (
    <>
      {groupedItems.map((group, index) => (
        <div key={index} className={`flex bg-gray-${index % 2 === 0 ? '300' : '200'} gap-4 transition duration-300 hover:bg-gray-400 p-[0.6rem]`}>
          <div className="flex-1">{group.questionTitle}</div>
          <div className="flex-1 text-center">{(group.combinedValue == "" || group.combinedValue == ", ") ? "NA" : group.combinedValue}</div>
        </div>
      ))}
    </>
  );
}



const ResultComponent = ({ setShowResultModal, roomId, roomName, requestType, pending, roomStatus }) => {
  const [dropdownData, setDropdownData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  localStorage.setItem('individual_file_id', id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosAuth.get(`rooms/${roomId}`);
        setDropdownData(response?.data);

      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchData();

  }, [roomId]);

  const handleEmailAction = async (e) => {
    e.stopPropagation(); // Stop event propagation to prevent modal from closing
    try {
      // Call the API to trigger the email action
      await axiosAuth.get(`send-email/${roomId}`);
      // Show success toast
      toast.success('Email sent successfully!', { position: toast.POSITION.BOTTOM_RIGHT });
    } catch (error) {
      console.error("Error triggering email action:", error);
      // Show error toast
      toast.error('Failed to send email. Please try again.', { position: toast.POSITION.BOTTOM_RIGHT });
    }
  };
  const RoomDelete = ({ openDialog }) => {
    const [open, setOpen] = useState(openDialog);
    const handleClose = () => {
      setOpen(false);
      setOpenDialog(false)

    };
    const handleDelete = async () => {
      try {
        await axiosAuth.get(`delete-room/${roomId}`);
        toast.success("Survey deleted successfully");

        handleClose()
        setOpenDialog(false)

        navigate(`/home`)
      } catch (error) {
        console.error("Error while deleting survey:", error);
      }

    }
    return (
      <div>
        {open && (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this survey?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action cannot be undone. Proceeding will permanently remove all data associated with this survey. Do you wish to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button color="error" variant="contained" onClick={handleDelete} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  };
  const handleRoomDelete = () => {
    setOpenDialog(true)
  }

  return (
    <div className="flex flex-col h-[80%] sm:w-[73%] break-all sm:break-words text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl rounded-lg shadow-md overflow-hidden">
      <div onClick={(e) => { e.stopPropagation() }} className="flex justify-between gap-4  flex-row-reverse items-center p-4 bg-gradient-to-r from-slate-700 to-slate-800 text-white">
        <CloseCircle className="cursor-pointer" onClick={() => setShowResultModal(false)} size="25" color="#ff5c21" />
        <RoomDelete openDialog={openDialog} />
        {
          <div className="flex items-center gap-5 ">

            <button
              onClick={() => {
                const updatedDropdownData = {
                  ...dropdownData,
                  roomName: roomName,
                  requestType: requestType,
                  roomId: roomId
                };
                navigate(`/individual-room/edit/${roomId}`, {
                  state: updatedDropdownData
                });
              }}
            >
              { <Edit size="20" className="hover:text-indigo-500 text-white text-2xl" />}
            </button>
            {<FontAwesomeIcon icon={faTrash} onClick={handleRoomDelete} className="hover:text-red-500 text-lg cursor-pointer " />}

          </div>

        }

      </div>
      <div className="flex bg-slate-500 text-white p-[0.6rem] text-center">
        <div className="flex-1">Questions</div>
        <div className="flex-1 ">Answers</div>
      </div>
      <div onClick={(e) => { e.stopPropagation() }} className="flex-grow p-4 overflow-x-auto bg-gray-200">
        <SurveyResult dropdownData={dropdownData} />
        {/* {
        
        
        dropdownData?.room_information?.map((item, index) => (
          <div key={index} className={`flex bg-gray-${index % 2 === 0 ? '300' : '200'} gap-4 transition duration-300 hover:bg-gray-400 p-[0.6rem]`}>
            <div className="flex-1">{item.question_title}</div>
            <div className="flex-1 text-center">{item.answer?.value}</div>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default ResultComponent;
