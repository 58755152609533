import React from "react";

const Steps = ({ currentStep,totalSteps, height = 5 }) => {

  let progress = (currentStep / totalSteps) * 100;

  if(progress >= 100){
    progress= 100-progress;
  }


  const Parentdiv = {
    height: height,
    width: "100%",
    margin: '0.5rem 0.1px 0.1rem',
    backgroundColor: "white",
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    background: "#8E2DE2" /* fallback for old browsers */,
    background:
      "-webkit-linear-gradient(to right, #4A00E0, #8E2DE2)" /* Chrome 10-25, Safari 5.1-6 */,
    background:
      "linear-gradient(to right, #4A00E0, #8E2DE2)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,

    borderRadius: 40,
    textAlign: "right",
    position: "relative",
    overflow: "hidden", // Ensure the glow is contained within the progress bar
  };

  const Shimmer = {
    position: "absolute",
    top: 0,
    left: "-100%",
    height: "100%",
    width: "200%",
    background:
      "-webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(50%, rgba(255,255,255,0.5)), color-stop(100%, rgba(255,255,255,0)))",
    background:
      "linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.5), rgba(255,255,255,0))",
    animation: "shimmer 3s linear infinite forwards",
    zIndex: 1,
  };

  const progresstext = {
    color: "black",
    fontWeight: 900,
    position: "absolute",
    right: "4%",
    top: "5%",
    color: "white",
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={Shimmer}></span>
        {/* <span style={progresstext}>{`${progress}%`}</span> */}
      </div>
    </div>
  );
};

export default Steps;
