import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { axiosAuth } from '../../../../lib/axiosInstance';
import Sidebar from '../../../Sidebar';
import { LiaEyeSolid } from 'react-icons/lia';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { CloseCircle } from 'iconsax-react';

const QuestionList = () => {
    const { code } = useParams();
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    localStorage.setItem('Macros', code);
    useEffect(() => {
        fetchData();
    }, [code]);

    const fetchData = async () => {
        const response = await axiosAuth.post('/get-question-list', { code });
        setQuestions(response.data);
    };

    const deleteQuestion = async (id) => {
        if (window.confirm('Are you sure you want to delete this question?')) {
            try {
                await axiosAuth.post('/delete-question', { id });
                fetchData(); // Refresh the question list
            } catch (error) {
                console.error('Error deleting question:', error);
                alert('Failed to delete question. Please try again.');
            }
        }
    };

    const editQuestion = (question) => {
        setCurrentQuestion(question);
        setIsModalOpen(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentQuestion((prev) => ({ ...prev, [name]: value }));
    };

    const saveChanges = async () => {
        try {
            await axiosAuth.post('/edit-question', currentQuestion);
            setIsModalOpen(false);
            fetchData(); // Refresh the question list
        } catch (error) {
            console.error('Error editing question:', error);

        }
    };

    const handleCloseModal = () => {
        navigate('/admin/editor');
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-1 p-8 bg-gray-100 max-h-screen overflow-scroll">
                <div className="bg-white shadow-md rounded-lg p-6">
                    <div className='flex justify-between'>
                        <div className=" flex text-2xl font-bold mb-4">Question List for Code: {code}</div>
                        <div className='flex'><button className="bg-[#36154e] text-white px-3 py-1 rounded text-sm h-8" onClick={handleCloseModal} >Back</button></div>
                    </div>
                    <div className="overflow-x-auto md:text-[17px] text-[14px]">
                        <table className="min-w-full bg-white border">
                            <thead>
                                <tr className="bg-[#36154e] text-white text-left">
                                    <th className="px-4 py-2">Question</th>
                                    <th className="px-4 py-2">Code</th>
                                    <th className="px-4 py-2">Type of Rendering</th>
                                    <th className="px-4 py-2 text-nowrap">Depending On</th>
                                    <th className="px-4 py-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {questions.length > 0 ? (
                                    questions.map((question) => (
                                        <tr key={question.id} className="border-b">
                                            <td className="px-4 py-2">{question.title}</td>
                                            <td className="px-4 py-2">{question.code}</td>
                                            <td className="px-4 py-2">{question.renderable_type}</td>
                                            <td className="px-4 py-2">{question.prerequisite?.showConditions[0]?.inputCode ?? '-'}</td>
                                            <td className="px-4 md:px-6 py-3 flex space-x-2">
                                                <Link to={`/admin/dropdown-list/${question.code}`} className="text-blue-500 hover:text-blue-700 font-semibold">
                                                    <LiaEyeSolid size={'20px'} color="grey" />
                                                </Link>
                                                <button
                                                    onClick={() => deleteQuestion(question.id)}
                                                    className="text-red-500 hover:text-red-700 font-semibold"
                                                >
                                                    <AiFillDelete />
                                                </button>
                                                <button
                                                    onClick={() => editQuestion(question)}
                                                    className="text-green-500 hover:text-green-700 font-semibold"
                                                >
                                                    <AiFillEdit />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5} className="text-center py-4">No questions found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {
                isModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-8 rounded shadow-md w-96">
                            <h2 className="text-xl font-bold mb-4">Edit Question</h2>
                            <div className="mb-4">
                                <label className="block text-gray-700">Title</label>
                                <input
                                    type="text"
                                    name="title"
                                    value={currentQuestion.title}
                                    onChange={handleChange}
                                    className="w-full border p-2 rounded"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700">Code</label>
                                <input
                                    type="text"
                                    name="code"
                                    value={currentQuestion.code}
                                    onChange={handleChange}
                                    className="w-full border p-2 rounded"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700">Type of Rendering</label>
                                <input
                                    type="text"
                                    name="renderable_type"
                                    value={currentQuestion.renderable_type}
                                    onChange={handleChange}
                                    className="w-full border p-2 rounded"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700">Depending On</label>
                                <input
                                    type="text"
                                    name="prerequisite.showConditions[0].inputCode"
                                    value={currentQuestion.prerequisite?.showConditions[0]?.inputCode || ''}
                                    onChange={handleChange}
                                    className="w-full border p-2 rounded"
                                />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    onClick={() => setIsModalOpen(false)}
                                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={saveChanges}
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default QuestionList;
