import React, { useState, useEffect } from "react";
import {
  utils_data_for_form,
  convert_array_to_object_for_dropdown_data_for_dynamically_rendered_component,
  convert_array_to_object_for_dropdown_data_not_for_llama_form,
} from "../../lib/utils";
import Select from "react-select";
import "../ComponentTwo.css";
import { Player } from "@lottiefiles/react-lottie-player";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {
  StringContentForDoubleDropdownMulti,
  tool_tip_multi_multi,
} from "../../lib/toolTipFunction";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { Edit, Add, TickCircle } from "iconsax-react";
import { render } from "@testing-library/react";
import loader from "./loader.json";

const DropdownMultiMulti = ({
  id,
  label,
  type,
  options,
  response,
  setPreviousQuestion,
  previousQuestion,
  setInputResponse,
  setPresentQuestion,
  inputResponse,
  presentQuestion,
  option_groups,
  optionsChosenForCountry,
  setOptionChosenForCountry,
  last_question_for_part_one,
  loading,
  setLoading,
  setShow,
  setLastResponse,
  lastResponse,
}) => {
  const [rendered, setRendered, isRe] = useState(false);
  const { dropdown, second_values } = option_groups;
  const [className, setClassName] = useState();
  const [main, setMain] = useState();
  const [ok, setOk] = useState(false);
  const [renderSelectCount, setRenderSelecCount] = useState(1);
  const [currentVal, setCurrentVal] = useState({});
  const customStyles = utils_data_for_form.dropdown_style;

  const drop_down_option_main =
    convert_array_to_object_for_dropdown_data_not_for_llama_form(dropdown);
  const drop_down_option_secondary =
    convert_array_to_object_for_dropdown_data_not_for_llama_form(second_values);

  const handle_change_main = (e, index) => {
    let arr = inputResponse;

    // let resp_arr = e.map((val) => {
    //   return val.label;
    // });
    let resp_arr = e.label;
    let answer_id = e.value;
    // let value_no_boolean = e.filter((val) => val.label === "No").length;
    let value_no_boolean = e.label === "No";
    value_no_boolean ? setMain("No") : setMain("Yes");
    arr = arr.map((val) => {
      if (val.id == id) {
        setCurrentVal(val);
        if (value_no_boolean) {
          // val.response = { main: resp_arr, done: true };
          // val.response ={index:{[resp_arr]:null},done:true}
          val.response = { ...val.response, done: false };
          if (val.response.hasOwnProperty(index)) {
            val.response[index].main = resp_arr;
          } else {
            val.response = { ...val.response, done: false };
            // val.response ={[index]:{main:resp_arr,secondary:null},done:true}
            val.response = {
              answer: resp_arr,
              answer_id: answer_id,
              quantity: null,
              quantity_id: null,
            };
          }
          if (id === previousQuestion[previousQuestion.length - 1].id) {
            setLastResponse(true);
          }
        } else {
          // if (val?.response?.secondary) {
          //   // val.response = { ...val.response, main: resp_arr, done: false };
          // } else {
          // val.response = { main: resp_arr, done: false };
          // val.response ={[resp_arr]:null,done:false}
          val.response = { ...val.response, done: false };

          // val.response ={[index]:{main:resp_arr,secondary:null},done:true}

          // val.response[index] = {
          //   main: resp_arr,
          //   secondary: null,
          // };
          val.response = {
            answer: resp_arr,
            answer_id: answer_id,
            quantity: null,
            quantity_id: null,
          };

          // }
        }
        return val;
      } else {
        return val;
      }
    });

    if (value_no_boolean) {
      if (id !== "q71") {
        setPresentQuestion({
          ...previousQuestion[
          previousQuestion.indexOf(
            previousQuestion.find((val) => val.id == id)
          ) + 1
          ],
          response: null,
        });
      } else {
        if (isRe) setShow(true);
      }
    }

    setInputResponse([...arr]);
  };

  const handle_change_secondary = (e, index) => {
    let arr = inputResponse;

    arr = arr.map((val) => {
      if (val.id == id) {
        let secondaryLabel = e.label;
        let secondaryAnswerId = e.value;
        // val.response = { ...val.response, done: true, secondary: arr };
        if (val.response.hasOwnProperty(index))
          // val.response[index].quantity = secondaryLabel;
          val.response.quantity = secondaryLabel;
        // val.response[index].quantity_id = secondaryAnswerId; // Include answer ID
        val.response.quantity = secondaryLabel;
        val.response.quantity_id = secondaryAnswerId; // Include answer ID

        // return val;
      } else {
        // return val;
        return;
      }
    });

    if (ok) {
      setInputResponse([...arr]);
    }
  };

  const handleReset = () => {
    let arr = inputResponse;
    let index_search = arr.indexOf(arr.find((val) => val.id == id));
    arr = arr
      .map((val, index) => {
        if (index == index_search) {
          val.response = null;
          val.answer_id = null;
          return val;
        } else if (index < index_search) {
          return val;
        }
      })
      .filter(
        (val, index, self) => val !== undefined && index === self.indexOf(val)
      );
    setInputResponse([...arr]);
    setMain("Yes");
    setOk(false);
    setLastResponse(false);
  };
  useEffect(() => {
    if (id === "q70") {
      setRendered(true);
    }
  }, [id]);

  useEffect(() => {
    if (rendered) {
      // Execute your logic after q70 is rendered
    }
  }, [rendered]);
  return (
    <>
      {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
      <div className="A1-multi_dropdown_wrapper">
        {(response && response.done) || lastResponse ? (
          // <div
          //   style={{
          //     display: "flex",
          //     alignItems: "center",
          //     flexDirection: "row",
          //     gap: 5,
          //   }}
          // >
          //   {
          //     <div
          //       className={`A1-select-double-dropdown ${id}`}
          //       onMouseEnter={(e) => {
          //         setClassName(e.target.classList[1]);
          //       }}
          //     >
          //       <Tippy
          //         content="Do you want to edit the data? Doing so, you will lose all your stored data."
          //         onClick={handleReset}
          //       >
          //         <Edit
          //           size="20"
          //           color="#2ccce4"
          //           onClick={handleReset}
          //           style={{ padding: 4 }}
          //         />
          //       </Tippy>
          //       <Select
          //         options={drop_down_option_main}
          //         onChange={handle_change_main}
          //         styles={customStyles}
          //         value={{
          //           value: String(response.main).toLowerCase(),
          //           label: response.main,
          //         }}
          //         isDisabled={true}
          //       />
          //       {response && response.secondary && (
          //         <Select
          //           options={drop_down_option_secondary}
          //           onChange={handle_change_secondary}
          //           styles={customStyles}
          //           value={{
          //             value: String(response.secondary).toLowerCase(),
          //             label: response.secondary,
          //           }}
          //           isDisabled={true}
          //         />
          //       )}
          //     </div>
          //   }
          // </div>
          <div>
            {[...Array(Object.keys(response).length - (Object.keys(response).length - 1))].map((e, index) => (
              <div key={index} className="multi_dropdown_wrapper">
                <div className="A1-select-double-dropdown">
                  {index == 0 ? (
                    <Tippy
                      content="Do you want to edit the data? Doing so, you will lose all your stored data."
                      onClick={handleReset}
                    >
                      <Edit
                        size="20"
                        color="#2ccce4"
                        onClick={handleReset}
                        style={{ padding: 4 }}
                      />
                    </Tippy>
                  ) : (
                    ""
                  )}
                  <Select
                    options={drop_down_option_main}
                    onChange={(e) => {
                      handle_change_main(e, index);
                    }}
                    styles={customStyles}
                    // isMulti
                    isDisabled={true}
                    value={{
                      value: response.answer_id,
                      label: response.answer,
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {main && main !== "No" && (
                    <div style={{ display: "flex" }}>
                      <Select
                        options={drop_down_option_secondary}
                        onChange={(e) => {
                          handle_change_secondary(e, index);
                        }}
                        styles={customStyles}
                        // isMulti
                        isDisabled={true}
                        value={{
                          value: response.quantity_id,
                          label: response.quantity,
                        }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            {loading && (
              <div style={{ height: "4rem", width: "4rem" }}>
                <Player
                  src={loader}
                  className="player"
                  loop
                  autoplay
                />
              </div>
            )}
            {/* {!loading && (
                <div className="A1-select-double-dropdown">
                  <Select
                    options={drop_down_option_main}
                    onChange={handle_change_main}
                    styles={customStyles}
                    // isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {main && main !== "No" && (
                    <div style={{ display: "flex" }}>
                      <Select
                        options={drop_down_option_secondary}
                        onChange={handle_change_secondary}
                        styles={customStyles}
                        // isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                      {renderSelectCount == 0 && (
                        <div
                          onClick={(e) => {
                            setRenderSelecCount((prev) => prev + 1);
                          }}
                        >
                          <Add size="40" color="black" style={{ padding: 4 }} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )} */}
            {!loading &&
              [...Array(renderSelectCount)].map((e, index) => (
                <div key={index} className="multi_dropdown_wrapper">
                  <div className="A1-select-double-dropdown">
                    <Select
                      options={drop_down_option_main}
                      onChange={(e) => {
                        handle_change_main(e, index);
                      }}
                      styles={customStyles}
                      // isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                    {main && main !== "No" && (
                      <div style={{ display: "flex" }}>
                        <Select
                          options={drop_down_option_secondary}
                          onChange={(e) => {
                            handle_change_secondary(e, index);
                          }}
                          styles={customStyles}
                          // isMulti
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        {renderSelectCount - 1 == index && (
                          <div className="add_save_bottom_container">
                            {/* {
                              <div
                              className="add_icon_container"
                              onClick={(e) => {
                                setRenderSelecCount((prev) => prev + 1);
                              }}
                              >
                                <Add
                                  size="40"
                                  color="#5957FF"
                                  style={{ padding: 4 }}
                                />
                              </div>
                            } */}
                            <div
                              onClick={() => {
                                if (!currentVal.response) return;
                                currentVal.response.done = true;
                                if (
                                  previousQuestion[previousQuestion.length - 1]
                                    .id !== id
                                ) {
                                  setPresentQuestion({
                                    ...previousQuestion[
                                    previousQuestion.indexOf(
                                      previousQuestion.find(
                                        (val) => val.id == id
                                      )
                                    ) + 1
                                    ],
                                    response: null,
                                  });
                                  setOk(!ok);
                                } else {
                                  setOk(!ok);
                                  setShow(true);
                                  setLastResponse(true);
                                }
                              }}
                            >
                              <TickCircle size="32" color="#37d67a" />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
      {/* </div> */}
    </>
  );
};

export default DropdownMultiMulti;
