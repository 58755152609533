import axios from "axios";
// ${JSON.parse(localStorage.getItem('access_token')) || ''}
export const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_ROOT_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("access_token")) || ""
    }`,
  },
});

axiosAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("authenticated");
      localStorage.removeItem("access_token");
      localStorage.removeItem("expires_in");   
      window.location.href = "/login/"; 
    }
    return Promise.reject(error);
  }
);

export const axiosNoAuth = axios.create({
  baseURL: process.env.REACT_APP_ROOT_BASE_URL,
  headers: {
    "content-type": "multipart/form-data",
  },
});
