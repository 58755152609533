import React from 'react';

const ErrorModal = ({ show, onClose, errorMessage }) => {
  if (!show) return null;

  // Split the error message by commas
  const errorMessages = errorMessage.split(',');

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="fixed inset-0 bg-gray-800 opacity-50 blur-lg"></div>
      <div className="relative w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-4xl p-5 mx-auto my-6 bg-white border border-gray-300 rounded-lg shadow-lg">
        {/* Content */}
        <div className="text-left">
          <h3 className="text-xl font-semibold mb-2">Error</h3>
          {/* Display each error message as a bullet point */}
          <ul className="text-red-600 list-disc list-inside">
            {errorMessages.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
        {/* Close button */}
        <div className="mt-4 text-center">
          <button
            onClick={onClose}
            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600 focus:outline-none"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
