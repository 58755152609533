import React, { useState, useRef, useEffect } from "react";
import {
  AiOutlineUser,
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineHome,
} from "react-icons/ai";
import { BsBuilding, BsMap } from "react-icons/bs";
import { RiAdminLine } from "react-icons/ri";
import { axiosAuth } from "../../lib/axiosInstance";
import { toast } from "react-toastify";


const AddUserModal = ({ closeModal, addUser, editingUser }) => {
  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    contact_number: "",
    companies_id: "",
    location: "",
    address: "",
    role:"",
  });

  const [companies, setCompanies] = useState([]);
  const [Roles, setRoles] = useState([]);
  const [userData, setUserData] = useState([]);

  const fetchCompaniesDetails = async () => {
    try {
      const {data} = await axiosAuth.get('/companies');
      setCompanies(data.companies);
    } catch (error) {
      console.error(error);
      setCompanies([]); 
    }
  };

  const fetchAllRoles = async () => {
    try {
      const {data} = await axiosAuth.get('/roles');
      setRoles(data.roles);
    } catch (error) {
      console.error(error);
      setRoles([]); 
    }
  };

  const getCurrentUserData = async () => {
    try {
      const {data} = await axiosAuth.get('/user');
      setUserData(data);
    } catch (error) {
      console.error(error);
      setUserData([]); 
    }
  };
  useEffect(() => {
    fetchCompaniesDetails();
    fetchAllRoles();
    getCurrentUserData();
  }, []);
  useEffect(() => {
    if (editingUser) {
      setNewUser(editingUser);
    }
  }, [editingUser]);

  const [errors, setErrors] = useState({});

  const modalRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    userData?.user?.role === "company-admin"?
      setNewUser({ ...newUser, [name]: value,companies_id:userData?.user?.companies_id }):
    setNewUser({ ...newUser, [name]: value,roleId:value });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleUpdateUser = async () => {
    const newErrors = {};

    if (!newUser.name) newErrors.name = " ⚠️ Name is required";
    if (!newUser.email) {
      newErrors.email = " ⚠️ Email is required";
    } else if (!validateEmail(newUser.email)) {
      newErrors.email = "Invalid email format";
    }
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      addUser(newUser);
      const res = await axiosAuth.post(`/user/${newUser?.id}`, newUser, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);

      closeModal();
    } else {
      setErrors(newErrors);
      setTimeout(() => {
        setErrors({});
      }, 3000);
    }
  };

  const handleAddUser = async () => {
    const newErrors = {};

    if (!newUser.name) newErrors.name = " ⚠️ Name is required";
    if (!newUser.email) {
      newErrors.email = " ⚠️ Email is required";
    } else if (!validateEmail(newUser.email)) {
      newErrors.email = "Invalid email format";
    }

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      try {
        const res = await axiosAuth.post(`create-user`, newUser);
        addUser(res?.data?.user);

        toast.success("User created successfully");
        setLoading(false);
        closeModal();
      } catch (err) {
        setLoading(false);
        console.error(err);
        toast.error(err?.response?.data?.message ?? "Error creating user ");
      }
      setNewUser({
        name: "",
        email: "",
        contact_number: "",
        companies_id: "",
        location: "",
        address: "",
        role:"",
      });
    } else {
      setErrors(newErrors);
      setTimeout(() => {
        setErrors({});
      }, 3000);
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center min-h-screen">
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
      <div
        ref={modalRef}
        className="bg-white relative rounded-lg shadow-lg p-8 z-20 max-w-md w-full transform transition-transform duration-300 scale-95"
      >
        <h2 className="text-2xl font-bold mb-6 text-center">
          {editingUser ? "Edit User" : "Add New User"}
        </h2>
        {loading && (
          <div className="">
            <div
              role="status"
              class="absolute  z-20 -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
            >
              <svg
                aria-hidden="true"
                class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#5d208c]"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div className={`${loading ? "pointer-events-none blur-[1px]" : ""}`}>
          {errors.name && (
            <p className="text-red-500 text-sm py-2">{errors.name}</p>
          )}
          <div className="relative mb-6">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <AiOutlineUser className="w-5 h-5 text-gray-500 " />
            </div>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500 w-full ps-10 p-2.5 transition-shadow duration-300 shadow-sm hover:shadow-md"
              placeholder="John Smith"
              id="name"
              type="text"
              name="name"
              value={newUser.name}
              onChange={handleChange}
            />
          </div>

          {errors.email && (
            <p className="text-red-500 text-sm py-1">{errors.email}</p>
          )}
          <div className="relative mb-6">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <AiOutlineMail className="w-5 h-5 text-gray-500 " />
            </div>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500 w-full ps-10 p-2.5 transition-shadow duration-300 shadow-sm hover:shadow-md"
              id="email"
              type="email"
              placeholder="xyz@example.com"
              name="email"
              value={newUser.email}
              onChange={handleChange}
            />
          </div>
          <div className="relative mb-6">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <AiOutlinePhone className="w-5 h-5 text-gray-500 " />
            </div>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500 w-full ps-10 p-2.5 transition-shadow duration-300 shadow-sm hover:shadow-md"
              id="contact_number"
              type="tel"
              placeholder="0123456789"
              maxLength={13}
              name="contact_number"
              value={newUser.contact_number}
              onChange={handleChange}
            />
          </div>
          <div className="relative mb-6">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <BsBuilding className="w-5 h-5 text-gray-500 " />
            </div>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500 w-full ps-10 p-2.5 transition-shadow duration-300 shadow-sm hover:shadow-md"
              id="company_name"
              name="companies_id"
              value={newUser.companies_id}
              onChange={handleChange}
            >
              {userData?.user?.role === "company-admin" ? (
                <option key={userData?.user?.companies_id} value={userData?.user?.companies_id}>{userData?.user?.company_name}</option>
              ) : (
                <>
                  <option value="">Select Company</option>
                  {companies.length > 0 ? (
                    companies.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))
                  ) : (
                    <option>Loading companies...</option>
                  )}
                </>
              )}
            </select>
          </div>
           <>
      {userData?.user?.role === "admin" && (
        <div className="relative mb-6">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            <RiAdminLine className="w-5 h-5 text-gray-500" />
          </div>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500 w-full ps-10 p-2.5 transition-shadow duration-300 shadow-sm hover:shadow-md"
            id="role_id"
            name="role_name"
            value={newUser.roleId}
            onChange={handleChange}
          >
            <option value="">Select Role</option>
            {Roles.length > 0 ? 
            // (
            //   Roles.map((Role) => (
            //     <option key={Role.id} value={Role.id} className="capitalize">
            //       {Role.name} 
            //     </option>
            //   ))
            // ) 
            (
              Roles.map((Role) => {
                let displayName;
                switch (Role.name) {
                  case 'admin':
                    displayName = 'Ripley Admin';
                    break;
                  case 'company-admin':
                    displayName = 'Customer Admin';
                    break;
                  case 'user':
                    displayName = 'Customer Field Staff';
                    break;
                  default:
                    displayName = Role.name; // Fallback to the original name if no match is found
                }
                return (
                  <option key={Role.id} value={Role.id} className="capitalize">
                    {displayName}
                  </option>
                );
              })
            )
            : (
              <option>Loading Role...</option>
            )}
          </select>
        </div>
      )}
    </>
          <div className="relative mb-6">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none ">
              <AiOutlineHome className="w-5 h-5 text-gray-500 " />
            </div>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:ring-blue-500 focus:border-blue-500 w-full ps-10 p-2.5 transition-shadow duration-300 shadow-sm hover:shadow-md"
              id="address"
              type="text"
              placeholder="xyz,Street"
              name="address"
              value={newUser.address}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex justify-end gap-4 items-center w-full">
          <button
            onClick={closeModal}
            disabled={loading}
            className="bg-gray-300 text-gray-700 font-semibold py-2 rounded-lg w-24 hover:bg-gray-400 transition-colors duration-300"
          >
            Cancel
          </button>
          {editingUser ? (
            <button
              onClick={handleUpdateUser}
              disabled={loading}
              className={` bg-[#5d208c] flex gap-2 justify-center items-center hover:bg-[#2a133b] text-white font-semibold py-2 w-24 rounded-lg transition-colors duration-300 ${
                loading && "opacity-20"
              }`}
            >
              <span>Update</span>
            </button>
          ) : (
            <button
              disabled={loading}
              onClick={handleAddUser}
              className={`bg-[#5d208c] flex gap-2 items-center justify-center  hover:bg-[#2a133b] text-white font-semibold py-2 w-24 rounded-lg transition-colors duration-300 ${
                loading && "opacity-20"
              }`}
            >
              <span>Add</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
