import React from 'react';
import { Navigate } from 'react-router-dom';
import useCookies from '../../customHooks/useCookies';

const PrivateRoute = ({ children, isLoggedIn, requiredRole }) => {

  const {  getCookie } = useCookies();
  const role = getCookie('user_role'); 
  const auth = JSON.parse(localStorage.getItem('authenticated'));

  if (!auth) {
    return <Navigate to="/login" replace />;
  }

  if (requiredRole && !requiredRole.includes(role)) {
    return <Navigate to="/not-found" replace />; 
  }

  return children;
};

export default PrivateRoute;
