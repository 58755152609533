import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../../Sidebar";
import "react-confirm-alert/src/react-confirm-alert.css";
import { LiaEyeSolid } from "react-icons/lia";
import Modal from 'react-modal';
import { axiosAuth } from "../../../lib/axiosInstance";
import RequestDropdown from "./RequestDropdown";
import { CloseCircle } from "iconsax-react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const Surveys = () => {
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [surveyDetails, setSurveyDetails] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);


  const getAllUser = async (page = 1, option = null) => {
    try {
      let res;
      if (option) {
        // Fetch filtered data
        res = await axiosAuth(`/getAllUser`, {
          params: {
            option,
            page,
            limit: itemsPerPage,
          },
        });
      } else {
        // Fetch all data
        res = await axiosAuth(`/getAllUser?page=${page}&limit=${itemsPerPage}`);
      }

      setSurveys(res.data.data);
      setTotalPages(res.data.last_page);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUser(currentPage, selectedOption);
  }, [currentPage, selectedOption]);


  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleView = async (survey) => {
    try {
      const res = await axiosAuth(`/rooms/${survey?.roomId}`);
      setDropdownData(res?.data)
      setShowModal(true);
    } catch (error) {
      toast.error('Question details is empty', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSurveyDetails([]);
  };



  // First, organize the data by grouping items with the same question_title
  const groupedData = {};
  dropdownData?.room_information?.forEach(item => {
    if (!groupedData[item.question_title]) {
      groupedData[item.question_title] = [];
    }
    groupedData[item.question_title].push(item.answer?.value);
    if (item.addOn !== undefined && item.addOn !== null && item.addOn !== "") {
      // If 'addOn' is present, push it to the corresponding array in 'groupedData'
      groupedData[item.question_title].push(item.addOn);
    }
  });

  // Then, map over the grouped data and combine values into a single string separated by commas
  const groupedItems = Object.keys(groupedData).map(questionTitle => ({
    questionTitle,
    combinedValue: groupedData[questionTitle].join(', ')
  }));

  console.log(groupedItems)


  const fetchUsers = useCallback(async (query) => {
    if (query === '') {
      getAllUser();
      return;
    }
    try {
      const response = await axiosAuth('/filterbyname', {
        params: { name: query },
      });
      setSurveys(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
    }
  }, []);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const handleSearch = useCallback(debounce((query) => {
    fetchUsers(query);
  }, 500),
    [fetchUsers])

  const handleChange = (e) => {
    const query = e.target.value;
    handleSearch(query)

  }


  return (
    <Sidebar>
      <div className="p-4 mt-16 lg:mt-0 md:p-8 bg-gray-100 h-full">
        <h1 className="text-2xl md:text-3xl font-bold text-slate-800 mb-4 md:mb-6 flex justify-between">
          Macros

        </h1>
        <div className="flex justify-end">

          <input type="text" onChange={handleChange}
            placeholder="Search users" className="w-md p-2 border border-gray-300 rounded mb-4 ">
          </input>
          <RequestDropdown
            setSurveys={setSurveys}
            setTotalPages={setTotalPages}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <div className="bg-white rounded-lg shadow-md overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-[#36154e] text-white">
                <th className="px-4 md:px-6 py-3 text-left">Macros Name</th>
                <th className="px-4 md:px-6 py-3 text-left">Claim ID</th>
                <th className="px-4 md:px-12 py-3 text-left">User Details</th>
                <th className="px-4 md:px-6 py-3 text-left">Company Details</th>
                <th className="px-4 md:px-6 py-3 text-left">Address</th>
                <th className="px-4 md:px-6 py-3"></th>
              </tr>
            </thead>
            {loading ? ( // Display skeleton loader when loading is true
              <tbody>
                {[...Array(10)].map((_, index) => (
                  <tr key={index}>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-12 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-12 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-16 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3 text-left">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3 flex space-x-2">
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : surveys?.length > 0 ? (
              <tbody className="divide-y divide-blue-200 text-nowrap">
                {surveys?.map((survey) => (
                  <tr key={survey.id}>
                    <td className="px-4 md:px-6 py-3 text-left">{survey?.room_name}</td>
                    <td className="px-4 md:px-12 py-3">{survey?.ClaimId}</td>
                    <td className="px-4 md:px-12 py-3">
                      {survey.fullname ?? "-"}
                    </td>
                    <td className="px-4 md:px-16 py-3">
                      {survey.company_details ?? "-"}
                    </td>
                    <td className="px-4 md:px-6 py-3 text-left">{survey.address ?? "-"}</td>
                    <td className="px-4 md:px-6 py-3 flex space-x-2">
                      <button
                        className="text-blue-500 hover:text-blue-700 font-semibold"
                        onClick={() => handleView(survey)}
                      >
                        <LiaEyeSolid size={'20px'} color="grey" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody className="divide-blue-200 w-full border-2">
                <tr>
                  <td
                    colSpan={6}
                    className="p-3 text-center italic text-gray-400 font-medium text-xl"
                  >
                    No surveys available.
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          {/* Pagination */}
          <div className="flex justify-center gap-4 py-2">
            <button
              className={`py-1 w-[5rem] text-slate-50 bg-[#36154e] rounded ${currentPage === 1 ? "opacity-20" : ""}`}
              disabled={currentPage === 1}
              onClick={prevPage}
            >
              Previous
            </button>
            <button
              className={`py-1 w-[5rem] text-slate-50 bg-[#36154e] rounded ${currentPage === totalPages ? "opacity-20" : ""}`}
              disabled={currentPage === totalPages}
              onClick={nextPage}
            >
              Next
            </button>
          </div>
        </div>

        {/* Modal */}
        <Modal
          isOpen={showModal}
          onRequestClose={handleCloseModal}
          ariaHideApp={false} // Suppressing the warning for demo purposes. Set it properly in a real app.
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '5px',
              width: '80%',
              maxWidth: '800px',
              overflow: 'auto',
              height: '50%',
            },
          }}
        >
          {selectedSurvey && (
            <div>

              <p><strong>Claim ID:</strong> {selectedSurvey.user_id}</p>

              <h2><strong>
                Survey Details
              </strong></h2>


            </div>
          )}

          {/* Display dynamic cards horizontally based on surveyDetails */}
          <div className="flex bg-slate-500 text-white p-[0.6rem] text-center">
            <div className="flex-1">Questions</div>
            <div className="flex-1 ">Answers</div>
            <CloseCircle className="cursor-pointer" onClick={handleCloseModal} size="25" color="#ff5c21" />
          </div>
          <div onClick={(e) => { e.stopPropagation() }} className="flex-grow p-4 overflow-x-auto bg-gray-200">
            {groupedItems.map((group, index) => (
              <div key={index} className={`flex bg-gray-${index % 2 === 0 ? '300' : '200'} gap-4 transition duration-300 hover:bg-gray-400 p-[0.6rem]`}>
                <div className="flex-1">{group.questionTitle}</div>
                <div className="flex-1 text-center">{(group.combinedValue == "" || group.combinedValue == ", ") ? "NA" : group.combinedValue}</div>
              </div>
            ))}
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </Sidebar>
  );
};

export default Surveys;
