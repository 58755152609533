import React from "react";
import { useEffect, useState } from "react";
import { axiosAuth } from "../lib/axiosInstance";
import axios from "axios";

export const useHome = () => {
  const [data, setData] = useState([]);
  const [indivisualData, setIndivisualData] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    fetchClaim();
  }, []);

  const fetchClaim = () => {
    setLoading(true);
    try {
      axiosAuth
        .get("/claims")
        .then((response) => {
          if(response.data!=''){
            setData(response.data);
          }else{
            setData([]);
          }
          
        })
        .catch((err) => { })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(true);
    }
  };

  const fetchIndivisualClaim = (id) => {
    setLoading(true)
    try {
      axiosAuth.get(`/claims/${id}`).then(response => {
        setIndivisualData(response.data)
      }).catch((err) => {
      }).finally(() => {
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
    }
  };

  return { data, loading, indivisualData, fetchIndivisualClaim };
};
