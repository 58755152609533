import React, { useEffect, useState } from 'react';
import { axiosAuth } from '../../../lib/axiosInstance';
import axios from 'axios';
const RequestDropdown = ({ setSurveys, setTotalPages, setCurrentPage, currentPage, itemsPerPage, selectedOption, setSelectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const allTemplate = async () => {
    const res = await axiosAuth('/dropdowndata');
    setTitle(res?.data)
    console.log(res?.data?.title)
  }
  useEffect(() => {
    allTemplate()
  }, [])

  const handleOptionClick = async (option) => {
    try {
      setSelectedOption(option);
      setIsOpen(false);
      setCurrentPage(1); // Reset page to 1 when a new option is selected

      // Fetch filtered data based on dropdown selection
      const res = await axiosAuth('/getAllUser', {
        params: {
          option,
          page: 1, // Always reset to the first page
          limit: itemsPerPage,
        },
      });

      setSurveys(res.data.data);
      setTotalPages(res.data.last_page);
    } catch (error) {
      console.error('Error sending option to backend:', error);
    }
  };


  return (
    <div className="inline-block h-10 text-left ml-3 relative ">
      <div
        className="flex rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={toggleDropdown}
      >
        {selectedOption ? selectedOption.title : 'Select Options'}
        <svg
          className="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg >
      </div >

      {
        isOpen && (
          <div
            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-y-auto max-h-60"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="py-1" role="none">
              {title.length > 0 ? (
                title?.map((option, index) => (
                  <div
                    onClick={() => handleOptionClick(option)}
                    key={index}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    {option?.title} {/* Assuming `option.value` contains the display text */}
                  </div>
                ))
              ) : (
                <span className="block px-4 py-2 text-sm text-gray-700">No options available</span>
              )}
            </div>
          </div>
        )
      }
    </div >
  );
};

export default RequestDropdown;
