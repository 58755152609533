import React, { useState } from "react";
import { useEffect } from "react";
import ProgressBar from "./OtherComponents/ProgressBar";
import { utils_data_for_dynamic_form } from "../lib/utilsDynamic";
import { toast } from "react-toastify";
import ErrorModal from "./OtherComponents/ErrorComponent";
import { axiosAuth } from "../lib/axiosInstance";

const FormClaim = ({
  questions,
  setClaimId,
  setCurrentStep,
}) => {
  const [selectedRadioOption, setSelectedRadioOption] = useState("No");
  const [shouldDisplayAdditionalQuestions, setShouldDisplayAdditionalQuestions] = useState(false);
  const [q16Value, setQ16Value] = useState(""); // State to manage the value of q16
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isButtonVisible, setButtonVisible] = useState(true);

  const [errors, setErrors] = useState({
    q3: false,
    q4: false,
    q5: false,
    q15: false,
    q16: false,
    // ... add other questions as needed
  });
  const [q7Val, setQ7] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  questions = utils_data_for_dynamic_form.question_part_one;


  useEffect(() => {
    // setShow(true);
    document.getElementById('q18').value = 10;
    document.getElementById('q20').value = 10;
    document.getElementById('q19').value = 5;
    document.getElementById('q21').value = 5;
    return () => { };
  }, []);

  const fetchData = async () => {
    try {
      const { data: { claimInformation } } = await axiosAuth.get("/user-details-claim");

      const fetchedQuestionData = {};
      let selectedValue = "";

      for (const key in question_mapper) {
        if (key === "q16") {
          const firstAlphabet = claimInformation[question_mapper[key]];
          questions.map((question) => {
            if (question.id === key) {
              question.options.map((option) => {
                selectedValue = option.value;
              });
            }
          });
          handleInputChange(key, firstAlphabet, selectedValue);
        }

        if (key == 'q1' || key == 'q2') {
          continue;
        }
        if (key == "q22") {
          break;
        } else if (key == "q17") {
          const isOutsideServiceArea = claimInformation[question_mapper[key]];
          console.log(isOutsideServiceArea);
          const radioElement = document.getElementById(
            isOutsideServiceArea == false ? "No" : "Yes"
          );
          radioElement.checked = true;
          console.log(radioElement);
          fetchedQuestionData[key] = claimInformation[question_mapper[key]];
        } else if (question_mapper.hasOwnProperty(key)) {
          const fieldName = question_mapper[key];
          fetchedQuestionData[key] = claimInformation[fieldName];
          const element = document.getElementById(key);
          const value = fetchedQuestionData[key];


          if (key == "q7") {
            setQ7(value);
          }
          if (key == "q8") {
            setCity(value);
          }
          if (key == "q9") {
            setState(value);
          }
          element.value = value;

          setAnswers((prevState) => ({
            ...prevState,
            ...fetchedQuestionData,
          }));
        }
      }

      setButtonVisible(false);

    } catch (error) {
      console.error("Error fetching user details:", error);
      toast.error(error.message);
    }
  };
  const create_claim = async (body) => {
    console.log(body, "body");
    try {
      const response = await axiosAuth.post("/claims", body);
      toast.success("Claim Information Saved");
      return response;
    } catch (error) {
      // Handle the error here
      if (Array.isArray(error.response?.data?.errors)) {
        const errorMessage = error.response.data.errors.join(", ");
        setErrorMessage(errorMessage);
      } else {
        // Handle other types of errors or a general error message
        setErrorMessage(error.response?.data?.message || "An error occurred");
      }
      setShowErrorModal(true); // Show the error modal
      throw error; // Rethrow the error if you want to handle it further up the chain
    }
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const handle_final_submit = async (payload) => {
    try {
      // Assuming create_claim returns a Promise
      const response = await create_claim(payload);
      setClaimId(response.data.id);
      setCurrentStep(1);
      // setShow(false);

    } catch (error) {
      // Handle any errors that may occur during the submission
      console.error("Error submitting claim:", error);
    }
  };
  const [userDetails, setUserDetais] = useState(null);
  const fetchUser = async () => {
    try {
      const response = await axiosAuth.get("/user");
      setUserDetais(response.data.user);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const [answers, setAnswers] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
    q8: "",
    q9: "",
    q10: "",
    q11: "",
    q12: "",
    q13: "",
    q14: "",
    q15: "",
    q16: "",
    q17: "1",
    q18: "",
    q19: "",
    q20: "",
    q21: "",
    q22: "",
    q23: "",
    q29: "",
    q24: {
      dropdown1: "",
      dropdown2: "",
      dropdown3: "",
    },
    q31: "",
  });
  useEffect(() => {
    // Update q18 based on the value of q16
    if (q16Value === 'E') {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        q18: 10,
        q19: 5
      }));
    } else if (q16Value === 'R') {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        q20: 10,
        q21: 5
      }));
    }
  }, [answers.q16]);


  const validationPatterns = {
    q3: /^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$/,
    q5: /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
    q15: /^-?\d+$/,
    q16: /^-?\d+$/,
    q4: /^\+?[0-9 -]*$/,
  };

  const filterPlaceholderWords = (label) => {
    const unwantedWords = ["What", "is", "your", "?", "?"];
    const filteredWords = label
      .split(" ")
      .filter((word) => !unwantedWords.includes(word))
      .join(" ");
    return `Enter ${filteredWords}`;
  };

  const filterValidationWords = (label) => {
    const unwantedWords = ["What", "is", "your", "?", "?"];
    const filteredWords = label
      .split(" ")
      .filter((word) => !unwantedWords.includes(word))
      .join(" ");
    return `${filteredWords}`;
  };

  const validateInput = (questionId, value) => {
    if (
      validationPatterns[questionId] &&
      !validationPatterns[questionId].test(value)
    ) {
      return false;
    }
    return true;
  };

  const handleInputChange = (questionId, value, key) => {
    if (questionId === "q16") {
      const regex = /\b(E&R|E&C|E|R)\b/;
      const match = key.match(regex);
      if (match) {
        const extracted = match[0];
        setQ16Value(extracted)
      }
      else {
        setQ16Value('')
      }
    }
    const isValid = validateInput(questionId, value);
    setAnswers((prevAnswers) => {
      if (questionId === "q24_1") {
        return {
          ...prevAnswers,
          q24: {
            ...prevAnswers.q24,
            dropdown1: value,
          },
        };
      } else if (questionId === "q24_2") {
        return {
          ...prevAnswers,
          q24: {
            ...prevAnswers.q24,
            dropdown2: value,
          },
        };
      } else if (questionId === "q24_3") {
        return {
          ...prevAnswers,
          q24: {
            ...prevAnswers.q24,
            dropdown3: value,
          },
        };
      } else {
        if (questionId == "q7") {
          setQ7(value);
          return {
            ...prevAnswers,
            [questionId]: value,
          };
        }
        return {
          ...prevAnswers,
          [questionId]: value,
        };
      }
    });

    // Update the errors state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [questionId]: !isValid,
    }));
  };
  console.log(q16Value);
  const handleRadioChange = (questionId, value, key) => {
    setSelectedRadioOption(value);
    handleInputChange(questionId, value, key);
    // Set shouldDisplayAdditionalQuestions based on the selected value
    setShouldDisplayAdditionalQuestions(value === "Yes" ? true : false);
  };

  const question_mapper = {
    q29: "date_of_loss",
    q0: "home_owner_name",
    q1: "company_name_or_location",
    q2: "requester_name",
    q3: "full_name",
    q4: "phone",
    q5: "email",
    q6: "address",
    q7: "country",
    q8: "city",
    q9: "region",
    q10: "postal_code",
    q11: "type_of_loss",
    q12: "carrier_name",
    q13: "claim_adjuster",
    q14: "claim_number",
    q15: "policy_number",
    q16: "type_of_request",
    q17: "is_outside_of_service_area",
    q18: "emergency_profit",
    q19: "emergency_overhead",
    q20: "reconstruction_profit",
    q21: "reconstruction_overhead",
    q22: "travel_expenses",
    q23: "other_expenses",
    q24: "total_claim_amount",

    // q31:"home_owner_name"
    // q25:
  };


  const handleSubmit = () => {
    const unansweredQuestions = [];

    const payload = Object.entries(answers).reduce(
      (acc, [questionId, answer]) => {
        if (questionId === 'q1') {
          answer = userDetails?.company_name;
        }
        if (questionId === 'q2') {
          answer = userDetails?.name;
        }
        const mappedKey = question_mapper[questionId];
        if (mappedKey) {
          acc[mappedKey] = answer;
        } else {
          const questionLabel = questions.find(
            (question) => question.id === questionId
          )?.label; // Find the question label
          unansweredQuestions.push({ questionId, questionLabel }); // Push both questionId and questionLabel
        }
        return acc;
      },
      {}
    );

    handle_final_submit(payload);
  };

  const renderUnansweredQuestions = (unansweredQuestions) => {
    const toastId = "unansweredQuestionsToast"; // Unique toastId for the toast

    const toastContent = (
      <div className="bg-red-500 text-white p-4 rounded-md">
        <h2 className="text-lg font-bold mb-2">
          Please answer the following questions:
        </h2>
        <ul>
          {unansweredQuestions.map(({ questionId, questionLabel }) => (
            <li key={questionId} className="mb-1">
              {questionLabel}: {questionId}
            </li>
          ))}
        </ul>
      </div>
    );

    toast.dismiss(toastId); // Dismiss previous toast with the same toastId
    toast.error(toastContent, { toastId, className: "custom-toast" }); // Show toast with error type and custom class
  };
  const qid = ['q3', 'q6']

  const [selectedDate, setSelectedDate] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    setSelectedDate(value);

    setAnswers((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <>
      <div className="pt-5 flex items-center  justify-center p-5 bg-gray-100">
        <div
          className={`col-span-full md:col-span-2 gap-5  lg:col-span-3 bg-white sm:p-6 py-10 pt-0 rounded-lg
    sm:w-full w-full  xl:w-1/2 2xl:w-1/2 lg:mt-14 mt-20 xl:mt-5`}
        >
          <ProgressBar currentStep={1} totalSteps={2} />
        </div>
      </div>

      <div className="flex items-center justify-center bg-gray-100">
        <div
          className="col-span-full md:col-span-2 lg:col-span-3 pt-0 rounded-lg
                sm:w-full md:w-full lg:w-full xl:w-1/2 2xl:w-1/2"
        >
          <h4 className="text-xl font-semibold mb-4 text-center">
            Claim Information Form
          </h4>
        </div>
      </div>
      <div className="min-h-screen flex items-center pt-5 justify-center bg-gray-100 p-5">
        <div
          className="col-span-full md:col-span-2 lg:col-span-3 bg-white p-6 pt-5 rounded-lg shadow-xl 
                sm:w-full md:w-full lg:w-full xl:w-1/2 2xl:w-1/2"
        >
          {isButtonVisible && (
            <div className="flex justify-end">
              <button
                className="flex items-center p-2   text-white font-medium rounded-[7px] justify-end  shadow-md  bg-[#9780FF] hover:bg-[#785eea] "
                onClick={fetchData}
                id="auto-fill-btn"
              >
                Auto Fill
              </button>
            </div>
          )}
          {questions &&
            Array.isArray(questions) &&
            questions.map((question, index) => (

              <div key={index} className="mb-4">
                {shouldDisplayAdditionalQuestions ||
                  !["q22", "q23", "q24"].includes(question.id) ? (
                  <p className={`mb-1 text-gray-800 ${((question.id === 'q18' || question.id === 'q19') && (q16Value !== 'E&R' && q16Value !== 'E' && q16Value !== 'E&C')) ? 'hidden' : ''} ${((question.id === 'q20' || question.id === 'q21') && (q16Value !== 'E&R' && q16Value !== 'R')) ? 'hidden' : ''}`}
                  >
                    {question.label} {question.required ? "*" : ""}
                  </p>
                ) : null}

                {question.type === "dropdown" &&
                  question.id != 'q1' &&
                  question.id != 'q2' &&
                  question.id !== "q7" &&
                  question.id !== "q16" &&
                  question.id !== "q11" &&
                  question.id !== "q22" &&
                  question.id !== "q23" &&
                  question.id !== "q24" &&
                  question.options ? (

                  <select
                    className={`w-full p-2 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300 border-red-500 ${((question.id === 'q18' || question.id === 'q19') && (q16Value !== 'E&R' && q16Value !== 'E' && q16Value !== 'E&C')) ? 'hidden' : ''} ${((question.id === 'q20' || question.id === 'q21') && (q16Value !== 'E&R' && q16Value !== 'R')) ? 'hidden' : ''}`}

                    key={`${question.id}_options`}
                    name={question.id}
                    id={question.id}

                    onChange={(e) =>
                      handleInputChange(
                        question.id,
                        e.target.value,
                        e.target.key
                      )
                    }
                  >
                    <option value="">Select</option>
                    {question.options.map((option, optionIndex) => (
                      <option
                        key={optionIndex}
                        value={option.key}
                        className="py-2 bg-gray-200 rounded-md"
                      >
                        {option.value}
                      </option>
                    ))}
                  </select>
                ) : null}


                {
                  question.type === "date" && question.id === 'q29' && (
                    <div className="">

                      <input
                        name={question.id}
                        type="date"
                        id={question.id}
                        className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-full"
                        value={answers[question.id]}
                        onChange={handleChange}
                      />
                    </div>
                  )
                }
                {

                  question.type === "dropdown" && question.id == 'q1' &&
                    question.options ? (
                    <input

                      className="w-full p-2 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300 "
                      type="text"
                      name={question.id}

                      id={question.id}
                      disabled={true}
                      value={userDetails?.company_name}


                    />
                  ) : ""
                }
                {
                  question.type === "dropdown" && question.id == 'q2' &&
                    question.options ? (
                    <input
                      className="w-full p-2 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300"
                      type="text"
                      name={question.id}
                      id={question.id}
                      disabled={true}
                      value={userDetails?.name}


                    />
                  ) : ""
                }

                {question.type === "radio" && question.options
                  ? question.options.map((option, optionIndex) => (
                    <div key={optionIndex} className="mb-2">
                      <input
                        type="radio"
                        name={option.key}
                        id={option.value}
                        value={option.value}
                        checked={selectedRadioOption == option.value}
                        className="mr-2 cursor-pointer"
                        onChange={(e) =>
                          handleRadioChange(
                            question.id,
                            e.target.value,
                            e.target.name
                          )
                        }
                      />
                      <label
                        htmlFor={option.value}
                        className="cursor-pointer"
                      >
                        {option.value}
                      </label>
                    </div>
                  ))
                  : null}
                {question.type === "checkbox" && question.options
                  ? question.options.map((option, optionIndex) => (
                    <div key={optionIndex}>
                      <input
                        type="checkbox"
                        name={question.id}
                        id={option.value}
                        value={option.key}
                        onChange={(e) =>
                          handleInputChange(
                            question.id,
                            e.target.value,
                            e.target.key
                          )
                        }
                      />
                      <label htmlFor={option.value}>{option.value}</label>
                    </div>
                  ))
                  : null}
                {question.type === "dropdown" && question.id === "q7" && (
                  <div>
                    <select
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 border-red-500"
                      name={question.id}

                      id={question.id}
                      onChange={(e) =>
                        handleInputChange(
                          question.id,
                          e.target.value,
                          e.target.key
                        )
                      }
                    >
                      <option value=""> Select</option>
                      {question.options.map((option, optionIndex) => (
                        <option key={optionIndex} value={option.key}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {question.type === "dropdown" &&
                  (question.id === "q16" || question.id === "q11") && (
                    <div>
                      <select
                        className="w-full p-2 border border-gray-400 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300 border-red-500"
                        name={question.id}
                        id={question.id}
                        onChange={(e) =>
                          handleInputChange(
                            question.id,
                            e.target.value,
                            e.target.options[e.target.selectedIndex].text
                          )
                        }
                      >
                        <option value=""> Select</option>
                        {question.options.map((option, optionIndex) => (
                          <option key={optionIndex} value={optionIndex + 1}>
                            {option.value}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                {shouldDisplayAdditionalQuestions &&
                  ["q22", "q23"].includes(question.id) ? (
                  <select
                    className="w-full p-2 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300"
                    key={`${question.id}_options`}
                    name={question.id}

                    id={question.id}
                    onChange={(e) =>
                      handleInputChange(
                        question.id,
                        e.target.value,
                        e.target.key
                      )
                    }
                  >
                    <option value="">Select</option>
                    {question.options.map((option, optionIndex) => (
                      <option

                        key={optionIndex}
                        value={option.key}
                        className="py-2 bg-gray-200 rounded-md"
                      >
                        {option.value}
                      </option>
                    ))}
                  </select>
                ) : null}

                {shouldDisplayAdditionalQuestions && question.id === "q24" ? (
                  <div className="flex flex-col">
                    {/* Dropdown 1 */}
                    <div className="mb-4">
                      <p className="mb-1">{q16Value === "E" ? question.options[0].label : q16Value === "R" ? question.options[1].label : question.options[2].label}</p>
                      <select
                        className="w-full p-2 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300"
                        name={`${question.id}_1`}
                        id={`${question.id}_1`}
                        onChange={(e) =>
                          handleInputChange(`${question.id}_1`, e.target.value)
                        }
                        disabled={!q16Value} // Disable the dropdown if q16Value is not defined
                      >
                        <option value="">
                          Select Applicable: (Assumes "Helper" Travelled with
                          Tradesperson)
                        </option>
                        {q16Value === "E" &&
                          question.options[0].values.map(
                            (option, optionIndex) => (
                              <option
                                key={optionIndex}
                                value={option.key}
                                className="py-2 bg-gray-200 rounded-md"
                              >
                                {option.text}
                                {console.log(option.text)}
                              </option>
                            )
                          )}

                        {q16Value === "R" &&
                          question.options[1].values.map(
                            (option, optionIndex) => (
                              <option
                                key={optionIndex}
                                value={option.key}
                                className="py-2 bg-gray-200 rounded-md"
                              >
                                {console.log(option.text)}
                                {option.text}
                              </option>
                            )
                          )}
                        {q16Value === "E&R" &&
                          question.options[2].values.map(
                            (option, optionIndex) => (
                              <option
                                key={optionIndex}
                                value={option.key}
                                className="py-2 bg-gray-200 rounded-md"
                              >
                                {console.log(option.text)}
                                {option.text}
                              </option>
                            )
                          )}
                      </select>
                    </div>

                    {/* Dropdown 2 */}
                    <p>{question.options[3].display}</p>
                    <select
                      className="w-1/3 p-2 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300"
                      name={`${question.id}_2`}
                      id={`${question.id}_2`}
                      onChange={(e) =>
                        handleInputChange(`${question.id}_2`, e.target.value)
                      }
                      disabled={!q16Value} // Disable the dropdown if q16Value is not defined
                    >
                      <option value="">Select</option>
                      {question.options[3].values.map((option, optionIndex) => (
                        <option
                          key={optionIndex}
                          value={option.key}
                          className="py-2 bg-gray-200 rounded-md"
                        >
                          {option.text}
                        </option>
                      ))}
                    </select>

                    {/* Dropdown 3 */}
                    <p>{question.options[4].display}</p>
                    <select
                      className="w-1/3 p-2  border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring focus:border-blue-300"
                      name={`${question.id}_3`}
                      id={`${question.id}_3`}
                      onChange={(e) =>
                        handleInputChange(`${question.id}_3`, e.target.value)
                      }
                      disabled={!q16Value} // Disable the dropdown if q16Value is not defined
                    >
                      <option value="">Select</option>
                      {question.options[4].values.map((option, optionIndex) => (
                        <option
                          key={optionIndex}
                          value={option.key}
                          className="py-2 bg-gray-200 rounded-md"
                        >
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}

                {question.type === "dropdown" &&
                  question.option_groups &&
                  question.id === "q8" && (
                    <select
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-green-300 border-red-500"
                      name={question.id}
                      id={question.id}
                      onChange={(e) =>
                        handleInputChange(
                          question.id,
                          e.target.value,
                          e.target.key
                        )
                      }
                    >
                      <option value="">Select</option>
                      {q7Val === "canada" &&
                        question.option_groups[1]?.options.map(
                          (option, optionIndex) => (
                            <option
                              key={optionIndex}
                              value={option.key}
                              selected={city === option.key}
                            >
                              {option.value}
                            </option>
                          )
                        )}
                      {q7Val === "us" &&
                        question.option_groups[0]?.options.map(
                          (option, optionIndex) => (
                            <option
                              key={optionIndex}
                              value={option.key}
                              selected={city === option.key}
                            >
                              {option.value}
                            </option>
                          )
                        )}
                    </select>
                  )}
                {question.type === "dropdown" &&
                  question.option_groups &&
                  question.id === "q9" && (
                    <select
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 border-red-500"
                      name={question.id}
                      id={question.id}
                      onChange={(e) =>
                        handleInputChange(
                          question.id,
                          e.target.value,
                          e.target.key
                        )
                      }
                    >
                      <option value="">Select</option>
                      {q7Val === "canada" &&
                        question.option_groups[1]?.options.map(
                          (option, optionIndex) => (
                            <option
                              key={optionIndex}
                              value={option.key}
                              selected={state === option.key}
                            >
                              {option.value}
                            </option>
                          )
                        )}
                      {q7Val === "us" &&
                        question.option_groups[0]?.options.map(
                          (option, optionIndex) => (
                            <option
                              key={optionIndex}
                              value={option.key}
                              selected={state === option.key}
                            >
                              {option.value}
                            </option>
                          )
                        )}
                    </select>
                  )}

                {question.type === "text" && (

                  <div className={'error-wrapper relative'}>
                    <input
                      type="text"
                      name={question.id}
                      id={question.id}
                      placeholder={filterPlaceholderWords(question.label)}
                      className={`w-full p-2 border  rounded-md focus:outline-none ${qid.includes(question.id) ? "border-red-500" : ''}  
                      ${errors[question.id]
                          ? "focus:ring-red-500 border-red-500"
                          : "focus:ring focus:ring-blue-300 border-gray-300"
                        }`}
                      style={{

                        border: errors[question.id]
                          ? "1px solid red !important"
                          : "1px solid gray !important",
                      }}
                      onChange={(e) =>
                        handleInputChange(question.id, e.target.value)
                      }
                      title={
                        errors[question.id]
                          ? `${question.label
                            .split(" ")
                            .slice(2)
                            .join(" ")} is invalid.`
                          : ""
                      }
                    />
                    {errors[question.id] && (
                      <div className="relative mt-8">
                        <p
                          id={`${question.id}-error`}
                          className="text-red-500 text-sm bg-red-100 border border-red-500 rounded-b-lg p-2 absolute left-0 bottom-full w-full"
                        >
                          {`${filterValidationWords(
                            question.label
                          )} is invalid.`}
                        </p>
                      </div>
                    )}
                  </div>
                )}

              </div>
            ))}

          <ErrorModal
            show={showErrorModal}
            onClose={handleCloseErrorModal}
            errorMessage={errorMessage}
          />
          <button
            onClick={handleSubmit}
            className="bg-[#9780FF] font-medium hover:bg-[#785eea] text-white px-4 py-2 rounded-md   "
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default FormClaim;
