// NotFound.js
import React from 'react';
import Sidebar from '../Sidebar';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { IoIosArrowBack } from 'react-icons/io'; // Import IoIosArrowBack icon from react-icons/io
import useCookies from '../../customHooks/useCookies';

const NotFound = () => {
  const { getCookie } = useCookies();
  const role = getCookie('user_role');
  return (
    <div className="flex flex-col justify-center items-center h-screen gap-5">
      <Link to={`${role == 'admin' ? '/admin/dashboard' : '/home'}`} className="text-white  mt-8 flex rounded-md bg-purple-600 p-2">
        <IoIosArrowBack size={20} className="mr-2" /> Go back to Home
      </Link>
      <img
        src="https://admiral.digital/wp-content/uploads/2023/08/404_page-not-found.png" // Replace with your own 404 image URL
        alt="Page Not Found"
        className="md:max-w-[50rem] xs:max-w-[20rem]  h-auto"
      />
    </div>
  );
};

export default NotFound;
