import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const LineChart = ({ data, label, borderColor }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = createLineChart(chartRef, data, label, borderColor);

    return () => {
      chart.destroy();
    };
  }, [data, label, borderColor]);

  const createLineChart = (chartRef, data, label, borderColor) => {
    return new Chart(chartRef.current, {
      type: "line",
      data: {
        labels: label,
        datasets: [
          {
            label: "count",
            data: data,
            borderColor: borderColor,
            fill: false,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        aspectRatio: 2,
      },
    });
  };

  return <canvas ref={chartRef}></canvas>;
};

export default LineChart;
