import React, { useState, useEffect } from "react";
import {
  utils_data_for_form,
  convert_array_to_object_for_dropdown_data_for_dynamically_rendered_component,
} from "../../lib/utils";
import Select from "react-select";
import "../ComponentTwo.css";
import { Player } from "@lottiefiles/react-lottie-player";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Edit } from "iconsax-react";
import loader from "./loader.json";

const Dropdown = ({
  id,
  label,
  type,
  options,
  response,
  setPreviousQuestion,
  previousQuestion,
  setInputResponse,
  setPresentQuestion,
  inputResponse,
  presentQuestion,
  option_groups,
  optionsChosenForCountry,
  setOptionChosenForCountry,
  last_question_for_part_one,
  loading,
  setLoading,
  setLastResponse,
  lastResponse,
  setTextArea,
  textArea,
}) => {
  const [dropDownResponse, setDropDownResponse] = useState();
  const [className, setClassName] = useState();
  const customStyles = utils_data_for_form.dropdown_style;

  useEffect(() => {
    if (dropDownResponse) {
      setInputResponse([
        {
          id: id,
          label: label,
          type: type,
          options: options,
          response: dropDownResponse,
        },
        ...inputResponse,
      ]);
    }
  }, [dropDownResponse]);

  const drop_down_option =
    convert_array_to_object_for_dropdown_data_for_dynamically_rendered_component(
      option_groups &&
        option_groups.length &&
        optionsChosenForCountry == "Canada"
        ? option_groups[1].options
        : option_groups && optionsChosenForCountry == "United States"
        ? option_groups[0].options
        : options
    );

  const handle_change = (e) => {
    let arr = inputResponse;
    arr = arr.map((val) => {
      if (val.id == id) {
        val.response = {
          answer: e.label,
          answer_id: e.value,
        };
        return val;
      } else {
        return val;
      }
    });
    if (id == "q7") {
      setOptionChosenForCountry(e.label);
    }


    setInputResponse([...arr]);
    if (!last_question_for_part_one) {
      setPresentQuestion({
        ...previousQuestion[
          previousQuestion.indexOf(
            previousQuestion.find((val) => val.id == id)
          ) + 1
        ],
        response: null,
      });
    }
  };

  const handleReset = () => {
    let arr = inputResponse;
    let index_search = arr.indexOf(arr.find((val) => val.id == id));
    arr = arr
      .map((val, index) => {
        if (index == index_search) {
          val.response = null;
          return val;
        } else if (index < index_search) {
          return val;
        }
      })
      .filter((val) => val !== undefined);

    if (textArea == true) setTextArea(false);

    setInputResponse([...arr]);
    setLastResponse(false);
  };

  return (
    <div className="A1-dropdown">
      {response || lastResponse ? (
        <div className="flex items-center flex-row gap-1 cursor-pointer">
          <Tippy
            content="Do you want to edit the data? Doing so, you will lose all your stored data."
            onClick={handleReset}
          >
            <Edit
              size="25"
              color="#2ccce4"
              onClick={handleReset}
              className="p-[4px]"
            />
          </Tippy>
          <div
            className={`lg:w-[30rem] md:w-[30rem] w-[14rem] sm:w-[18rem] ${id}`}
            onMouseEnter={(e) => {
              setClassName(e.target.className.split(" ")[1]);
            }}
          >
            <Select
              options={drop_down_option}
              onChange={handle_change}
              styles={customStyles}
              value={{
                value: String(response.answer_id).toLowerCase(),
                label: response.answer,
              }}
              isDisabled={true}
            />
          </div>
        </div>
      ) : (
        <>
          {loading && (
            <div className="h-[4rem] w-[4rem]">
              <Player
                src={loader}
                className="player"
                loop
                autoplay
              />
            </div>
          )}
          {!loading && (
            <div className="lg:w-[30rem] md:w-[30rem] w-[14rem] sm:w-[18rem]">
              <Select
                options={drop_down_option}
                onChange={handle_change}
                styles={customStyles}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Dropdown;
// lg:w-[30rem] md:w-[30rem] w-[14rem] sm:w-[18rem]
