import React, { useState, useEffect } from "react";
import ProgressBar from "./OtherComponents/ProgressBar";
import { toast } from "react-toastify";
import { HashLoader } from "react-spinners";
import ErrorModal from "./OtherComponents/ErrorComponent";
import { axiosAuth } from "../lib/axiosInstance";
import { utils_data_for_dynamic_form } from "../lib/utilsDynamic";

const FormClaim = ({ setClaimId }) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isButtonVisible, setButtonVisible] = useState(true);
  const [visibleFields, setVisibleFields] = useState({
    emergency: false,
    reconstruction: false,
    outsideService: false,
  });
  const [type, setType] = useState(null);
  const [Q24Label, setQ24Label] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [userDetails, setUserDetais] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchUser = async () => {
    try {
      const response = await axiosAuth.get("/user");
      setUserDetais(response.data.user);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    fetchUser();
  }, []);

  // 🔹 Fetch Questions Dynamically
  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const response = await axiosAuth.get("claims/questions");
      setQuestions(response.data);

      // Initialize answers state dynamically
      const initialAnswers = {};
      response.data.forEach((q) => {
        initialAnswers[q.key] = "";
      });
      setAnswers(initialAnswers);
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setLoading(false);
    }
  };

  // 🔹 Auto Fill Function (Fetch User Claim Data)
  const fetchClaimData = async () => {
    try {
      const { data } = await axiosAuth.get("/user-details-claim");

      if (!data.claimInformation) {
        toast.error("No claim data found");
        return;
      }

      const claimData = data.claimInformation;

      const updatedAnswers = {};
      const questionsFromUtils = utils_data_for_dynamic_form.question_part_one;

      // Map API keys back to question IDs
      Object.keys(question_mapper).forEach((questionId) => {
        const mappedKey = question_mapper[questionId]; // Get the mapped API key

        if (claimData.hasOwnProperty(mappedKey)) {
          if (
            mappedKey === "total_claim_amount" &&
            typeof claimData[mappedKey] === "object"
          ) {
            // Ensure dropdown values are correctly set
            updatedAnswers[questionId] = {
              dropdown1: claimData[mappedKey].dropdown1 || "",
              dropdown2: claimData[mappedKey].dropdown2 || "",
              dropdown3: claimData[mappedKey].dropdown3 || "",
            };
          } else {
            updatedAnswers[questionId] = claimData[mappedKey] ?? ""; // Handle null values
          }
        }
      });

      setAnswers(updatedAnswers);
      setButtonVisible(false);
      toast.success("Auto-filled form successfully!");
    } catch (error) {
      console.error("Error fetching user details:", error);
      toast.error("Failed to auto-fill form.");
    }
  };

  // 🔹 Handle Input Change
  const handleInputChange = (questionId, value) => {
    setAnswers((prev) => {
      // 🔹 Handle `q24` (Special Case for Total Claim Amount)
      if (
        questionId === "q24_trade" ||
        questionId === "q24_per_hour" ||
        questionId === "q24_total_trips"
      ) {
        return {
          ...prev,
          q24: {
            ...prev.q24, // Preserve existing values
            [questionId === "q24_trade"
              ? "dropdown1"
              : questionId === "q24_per_hour"
              ? "dropdown2"
              : "dropdown3"]: value,
          },
        };
      }

      // 🔹 Default Handling for Other Inputs
      return {
        ...prev,
        [questionId]: value,
      };
    });
    if (questionId === "q16") {
      const numericValue = parseInt(value, 10); // Convert to number

      setVisibleFields({
        emergency:
          (numericValue >= 1 && numericValue <= 7) || numericValue >= 14,
        reconstruction:
          (numericValue >= 8 && numericValue <= 13) || numericValue >= 14,
        outsideService: visibleFields.outsideService,
      });
    }
    if (questionId === "q17") {
      if (value === "Yes") {
        setVisibleFields((prev) => ({
          ...prev,
          outsideService: true,
        }));
      } else {
        setVisibleFields((prev) => ({
          ...prev,
          outsideService: false,
        }));
      }
    }
  };

  // 🔹 Validate Inputs
  const validateForm = () => {
    let newErrors = {};
    questions.forEach((q) => {
      if (q.required && !answers[q.key]) {
        newErrors[q.key] = "This field is required";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    questions.forEach((q) => {
      if (q.key === "q24") {
        if (answers["q16"] >= 1 && answers["q16"] <= 7) {
          setQ24Label(q.options[0][0]?.label);
        } else if (answers["q16"] >= 8 && answers["q16"] <= 13) {
          setQ24Label(q.options[0][1]?.label);
        } else if (answers["q16"] >= 14 && answers["q16"] <= 19) {
          setQ24Label(q.options[0][2]?.label);
        } else {
          setQ24Label(q.options[0][2]?.label); // Reset if no match
        }
      }
    });

    if (answers["q16"] >= 1 && answers["q16"] <= 7) {
      setType("Emergency");
    } else if (answers["q16"] >= 8 && answers["q16"] <= 13) {
      setType("Rebuild");
    } else if (answers["q16"] >= 14 && answers["q16"] <= 19) {
      setType("Contents");
    } else {
      setType(""); // Reset if no match
    }
  }, [answers["q16"]]);

  const question_mapper = {
    q29: "date_of_loss",
    q0: "home_owner_name",
    q1: "company_name_or_location",
    q2: "requester_name",
    q3: "full_name",
    q4: "phone",
    q5: "email",
    q6: "address",
    q7: "country",
    q8: "city",
    q9: "region",
    q10: "postal_code",
    q11: "type_of_loss",
    q12: "carrier_name",
    q13: "claim_adjuster",
    q14: "claim_number",
    q15: "policy_number",
    q16: "type_of_request",
    q17: "is_outside_of_service_area",
    q18: "emergency_profit",
    q19: "emergency_overhead",
    q20: "reconstruction_profit",
    q21: "reconstruction_overhead",
    q22: "travel_expenses",
    q23: "other_expenses",
    q24: "total_claim_amount",

    // q31:"home_owner_name"
    // q25:
  };

  // 🔹 Handle Form Submission
  const handleSubmit = async () => {
    // if (!validateForm()) return;

    try {
      const formattedData = {};

      Object.keys(answers).forEach((questionId) => {
        const mappedKey = question_mapper[questionId]; // Get correct API key

        if (mappedKey) {
          if (
            mappedKey === "total_claim_amount" &&
            typeof answers[questionId] === "object"
          ) {
            formattedData[mappedKey] = {
              dropdown1: answers[questionId].dropdown1 || "",
              dropdown2: answers[questionId].dropdown2 || "",
              dropdown3: answers[questionId].dropdown3 || "",
            };
          } else if (questionId === "q11" || questionId === "q16") {
            // Convert dropdown value to its index before submission
            const question = questions.find((q) => q.key === questionId);

            if (question && Array.isArray(question.options.options)) {
              const optionIndex = question.options.options.indexOf(
                answers[questionId]
              );

              formattedData[mappedKey] =
                optionIndex !== -1 ? optionIndex + 1 : answers[questionId];
            }
          } else {
            formattedData[mappedKey] = answers[questionId] ?? ""; // Handle null values
          }
        }
      });

      const response = await axiosAuth.post("/claims", formattedData);
      toast.success("Claim Information Saved");
      setClaimId(response.data.id);
      setCurrentStep(2);
    } catch (error) {
      console.error("Error submitting claim:", error);
      setErrorMessage(error.response?.data?.message || "An error occurred");
      setShowErrorModal(true);
    }
  };

  const filterPlaceholderWords = (label) => {
    const unwantedWords = ["What", "is", "your", "?", "?"];
    const filteredWords = label
      .split(" ")
      .filter((word) => !unwantedWords.includes(word))
      .join(" ");
    return `Enter ${filteredWords}`;
  };

  return (
    <>
      <div className="flex items-center justify-center bg-gray-100">
        <div
          className="col-span-full md:col-span-2 lg:col-span-3 pt-0 rounded-lg
                sm:w-full md:w-full lg:w-full xl:w-1/2 2xl:w-1/2"
        >
          <h4 className="text-xl font-semibold mb-4 text-center">
            Claim Information Form
          </h4>
        </div>
      </div>
      {loading ? (
        <div className="min-h-screen flex items-center pt-5 justify-center bg-gray-100 p-5">
          <HashLoader
            size={40}
            color="#1B2559"
            loading={true}
            css="margin: 0 auto;"
          />
        </div>
      ) : (
        <div className="min-h-screen flex items-center pt-5 justify-center bg-gray-100 p-5">
          <div
            className="col-span-full md:col-span-2 lg:col-span-3 bg-white p-6 pt-5 rounded-lg shadow-xl 
                sm:w-full md:w-full lg:w-full xl:w-1/2 2xl:w-1/2"
          >
            {/* 🔹 Auto Fill Button */}
            {isButtonVisible && (
              <div className="flex justify-end mb-3">
                <button
                  className="flex items-center p-2   text-white font-medium rounded-[7px] justify-end  shadow-md  bg-[#4c0f78] hover:bg-[#785eea]"
                  onClick={fetchClaimData}
                >
                  Auto Fill
                </button>
              </div>
            )}

            {/* 🔹 Render Dynamic Questions */}
            {questions.map((question) => {
              if (
                (["q18", "q19"].includes(question.key) &&
                  !visibleFields.emergency) ||
                (["q20", "q21"].includes(question.key) &&
                  !visibleFields.reconstruction) ||
                (["q22", "q23", "q24"].includes(question.key) &&
                  !visibleFields.outsideService)
              ) {
                return null;
              }
              return (
                <div key={question.key} className="mb-4">
                  {question.key !== "q24" && (
                    <label className="block mb-1">
                      {question.label} {question.required ? "*" : ""}
                    </label>
                  )}

                  {/* 🔹 Text Input */}
                  {question.type === "text" && (
                    <input
                      type="text"
                      className={`w-full p-2 border rounded-md ${
                        errors[question.id]
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      value={
                        question.key === "q2"
                          ? userDetails?.name || ""
                          : question.key === "q1"
                          ? userDetails?.company_name || ""
                          : answers[question.key] || ""
                      }
                      onChange={(e) =>
                        handleInputChange(question.key, e.target.value)
                      }
                      disabled={question.key === "q2"} // Disable input if it's q2
                      placeholder={filterPlaceholderWords(question.label)}
                    />
                  )}

                  {/* 🔹 Date Input */}
                  {question.type === "date" && (
                    <input
                      type="date"
                      className="w-full p-2 border border-gray-300 rounded-md"
                      value={answers[question.key] || ""}
                      onChange={(e) =>
                        handleInputChange(question.key, e.target.value)
                      }
                    />
                  )}

                  {/* 🔹 Dropdown Select */}
                  {question.type === "dropdown" &&
                    (() => {
                      // 🔹 Condition to hide certain fields when their visibility is false
                      if (
                        ["q24"].includes(question.key) &&
                        !visibleFields.outsideService
                      ) {
                        return null;
                      }

                      // 🔹 Special handling for q24 (3 dropdowns based on q16 & q17)
                      if (question.key === "q24") {
                        return visibleFields.outsideService ? (
                          <>
                            {/* 1️⃣ TRADE & NUMBER OF TRIPS (Depends on q16) */}
                            <label className="block mb-1">
                              {question.label} {question.required ? "*" : ""}
                            </label>
                            <label className="block mb-1">{Q24Label}</label>
                            <select
                              className="w-full p-2 border border-gray-300 rounded-md mb-3"
                              value={answers[question.key]?.dropdown1 || ""}
                              onChange={(e) =>
                                handleInputChange("q24_trade", e.target.value)
                              }
                            >
                              <option value="">Select</option>
                              {(() => {
                                const q24Question = questions.find(
                                  (q) => q.key === "q24"
                                );
                                if (
                                  !q24Question ||
                                  !Array.isArray(q24Question.options)
                                )
                                  return null;
                                // Get selected type from q16
                                const selectedType = answers["q16"];
                                const filteredOptions =
                                  q24Question.options[0].filter(
                                    (group) =>
                                      group.condition.toLowerCase() ===
                                      type?.toLowerCase()
                                  );

                                return filteredOptions.map((group, index) => (
                                  <optgroup key={index} label={group.label}>
                                    {group.values.map((option) => (
                                      <option
                                        key={option.key}
                                        value={option.key}
                                      >
                                        {option.text}
                                      </option>
                                    ))}
                                  </optgroup>
                                ));
                              })()}
                            </select>

                            <label className="block mb-1">
                              Enter Per Hour Amount *
                            </label>
                            <select
                              className="w-full p-2 border border-gray-300 rounded-md mb-3"
                              value={answers[question.key]?.dropdown2 || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  "q24_per_hour",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select</option>
                              {[
                                "0.25",
                                "0.50",
                                "0.75",
                                "1.00",
                                "1.25",
                                "1.50",
                                "1.75",
                                "2.00",
                                "2.25",
                                "2.50",
                                "2.75",
                                "3.00",
                                "3.25",
                                "3.50",
                                "3.75",
                                "4.00",
                              ].map((value) => (
                                <option key={value} value={value}>
                                  {value}
                                </option>
                              ))}
                            </select>

                            <label className="block mb-1">
                              Enter Total # of Trips *
                            </label>
                            <select
                              className="w-full p-2 border border-gray-300 rounded-md"
                              value={answers[question.key]?.dropdown3 || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  "q24_total_trips",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select</option>
                              {[...Array(10).keys()].map((num) => (
                                <option key={num + 1} value={num + 1}>
                                  {num + 1}
                                </option>
                              ))}
                            </select>
                          </>
                        ) : null;
                      }

                      // 🔹 Default dropdown logic for other questions
                      return (
                        <select
                          className="w-full p-2 border border-gray-300 rounded-md"
                          value={
                            ["q16", "q11"].includes(question.key)
                              ? question.options.options?.find(
                                  (opt) =>
                                    JSON.stringify(opt.req) ===
                                    JSON.stringify(answers[question.key])
                                )?.value || ""
                              : answers[question.key] || ""
                          }
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            const selectedOption =
                              question.options.options?.find(
                                (option) => option.value === selectedValue
                              );

                            const newValue =
                              ["q16", "q11"].includes(question.key) &&
                              selectedOption
                                ? selectedOption.req
                                : selectedValue;

                            handleInputChange(question.key, newValue);
                          }}
                        >
                          <option value="">Select</option>

                          {Array.isArray(question.options.options) &&
                          question.options.options.length > 0
                            ? question.options.options.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.value}
                                </option>
                              ))
                            : Array.isArray(question.options.option_groups) &&
                              question.options.option_groups.length > 0
                            ? question.options.option_groups
                                .filter((group) => {
                                  if (!group.conditions) return true;
                                  return (
                                    answers[group.conditions.questionId] ===
                                    group.conditions.value
                                  );
                                })
                                .flatMap((group) => group.options)
                                .map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.value}
                                  </option>
                                ))
                            : console.error(
                                "Neither options nor option_groups found!",
                                question
                              )}
                        </select>
                      );
                    })()}

                  {/* 🔹 Radio Buttons */}
                  {question.type === "radio" &&
                    Array.isArray(question.options.options) && (
                      <div className="flex flex-col">
                        {" "}
                        {/* Ensure vertical alignment */}
                        {question.options.options.map((option, index) => (
                          <label
                            key={index}
                            className="inline-flex items-center mb-2"
                          >
                            {" "}
                            {/* Add spacing between options */}
                            <input
                              type="radio"
                              name={question.key} // Ensure this is the same key used in state
                              value={option.value}
                              checked={
                                (answers[question.key] || "No") === option.value
                              }
                              onChange={(e) =>
                                handleInputChange(question.key, e.target.value)
                              }
                              className="mr-2"
                            />
                            {option.value}
                          </label>
                        ))}
                      </div>
                    )}

                  {/* 🔹 Display Errors */}
                  {errors[question.id] && (
                    <p className="text-red-500 text-sm">
                      {errors[question.id]}
                    </p>
                  )}
                </div>
              );
            })}

            <ErrorModal
              show={showErrorModal}
              onClose={() => setShowErrorModal(false)}
              errorMessage={errorMessage}
            />

            {/* 🔹 Submit Button */}
            <button
              onClick={handleSubmit}
              className="bg-[#4c0f78] font-medium hover:bg-[#785eea] text-white px-4 py-2 rounded-md"
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FormClaim;
