import React, { useEffect, useState } from 'react';

const CheckboxButtonWrapper = ({ options, onSelectionChange }) => {
  // State to store selected values and ids
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  // Function to handle checkbox change
  const handleCheckboxChange = (option) => {
    const { id, value, code } = option;
    setSelectedValues(prevSelectedValues => {
      if (prevSelectedValues.includes(value)) {
        return prevSelectedValues.filter((item) => item !== value);
      } else {
        return [...prevSelectedValues, value];
      }
    });
    setSelectedIds(prevSelectedIds => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  useEffect(() => {
    onSelectionChange(selectedValues, selectedIds);
  }, [selectedValues, selectedIds, onSelectionChange]);

  return (
    <div className="checkbox-button-wrapper grid grid-cols-2 sm:grid-cols-2 gap-4">
      {options.map((option, index) => (
        <div key={index} className="checkbox-option flex-auto py-2 px-4 rounded truncate cursor-pointer" >
          <input
            type="checkbox"
            id={`checkbox${index}`}
            value={option.id}
            onChange={() => handleCheckboxChange(option)}
            checked={selectedValues.includes(option.value)}
          />
          <label htmlFor={`checkbox${index}`} className='cursor-pointer text-md truncate text-center'  style={{ width: '100%' }}>{option.value}</label>
        </div> 
      ))}
    </div>
  );
};

export default CheckboxButtonWrapper;
