// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-message-skeleton {
  display: flex;
  align-items: flex-center;
  margin-bottom: 10px;
  margin-left: 20px;
}

.avatar-skeleton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 10px;
}

.message-content-skeleton {
  flex-grow: 1;
}

.text-skeleton {
  background-color: #ccc;
  padding: 25px;
  position: relative;
  width: 13rem;
}

@keyframes pulse {

  50% {
    opacity: .5;
  }
}

.text-skeleton {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

@media (min-width: 640px) {

  .text-skeleton {
    width: 25rem;
  }
}

.shimmer-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);
  animation: shimmer 1.5s infinite; 
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/OtherComponents/ChatMessageSkeleton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,YAA0E;AAC5E;;AADE;;EAAA;IAAA;EAA0E;AAAA;;AAA1E;EAAA,yDAA0E;EAA1E,+BAA0E;EAA1E,kCAA0E;EAA1E;AAA0E;;AAA1E;;EAAA;IAAA;EAA0E;AAAA;;AAG5E;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oFAAoF;EACpF,gCAAgC;AAClC;;AAEA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".chat-message-skeleton {\n  display: flex;\n  align-items: flex-center;\n  margin-bottom: 10px;\n  margin-left: 20px;\n}\n\n.avatar-skeleton {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  background-color: #ccc;\n  margin-right: 10px;\n}\n\n.message-content-skeleton {\n  flex-grow: 1;\n}\n\n.text-skeleton {\n  background-color: #ccc;\n  padding: 25px;\n  position: relative;\n  @apply rounded-r-full rounded-bl-full sm:w-[25rem] w-[13rem] animate-pulse;\n}\n\n.shimmer-effect {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: linear-gradient(to right, transparent 0%, #f0f0f0 50%, transparent 100%);\n  animation: shimmer 1.5s infinite; \n}\n\n@keyframes shimmer {\n  0% {\n    transform: translateX(-100%);\n  }\n  100% {\n    transform: translateX(100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
