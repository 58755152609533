import React, { useState, useEffect } from "react";
import "../ComponentTwo.css";
import { Edit, RefreshCircle, Send } from "iconsax-react";
import { utils_data_for_dynamic_form } from "../../lib/utilsDynamic";
import { Player } from "@lottiefiles/react-lottie-player";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { StringContentForInput } from "../../lib/toolTipFunction";
import { toast } from "react-toastify";

const Input = ({
  id,
  label,
  type,
  options,
  response,
  setPreviousQuestion,
  previousQuestion,
  setInputResponse,
  setPresentQuestion,
  inputResponse,
  presentQuestion,
  loading,
  setLoading,
  setLastResponse,
  lastResponse,
  setTextInputId,
  setTextArea,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [textAreaResponse, setTextAreaResponse] = useState();
  const [className, setClassName] = useState();

  useEffect(() => {
    setTextInputId(id);
  }, []);

  const textareaStyle = {
    border: "2px solid #ccc",
    borderRadius: "8px",
    padding: "8px",
    width: "200%",
    height: "5%",
    transition: "box-shadow 0.3s",
    boxShadow: isHovered
      ? "0 0 10px rgba(0, 123, 255, 0.7)"
      : "0 0 0 rgba(0, 123, 255, 0)",
    outline: "none",
    cursor: "pointer",
    fontSize: "16px",
  };

  const buttonStyle = {
    padding: "5px 10px",
    fontSize: "16px",
    // backgroundColor: '#4CAF50',
    // color: 'white',
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginLeft: "1rem",
  };

  const handle_submit = () => {};

  const handleReset = () => {
    let arr = inputResponse;
    let index_search = arr.indexOf(arr.find((val) => val.id == id));
    arr = arr
      .map((val, index) => {
        if (index == index_search) {
          val.response = null;
          return val;
        } else if (index < index_search) {
          return val;
        }
      })
      .filter((val) => val !== undefined);
    setInputResponse([...arr]);
    setTextArea(true);
    setLastResponse(false);
  };

  return (
    <>
      {response && !lastResponse && (
        <div className="flex justify-end items-baseline mr-0 flex-row gap-1">
          <Tippy
            content="Do you want to edit the data? Doing so, you will lose all your stored data."
            onClick={handleReset}
          >
            <Edit
              size="25"
              color="#2ccce4"
              onClick={handleReset}
              style={{ padding: 4 }}
            />
          </Tippy>
          <div
            className={`A1-dropdown ${id}`}
            onMouseEnter={(e) => {
              setClassName(e.target.className.split(" ")[1]);
            }}
          >
            <div className="form-row-1">
              <div className={`form-column-1 ${id}`}>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={handle_submit}
                  value={response ? response : ""}
                  placeholder="Type here"
                  disabled={true}
                  className={`labelimp ${id}`}
                  onMouseEnter={(e) => {
                    setClassName(e.target.className.split(" ")[1]);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {textArea && !response && !lastResponse && (
        <div className="textarea-input" style={{ display: "flex" }}>
          <>
            {loading && (
              <div style={{ height: "4rem", width: "4rem" }}>
                <Player
                  src="https://lottie.host/16487e6f-6e86-4956-af5a-f89515804257/3EVqJvUh3i.json"
                  className="player"
                  loop
                  autoplay
                />
              </div>
            )}
            {!loading && (
              <textarea
                style={textareaStyle}
                onClick={() => {
                  setIsHovered(!isHovered);
                }}
                onChange={(e) => {
                  setTextAreaResponse(e.target.value);
                }}
                placeholder="Type something..."
              ></textarea>
            )}
            {!loading && (
              <button style={buttonStyle} onClick={handle_submit}>
                <Send size="32" color="#2ccce4" variant="Bold" />
              </button>
            )}
          </>
        </div>
      )} */}
    </>
  );
};

export default Input;
