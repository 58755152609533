import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../Sidebar";
import AddUserModal from "../../adminComponent/AddUserModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineExclamationCircle,
} from "react-icons/ai";
import { axiosAuth } from "../../../lib/axiosInstance";
import useCookies from "../../../customHooks/useCookies";
import Skeleton from "react-loading-skeleton";
const Users = () => {
  const { getCookie } = useCookies();
  const userRole = getCookie("user_role");
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 10;
  const fetchData = async () => {
    try {
      const response = await axiosAuth.get("/users", {
        params: { page: currentPage, perPage: itemsPerPage },
      });
      setUsers(response?.data.data); // Access the paginated data
      setLastPage(response?.data.last_page);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };




  useEffect(() => {
    fetchData();

  }, [currentPage]);

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteModalRef = useRef(null);
  const [editingUser, setEditingUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const handleClickOutside = (event) => {
    if (
      deleteModalRef.current &&
      !deleteModalRef.current.contains(event.target)
    ) {
      closeDeleteModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditingUser(null);
    fetchData();
  };

  const addUser = (newUser) => {

    if (editingUser) {
      setUsers(
        users.map((user) =>
          user.id === editingUser.id ? { ...editingUser, ...newUser } : user
        )
      );
    } else {
      setUsers([...users, { id: users.length + 1, ...newUser }]);
      fetchData();
    }
  };

  const confirmRemoveUser = (userId) => {
    setShowDeleteModal(true);
    setCurrentUser(userId);
  };

  const removeUser = async () => {
    setUsers(users.filter((user) => user.id !== currentUser));
    try {
      await axiosAuth.delete(`delete-user/${currentUser}`);
    } catch (error) {
      console.log("error while deleting user:", error);
    }
    closeDeleteModal();
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const DeleteUserModal = () => {
    return (
      <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
        <div
          ref={deleteModalRef}
          className="bg-white rounded-lg shadow-lg p-8 z-20 max-w-md w-full transform transition-transform duration-300 scale-95"
        >
          <div className="grid gap-6">
            <div className="flex items-center justify-center text-red-500">
              <AiOutlineExclamationCircle className="w-12 h-12" />
            </div>
            <div className="text-center">
              <h2 className="text-2xl font-semibold mb-2">Confirm Deletion</h2>
              <p className="text-gray-700">
                Are you sure you want to delete this user?
              </p>
              <p className="text-gray-500 mt-1">
                This action cannot be undone.
              </p>
            </div>
            <div className="flex justify-end text-white gap-4 mt-4">
              <button
                onClick={closeDeleteModal}
                className="w-24 py-2 rounded bg-gray-400 hover:bg-gray-600 transition-colors duration-300"
              >
                Cancel
              </button>
              <button
                onClick={removeUser}
                className="w-24 py-2 rounded bg-red-500 hover:bg-red-700 transition-colors duration-300"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const editUser = (user) => {
    setEditingUser(user);
    openModal();
  };
  return (
    <Sidebar>
      <div className="p-4 mt-16 lg:mt-0 md:p-8 bg-gray-100 h-full">
        <h1 className="text-2xl md:text-3xl font-bold text-slate-800 mb-4 md:mb-6">
          Users
        </h1>

        {/* Add user button */}
        <button
          onClick={openModal}
          className="bg-[#36154e] text-white font-semibold py-2 px-4 rounded-lg mb-4 md:mb-6 hover:bg-[#2a133b] transition-colors"
        >
          Add User
        </button>

        {/* User modal */}
        {showModal && (
          <AddUserModal
            closeModal={closeModal}
            addUser={addUser}
            editingUser={editingUser}
          />
        )}

        {showDeleteModal && <DeleteUserModal />}
        {/* List of users */}
        <div className="bg-white rounded-lg shadow-md overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-[#36154e] text-white">
                <th className="px-4 md:px-6 py-3 text-left">ID</th>
                <th className="px-4 md:px-6 py-3 text-left">Name</th>
                <th className="px-4 md:px-6 py-3 text-left">Email</th>
                <th className="px-4 md:px-6 py-3 text-left">Contact Number</th>
                <th className="px-4 md:px-6 py-3 text-left">Company Name/Location</th>
                {/* <th className="px-4 md:px-6 py-3 text-left">Location</th> */}
                <th className="px-4 md:px-6 py-3 text-left">Address</th>
                <th className="px-4 md:px-6 py-3 text-left">Roles</th>
                <th className="px-4 md:px-6 py-3"></th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                {[...Array(10)].map((_, index) => (
                  <tr key={index}>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3">
                      <Skeleton />
                    </td>
                    <td className="px-4 md:px-6 py-3 flex space-x-2">
                      <Skeleton />
                    </td>

                  </tr>
                ))}
              </tbody>
            ) : users.length > 0 ? (
              <tbody className="divide-y divide-blue-200  text-nowrap">
                {users.map((user) => (
                  <tr key={user.id}>
                    <td className="px-4 md:px-6 py-3">{user?.id}</td>
                    <td className="px-4 md:px-6 py-3">{user?.name}</td>
                    <td className="px-4 md:px-6 py-3">{user?.email}</td>
                    <td className="px-4 md:px-6 py-3">
                      {user.contact_number ?? "-"}
                    </td>
                    <td className="px-4 md:px-6 py-3">
                      {user.company_name ?? "-"}
                    </td>
                    {/* <td className="px-4 md:px-6 py-3">
                      {user.location ?? "-"}
                    </td> */}
                    <td className="px-4 md:px-6 py-3">
                      {user?.address ?? "-"}
                    </td>
                    {/* <td className="px-4 md:px-6 py-3">{user?.role ?? "user"}</td> */}
                    <td className="px-4 md:px-6 py-3">
                      {(() => {
                        if (!user?.role) return "Customer Field Staff";
                        switch (user.role) {
                          case "admin":
                            return "Ripley Admin";
                          case "company-admin":
                            return "Customer Admin";
                          case "user":
                            return "Customer Field Staff";
                          default:
                            return user.role;
                        }
                      })()}
                    </td>
                    <td className="px-4 md:px-6 py-3 flex space-x-2">
                      <button
                        onClick={() => editUser(user)}
                        className="text-blue-500 hover:text-blue-700 font-semibold"
                      >
                        <AiFillEdit />
                      </button>

                      {user?.role != "admin" && (
                        <button
                          onClick={() => confirmRemoveUser(user?.id)}
                          className="text-red-500 hover:text-red-700 font-semibold"
                        >
                          <AiFillDelete />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody className=" divide-blue-200   w-full border-2">
                <tr>
                  <td
                    colSpan={7}
                    className="p-3 text-center italic text-gray-400 font-medium  text-xl"
                  >
                    No results found
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          {/* Pagination */}
          <div className="flex justify-center gap-4 py-2">
            <button
              className={`py-1 w-[5rem] text-slate-50 bg-[#36154e]   rounded ${currentPage == 1 ? "opacity-20" : ""
                }`}
              disabled={currentPage == 1}
              onClick={prevPage}
            >
              Previous
            </button>
            <button
              className={`py-1 w-[5rem] text-slate-50 bg-[#36154e]  rounded ${currentPage == lastPage ? "opacity-20" : ""
                }`}
              disabled={currentPage == lastPage}
              onClick={nextPage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default Users;
