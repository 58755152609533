import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../Sidebar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Select from '@mui/material/Select';
import { Player } from "@lottiefiles/react-lottie-player";
import FormControl from '@mui/material/FormControl';
import robotAnimation from "../asset/robot_animation.json";
import {
  Send,
  ArrowSquareDown,
  Edit2,
  TickCircle,
  AddCircle,
  CloseCircle,
} from "iconsax-react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosAuth } from "../../lib/axiosInstance";
import { ToastContainer } from "react-toastify";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import CheckboxButtonWrapper from "../OtherComponents/CheckboxButtonWrapper";
import Steps from "../OtherComponents/Steps";
import ChatMessageSkeleton from "../OtherComponents/ChatMessageSkeleton";
import { MenuItem } from "@mui/material";
const q1Option = [
  {
    key: "Interior",
    value: "Interior",
    req: [1],
  },
  {
    key: "Exterior",
    value: "Exterior",
    req: [2],
  },

  {
    key: "Content",
    value: "Content",
    req: [3],
  },
];

const questions = {
  1: {
    id: 1,
    title: "Choose Scope ?",
    renderable_type: "select",
    options: q1Option,
  },
  2: {
    id: 2,
    title: "Room name ?",
    renderable_type: "text",
  },
  3: {
    id: 3,
    title: "Structure/Area Name ?",
    renderable_type: "text",
  },
  4: {
    id: 4,
    title: "Content Name ?",
    renderable_type: "text",
  },
};

export default function IndivisualRooms() {
  const { id } = useParams();
  const chatContainerRef = useRef(null);
  const navigate = useNavigate();

  const [showInput, setShowInput] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(questions[1]);
  const [showError, setShowError] = useState(false);
  const [currentReq, setCurrentReq] = useState(0);
  const [chat, setChat] = useState([]);
  const [payloadArray, setPayloadArray] = useState([]);
  const [showQuestion, setShowQuestion] = useState(false);
  const [mulVals, setMulVals] = useState("");

  const [mulVal1, setMulVal1] = useState("");
  const [mulVal2, setMulVal2] = useState("");
  const [mulVal3, setMulVal3] = useState("");
  const [mulValAddon, setMulValAddon] = useState("");

  const [loading, setLoading] = useState(false);

  const [mulValText1, setMulValText1] = useState("");
  const [mulValText2, setMulValText2] = useState("");
  const [mulValText3, setMulValText3] = useState("");
  const [mulValTextAddon, setMulValTextAddon] = useState("");

  const [currentReqValue, setCurrentReqValue] = useState("");
  const [room_id, setRoom_id] = useState(null);
  const [currentInfoId, setCurrentInfoId] = useState("");
  const [req, setReq] = useState({});

  const [currentStep, setCurrentStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(14);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState('');
  const [isOpenDropdown1, setIsOpenDropdown1] = useState(false);
  const [isOpenDropdown2, setIsOpenDropdown2] = useState(false);
  const [isOpenDropdown3, setIsOpenDropdown3] = useState(false);
  const [isOpenDropdown4, setIsOpenDropdown4] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionIds, setSelectedOptionIds] = useState(null);



  // Extract the query parameters from the location object
  const queryParams = new URLSearchParams(location.search);

  // Get the value of the 'type_of_request' parameter
  const typeOfRequest = queryParams.get("type_of_request");

  const resetState = (source) => {
    setMulVals([
      {
        val1: "",
        val2: "",
        val3: "",
        mulValAddon: "",
        valText1: "",
        valText2: "",
        valText3: "",
        mulValTextAddon: "",
        isOpen: false, // State for the first dropdown
        isOpen2: false, // State for the second dropdown
        isOpen3: false, // State for the third dropdown
      },
    ]);
    setMulVal1("");
    setMulVal2("");
    setMulVal3("");
    setMulValText1("");
    setMulValText2("");
    setMulValText3("");
    setMulValAddon("");
    setMulValTextAddon("");
  };
  const reqType = (val) => {
    setCurrentStep(1);
    const reqArr = val.req;
    setCurrentReqValue(val.value);
    const firstVal = reqArr.shift();

    localStorage.setItem("req_arr", JSON.stringify(reqArr));
    setCurrentReq(firstVal);
    setReq(val);
    if (firstVal === 1) {
      setCurrentQuestion(questions[2]);
    } else if (firstVal === 2) {
      setCurrentQuestion(questions[3]);
    } else if (firstVal === 3) {
      setCurrentQuestion(questions[4]);
    }
    setShowQuestion(true);

    setShowInput(true);
  };
  const createRoom = async (e) => {
    e.preventDefault();
    const createRoomIds = [2, 3, 4];

    if (createRoomIds.includes(currentQuestion.id)) {
      const payload = {
        room_name: inputVal,
        type_of_request: typeOfRequest,
      };

      const obj2 = {
        question: currentQuestion,
        ans: inputVal,
      };

      try {
        const { data } = await axiosAuth.post(
          `room-information/create/${id}`,
          payload
        );
        if (data?.room_id) {
          setRoom_id(data?.room_id);
          setShowInput(false);
          setInputVal("");
          await fetchQuestion(data?.room_id);
          setChat([...chat, obj2]);
          scrollToBottom();
          setShowQuestion(true);
          resetState("postData");
        }
      } catch (error) {
        console.error("Error creating room:", error);
      }
    } else {
      console.log("Post another text ans");
    }
  };

  const fetchQuestion = async (roomId) => {
    try {
      setLoading(true);
      const { data } = await axiosAuth.get(
        `/room-information/questions/${roomId}`
      );
      setTotalSteps(data.total_questions_count + 2);
      setCurrentStep(2);
      setCurrentQuestion(data?.question);
      setCurrentInfoId(data?.room_information_answer_id);
      setLoading(false);

      data?.question?.renderable_type === "text" && setShowInput(true);
    } catch (error) {
      console.error("Error fetching question:", error);
    }
  };

  const handleMulValChange = (index, key, textKey, textValue, value) => {
    const updatedMulVals = [...mulVals];
    if (!updatedMulVals[index]) {
      updatedMulVals[index] = { [key]: '', [textKey]: '' }; // Create a new empty object
    }

    updatedMulVals[index][key] = value;
    updatedMulVals[index][textKey] = textValue;
    // Add multiple values to the object

    setMulVals(updatedMulVals);
  };
  const [activeIndex, setActiveIndex] = useState(0);

  const handleAddCircle = (index) => {
    setMulVals(prevMulVals => {
      const lastMulVal = prevMulVals[prevMulVals.length - 1] || {};
      const newMulVal = {
        val1: '',
        val2: '',
        val3: '',
        valText1: '',
        valText2: '',
        valText3: '',
        mulValAddon: '',
        mulValTextAddon: '',
        isOpen: false, // State for the first dropdown
        isOpen2: false, // State for the second dropdown
        isOpen3: false, // State for the third dropdown
      };
      return [...prevMulVals, newMulVal]; // Return a new array with the updated objects
    });
    setActiveIndex(mulVals.length);
    scrollToBottom();
  };


  const handleRemoveCircle = (index) => {
    setMulVals(prevMulVals => prevMulVals.filter((_, i) => i !== index));
    setActiveIndex(prevActiveIndex => prevActiveIndex > 0 ? prevActiveIndex - 1 : 0); // Adjust active index
  };
  const postData = async (payload, obj, deleteQuestionsIds = []) => {
    if (deleteQuestionsIds.length > 0) {
      payload.delete_questions = deleteQuestionsIds;
    }
    setChat([...chat, obj]);
    setLoading(true);
    setShowInput(false);
    try {
      const { data } = await axiosAuth.post(
        `/room-information/questions/${room_id}`,
        payload
      );
      if (data.submitted) {
        setLoading(false);
        const reqArray = JSON.parse(localStorage.getItem("req_arr"));
        if (!reqArray.length) {
          toast.success("Survey created successfully");
          navigate(`/individual-file/${id}`);
        } else {
          setChat([...chat, obj]);
          setPayloadArray([...payloadArray, payload]);
          scrollToBottom();
          reqType(req);

          // Set dropdown values before calling resetState
          setCurrentQuestion(data.question);
          setCurrentStep(totalSteps - data?.remaining_questions_count);

          setCurrentInfoId(data.room_information_answer_id);
          resetState("postData");
          setLoading(false);
        }
      } else {
        setCurrentStep(totalSteps - data?.remaining_questions_count);
        setCurrentQuestion(data.question);
        setCurrentInfoId(data.room_information_answer_id);

        setChat([...chat, obj]);
        setPayloadArray([...payloadArray, payload]);
        scrollToBottom();
        resetState("postData");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error posting data:", error);
      resetState("errorPostData");
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (option, ids) => {
    setSelectedOption(option);
    setSelectedOptionIds(ids);
  };

  const postSelectAns = async (e, val, ans, addon = "", addOnText = "") => {
    e.preventDefault();

    const obj = {
      question: currentQuestion,
      ans: addOnText ? ans + ' , ' + addOnText : ans,
      payload: val,
    };

    const finalOutput = {
      answers: [
        {
          answer_id: val,
          is_after_hours: null,
          add_on: addon,
          quantity: null,
          number_of_days: null,
          room_information_answer_id: Number(currentInfoId),
          code: currentQuestion.code,
        },
      ],
      question_id: currentQuestion.id,
      type_of_request: typeOfRequest,
      related_code: currentQuestion.related_code,
    };
    await postData(finalOutput, obj);
  };

  const PostMulAns = async () => {

    // Determine if any dropdown is not selected
    const isAnyDropdownNotSelected = mulVals.some((mulVal) => {
      if (currentQuestion?.renderable_type === "dropdown_multi_value_and_multi_value") {
        if (currentQuestion?.title.includes("contents") && mulVal.valText1?.includes("Protect")) {
          // If quantities dropdown is required, check both dropdowns
          return !mulVal.val1 || !mulVal.val2;
        } else {
          // Otherwise, only check the first dropdown
          return !mulVal.val1;
        }
      } else {
        return !mulVal.val1;
      }
    });
    if (isAnyDropdownNotSelected) {
      // Display error toast
      toast.error("Please select values for all dropdowns");
      setShowError(true);
      return;
    }

    const answersArray = mulVals?.map((mulVal, index) => {
      const updatedRoomInformationAnswerId = Number(currentInfoId) + index;
      return {
        answer_id: mulVal.val1,
        is_after_hours: null,
        quantity: mulVal.val2,
        number_of_days: null,
        add_on: mulVal?.mulValAddon || null,
        room_information_answer_id: updatedRoomInformationAnswerId,
        code: currentQuestion.code,
      };
    });

    const obj = {
      question: currentQuestion,
      ans: mulValText1,
      ans1: mulValText2,
      ans2: null,
      ans_id: mulVal1,
      quantity: mulVal2,
      number_of_days: mulVal3,
      multiAns: true,
      multiAnsVal: mulVals,
    };

    const finalOutput = {
      answers: answersArray,
      question_id: currentQuestion.id,
      type_of_request: typeOfRequest,
      related_code: currentQuestion.related_code,
    };

    await postData(finalOutput, obj);


    // Reset the error state
    setShowError(false);
  };

  const PostMulQtyAndDays = async () => {
    const isAnyDropdownNotSelected = !mulVal1 || !mulVal2 || !mulVal3;

    if (isAnyDropdownNotSelected) {
      // Display error toast
      toast.error("Please select values for all dropdowns");
      setShowError(true);
      return;
    }

    const payload = {
      question_id: currentQuestion.id,
      answers: [
        {
          answer_id: mulVal1,
          is_after_hours: null,
          quantity: mulVal2,
          number_of_days: mulVal3,
          add_on: mulValAddon,
          room_information_answer_id: Number(currentInfoId),
          code: currentQuestion.code,
        },
      ],
      type_of_request: typeOfRequest,
      related_code: currentQuestion.related_code,
    };
    const answersArray = mulVals?.map((mulVal, index) => {
      const updatedRoomInformationAnswerId = Number(currentInfoId) + index;
      return {
        answer_id: mulVal.val1,
        is_after_hours: null,
        quantity: mulVal.val2,
        number_of_days: mulVal.val3,
        add_on: mulVal?.mulValAddon || null,
        room_information_answer_id: updatedRoomInformationAnswerId,
        code: currentQuestion.code,
      };
    });

    const obj = {
      question: currentQuestion,
      ans: mulValText1,
      ans1: mulValText2,
      ans2: mulValText3,
      ans_id: mulVal1,
      quantity: mulVal2,
      number_of_days: mulVal3,
      addonAns: mulValTextAddon,
      add_on: mulValAddon,
      multiAns: true,
      multiAnsVal: mulVals,
    };
    const finalOutput = {
      answers: answersArray,
      question_id: currentQuestion.id,
      type_of_request: typeOfRequest,
      related_code: currentQuestion.related_code,
    };

    await postData(finalOutput, obj);
  };
  const PostMulAddQtyAndDays = async () => {


    const createPayload = (question, mulVal1, mulVal2, mulVal3, mulValAddon, currentInfoId) => ({
      question_id: question.id,
      answers: [
        {
          answer_id: mulVal1,
          is_after_hours: null,
          quantity: mulVal2,
          number_of_days: question.code === "CEI9" ? null : mulVal3,
          add_on: mulValAddon || "",
          room_information_answer_id: Number(currentInfoId),
          code: question.code,
        },
      ],
      type_of_request: typeOfRequest,
      related_code: question.related_code,
    });

    const createAnswersArray = (question, mulVals, currentInfoId) =>
      mulVals?.map((mulVal, index) => {
        const updatedRoomInformationAnswerId = Number(currentInfoId) + index;
        return {
          answer_id: mulVal.val1,
          is_after_hours: null,
          quantity: mulVal.val2,
          number_of_days: mulVal.val3 || null,
          add_on: mulVal?.mulValAddon || null,
          room_information_answer_id: updatedRoomInformationAnswerId,
          code: question.code,
        };
      });

    const createObj = (question, mulValText1, mulValText2, mulValText3, mulVal1, mulVal2, mulVal3, mulValTextAddon, mulValAddon) => ({
      question,
      ans: mulValText1,
      ans1: mulValText2,
      ans2: mulValText3 || "",
      ans_id: mulVal1,
      quantity: mulVal2,
      number_of_days: mulVal3 || "",
      addonAns: mulValTextAddon || "",
      add_on: mulValAddon || "",
      multiAns: true,
      multiAnsVal: mulVals,
    });

    const payload = createPayload(currentQuestion, mulVal1, mulVal2, mulVal3, mulValAddon, currentInfoId);
    const answersArray = createAnswersArray(currentQuestion, mulVals, currentInfoId);
    const obj = createObj(currentQuestion, mulValText1, mulValText2, mulValText3, mulVal1, mulVal2, mulVal3, mulValTextAddon, mulValAddon);

    const finalOutput = {
      answers: answersArray,
      question_id: currentQuestion.id,
      type_of_request: typeOfRequest,
      related_code: currentQuestion.related_code,
    };

    await postData(finalOutput, obj);


  }


  const PostMulQtyAndAfterHours = async () => {
    const isAnyDropdownNotSelected = !mulVal1 || !mulVal2 || !mulVal3;

    if (isAnyDropdownNotSelected) {
      // Display error toast
      toast.error("Please select values for all dropdowns");
      setShowError(true);
      return;
    }
    const payload = {
      question_id: currentQuestion.id,
      answers: [
        {
          answer_id: mulVal1,
          is_after_hours: mulVal3,
          quantity: mulVal2,
          number_of_days: null,
          add_on: mulValAddon,
          room_information_answer_id: Number(currentInfoId),
          code: currentQuestion.code,
        },
      ],
      type_of_request: typeOfRequest,
      related_code: currentQuestion.related_code,
    };

    const obj = {
      question: currentQuestion,
      ans: mulValText1,
      ans1: mulValText2,
      ans2: mulValText3,
      answer_id: mulVal1,
      is_after_hours: mulVal3,
      quantity: mulVal2,
      addonAns: mulValTextAddon,
      add_on: mulValAddon,
      multiAns: true,
      multiAnsVal: mulVals,
    };

    await postData(payload, obj);
  };
  const PostDate = async (formattedDate) => {
    setLoading(true);

    const payload = {
      question_id: currentQuestion.id,
      answers: [
        {
          answer_id: null,
          is_after_hours: null,
          quantity: null,
          number_of_days: null,
          room_information_answer_id: Number(currentInfoId),
          code: currentQuestion.code,
          date_time: formattedDate,
        },
      ],
      related_code: currentQuestion.related_code,
    };
    const obj = {
      question: currentQuestion,
      ans: formattedDate,
      ans1: null,
      answer_id: null,
      quantity: null,
    };
    await postData(payload, obj);
  };

  const PostTable = async () => {
    setLoading(true);

    const payload = {
      question_id: currentQuestion.id,
      answers: [
        {
          answer_id: selectedOptionIds,
          is_after_hours: null,
          quantity: null,
          number_of_days: null,
          room_information_answer_id: Number(currentInfoId),
          code: currentQuestion.code,
        },
      ],
      type_of_request: typeOfRequest,
      related_code: currentQuestion.related_code,
    };
    const obj = {
      question: currentQuestion,
      ans: selectedOption,
      ans1: null,
      answer_id: selectedOptionIds,
      quantity: null,
    };

    await postData(payload, obj);
  };
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [chat, activeIndex]);

  const EditResponse = async (currentChat, index) => {
    alert(index);
    setCurrentStep(index);
    if (currentChat?.question.id === 1 || currentChat?.question.id === 2) {
      setShowQuestion(false);
      setChat([]);
      setPayloadArray([]);
      return;
    }

    if (index === 2 && currentChat?.question?.id != 131) {
      fetchQuestion(room_id);
      const removeChat = chat.splice(2);
      setChat([...chat]);
      setPayloadArray([]);
      return;
    }
    let prevChat;
    let prevPayLoad;
    if (currentChat?.question?.id === 131) {
      prevChat = chat[index];
      prevPayLoad = payloadArray[index - 2];
    } else {
      prevChat = chat[index - 1];
      prevPayLoad = payloadArray[index - 3];
    }
    // get all the questions ids before the current question
    const deleteQuestionsIds = chat.slice(0, index).map((chat) => chat.question.id);

    await postData(prevPayLoad, prevChat, deleteQuestionsIds);
    setMulVals(() => {
      // Map through the existing answers to set multiple values if needed
      const multipleAnswers = currentChat?.multiAnsVal || [];
      const updatedMulVals = multipleAnswers.map((answer) => ({
        val1: answer?.val1,
        val2: answer?.val2,
        val3: answer?.val3,
        valText1: answer?.valText1,
        valText2: answer?.valText2,
        valText3: answer?.valText3,
        mulValAddon: answer?.mulValAddon,
        mulValTextAddon: answer?.mulValTextAddon,
        isOpen: false,
        isOpen2: false,
        isOpen3: false,
      }));
      return updatedMulVals; // Replace previous values with new ones from multiple answers
    });

    if (currentChat?.multiAnsVal && currentChat?.multiAnsVal.length > 0) {
      // Map through all answers and set multiple values dynamically
      const allMulValText1 = currentChat?.multiAnsVal.map((answer) => answer?.valText1 || "");
      const allMulValText2 = currentChat.multiAnsVal.map((answer) => answer?.valText2 || "");
      const allMulValText3 = currentChat.multiAnsVal.map((answer) => answer?.valText3 || "");
      const allMulVal1 = currentChat.multiAnsVal.map((answer) => answer?.val1 || null);
      const allMulVal2 = currentChat.multiAnsVal.map((answer) => answer?.val2 || null);
      const allMulVal3 = currentChat.multiAnsVal.map((answer) => answer?.val3 || answer?.is_after_hours?.id || null);
      // Update state to hold arrays for each value
      setMulValText1(allMulValText1);
      setMulValText2(allMulValText2);
      setMulValText3(allMulValText3);
      setMulVal1(allMulVal1);
      setMulVal2(allMulVal2);
      setMulVal3(allMulVal3);
    }

    setCurrentQuestion(currentChat.question);
    setChat(chat.slice(0, index));
    setPayloadArray(payloadArray.slice(0, index - 2));

    setChat(chat.slice(0, index));
    setPayloadArray(payloadArray.slice(0, index - 2));
  };

  const getMessage = (codePrefix) => {
    const messages = {
      CEI: "(Emergency) Interior Only",
      CEX: "(Emergency) Exterior Only",
      DEC: "(Emergency) Separate Contents Only",
      CRI: "(Rebuild) Interior Only",
      CRX: "(Rebuild) Exterior Only",
    };
    return messages[codePrefix] ? messages[codePrefix] + " Scenario " : "";
  };

  const determineCategory = (value) => {
    if (value.includes('Floor')) return 'Floor';
    if (value.includes('Wall')) return 'Wall';
    if (value.includes('Ceiling')) return 'Ceiling';
    if (value.includes('Lighting')) return 'Lighting';
    if (value.includes('CORK')) return 'CORK';
    if (value.includes('LAMINATE')) return 'LAMINATE';
    if (value.includes('TILE')) return 'TILE';
    if (value.includes('VINYL PLANK')) return 'VINYL';
    if (value.includes('WOOD ')) return 'WOOD ';
    if (value.includes('CARPET')) return 'CARPET'
    if (value.includes('HARD SURFACE')) return 'HARD SURFACE'
    if (value.includes('DRYWALL')) return 'DRY WALL'
    if (value.includes('INSULATION')) return 'INSULATION'
    if (value.includes('PLASTER OVER')) return 'PLASTER OVER'
    if (value.includes("Door Casing")) return "Door Casing";
    if (value.includes("Bifold")) return "Bifold"
    if (value.includes("Interior ")) return "Interior Door"
    if (value.includes("Bypass")) return "Bypass"
    if (value.includes("Int.")) return "Int"
    if (value.includes("Fridge") || (value.includes("Dryer")) || (value.includes("Range")) || (value.includes("Washer")) || (value.includes("Dishwasher"))) return "Typical Appliances"
    if (value.includes("Soffit")) return "Soffit"
    if (value.includes('Wooden')) return 'Wooden Base';
    if (value.includes('Shed')) return 'Shed';
    if (value.includes('Gazebo')) return 'Gazebo';
    if (value.includes('Greenhouse')) return 'Greenhouse';
    if (value.includes('Carport')) return 'Carport';
    if (value.includes('Pergoal')) return 'Pergoal';
    if (value.includes('Hoop Building')) return 'Hoop Building Kit';
    if (value.includes('Quonset Building')) return 'Quonset "Kit"';
    if (value.includes('Metal')) return 'Metal';
    if (value.includes('Lumber')) return 'Lumber';
    if (value.includes('Temporary')) return 'Temporary';
    if (value.includes('Board')) return 'Board';
    return 'OTHER'; // Default category
  };
  let lastCategory = null;
  const handleIcon = (event, index, dropdownKey) => {
    event.preventDefault();
    event.stopPropagation();
    if (index === 0 && dropdownKey === "dropdown") {
      setIsOpen(!isOpen);
    } else {
      handleDropdownToggle(index, dropdownKey); // Toggle the specific dropdown
    }
  }
  const handleDropdownToggle = (index, dropdownKey) => {
    setMulVals((prevMulVals) => {
      const newMulVals = [...prevMulVals];
      newMulVals[index][dropdownKey] = !newMulVals[index][dropdownKey];
      return newMulVals;
    });
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    const parsedValue = JSON.parse(event.target.value);
    reqType(parsedValue);
  };
  useEffect(() => {
    if (currentReqValue && currentReq) {
      // Create obj1 after state updates
      const obj1 = {
        question: questions[1],
        ans: currentReqValue,
        payload: currentReq,
      };
      // check if the current question is the first question        
      setChat([obj1]);
      scrollToBottom();
    }
  }, [currentReqValue, currentReq]);


  const [selectedOptions, setSelectedOptions] = useState([]);

  const [obj, setObj] = useState([]);

  useEffect(() => {
    // Update the obj state whenever selectedOptions changes
    const newObjects = selectedOptions.map((option) => ({
      value: option.value,
      id: option.id,
      code: option.code
    }));
    setObj(newObjects);
  }, [selectedOptions]);

  const [code, setCode] = useState(localStorage.getItem('code') || '');
  const fetchOptions = async () => {
    const request = {
      typeOfRequest: typeOfRequest
    }
    const res = await axiosAuth.get('/gettitle', {
      params: { typeOfRequest }
    })
    localStorage.setItem('code', res?.data?.code)
    setCode(res?.data?.code)
  }
  useEffect(() => {
    fetchOptions()
  }, [])



  const [question, setQuestion] = useState([]);


  const initialQuestions = useRef(questions[1]?.options || []);


  const removeElementByKey = (arr, keysToRemove) => {
    return arr.filter(option => !keysToRemove.includes(option.key));
  };


  useEffect(() => {
    const codeIRegex = /\bI\b/;
    const codeIXRegex = /(?:I&X|IX)(?![C])/;
    const codeICRegex = /\bIC\b/;
    const codeXCRegex = /\bXC\b/;
    const codeXRegex = /(?:E&R - X|E - X)/; //for Emergency Rebuild exterior and Exterior only

    const getIX = localStorage.getItem('code');

    if (codeIRegex.test(getIX)) {
      const keysToRemove = ['Exterior', 'Content'];
      const updatedOptions = removeElementByKey(initialQuestions.current, keysToRemove);
      setQuestion(updatedOptions);
    } else if (codeIXRegex.test(getIX)) {
      const keysToRemove = ['Content'];
      const updatedOptions = removeElementByKey(initialQuestions.current, keysToRemove);
      setQuestion(updatedOptions);
    }
    else if (codeICRegex.test(getIX)) {
      const keysToRemove = ['Exterior'];
      const updatedOptions = removeElementByKey(initialQuestions.current, keysToRemove);
      setQuestion(updatedOptions);
    }
    else if (codeXCRegex.test(getIX)) {
      const keysToRemove = ['Interior'];
      const updatedOptions = removeElementByKey(initialQuestions.current, keysToRemove);
      setQuestion(updatedOptions);
    }
    else if (codeXRegex.test(getIX)) {
      const keysToRemove = ['Interior', 'Content'];
      const updatedOptions = removeElementByKey(initialQuestions.current, keysToRemove);
      setQuestion(updatedOptions);

    }
    else {
      setQuestion(initialQuestions.current);
    }
  }, [code]);


  const setDefaultValues = (question) => {
    if (!question?.possible_answers) return;
    console.log(question, "question");
    if (question?.default_answer) {
      const { id, value } = question.default_answer;

      // Set the default answer from the backend to the appropriate dropdown
      handleMulValChange(0, "val1", "valText1", value, id);
      setMulValText1(value);
      setMulVal1(value);

      return; // Exit if default answer is already set
    }
  };

  useEffect(() => {
    if (currentQuestion) {
      setDefaultValues(currentQuestion);
    }
  }, [currentQuestion]);

  // console.log(chat, "chat")
  const ensureDefinedValue = (value, fallback) => value !== undefined ? value : fallback;
  return (
    <Sidebar>
      <div className="flex h-[100vh]  justify-center   overflow-auto  items-end  ">
        <div className="xl:w-[70%] w-[95%]  my-2   min-w-[20rem]  align-center bg-slate-100 border rounded-md lg:mx-16  md:w-[90%]  ans-shadow  ">
          <div
            className={` relative lg:max-h-[94dvh] grid items-end max-h-[15vh] xs:max-h-[77dvh] sm:max-h-[82dvh] overflow-auto ${chat.length < 2 ? "min-h-[91vh]" : ""}`}
          >
            {chat.length < 4 && (
              <div
                className={`sm:flex sm:h-full justify-center overflow-auto items-end rounded-3xl  ${chat.length < 2 && "p-0"
                  }`}
              >
                <div className="px-6  sm:w-full md:w-66.66667 lg:w-50 xl:w-full">
                  {/* Welcome message with animation */}
                  <div
                    className={`flex flex-col items-center  ${!showInput ? "" : ""}`}>
                    <h1 className="sm:text-3xl font-bold mb-4 text-blue-800 whitespace-nowrap xs:text-2xl xs:pt-4">
                      Welcome to Smart Scope!
                    </h1>
                    {/* Animation */}
                    {/* <div
                      className="flex justify-center items-center sm:h-72"
                    >
                      <Player
                        autoplay
                        loop
                        src={robotAnimation}
                        className="animated-robot sm:h-72 sm:w-72"
                      />
                    </div> */}

                    {/* Guide to select request type */}
                    <div className="sm:flex hidden  flex-col items-center pt-3 gap-4">
                      <span className=" text-lg text-gray-700">
                        Our team will assist you in filling out the form.
                      </span>
                      <span className="text-lg text-gray-700">
                        If you made a mistake in selecting the correct answer,
                        you can always re-edit the answer.
                      </span>
                      <span className="text-lg text-gray-700">
                        Simply select a Type to get started.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* chat box */}
            {chat.length < 2 && (
              <div className="px-6 my-10">
                <div className="flex gap-3  ">
                  <div className="ml-2 px-5 py-3  bg-gradient-to-r bg-[#f7daad] rounded-br-3xl rounded-tr-3xl rounded-bl-3xl text-black shadow-xl">
                    <p className="mb-0">{questions[1].title}</p>
                    <p className="text-xs font-thin text-gray-500">
                      {/* {currentTime} */}
                    </p>
                  </div>
                </div>

                <div className="individual-room-ans-container justify-end flex gap-3 ml-6">
                  <div className=" py-10  relative flex flex-row items-center gap-2 text-black">
                    <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedValue}
                        onChange={handleChange}
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        onOpen={() => setIsOpen(true)}
                        inputProps={{ IconComponent: () => null }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {question?.map((ele, index) => (
                          <MenuItem key={index} value={JSON.stringify(ele)}>
                            {ele.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <ArrowSquareDown
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "5px",
                        transform: "translateY(-50%)",

                      }}
                      size="32"
                      color="#4c0f78"
                      variant="Bold"
                      onClick={(e) => handleIcon(e, 0, "dropdown")}
                    />
                  </div>
                </div>
              </div>
            )}
            {chat.length >= 2 && (
              <div className="messages-container min-h-[40vh]  px-6 overflow-auto  border-dashed border-b-2 ">
                {chat.map((ele, index) => (
                  <div key={index}>
                    {index > 0 &&
                      ele?.question?.code?.slice(0, 3) !==
                      chat[index - 1]?.question?.code?.slice(0, 3) && (
                        <div className="relative flex justify-center py-2">
                          <div className="p-2 px-4 bg-gray-300 text-center rounded-full absolute top-0 -mt-2">
                            <p className="text-sm font-thin text-grey-300">
                              <span className="text-violet-600 font-bold">
                                {getMessage(ele?.question?.code?.slice(0, 3))}
                              </span>
                            </p>
                          </div>
                        </div>
                      )}

                    <div key={index} className="my-6">
                      <div className="flex my-10">
                        <div>

                        </div>
                        <div className="ml-2 px-5 py-3 bg-gradient-to-r bg-[#f7daad] rounded-br-3xl rounded-tr-3xl rounded-bl-3xl text-black shadow-xl">
                          <p className="mb-0 text-right ">
                            {ele?.question?.title}
                          </p>
                          <p className="text-xs font-thin text-grey-300">
                            {/* {currentTime} */}
                          </p>
                        </div>
                      </div>
                      <div className="flex pl-5 pl- justify-end">
                        <button onClick={() => EditResponse(ele, index)}>
                          <Edit2
                            size="20"
                            color="#724CF9"
                            className="mt-4 cursor-pointer"
                          />
                        </button>

                        <div className=" py-3 flex">
                          {ele?.multiAns ? (
                            <div>
                              <div className="py-3 pr-5 pl-5 mb-2 bg-gradient-to-r bg-[#4c0f78] rounded-bl-3xl rounded-tl-3xl rounded-tr-3xl text-white shadow-xl">
                                <div>
                                  {ele?.multiAnsVal.map((multiAns, index) => (
                                    <React.Fragment key={index}>
                                      {multiAns.valText2 ? `${multiAns.valText1} :: ${multiAns.valText2} ` : `${multiAns.valText1}`}
                                      <br />
                                      {multiAns.mulValTextAddon && (
                                        <>
                                          {multiAns.mulValTextAddon}
                                          {index < ele.multiAnsVal.length - 1 && <br />}
                                        </>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </div>

                                {
                                  <p className="text-xs pt-2 pr-2 font-thin text-right text-white">
                                    {/* {currentTime} */}
                                  </p>
                                }
                              </div>
                            </div>
                          ) : (
                            <div className="rounded-md lg:px-1 pr-0 flex flex-col items-end justify-start w-full">
                              {/* Answer 1 */}
                              <div className="py-3 pr-5 pl-5 mb-2 bg-gradient-to-r bg-[#4c0f78] rounded-bl-3xl rounded-tl-3xl rounded-tr-3xl text-white shadow-xl">
                                <div className="rounded-md sm:px-4 lg:pr-5 xl:pr-8 pl-0 flex flex-col items-start justify-start w-full">
                                  {ele?.ans1 && (
                                    <div className="flex items-start">
                                      {/* <label>Answer 1:</label> */}
                                      <p className="pl-1 rounded mb-0 ml-2">
                                        {Array.isArray(ele?.ans)
                                          ? ele.ans.join(", ")
                                          : ele?.ans}
                                      </p>
                                    </div>
                                  )}
                                  {!ele?.ans1 && (
                                    <p className="pl-1 mb-0">
                                      {Array.isArray(ele?.ans)
                                        ? ele.ans.join(", ")
                                        : ele?.ans}
                                    </p>
                                  )}
                                </div>
                                {!ele?.ans1 && (
                                  <p className="text-xs pt-2 pr-2 font-thin text-right text-white">
                                    {/* {currentTime} */}
                                  </p>
                                )}
                              </div>

                              {/* Answer 2 */}
                              {ele?.ans1 && !ele?.ans2 && (
                                <div
                                  className={`mb-5 px-5 py-3 bg-gradient-to-r bg-[#4c0f78] rounded-bl-3xl rounded-tr-xl text-white shadow-xl ${ele?.ans2 ? "rounded-tr-3xl" : ""
                                    }`}
                                >
                                  <div className="rounded-md sm:px-4 lg:px-6 xl:px-8 flex flex-col items-start justify-start w-full">
                                    <div className="flex items-start">
                                      {/* <label>Answer 2:</label> */}
                                      <p
                                        className="pl-1 rounded mb-0 ml-2"
                                        style={{ overflowWrap: "break-word" }}
                                      >
                                        Quantity:{Array.isArray(ele?.ans1)
                                          ? ele.ans1.join(", ")
                                          : ele?.ans1}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="text-xs pt-2 pr-2 font-thin text-right text-white">
                                    {/* {currentTime} */}
                                  </p>
                                </div>
                              )}

                              {ele?.ans1 && ele?.ans2 && (
                                <div className="mb-2 px-5 py-2 bg-gradient-to-r bg-[#4c0f78] rounded-bl-xl rounded-tr-xl text-white shadow-xl">
                                  <div className="rounded-md sm:px-4 lg:px-6 xl:px-8 flex flex-col items-start justify-start w-full">
                                    <div className="flex items-start">
                                      {/* <label>Answer 2:</label> */}
                                      <p
                                        className="pl-1 rounded mb-0 ml-2"
                                        style={{ overflowWrap: "break-word" }}
                                      >
                                        Quantity:{Array.isArray(ele?.ans1)
                                          ? ele.ans1.join(", ")
                                          : ele?.ans1}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* Answer 3 */}
                              {ele?.addonAns && (
                                <div className="mb-2 px-5 py-2 bg-gradient-to-r bg-[#4c0f78] rounded-bl-xl rounded-tr-xl text-white shadow-xl">
                                  <div className="rounded-md sm:px-4 lg:px-6 xl:px-8 flex flex-col items-start justify-start w-full">
                                    <div className="flex items-start">
                                      {/* <label>Answer 2:</label> */}
                                      <p
                                        className="pl-1 rounded mb-0 ml-2"
                                        style={{ overflowWrap: "break-word" }}
                                      >
                                        {Array.isArray(ele?.addonAns)
                                          ? ele.addonAns.join(", ")
                                          : ele?.addonAns}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* Answer 3 */}
                              {ele?.ans2 && ele?.question?.renderable_type === "dropdown_and_quantity_and_days" && (
                                <div className="py-1 px-5 bg-gradient-to-r bg-[#4c0f78] rounded-bl-3xl rounded-tr-xl text-white shadow-xl">
                                  <div className="rounded-md sm:px-4 lg:px-6 xl:px-8 flex flex-col items-start justify-start w-full">
                                    <div className="flex items-start">
                                      {/* <label>Answer 3:</label> */}

                                      <p
                                        className="pl-1 rounded-b-lg mb-0 ml-2"
                                        style={{ overflowWrap: "break-word" }}
                                      >
                                        No. of Days: {Array.isArray(ele?.ans2)
                                          ? ele.ans2.join(", ")
                                          : ele?.ans2}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="text-xs pt-2 pr-2 font-thin text-right text-white">
                                    {/* {currentTime} */}
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {!loading && (
              <div className="rounded-bl-3xl  relative rounded-br-3xl  w-full justify-center">
                {showQuestion && currentQuestion && (
                  <div className="questionContainer  py-4 flex items-center pb-5 mb-5">

                    <p className="ml-2 px-5 py-3  bg-gradient-to-r bg-[#f7daad]  rounded-br-3xl rounded-tr-2xl rounded-bl-3xl text-black shadow-xl">
                      {currentQuestion?.title}
                    </p>
                  </div>
                )}
                {/* user Interaction box */}
                {(chat.length >= 2 || showInput) && (
                  <div
                    ref={chatContainerRef}
                    className={`flex gap-3 justify-end bg-slate-100  py-4   w-full flex-wrap questionContainer      ${currentQuestion?.renderable_type === "table"
                      ? "justify-center"
                      : " justify-end "
                      }`}
                  >
                    {showInput && (
                      <form
                        onSubmit={createRoom}
                        className=" relative  flex justify-end gap-2 bg-[#f2f2f200] p-2  w-full  "
                      >
                        <input
                          placeholder="Something ..."
                          onChange={(e) => setInputVal(e.target.value.trim())}
                          className="sm:w-[20rem] w-[15rem] pr-16 pl-3 rounded-md  py-2 outline-none border"
                        />
                        <button
                          className={` ${inputVal ? "" : "opacity-100"
                            } bg-[#ffffff] border w-14 absolute  rounded-r-md flex justify-center items-center py-1`}
                          disabled={!inputVal}
                        >
                          <Send
                            size="32"
                            color={inputVal ? "#4c0f78" : "#EBD9FC"}
                            variant="Bold"
                          />
                        </button>
                      </form>
                    )}

                    {/* dropdowns */}
                    {currentQuestion?.renderable_type === "dropdown" ? (
                      // single dropdown
                      <div className="flex sm:gap-4 gap-1 flex-row" >
                        {
                          mulVals?.map((mulVal, index) => (
                            <div key={index} className="flex  flex-col   gap-2 sm:mr-2">
                              <div className="flex justify-center items-center relative">
                                <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                  <Select
                                    value={ensureDefinedValue(JSON.stringify({ value: mulVal?.valText1, id: mulVal?.val1 }), JSON.stringify({ value: "", id: "" }))}
                                    onChange={(e) => {
                                      const selectedValue = JSON.parse(e.target.value);
                                      // Instead of using `e.target.options`, safely extract the selected text from the element
                                      const selectedText = currentQuestion?.possible_answers?.dropdown.find(
                                        (ele) => ele.value === selectedValue.value
                                      )?.value;
                                      handleMulValChange(
                                        index,
                                        "val1",
                                        "valText1",
                                        selectedValue.value,
                                        selectedValue.id
                                      );
                                      setMulValText1(selectedText || ""); // Fallback to an empty string if undefined
                                      setMulVal1(selectedValue || "");  // Assuming this is for another value related to the selection
                                    }}
                                    inputProps={{
                                      IconComponent: () => null
                                    }} // this part
                                    displayEmpty
                                    open={mulVal.isOpen} // Use the specific state for this dropdown
                                    onClose={() => handleDropdownToggle(index, "isOpen")}
                                    onOpen={() => handleDropdownToggle(index, "isOpen")}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 300, // Set the max height for the dropdown
                                          maxWidth: 300,
                                          overflowY: 'auto',
                                        },
                                      },
                                    }}
                                  >
                                    <MenuItem value={JSON.stringify({ value: "", id: "" })} disabled>
                                      Select...
                                    </MenuItem>
                                    {currentQuestion.possible_answers.dropdown.map((ele, i) => {
                                      const category = determineCategory(ele.value);
                                      const isCategoryChanged = category !== lastCategory;
                                      lastCategory = category;

                                      return [
                                        isCategoryChanged && (
                                          <MenuItem disabled style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                                            {category}
                                          </MenuItem>
                                        ),
                                        <MenuItem
                                          value={JSON.stringify({ value: ele.value, id: ele.id })}
                                          // key={i}
                                          sx={{
                                            whiteSpace: 'normal',       // Allow the text to wrap
                                            wordWrap: 'break-word',     // Break long words if necessary
                                            overflowWrap: 'break-word', // Break words at appropriate places
                                          }}
                                        >
                                          {ele.value}
                                        </MenuItem>
                                      ];
                                    })}
                                  </Select>
                                  <ArrowSquareDown
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "5px",
                                      transform: "translateY(-50%)",
                                    }}
                                    size="32"
                                    color="#4c0f78"
                                    variant="Bold"
                                    onClick={(e) => handleIcon(e, index, "isOpen")}
                                  />
                                </FormControl>
                              </div>
                              {currentQuestion?.possible_answers?.addOnDropdown
                                .length > 0 && (
                                  <div className="flex  justify-center items-center relative">
                                    <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                      <Select
                                        value={ensureDefinedValue(JSON.stringify({ value: mulVal?.mulValTextAddon, id: mulVal?.mulValAddon }), JSON.stringify({ value: "", id: "" }))}
                                        onChange={(e) => {
                                          const selectedValue = JSON.parse(e.target.value);
                                          const selectedText = currentQuestion?.possible_answers?.addOnDropdown.find(
                                            (ele) => ele.value === selectedValue.value)?.value;
                                          handleMulValChange(
                                            index,
                                            "mulValAddon",
                                            "mulValTextAddon",
                                            selectedValue.value,
                                            selectedValue.id
                                          )
                                          setMulValTextAddon(selectedText || "");
                                          setMulValAddon(e.target.value);
                                        }}
                                        inputProps={{ IconComponent: () => null }} // this part
                                        displayEmpty
                                        open={mulVal.isOpen3} // Use the specific state for this dropdown
                                        onClose={() => handleDropdownToggle(index, "isOpen3")}
                                        onOpen={() => handleDropdownToggle(index, "isOpen3")}
                                        MenuProps={{
                                          PaperProps: {
                                            style: {
                                              maxHeight: 300, // Set the max height for the dropdown
                                              maxWidth: 300,
                                              overflowY: 'auto',
                                            },
                                          },
                                        }}
                                      >
                                        <MenuItem disabled value={JSON.stringify({ value: "", id: "" })}>
                                          Select Add ons
                                        </MenuItem>
                                        {currentQuestion.possible_answers?.addOnDropdown.map(
                                          (ele, i) => (
                                            <MenuItem
                                              value={JSON.stringify({ value: ele.value, id: ele.id })}
                                              key={i}
                                              sx={{
                                                whiteSpace: 'normal',       // Allow the text to wrap
                                                wordWrap: 'break-word',     // Break long words if necessary
                                                overflowWrap: 'break-word', // Break words at appropriate places
                                              }}
                                            >
                                              {ele?.value}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                      <ArrowSquareDown
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          right: "5px",
                                          transform: "translateY(-50%)",
                                        }}
                                        size="32"
                                        color="#4c0f78"
                                        variant="Bold"
                                        onClick={(e) => handleIcon(e, index, "isOpen3")}
                                      />
                                    </FormControl>
                                  </div>
                                )}
                            </div>
                          ))

                        }
                        <div className="flex">
                          <button onClick={PostMulAns}>
                            <TickCircle size="32" color="#00cc00" />
                          </button>
                        </div>

                      </div>
                    ) : currentQuestion?.renderable_type ===
                      "dropdown_multi_value_and_multi_value" ? (
                      // 2 dropdowns and addons
                      <div className="flex-col flex  md:gap-4">
                        {mulVals?.map((mulVal, index) => (
                          <div key={index} className="flex flex-col  gap-2">
                            {/* Dropdown 1 */}
                            <div className="flex justify-center items-center relative">
                              <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                  value={ensureDefinedValue(JSON.stringify({ value: mulVal?.valText1, id: mulVal?.val1 }), JSON.stringify({ value: "", id: "" }))}
                                  onChange={(e) => {
                                    const selectedValue = JSON.parse(e.target.value);
                                    // Instead of using `e.target.options`, safely extract the selected text from the element
                                    const selectedText = currentQuestion?.possible_answers?.dropdown.find(
                                      (ele) => ele.value === selectedValue.value
                                    )?.value;
                                    handleMulValChange(
                                      index,
                                      "val1",
                                      "valText1",
                                      selectedValue.value,
                                      selectedValue.id
                                    );
                                    setMulValText1(selectedText || ""); // Fallback to an empty string if undefined
                                    setMulVal1(selectedValue || "");  // Assuming this is for another value related to the selection
                                  }}
                                  inputProps={{
                                    IconComponent: () => null
                                  }} // this part
                                  displayEmpty
                                  open={mulVal.isOpen} // Use the specific state for this dropdown
                                  onClose={() => handleDropdownToggle(index, "isOpen")}
                                  onOpen={() => handleDropdownToggle(index, "isOpen")}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 300, // Set the max height for the dropdown
                                        maxWidth: 300,
                                        overflowY: 'auto',
                                      },
                                    },
                                  }}
                                >
                                  <MenuItem value={JSON.stringify({ value: "", id: "" })} disabled>
                                    Select...
                                  </MenuItem>

                                  {currentQuestion.possible_answers.dropdown.map((ele, i) => {
                                    const category = determineCategory(ele.value);
                                    const isCategoryChanged = category !== lastCategory;
                                    lastCategory = category;

                                    return [
                                      isCategoryChanged && (
                                        <MenuItem disabled style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                                          {category}
                                        </MenuItem>
                                      ),
                                      <MenuItem
                                        value={JSON.stringify({ value: ele.value, id: ele.id })}
                                        sx={{
                                          whiteSpace: 'normal',       // Allow the text to wrap
                                          wordWrap: 'break-word',     // Break long words if necessary
                                          overflowWrap: 'break-word', // Break words at appropriate places
                                        }}
                                      >
                                        {ele.value}
                                      </MenuItem>
                                    ];
                                  })}
                                </Select>
                                <ArrowSquareDown
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "5px",
                                    transform: "translateY(-50%)",
                                  }}
                                  size="32"
                                  color="#4c0f78"
                                  variant="Bold"
                                  onClick={(e) => handleIcon(e, index, "isOpen")}
                                />
                              </FormControl>
                            </div>

                            {/* Dropdown 2 */}
                            {currentQuestion?.title.includes("contents") && mulVal?.valText1?.includes("Protect") && (
                              <div className="flex  justify-center items-center relative">
                                <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                  <Select
                                    value={ensureDefinedValue(JSON.stringify({ value: mulVal?.valText2, id: mulVal?.val2 }), JSON.stringify({ value: "", id: "" }))}
                                    onChange={(e) => {
                                      const selectedValue = JSON.parse(e.target.value);
                                      const selectedText = currentQuestion?.possible_answers
                                        ?.second_values.find(
                                          (ele) => ele.value === selectedValue.value
                                        )?.value;
                                      handleMulValChange(
                                        index,
                                        "val2",
                                        "valText2",
                                        selectedValue.value,
                                        selectedValue.id
                                      );
                                      setMulValText2(selectedText || ""); // Use the corresponding text or fallback to an empty string
                                      setMulVal2(e.target.value); // Assuming this is for another value related to the selection
                                    }}
                                    inputProps={{
                                      IconComponent: () => null
                                    }} // this part
                                    open={mulVal.isOpen2} // Use the specific state for this dropdown
                                    onClose={() => handleDropdownToggle(index, "isOpen2")}
                                    onOpen={() => handleDropdownToggle(index, "isOpen2")}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 300, // Set the max height for the dropdown
                                          maxWidth: 300,
                                          overflowY: 'auto',
                                        },
                                      },
                                    }}
                                  >
                                    <MenuItem disabled value={JSON.stringify({ value: "", id: "" })}>
                                      Select quantities
                                    </MenuItem>
                                    {currentQuestion?.possible_answers?.second_values?.map(
                                      (ele, i) => (
                                        <MenuItem
                                          value={JSON.stringify({ value: ele?.value, id: ele?.id })}
                                          key={i}
                                          sx={{
                                            whiteSpace: 'normal',       // Allow the text to wrap
                                            wordWrap: 'break-word',     // Break long words if necessary
                                            overflowWrap: 'break-word', // Break words at appropriate places
                                          }}
                                        >
                                          {ele?.value}
                                        </MenuItem>
                                      )
                                    )}{" "}
                                  </Select>
                                  <ArrowSquareDown
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "5px",
                                      transform: "translateY(-50%)",
                                    }}
                                    size="32"
                                    color="#4c0f78"
                                    variant="Bold"
                                    onClick={(e) => handleIcon(e, index, "isOpen2")}
                                  />
                                </FormControl>
                              </div>
                            )}
                            {/* Add on Dropdown */}
                            {currentQuestion?.possible_answers?.addOnDropdown
                              .length > 0 && (
                                <div className="flex justify-center items-center relative">
                                  <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                    <Select
                                      value={ensureDefinedValue(JSON.stringify({ value: mulVal?.mulValTextAddon, id: mulVal?.mulValAddon }), JSON.stringify({ value: "", id: "" }))}
                                      onChange={(e) => {
                                        const selectedValue = JSON.parse(e.target.value);
                                        const selectedText = currentQuestion?.possible_answers?.addOnDropdown.find(
                                          (ele) => ele.value === selectedValue.value)?.value;
                                        handleMulValChange(
                                          index,
                                          "mulValAddon",
                                          "mulValTextAddon",
                                          selectedValue.value,
                                          selectedValue.id
                                        )
                                        setMulValTextAddon(selectedText || "");
                                        setMulValAddon(e.target.value);
                                      }}
                                      inputProps={{ IconComponent: () => null }} // this part
                                      displayEmpty
                                      open={mulVal.isOpen3} // Use the specific state for this dropdown
                                      onClose={() => handleDropdownToggle(index, "isOpen3")}
                                      onOpen={() => handleDropdownToggle(index, "isOpen3")}
                                      MenuProps={{
                                        PaperProps: {
                                          style: {
                                            maxHeight: 300, // Set the max height for the dropdown
                                            maxWidth: 300,
                                            overflowY: 'auto',
                                          },
                                        },
                                      }}
                                    >
                                      <MenuItem value={JSON.stringify({ value: "", id: "" })} disabled>
                                        Select Addons
                                      </MenuItem>
                                      {currentQuestion.possible_answers?.addOnDropdown.map((ele, i) => {
                                        const trimmedValue = ele.value.replace(/^ADD-ON:\s*/, '');
                                        return (
                                          <MenuItem
                                            key={`item-addon-${i}`}
                                            value={JSON.stringify({ value: ele.value, id: ele.id })}
                                            sx={{
                                              whiteSpace: 'normal',       // Allow the text to wrap
                                              wordWrap: 'break-word',     // Break long words if necessary
                                              overflowWrap: 'break-word', // Break words at appropriate places
                                            }}
                                          >
                                            {trimmedValue}
                                          </MenuItem>
                                        )
                                      })}
                                    </Select>
                                    <ArrowSquareDown
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        right: "5px",
                                        transform: "translateY(-50%)",
                                      }}
                                      size="32"
                                      color="#4c0f78"
                                      variant="Bold"
                                      onClick={(e) => handleIcon(e, index, "isOpen3")}

                                    />
                                  </FormControl>
                                </div>
                              )}

                            <div className="flex flex-row place-items-center">
                              {index === mulVals.length - 1 && (
                                <>
                                  <button onClick={handleAddCircle}>
                                    <AddCircle size="32" color="#FF8A65" />
                                  </button>
                                  <button onClick={PostMulAns}>
                                    <TickCircle size="32" color="#00cc00" />
                                  </button>
                                </>
                              )}
                              {index > 0 && index === mulVals.length - 1 && (
                                <button onClick={() => handleRemoveCircle(index)}>
                                  <CloseCircle size="32" color="#ff0000" />
                                </button>
                              )}
                              <ToastContainer />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : currentQuestion?.renderable_type ===
                      "dropdown_and_quantity_and_days" ? (
                      // 3 dropdowns with days and addon
                      <div className="flex-col flex md:gap-2 gap-1 ">
                        {mulVals?.map((mulVal, index) => (
                          <div key={index} className="flex flex-col  gap-2">
                            {/* Dropdown 1 */}
                            <div className="flex justify-center items-center relative">
                              <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                  value={ensureDefinedValue(JSON.stringify({ value: mulVal?.valText1, id: mulVal?.val1 }), JSON.stringify({ value: "", id: "" }))}
                                  onChange={(e) => {
                                    const selectedValue = JSON.parse(e.target.value);
                                    // Instead of using `e.target.options`, safely extract the selected text from the element
                                    const selectedText = currentQuestion?.possible_answers?.dropdown.find(
                                      (ele) => ele.value === selectedValue.value
                                    )?.value;
                                    handleMulValChange(
                                      index,
                                      "val1",
                                      "valText1",
                                      selectedValue.value,
                                      selectedValue.id
                                    );
                                    setMulValText1(selectedText || ""); // Fallback to an empty string if undefined
                                    setMulVal1(selectedValue || "");  // Assuming this is for another value related to the selection
                                  }}
                                  inputProps={{
                                    IconComponent: () => null
                                  }} // this part
                                  displayEmpty
                                  open={mulVal.isOpen} // Use the specific state for this dropdown
                                  onClose={() => handleDropdownToggle(index, "isOpen")}
                                  onOpen={() => handleDropdownToggle(index, "isOpen")}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 300, // Set the max height for the dropdown
                                        maxWidth: 300,
                                        overflowY: 'auto',
                                      },
                                    },
                                  }}
                                >
                                  <MenuItem value={JSON.stringify({ value: "", id: "" })} disabled>
                                    Select...
                                  </MenuItem>
                                  {Array.isArray(currentQuestion.possible_answers.dropdown) &&
                                    currentQuestion.possible_answers.dropdown.map((ele, i) => (
                                      <MenuItem
                                        value={JSON.stringify({ value: ele.value, id: ele.id })}
                                        key={i}
                                        sx={{
                                          whiteSpace: 'normal',       // Allow the text to wrap
                                          wordWrap: 'break-word',     // Break long words if necessary
                                          overflowWrap: 'break-word', // Break words at appropriate places
                                        }}
                                      >
                                        {ele.value}
                                      </MenuItem>

                                    ))}
                                </Select>
                                <ArrowSquareDown
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "5px",
                                    transform: "translateY(-50%)",
                                  }}
                                  size="32"
                                  color="#4c0f78"
                                  variant="Bold"
                                  onClick={(e) => handleIcon(e, index, "isOpen")}
                                />
                              </FormControl>
                            </div>

                            {/* Dropdown 2 */}
                            <div className="flex justify-center items-center relative">
                              <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                  value={ensureDefinedValue(JSON.stringify({ value: mulVal?.valText2, id: mulVal?.val2 }), JSON.stringify({ value: "", id: "" }))}
                                  onChange={(e) => {
                                    const selectedValue = JSON.parse(e.target.value);
                                    const selectedText = currentQuestion?.possible_answers
                                      ?.second_values.find(
                                        (ele) => ele.value === selectedValue.value
                                      )?.value;
                                    handleMulValChange(
                                      index,
                                      "val2",
                                      "valText2",
                                      selectedValue.value,
                                      selectedValue.id
                                    );
                                    setMulValText2(selectedText || ""); // Use the corresponding text or fallback to an empty string
                                    setMulVal2(e.target.value); // Assuming this is for another value related to the selection
                                  }}
                                  displayEmpty
                                  inputProps={{
                                    IconComponent: () => null
                                  }} // this part
                                  open={mulVal.isOpen2} // Use the specific state for this dropdown
                                  onClose={() => handleDropdownToggle(index, "isOpen2")}
                                  onOpen={() => handleDropdownToggle(index, "isOpen2")}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 300, // Set the max height for the dropdown
                                        maxWidth: 300,
                                        overflowY: 'auto',
                                      },
                                    },
                                  }}
                                >
                                  <MenuItem value={JSON.stringify({ value: "", id: "" })} disabled>
                                    Select quantities...
                                  </MenuItem>
                                  {currentQuestion.possible_answers.second_values.map((ele, i) => (
                                    <MenuItem
                                      key={`item-quant-${i}`}
                                      value={JSON.stringify({ value: ele.value, id: ele.id })}
                                      sx={{
                                        whiteSpace: 'normal',       // Allow the text to wrap
                                        wordWrap: 'break-word',     // Break long words if necessary
                                        overflowWrap: 'break-word', // Break words at appropriate places
                                      }}
                                    >
                                      {ele.value}
                                    </MenuItem>
                                  )
                                  )}
                                </Select>
                                <ArrowSquareDown
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "5px",
                                    transform: "translateY(-50%)",
                                  }}
                                  size="32"
                                  color="#4c0f78"
                                  variant="Bold"
                                  onClick={(e) => handleIcon(e, index, "isOpen2")}
                                />
                              </FormControl>
                            </div>
                            <div className="flex justify-center items-center relative">
                              <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                  value={ensureDefinedValue(JSON.stringify({ value: mulVal?.valText3, id: mulVal?.val3 }), JSON.stringify({ value: "", id: "" }))}
                                  onChange={(e) => {
                                    const selectedValue = JSON.parse(e.target.value);
                                    const selectedText = currentQuestion.possible_answers.third_values.find(
                                      (ele) => ele.value === selectedValue.value)?.value;
                                    handleMulValChange(
                                      index,
                                      "val3",
                                      "valText3",
                                      selectedValue.value,
                                      selectedValue.id
                                    );
                                    const value = JSON.parse(e.target.value).value;
                                    setMulValText3(selectedText || "");
                                    setMulVal3(e.target.value);
                                  }}
                                  inputProps={{ IconComponent: () => null }} // this part
                                  displayEmpty
                                  open={mulVal.isOpen3} // Use the specific state for this dropdown
                                  onClose={() => handleDropdownToggle(index, "isOpen3")}
                                  onOpen={() => handleDropdownToggle(index, "isOpen3")}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 300, // Set the max height for the dropdown
                                        maxWidth: 300,
                                        overflowY: 'auto',
                                      },
                                    },
                                  }}
                                >
                                  <MenuItem value={JSON.stringify({ value: "", id: "" })} disabled>
                                    Select # of days ...
                                  </MenuItem>
                                  {currentQuestion.possible_answers.third_values.map((ele, i) => (
                                    <MenuItem
                                      key={`item-days-${i}`}
                                      value={JSON.stringify({ value: ele.value, id: ele.id })}
                                      sx={{
                                        whiteSpace: 'normal',       // Allow the text to wrap
                                        wordWrap: 'break-word',     // Break long words if necessary
                                        overflowWrap: 'break-word', // Break words at appropriate places
                                      }}
                                    >
                                      {ele.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <ArrowSquareDown
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "5px",
                                    transform: "translateY(-50%)",
                                  }}
                                  size="32"
                                  color="#4c0f78"
                                  variant="Bold"
                                  onClick={(e) => handleIcon(e, index, "isOpen3")}
                                />
                              </FormControl>
                            </div>

                            {/* Add on Dropdown */}
                            {currentQuestion?.possible_answers?.addOnDropdown.length > 0 && (
                              <div className="flex justify-center items-center relative">
                                <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                  <Select
                                    value={ensureDefinedValue(JSON.stringify({ value: mulVal?.mulValTextAddon, id: mulVal?.mulValAddon }), JSON.stringify({ value: "", id: "" }))}
                                    onChange={(e) => {
                                      const selectedValue = JSON.parse(e.target.value);
                                      const selectedText = currentQuestion?.possible_answers?.addOnDropdown.find(
                                        (ele) => ele.value === selectedValue.value)?.value;
                                      handleMulValChange(
                                        index,
                                        "mulValAddon",
                                        "mulValTextAddon",
                                        selectedValue.value,
                                        selectedValue.id
                                      )
                                      setMulValTextAddon(selectedText || "");
                                      setMulValAddon(e.target.value);
                                    }}
                                    inputProps={{ IconComponent: () => null }} // this part
                                    displayEmpty
                                    open={mulVal.isOpen3} // Use the specific state for this dropdown
                                    onClose={() => handleDropdownToggle(index, "isOpen3")}
                                    onOpen={() => handleDropdownToggle(index, "isOpen3")}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 300, // Set the max height for the dropdown
                                          maxWidth: 300,
                                          overflowY: 'auto',
                                        },
                                      },
                                    }}
                                  >
                                    <MenuItem value={JSON.stringify({ value: "", id: "" })} disabled>
                                      Select Addons
                                    </MenuItem>
                                    {currentQuestion.possible_answers.addOnDropdown.map(
                                      (ele, i) => (
                                        <MenuItem
                                          key={`item-addon-${i}`}
                                          value={JSON.stringify({ value: ele.value, id: ele.id })}
                                          sx={{
                                            whiteSpace: 'normal',       // Allow the text to wrap
                                            wordWrap: 'break-word',     // Break long words if necessary
                                            overflowWrap: 'break-word', // Break words at appropriate places
                                          }}
                                        >
                                          {ele.value}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                  <ArrowSquareDown
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "5px",
                                      transform: "translateY(-50%)",
                                    }}
                                    size="32"
                                    color="#4c0f78"
                                    variant="Bold"
                                    onClick={(e) => handleIcon(e, index, "isOpen3")}

                                  />
                                </FormControl>
                              </div>
                            )}
                            {/* </div> */}
                            <div className="flex flex-row  place-items-center">
                              {index === mulVals.length - 1 && (
                                <>
                                  <button onClick={handleAddCircle}>
                                    <AddCircle size="32" color="#FF8A65" />
                                  </button>
                                  <button onClick={PostMulQtyAndDays}>
                                    <TickCircle size="32" color="#00cc00" />
                                  </button>
                                </>
                              )}
                              {index > 0 && index === mulVals.length - 1 && (
                                <button onClick={() => handleRemoveCircle(index)}>
                                  <CloseCircle size="32" color="#ff0000" />
                                </button>
                              )}
                            </div>
                            <ToastContainer />
                          </div>
                        ))}

                      </div>

                    ) : currentQuestion?.renderable_type ===
                      "dropdown_and_quantity" ? (
                      // 3 dropdowns with days and addon
                      <div className="flex-col flex md:gap-2 gap-1 ">
                        {mulVals?.map((mulVal, index) => (
                          <>
                            <div key={index} className="flex flex-col  gap-2">
                              {/* first dropdown */}
                              <div className="flex justify-center items-center relative">
                                <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                  <Select
                                    value={ensureDefinedValue(JSON.stringify({ value: mulVal?.valText1, id: mulVal?.val1 }), JSON.stringify({ value: "", id: "" }))}
                                    onChange={(e) => {
                                      const selectedValue = JSON.parse(e.target.value);
                                      // Instead of using `e.target.options`, safely extract the selected text from the element
                                      const selectedText = currentQuestion?.possible_answers?.dropdown.find(
                                        (ele) => ele.value === selectedValue.value
                                      )?.value;
                                      handleMulValChange(
                                        index,
                                        "val1",
                                        "valText1",
                                        selectedValue.value,
                                        selectedValue.id
                                      );
                                      setMulValText1(selectedText || ""); // Fallback to an empty string if undefined
                                      setMulVal1(selectedValue || "");  // Assuming this is for another value related to the selection
                                    }}
                                    inputProps={{
                                      IconComponent: () => null
                                    }} // this part
                                    displayEmpty
                                    open={mulVal.isOpen} // Use the specific state for this dropdown
                                    onClose={() => handleDropdownToggle(index, "isOpen")}
                                    onOpen={() => handleDropdownToggle(index, "isOpen")}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 300, // Set the max height for the dropdown
                                          maxWidth: 300,
                                          overflowY: 'auto',
                                        },
                                      },
                                    }}
                                  >
                                    <MenuItem value={JSON.stringify({ value: "", id: "" })} disabled>
                                      Select...
                                    </MenuItem>
                                    {currentQuestion?.possible_answers?.dropdown.map((ele, i) => {
                                      const category = determineCategory(ele.value);
                                      const isCategoryChanged = category !== lastCategory;
                                      console.log(lastCategory, "lastCategory")
                                      lastCategory = category;
                                      return [
                                        isCategoryChanged && (
                                          <MenuItem
                                            key={`category-${i}`}
                                            disabled
                                            style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}
                                          >
                                            {category}
                                          </MenuItem>
                                        ),
                                        <MenuItem
                                          key={`item-${ele.id}`}
                                          value={JSON.stringify({ value: ele.value, id: ele.id })}
                                          sx={{
                                            whiteSpace: 'normal',       // Allow the text to wrap
                                            wordWrap: 'break-word',     // Break long words if necessary
                                            overflowWrap: 'break-word', // Break words at appropriate places
                                          }}
                                        >
                                          {ele.value}
                                        </MenuItem>
                                      ];
                                    })}
                                  </Select>
                                  <ArrowSquareDown
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "5px",
                                      transform: "translateY(-50%)",
                                    }}
                                    size="32"
                                    color="#4c0f78"
                                    variant="Bold"
                                    onClick={(e) => handleIcon(e, index, "isOpen")}
                                  />
                                </FormControl>
                              </div>
                              {/* second dropdown */}
                              <div className="flex justify-center items-center">
                                <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                  <Select
                                    value={ensureDefinedValue(JSON.stringify({ value: mulVal?.valText2, id: mulVal?.val2 }), JSON.stringify({ value: "", id: "" }))}
                                    onChange={(e) => {
                                      const selectedValue = JSON.parse(e.target.value);
                                      const selectedText = currentQuestion?.possible_answers
                                        ?.second_values.find(
                                          (ele) => ele.value === selectedValue.value
                                        )?.value;
                                      handleMulValChange(
                                        index,
                                        "val2",
                                        "valText2",
                                        selectedValue.value,
                                        selectedValue.id
                                      );
                                      setMulValText2(selectedText || ""); // Use the corresponding text or fallback to an empty string
                                      setMulVal2(e.target.value); // Assuming this is for another value related to the selection
                                    }}
                                    displayEmpty
                                    inputProps={{
                                      IconComponent: () => null
                                    }} // this part
                                    open={mulVal.isOpen2} // Use the specific state for this dropdown
                                    onClose={() => handleDropdownToggle(index, "isOpen2")}
                                    onOpen={() => handleDropdownToggle(index, "isOpen2")}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 300, // Set the max height for the dropdown
                                          maxWidth: 300,
                                          overflowY: 'auto',
                                        },
                                      },
                                    }}
                                  >
                                    <MenuItem value={JSON.stringify({ value: "", id: "" })} disabled>
                                      Select quantities...
                                    </MenuItem>
                                    {currentQuestion.possible_answers?.second_values.map(
                                      (ele, i) => (
                                        <MenuItem
                                          key={`item-quant-${i}`}
                                          value={JSON.stringify({ value: ele.value, id: ele.id })}
                                          sx={{
                                            whiteSpace: 'normal',       // Allow the text to wrap
                                            wordWrap: 'break-word',     // Break long words if necessary
                                            overflowWrap: 'break-word', // Break words at appropriate places
                                          }}
                                        >
                                          {ele.value}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                  <ArrowSquareDown
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "5px",
                                      transform: "translateY(-50%)",
                                    }}
                                    size="32"
                                    color="#4c0f78"
                                    variant="Bold"
                                    onClick={(e) => handleIcon(e, index, "isOpen2")}

                                  />
                                </FormControl>
                              </div>

                              {/* Addon Dropdown */}
                              {currentQuestion?.possible_answers?.addOnDropdown
                                .length > 0 && (
                                  <div className="flex justify-center items-center relative">
                                    <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                      <Select
                                        value={ensureDefinedValue(JSON.stringify({ value: mulVal?.mulValTextAddon, id: mulVal?.mulValAddon }), JSON.stringify({ value: "", id: "" }))}
                                        onChange={(e) => {
                                          const selectedValue = JSON.parse(e.target.value);
                                          const selectedText = currentQuestion?.possible_answers?.addOnDropdown.find(
                                            (ele) => ele.value === selectedValue.value)?.value;
                                          handleMulValChange(
                                            index,
                                            "mulValAddon",
                                            "mulValTextAddon",
                                            selectedValue.value,
                                            selectedValue.id
                                          )
                                          setMulValTextAddon(selectedText || "");
                                          setMulValAddon(e.target.value);
                                        }}
                                        inputProps={{ IconComponent: () => null }} // this part
                                        displayEmpty
                                        open={mulVal.isOpen3} // Use the specific state for this dropdown
                                        onClose={() => handleDropdownToggle(index, "isOpen3")}
                                        onOpen={() => handleDropdownToggle(index, "isOpen3")}
                                        MenuProps={{
                                          PaperProps: {
                                            style: {
                                              maxHeight: 300, // Set the max height for the dropdown
                                              maxWidth: 300,
                                              overflowY: 'auto',
                                            },
                                          },
                                        }}
                                      >
                                        <MenuItem value={JSON.stringify({ value: "", id: "" })} disabled>
                                          Select Addons
                                        </MenuItem>
                                        {currentQuestion.possible_answers.addOnDropdown.map(
                                          (ele, i) => (
                                            <MenuItem
                                              key={`item-addon-${i}`}
                                              value={JSON.stringify({ value: ele.value, id: ele.id })}
                                              sx={{
                                                whiteSpace: 'normal',       // Allow the text to wrap
                                                wordWrap: 'break-word',     // Break long words if necessary
                                                overflowWrap: 'break-word', // Break words at appropriate places
                                              }}
                                            >
                                              {ele.value}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                      <ArrowSquareDown
                                        style={{
                                          position: "absolute",
                                          top: "50%",
                                          right: "5px",
                                          transform: "translateY(-50%)",
                                        }}
                                        size="32"
                                        color="#4c0f78"
                                        variant="Bold"
                                        onClick={(e) => handleIcon(e, index, "isOpen3")}

                                      />
                                    </FormControl>
                                  </div>
                                )}
                            </div>
                            <div className="flex flex-row  place-items-center">
                              {index === mulVals.length - 1 && (
                                <>
                                  <button onClick={handleAddCircle}>
                                    <AddCircle size="32" color="#FF8A65" />
                                  </button>
                                  <button onClick={PostMulAddQtyAndDays}>
                                    <TickCircle size="32" color="#00cc00" />
                                  </button>
                                </>
                              )}
                              {index > 0 && index === mulVals.length - 1 && (
                                <button onClick={() => handleRemoveCircle(index)}>
                                  <CloseCircle size="32" color="#ff0000" />
                                </button>
                              )}
                            </div>
                          </>
                        ))}
                      </div>
                    ) : currentQuestion?.renderable_type ===
                      "dropdown_and_quantity_and_afterhours" ? (
                      // 3 dropdowns with hours and addons
                      <div className="flex-col flex md:gap-2 gap-1">
                        <div className="flex flex-col gap-2">
                          <div className="flex justify-center items-center relative">
                            <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                              <Select
                                // value={mulValText1 || ""}
                                onChange={(e) => {
                                  const selectedValue = JSON.parse(e.target.value);
                                  // Instead of using `e.target.options`, safely extract the selected text from the element
                                  const selectedText = currentQuestion?.possible_answers?.dropdown.find(
                                    (ele) => ele.value === selectedValue.value
                                  )?.value;
                                  setMulValText1(selectedText || ""); // Fallback to an empty string if undefined
                                  setMulVal1(selectedValue || "");  // Assuming this is for another value related to the selection
                                }}
                                inputProps={{
                                  IconComponent: () => null
                                }} // this part
                                displayEmpty
                                open={isOpenDropdown1} // Use the specific state for this dropdown
                                onClose={() => setIsOpenDropdown1(false)}
                                onOpen={() => setIsOpenDropdown1(true)}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300, // Set the max height for the dropdown
                                      maxWidth: 300,
                                      overflowY: 'auto',
                                    },
                                  },
                                }}
                              >
                                <MenuItem disabled value="">
                                  Select...
                                </MenuItem>
                                {currentQuestion.possible_answers.dropdown.map(
                                  (ele, i) => (
                                    <MenuItem value={ele.id} key={i}>
                                      {ele.value}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                            <ArrowSquareDown
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "5px",
                                transform: "translateY(-50%)",
                              }}
                              size="32"
                              color="#4c0f78"
                              variant="Bold"
                              onClick={() => setIsOpenDropdown1(true)}
                            />
                          </div>
                          <div className="flex justify-center items-center relative">
                            <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                              <Select
                                // value={mulValText2 || ""}
                                onChange={(e) => {
                                  // const selectedValue = JSON.parse(e.target.value);
                                  // Instead of using `e.target.options`, safely extract the selected text from the element
                                  const selectedText = currentQuestion?.possible_answers?.second_values.find(
                                    (ele) => ele.value === selectedValue.value
                                  )?.value;
                                  setMulValText2(
                                    selectedText || ""
                                  );
                                  setMulVal2(e.target.value);
                                }}
                                inputProps={{
                                  IconComponent: () => null
                                }} // this part
                                displayEmpty
                                open={isOpenDropdown2} // Use the specific state for this dropdown
                                onClose={() => setIsOpenDropdown2(false)}
                                onOpen={() => setIsOpenDropdown2(true)}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300, // Set the max height for the dropdown
                                      maxWidth: 300,
                                      overflowY: 'auto',
                                    },
                                  },
                                }}
                              >
                                <MenuItem disabled value="">
                                  Select quantities ...
                                </MenuItem>
                                {currentQuestion.possible_answers.second_values.map(
                                  (ele, i) => (
                                    <MenuItem
                                      value={ele.id}
                                      key={i}
                                      sx={{
                                        whiteSpace: 'normal',       // Allow the text to wrap
                                        wordWrap: 'break-word',     // Break long words if necessary
                                        overflowWrap: 'break-word', // Break words at appropriate places
                                      }}
                                    >
                                      {ele.value}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                              <ArrowSquareDown
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "5px",
                                  transform: "translateY(-50%)",
                                }}
                                size="32"
                                color="#4c0f78"
                                variant="Bold"
                                onClick={() => setIsOpenDropdown2(true)}
                              />
                            </FormControl>
                          </div>

                          <div className="flex justify-center items-center relative">
                            <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                              <Select
                                onChange={(e) => {
                                  const selectedValue = JSON.parse(e.target.value);
                                  // Instead of using `e.target.options`, safely extract the selected text from the element
                                  const selectedText = currentQuestion.possible_answers.third_values.find(
                                    (ele) => ele.value === selectedValue.value)?.value;
                                  setMulValText3(
                                    selectedText
                                  );
                                  setMulVal3(e.target.value);
                                }}
                                inputProps={{
                                  IconComponent: () => null
                                }} // this part
                                displayEmpty
                                open={isOpenDropdown3} // Use the specific state for this dropdown
                                onClose={() => setIsOpenDropdown3(false)}
                                onOpen={() => setIsOpenDropdown3(true)}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 300, // Set the max height for the dropdown
                                      maxWidth: 300,
                                      overflowY: 'auto',
                                    },
                                  },
                                }}
                              >
                                <MenuItem disabled value="">
                                  Select hours ...
                                </MenuItem>
                                {currentQuestion.possible_answers.third_values.map(
                                  (ele, i) => (
                                    <MenuItem
                                      value={ele.id}
                                      key={i}
                                      sx={{
                                        whiteSpace: 'normal',       // Allow the text to wrap
                                        wordWrap: 'break-word',     // Break long words if necessary
                                        overflowWrap: 'break-word', // Break words at appropriate places
                                      }}
                                    >
                                      {ele.label}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                              <ArrowSquareDown
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "5px",
                                  transform: "translateY(-50%)",
                                }}
                                size="32"
                                color="#4c0f78"
                                variant="Bold"
                                onClick={() => setIsOpenDropdown3(true)}
                              />
                            </FormControl>
                          </div>

                          {/* Addon Dropdown */}
                          {currentQuestion?.possible_answers?.addOnDropdown
                            .length > 0 && (
                              <div className="flex justify-center items-center relative">
                                <FormControl className="sm:w-[20rem] w-[15rem] overflow-hidden bg-white" sx={{ m: 1, minWidth: 120 }} size="small">
                                  <Select
                                    onChange={(e) => {
                                      const selectedValue = JSON.parse(e.target.value);
                                      const selectedText = currentQuestion?.possible_answers?.addOnDropdown.find(
                                        (ele) => ele.value === selectedValue.value)?.value;
                                      setMulValTextAddon(selectedText || "");
                                      setMulValAddon(e.target.value);
                                    }}
                                    open={isOpenDropdown4} // Use the specific state for this dropdown
                                    onClose={() => setIsOpenDropdown4(false)}
                                    onOpen={() => setIsOpenDropdown4(true)}
                                    inputProps={{ IconComponent: () => null }} // this part
                                    displayEmpty
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 300, // Set the max height for the dropdown
                                          maxWidth: 300,
                                          overflowY: 'auto',
                                        },
                                      },
                                    }}
                                  >
                                    <MenuItem disabled value="">
                                      Select Addons
                                    </MenuItem>
                                    {currentQuestion.possible_answers.addOnDropdown.map(
                                      (ele, i) => (
                                        <MenuItem
                                          value={ele.id}
                                          key={i}
                                          sx={{
                                            whiteSpace: 'normal',       // Allow the text to wrap
                                            wordWrap: 'break-word',     // Break long words if necessary
                                            overflowWrap: 'break-word', // Break words at appropriate places
                                          }}
                                        >
                                          {ele.value}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                  <ArrowSquareDown
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "5px",
                                      transform: "translateY(-50%)",
                                    }}
                                    size="32"
                                    color="#4c0f78"
                                    variant="Bold"
                                    onClick={() => setIsOpenDropdown4(true)}
                                  />
                                </FormControl>
                              </div>
                            )}
                        </div>
                        <div className="flex flex-row place-items-center">
                          <button onClick={PostMulQtyAndAfterHours}>
                            <TickCircle size="32" color="#00cc00" />
                          </button>
                        </div>
                      </div>
                    ) : currentQuestion?.renderable_type === "date_time" ? (
                      <div className="flex justify-end">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDateTimePicker
                            className="bg-white"
                            defaultValue={dayjs(new Date())}
                            onAccept={(date) => {
                              // date is a Date object when the user picks a date so we need to convert it to a string
                              const formattedDate = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
                              PostDate(formattedDate);
                            }}
                            slotProps={{
                              textField: {
                                inputProps: {
                                  "aria-hidden": "true", // Add aria-hidden attribute here
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    ) : currentQuestion?.renderable_type === "radio" ? (
                      // yes/no buttons
                      <div className="flex gap-4 flex-wrap justify-end">
                        {currentQuestion?.possible_answers.dropdown.map(
                          (ele, i) => (
                            <div key={i}>
                              <button
                                type="button"
                                value={ele.id}
                                className="hover:bg-[#6A4AF0] bg-white p-1  px-6 rounded-md text-[#6A4AF0] font-bold hover:shadow-xl shadow-sm  hover:text-white white rounded-lg"
                                onClick={(e) => {
                                  postSelectAns(e, e.target.value, ele.value);
                                }}
                              >
                                {ele.value}
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    ) : currentQuestion?.renderable_type === "table" ? (
                      <>
                        <div className="flex  bg-gradient-to-r bg-[#f7daad] p-5 rounded-2xl md:flex-nowrap ">
                          <div className="w-full flex " >
                            <CheckboxButtonWrapper
                              options={currentQuestion.possible_answers.dropdown}
                              onSelectionChange={handleSelect}
                            />
                          </div>
                        </div>
                        <button
                          className={`bg-[#ffffff00]  w-14 p-3 flex mr-5 justify-center pr-5 items-center rounded-full ml-4 `}
                          onClick={PostTable}
                          disabled={selectedOption?.length < 1}
                        >
                          <div className="bg-[#ffffff] rounded-full p-2">
                            <Send
                              size="32"
                              color={
                                selectedOption?.length > 0
                                  ? "#4c0f78"
                                  : "#EBD9FC"
                              }
                              variant="Bold"
                            />
                          </div>
                        </button>

                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            )}
            {loading && (
              <div className="flex py-2  items-center">
                <ChatMessageSkeleton />
              </div>
            )}
          </div>
          <div className=" flex items-center w-full  ">
            <div className="flex  w-full">
              {currentReqValue && (
                <Steps currentStep={currentStep} totalSteps={totalSteps} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}