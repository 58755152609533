import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosAuth } from "../lib/axiosInstance";

export const useForm = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch question functions
  const fetchQuestion = async (id) => {
    if (!id) {
      console.log("Claim ID not provided. Returning early.");
      return null;
    }
    try {
      const response = await axiosAuth.get(`/claims/${id}/general-information/template`);
      setData(response.data.questions);
      return response.data.questions;
    } catch (error) {
      console.error("Error fetching questions:", error);
      return null;
    }
  };

  const fetchRoomQuestions = async (id) => {
    try {
      const response = await axiosAuth.get(`/claims/${id}/room-information/template`);
      const { questions } = response.data[0];
      setData(questions);
      return questions;
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred");
      throw err;
    }
  };

  // Create claim function
  const create_claim = async (body) => {
    try {
      const response = await axiosAuth.post("/claims", body);
      toast.success("Claim Information Saved");
      return response;
    } catch (error) {
      if (Array.isArray(error.response?.data?.errors)) {
        const errorMessage = error.response.data.errors.join(', ');
        setErrorMessage(errorMessage);
      } else {
        setErrorMessage(error.response?.data?.message || "An error occurred");
      }
      setShowErrorModal(true);
      throw error;
    }
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  // Create general function
  const create_general = async (id, body) => {
    const claim_id = id;
    try {
      const response = await axiosAuth.post(`/claims/${claim_id}/general-information`, body);
      document.body.classList.add('disable-background'); // Disable background interactions

      confirmAlert({
        title: "Success",
        message: "Basic Survey successfully",
        buttons: [
          {
            label: "Start Room Survey",
            onClick: () => {
              document.body.classList.remove('disable-background'); // Re-enable background interactions
              navigate(`/individual-file/${claim_id}`);
            },
          },
        ],
        closeOnClickOutside: false,  
        onClickOutside: () => {},
        afterClose: () => {
          document.body.classList.remove('disable-background'); // Re-enable background interactions
        },
        overlayClassName: 'submit-modal-overlay'
      });

      return response;
    } catch (error) {
      document.body.classList.remove('disable-background'); // Re-enable background interactions in case of error
      if (Array.isArray(error.response?.data?.errors)) {
        error.response.data?.errors.forEach((msg) => toast.error(msg));
      } else {
        toast.error(error.response?.data?.message || "An error occurred");
      }
      throw error;
    }
  };

  // Create room function
  const create_room = async (id, body) => {
    const claim_id = id;
    try {
      const response = await axiosAuth.post(`/claims/${claim_id}/room-information`, body);
      document.body.classList.add('disable-background'); // Disable background interactions

      confirmAlert({
        title: "Success",
        message: "Room created successfully",
        buttons: [
          {
            label: "View Rooms",
            onClick: () => {
              document.body.classList.remove('disable-background'); // Re-enable background interactions
              navigate(`/individual-file/${claim_id}`);
            },
          },
        ],
        closeOnClickOutside: false,  
        onClickOutside: () => {},
        afterClose: () => {
          document.body.classList.remove('disable-background'); // Re-enable background interactions
        },
        overlayClassName: 'submit-modal-overlay'
      });

      return response;
    } catch (error) {
      document.body.classList.remove('disable-background'); // Re-enable background interactions in case of error
      if (Array.isArray(error.response?.data?.errors)) {
        error.response.data?.errors.forEach((msg) => toast.error(msg));
      } else {
        toast.error(error.response?.data?.message || "An error occurred");
      }
      throw error;
    }
  };

  return {
    data,
    loading,
    error,
    fetchQuestion,
    fetchRoomQuestions,
    create_claim,
    create_room,
    create_general,
  };
};
