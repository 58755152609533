import React, { useEffect, useState, useRef, useCallback } from "react";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faSearch } from "@fortawesome/free-solid-svg-icons";
import companyLogo from "../../Logo/ripley-logo.png";
import { useAuth } from "../../customHooks/useAuth";
import { axiosAuth } from "../../lib/axiosInstance";

const HomePage = () => {
  const navigate = useNavigate();
  const { getUserDetails } = useAuth();

  // 🔹 State variables
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [userResponses, setUserResponse] = useState(null);
  
  // 🔹 Infinite Scroll States
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  // 🔹 Fetch User Details
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const userResponse = await getUserDetails(token);
        setUserResponse(userResponse);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);

  // 🔹 Fetch Claims Data with Pagination
  const fetchClaims = async (pageNumber = 1) => {
    setLoading(true);
    try {
      const response = await axiosAuth.get(`/claims?page=${pageNumber}`);
      console.log(response);
      if (response.data.data.length > 0) {
        setData((prevData) => [...prevData, ...response.data.data]);
      } else {
        setHasMore(false); // No more data to fetch
      }
    } catch (error) {
      console.error("Error fetching claims:", error);
    } finally {
      setLoading(false);
    }
  };

  // 🔹 Initial Fetch
  useEffect(() => {
    fetchClaims();
  }, []);

  // 🔹 Update filteredData when `searchQuery` changes
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter(
        (item) =>
          item.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.address.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchQuery, data]);

  // 🔹 Infinite Scroll Logic
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  // 🔹 Fetch More Data when Page Changes
  useEffect(() => {
    if (page > 1) {
      fetchClaims(page);
    }
  }, [page]);

  // 🔹 Format Date
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" });
  };

  return (
    <Sidebar>
      {/* 🔹 Logo */}
      <div className="home-page-logo">
        <img
          src={companyLogo}
          alt="Company Logo"
          className="mx-auto mb-2"
          style={{ maxWidth: "180px", maxHeight: "150px" }}
        />
      </div>

      {/* 🔹 Search Bar */}
      <div className="flex justify-center my-4">
        <div className="relative w-1/2">
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded-md pl-10"
            placeholder="Search by name or address..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-3 text-gray-500" />
        </div>
      </div>

      {/* 🔹 New Smart Scope Button */}
      <div className="home-new-smart-scope-container">
        {data.length > 0 && (
          <div className="cursor-pointer text-xl font-bold text-indigo-800" onClick={() => navigate("/form")}>
            <p className="home-new-smart-scope-btn">
              New Smart Scope
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#4c0f78"
                color="white"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-10 h-10 ml-2"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            </p>
          </div>
        )}
      </div>

      {/* 🔹 Display Data or No Results */}
      <div className="flex gap-4 justify-center items-center flex-wrap p-3 m-2">
        {filteredData.length > 0 ? (
          filteredData.map((item, i) => (
            <div
              key={i}
              ref={filteredData.length === i + 1 ? lastElementRef : null}
              className="home-file-card my-3 mx-13 h-fit"
              onClick={() => navigate(`/individual-file/${item.id}`)}
            >
              <div className="whitespace-nowrap text-[0.75rem] p-[5px] w-[80%]">
                <p className="mb-0">
                  <strong className="mr-[0.3rem]">Full Name:</strong>
                  <span>{item.full_name}</span>
                </p>
                <p className="mb-0 flex">
                  <strong className="mr-[0.3rem]">Address:</strong>
                  <span className="inline-block w-[12rem] truncate">{item.address}</span>
                </p>
                <p className="mb-0">
                  <strong className="mr-[0.3rem]">Date:</strong>
                  <span>{formatDate(item.created_at)}</span>
                </p>
              </div>
              <div>
                <FontAwesomeIcon icon={faLayerGroup} className="text-[#4c0f78]" />
              </div>
            </div>
          ))
        ) : (
          <div className="italic text-gray-500 text-sm">No records found</div>
        )}
      </div>

      {/* 🔹 Loading Spinner for Infinite Scroll */}
      <div className="flex justify-center items-center my-4 h-[50px]">
  {loading && <HashLoader size={40} color="#1B2559" loading={true} />}
</div>
    </Sidebar>
  );
};

export default HomePage;
