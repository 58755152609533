import React from "react";
import { useEffect, useState } from "react";
import { axiosNoAuth } from "../lib/axiosInstance";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import the hook
import { axiosAuth } from "../lib/axiosInstance";
import useCookies from "./useCookies";

export const useAuth = () => {
  const { setCookie, getCookie } = useCookies();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate(); // Initialize the navigate function

  const setItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const removeItem = (key) => {
    localStorage.removeItem(key);
  };

  const getUserDetails = async (accessToken) => {
    const rootUrl = process.env.REACT_APP_ROOT_URL;
    const userUrl = rootUrl.endsWith('/') ? `${rootUrl}user` : `${rootUrl}/user`;

    try {
      const response = await axios.get(userUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      const role = response?.data?.user?.role ?? 'user';
      setCookie('user_role', role);
    } catch (error) {
      // Extract and show error message
      console.log(error);
      return "error fetching user details";
    }
  };
  const login = async (body) => {
    try {
      setLoading(true);
      await axiosNoAuth
        .post("/oauth/token", body)
        .then(async (response) => {
          let { data } = response;
          const { access_token, expires_in, refresh_token, token_type } = data;
          await getUserDetails(access_token)
          // Set items in localStorage
          setItem("access_token", access_token);
          setItem("expires_in", expires_in);
          setItem("authenticated", true);

          setData({ success: true });
          toast.success("Login successful");
          const userRole = getCookie("user_role");
          userRole == 'admin' || userRole == 'company-admin' ? navigate("/admin/dashboard") : navigate("/home")

          window.location.reload();

          return true;
        })
        .catch((err) => {
          setData({ success: false });
          setLoading(false);
          toast.error("Wrong User Details");
          return false;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      // Extract and show error message
      const errorMsg =
        error.response?.data?.message || "An error occurred during login";
      toast.error(errorMsg);
      setLoading(false);
      setData({ success: false });
      return false;
    }
  };

  // const register = async (body) => {
  //     try {
  //         setLoading(true);
  //         await axiosNoAuth.post('/oauth/token', body).then(response => {

  //             let { data } = response;
  //             const { access_token, expires_in, refresh_token, token_type } = data;

  //             // Set items in localStorage
  //             setItem("access_token", access_token);
  //             setItem("expires_in", expires_in);
  //             setItem("authenticated", true);

  //             setData({ success: true });
  //             toast.success("Login successful");

  //             return true;
  //         }).catch((err) => {

  //         }).finally(() => {
  //             setLoading(false);
  //         });

  //     } catch (error) {
  //         // Extract and show error message
  //         const errorMsg = error.response?.data?.message || "An error occurred during login";
  //         toast.error(errorMsg);
  //         setLoading(false);
  //         setData({ success: false });
  //         return false;
  //     }
  // };

  const resetPassword = async (body) => {
    try {
      setLoading(true);
      await axiosNoAuth
        .post("/user/reset-password", body)
        .then((response) => {
          let { data } = response;
          const { access_token, expires_in, refresh_token, token_type } = data;
          setItem("access_token", access_token);
          setItem("expires_in", expires_in);
          setItem("authenticated", true);
          return true;
        })
        .catch((err) => { })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      // Extract and show error message
      const errorMsg =
        error.response?.data?.message || "An error occurred during login";
      toast.error(errorMsg);
      setLoading(false);
      setData({ success: false });
      return false;
    }
  };

  return { data, loading, error, login, getUserDetails, resetPassword };
};
