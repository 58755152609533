import React, { useEffect, useState } from "react";
import Sidebar from "../../Sidebar";
import { FiUsers } from "react-icons/fi"; // Importing icons
import { RiSurveyLine } from "react-icons/ri";
import LineChart from "../../adminComponent/LineChart";
import { axiosAuth } from "../../../lib/axiosInstance";

const Dashboard = () => {
  const [surveyData, setSurveyData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalSurvey, setTotalSurvey] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [difference, setDifference] = useState(30);

  useEffect(() => {
    // Get today's date
    const today = new Date();
    const todayFormatted = today.toISOString().split('T')[0];
    // Get date 30 days ago
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - 30);
    const pastDateFormatted = pastDate.toISOString().split('T')[0];

    // Set default dates
    setToDate(todayFormatted);
    setFromDate(pastDateFormatted);

    // Calculate the difference between the two dates
    const date1 = new Date(pastDateFormatted);
    const date2 = new Date(todayFormatted);

    const timeDifference = Math.abs(date2 - date1);
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    setDifference(dayDifference);
  }, []);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axiosAuth.get("/dashboard");
        setTotalUsers(response?.data?.users?.total_users);
        setTotalSurvey(response?.data?.surveys?.total_surveys);
        setUserData(response?.data?.users?.user_details_in_last_30_days);
        setSurveyData(response?.data?.surveys?.survey_details_in_last_30_days);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchDashboardData();
  }, []);

  const fetchDataUSingDate = async (fromDate, toDate) => {
    try {
      const response = await axiosAuth.get(`/dashboard?from_date=${fromDate}&to_date=${toDate}`);
      const data = response.data;
      setSurveyData(response?.data?.surveys?.survey_details_in_last_30_days);
      setUserData(response?.data?.users?.user_details_in_last_30_days);
      console.log('API response:', data);
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      fetchDataUSingDate(fromDate, toDate);
    }
  }, [fromDate, toDate]);

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  return (
    <Sidebar>
      <div className="p-8 bg-gray-100 md:h-full ">
        <h1 className="text-3xl font-bold mb-6 text-slate-800">Dashboard</h1>
        <div className="datrange p-2 mb-3">
          <input className='input outline-none border rounded-md px-3 py-2 '
            id="date_from"
            name="fate_from"
            type="date"
            value={fromDate}
            onChange={handleFromDateChange}
          />
          <span className="mx-2">To</span>
          <input className='input outline-none border rounded-md px-3 py-2 '
            id="date_to"
            name="date_to"
            type="date"
            value={toDate}
            onChange={handleToDateChange}
          />

        </div>


        <div className="grid grid-cols-1 md:grid-cols-2  gap-6">

          {/* Surveys Over Last 30 Days */}
          <div
            className="bg-white rounded-lg shadow-md p-6"
            style={{ borderColor: "#AC6542" }}
          >
            <h2 className="text-xl font-bold mb-4">
              {` Macros Over Last ${difference} Days`}
            </h2>
            <LineChart
              data={surveyData.map((survey) => survey?.count) ?? []}
              label={surveyData.map((survey) => survey?.date) ?? []}
              borderColor="#AC6542"
            />
          </div>

          {/* Users Over Last 30 Days */}
          <div
            className="bg-white rounded-lg shadow-md p-6"
            style={{ borderColor: "#52197E" }}
          >
            <h2 className="text-xl font-bold mb-4">Users Over Last 30 Days</h2>
            <LineChart
              data={userData.map((user) => user?.count) ?? []}
              label={userData.map((user) => user?.date) ?? []}
              borderColor="#52197E"
            />
          </div>
          <div className="bg-white rounded-lg shadow-md p-6 flex items-center justify-between">
            <div>
              <h2 className="text-xl font-bold mb-2">Total Users</h2>
              <p className="text-gray-400 font-medium text-lg">{totalUsers}</p>
            </div>
            <FiUsers className="text-3xl text-[#52197E]" />
          </div>

          <div className="bg-white rounded-lg shadow-md p-6 flex items-center justify-between">
            <div>
              <h2 className="text-xl font-bold mb-2">Total Macros</h2>
              <p className="text-gray-400 font-medium text-lg">{totalSurvey}</p>
            </div>
            <RiSurveyLine className="text-3xl text-[#52197E]" />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default Dashboard;
