import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosAuth } from '../../../../lib/axiosInstance';
import Sidebar from '../../../Sidebar';
import { AiFillCheckCircle, AiFillCloseCircle, AiFillEdit } from 'react-icons/ai';
import { FiToggleLeft, FiToggleRight } from 'react-icons/fi';
import { act } from 'react';

const DropdownList = () => {
    const { code } = useParams();
    const navigate = useNavigate();

    // State for quantities
    const [quantityData, setQuantityData] = useState([]);
    const [isQuantityModalOpen, setIsQuantityModalOpen] = useState(false);
    const [currentQuantity, setCurrentQuantity] = useState({ value: '', code: '' });
    const [isEditingQuantity, setIsEditingQuantity] = useState(false);
    const [quantityErrors, setQuantityErrors] = useState({});
    const deleteModalRef = useRef(null);

    // State for values
    const [valueData, setValueData] = useState([]);
    const [isValueModalOpen, setIsValueModalOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState({ value: '', emergency_macro: '', rebuild_macro: '' });
    const [isEditingValue, setIsEditingValue] = useState(false);
    const [valueErrors, setValueErrors] = useState({});
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const [activeTab, setActiveTab] = useState('quantities');

    const Macros = localStorage.getItem('Macros');
    const handleClickOutside = (event) => {
        if (deleteModalRef.current && !deleteModalRef.current.contains(event.target)) {
            setIsDeleteModalOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    useEffect(() => {
        fetchData();
    }, [code]);

    const fetchData = async () => {
        const response = await axiosAuth.post('/get-dropdown-list', { code });
        setQuantityData(response.data.quantities);
        setValueData(response.data.values);
    };

    // Functions for quantities
    const openAddQuantityModal = () => {
        // console.log(quantityData[0]);
        setCurrentQuantity({ value: '', code: quantityData[0]?.code });
        setIsEditingQuantity(false);
        setIsQuantityModalOpen(true);
    };

    const editQuantity = (quantity) => {
        setCurrentQuantity(quantity);
        setIsEditingQuantity(true);
        setIsQuantityModalOpen(true);
    };

    const validateQuantity = () => {
        const errors = {};
        if (!currentQuantity.value) errors.value = 'Value is required';
        if (!currentQuantity.code) errors.code = 'Code is required';
        setQuantityErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleToggle = (dropdownType) => {
        if (dropdownType === 'quantity') {
            setCurrentQuantity((prev) => ({ ...prev, is_delete: prev.is_delete === 0 ? 1 : 0 }));
        } else if (dropdownType === 'value') {
            setCurrentValue((prev) => ({ ...prev, is_delete: prev.is_delete === 0 ? 1 : 0 }));

        };
    };

    const saveQuantityChanges = async () => {
        if (!validateQuantity()) return;
        if (isEditingQuantity) {
            await axiosAuth.post('/edit-quantity-data', currentQuantity);
        } else {
            await axiosAuth.post('/add-quantity-data', { code, ...currentQuantity });
        }
        setIsQuantityModalOpen(false);
        fetchData();
    };

    // Functions for values
    const openAddValueModal = () => {
        setCurrentValue({ value: '', emergency_macro: '', rebuild_macro: '' });
        setIsEditingValue(false);
        setIsValueModalOpen(true);
    };

    const editValue = (value) => {
        setCurrentValue(value);
        setIsEditingValue(true);
        setIsValueModalOpen(true);
    };

    const validateValue = () => {
        const errors = {};
        if (!currentValue.value) errors.value = 'Value is required';

        setValueErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const saveValueChanges = async () => {
        if (!validateValue()) return;
        if (isEditingValue) {
            await axiosAuth.post('/edit-dropdown-data', currentValue);
        } else {
            await axiosAuth.post('/add-dropdown-data', { code, ...currentValue });
        }
        setIsValueModalOpen(false);
        fetchData();
    };
    const handleChange = (e, type) => {
        const { name, value } = e.target;
        if (type === 'quantity') {
            setCurrentQuantity((prev) => ({ ...prev, [name]: value }));
            setQuantityErrors((prev) => ({ ...prev, [name]: '' }));
        } else if (type === 'value') {
            setCurrentValue((prev) => ({ ...prev, [name]: value }));
            setValueErrors((prev) => ({ ...prev, [name]: '' }));
        }
    };

    const handleCloseModal = () => {
        navigate(`/admin/question-list/${Macros}`);
    };


    return (
        <div className="flex">
            <Sidebar />
            <div className="flex-1 p-8 bg-gray-100 max-h-screen overflow-scroll">
                <div className="bg-white shadow-md rounded-lg p-6">

                    <div className='flex justify-between'>
                        <div className="flex text-2xl font-bold mb-4">Dropdown List for Code: {code}</div>
                        <div className='flex'><button className="bg-[#36154e] text-white px-3 py-1 rounded text-sm h-8" onClick={handleCloseModal} >Back</button></div>
                    </div>
                    <div className="flex justify-center mb-4">
                        <button
                            onClick={() => setActiveTab('values')}
                            className={`px-4 py-2 rounded-t-lg border-b-2 ml-2 ${activeTab === 'values' ? 'border-[#36154e] text-[#36154e]' : 'border-gray-300 text-gray-500'}`}
                        >
                            Dropdown Values
                        </button>
                        <button
                            onClick={() => setActiveTab('quantities')}
                            className={`px-4 py-2 rounded-t-lg border-b-2 ${activeTab === 'quantities' ? 'border-[#36154e] text-[#36154e]' : 'border-gray-300 text-gray-500'}`}
                        >
                            Quantities
                        </button>

                    </div>
                    {activeTab === 'quantities' ? (
                        <>
                            <button
                                onClick={openAddQuantityModal}
                                className="mb-4 bg-[#36154e] text-white px-4 py-2 rounded"
                            >
                                Add New Quantity
                            </button>
                            <div className="overflow-x-auto md:text-[17px] text-[14px]">
                                <table className="min-w-full bg-white border">
                                    <thead>
                                        <tr className="bg-[#36154e] text-white text-left">
                                            <th className="px-4 py-2">Values</th>
                                            <th className="px-4 py-2">Code</th>
                                            <th className="px-4 py-2"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {quantityData.length > 0 ? (
                                            quantityData.map((quantity) => (
                                                <tr key={quantity.id} className="border-b">
                                                    <td className="px-4 py-2">{quantity.value}</td>
                                                    <td className="px-4 py-2">{quantity.code}</td>
                                                    <td className="px-4 py-2 text-right space-x-2">
                                                        {quantity?.is_delete == 1 ? (
                                                            <button
                                                                className="pointer-events-none text-red-500 hover:text-red-700 font-semibold"
                                                            >
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="text-green-500 hover:text-green-700 font-semibold"
                                                            >
                                                                <AiFillCheckCircle />
                                                            </button>
                                                        )}
                                                        <button
                                                            onClick={() => editQuantity(quantity)}
                                                            className="text-[#4c0f78] hover:text-blue-700 font-semibold"
                                                        >
                                                            <AiFillEdit />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={3} className="text-center py-4">No items found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={openAddValueModal}
                                className="mb-4 bg-[#36154e] text-white px-4 py-2 rounded"
                            >
                                Add New Value
                            </button>
                            <div className="overflow-x-auto md:text-[17px] text-[14px]">
                                <table className="min-w-full bg-white border">
                                    <thead>
                                        <tr className="bg-[#36154e] text-white text-left">
                                            <th className="px-4 py-2">Values</th>
                                            <th className="px-4 py-2">Emergency Macros</th>
                                            <th className="px-4 py-2">Rebuild Macros</th>
                                            <th className="px-4 py-2"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {valueData.length > 0 ? (
                                            valueData.map((value) => (
                                                <tr key={value.id} className="border-b">
                                                    <td className="px-4 py-2">{value.value}</td>
                                                    <td className="px-4 py-2">{value.emergency_macro}</td>
                                                    <td className="px-4 py-2">{value.rebuild_macro}</td>
                                                    <td className="px-4 py-2 text-right space-x-2">
                                                        {value?.is_delete === 1 ? (
                                                            <button
                                                                className="pointer-events-none text-red-500 hover:text-red-700 font-semibold"
                                                            >
                                                                <AiFillCloseCircle />
                                                            </button>

                                                        ) : (

                                                            <button
                                                                className="text-green-500 hover:text-green-700 font-semibold"
                                                            >
                                                                <AiFillCheckCircle />
                                                            </button>
                                                        )}
                                                        <button
                                                            onClick={() => editValue(value)}
                                                            className="text-[#4c0f78] hover:text-blue-700 font-semibold"
                                                        >
                                                            <AiFillEdit />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={4} className="text-center py-4">No items found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {/* Quantity Modal */}
            {
                isQuantityModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-8 rounded shadow-md w-96">
                            <h2 className="text-xl font-bold mb-4">{isEditingQuantity ? 'Edit Quantity Item' : 'Add New Quantity'}</h2>
                            <div className="mb-4">
                                <label className="block text-gray-700">Values</label>
                                <input
                                    type="text"
                                    name="value"
                                    value={currentQuantity.value}
                                    onChange={(e) => handleChange(e, 'quantity')}
                                    className="w-full border p-2 rounded"
                                />
                                {quantityErrors.value && <p className="text-red-500 text-sm">{quantityErrors.value}</p>}
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700">Code</label>
                                <input
                                    readOnly
                                    type="text"
                                    name="code"
                                    value={currentQuantity.code}
                                    onChange={(e) => handleChange(e, 'quantity')}
                                    className="w-full border p-2 rounded"
                                />
                                {quantityErrors.code && <p className="text-red-500 text-sm">{quantityErrors.code}</p>}
                            </div>
                            <div className="mb-4 flex items-center">
                                <label className="block text-gray-700 mr-2">Status</label>
                                <div
                                    className="cursor-pointer flex items-center"
                                    onClick={() => handleToggle("quantity")} // Toggle status on click
                                >
                                    {currentQuantity.is_delete === 0 ? (
                                        <button
                                            className="text-green-500 hover:text-green-700 font-semibold text-[37px]"
                                        >
                                            <FiToggleRight />
                                        </button>
                                    ) : (
                                        <button
                                            className="text-red-500 hover:text-red-700 font-semibold text-[37px]"
                                        >
                                            <FiToggleLeft />
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    onClick={() => setIsQuantityModalOpen(false)}
                                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={saveQuantityChanges}
                                    className="bg-[#36154e] text-white px-4 py-2 rounded"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }

            {/* Value Modal */}
            {
                isValueModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-8 rounded shadow-md w-96">
                            <h2 className="text-xl font-bold mb-4">{isEditingValue ? 'Edit Value Item' : 'Add New Value'}</h2>
                            <div className="mb-4">
                                <label className="block text-gray-700">Values</label>
                                <input
                                    type="text"
                                    name="value"
                                    value={currentValue.value}
                                    onChange={(e) => handleChange(e, 'value')}
                                    className="w-full border p-2 rounded"
                                />
                                {valueErrors.value && <p className="text-red-500 text-sm">{valueErrors.value}</p>}
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700">Emergency Macros</label>
                                <input
                                    type="text"
                                    name="emergency_macro"
                                    value={currentValue.emergency_macro}
                                    onChange={(e) => handleChange(e, 'value')}
                                    className="w-full border p-2 rounded"
                                />

                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700">Rebuild Macros</label>
                                <input
                                    type="text"
                                    name="rebuild_macro"
                                    value={currentValue.rebuild_macro}
                                    onChange={(e) => handleChange(e, 'value')}
                                    className="w-full border p-2 rounded"
                                />
                            </div>
                            <div className="mb-4 flex items-center">
                                <label className="block text-gray-700 mr-2">Status</label>
                                <div
                                    className="cursor-pointer flex items-center"
                                    onClick={() => handleToggle("value")} // Toggle status on click
                                >
                                    {currentValue.is_delete === 0 ? (
                                        <button
                                            className="text-green-500 hover:text-green-700 font-semibold text-[37px]"
                                        >
                                            <FiToggleRight />
                                        </button>
                                    ) : (
                                        <button
                                            className="text-red-500 hover:text-red-700 font-semibold text-[37px]"
                                        >
                                            <FiToggleLeft />
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    onClick={() => setIsValueModalOpen(false)}
                                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={saveValueChanges}
                                    className="bg-[#36154e] text-white px-4 py-2 rounded"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default DropdownList;
