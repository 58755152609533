import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../Sidebar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import loader from "../inputComponent/loader.json";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  Send,
  ArrowSquareDown,
  Edit2,
  TickCircle,
  AddCircle,
  CloseCircle,
  Next,
} from "iconsax-react";
import chat_icon from "../chaticon.png";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FaUserAlt } from "react-icons/fa";
import { axiosAuth } from "../../lib/axiosInstance";
import { ToastContainer } from "react-toastify";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import CheckboxButtonWrapper from "../OtherComponents/CheckboxButtonWrapper";
import Steps from "../OtherComponents/Steps";
import ChatMessageSkeleton from "../OtherComponents/ChatMessageSkeleton";
import { Button, Modal, TextField } from "@mui/material";
const q1Option = [
  {
    key: "(Emergency) Interior Only",
    value: "E - Interior Only",
    req: [1],
  },
  {
    key: "(Emergency)  Exterior Only",
    value: "E - Exterior Only",
    req: [2],
  },
  {
    key: "(Emergency) Separate Contents Only",
    value: "E - Separate Contents Only",
    req: [3],
  },
  {
    key: "(Emergency) Interior & Exterior",
    value: "E - Interior & Exterior",
    req: [4],
  },
  {
    key: "(Emergency) Interior & Separate Contents",
    value: "E - Interior & Separate Contents",
    req: [5],
  },
  {
    key: "(Emergency) Exterior & Separate Contents",
    value: "E - Exterior & Separate Contents",
    req: [6],
  },
  {
    key: "(Emergency) Interior, Exterior & Separate Contents",
    value: "E - Interior, Exterior & Separate Contents",
    req: [7],
  },
  {
    key: "(Rebuild) Interior Only",
    value: "R - Interior Only",
    req: [8],
  },
  {
    key: "(Rebuild) Exterior Only",
    value: "R - Exterior Only",
    req: [9],
  },
  {
    key: "(Rebuild) Interior & Exterior",
    value: "R - Interior & Exterior",
    req: [10],
  },
  {
    key: "(Rebuild) Interior & Separate Contents",
    value: "R - Interior & Separate Contents",
    req: [11],
  },
  {
    key: "(Rebuild) Exterior & Separate Contents",
    value: "R - Exterior & Separate Contents",
    req: [12],
  },
  {
    key: "(Rebuild) Interior, Exterior & Separate Contents",
    value: "R - Interior, Exterior & Separate Contents",
    req: [13],
  },
  {
    key: "(Emergency & Rebuild) Interior Only",
    value: "E&R - Interior Only",
    req: [14],
  },
  {
    key: "(Emergency & Rebuild) Exterior Only",
    value: "E&R - Exterior Only",
    req: [15],
  },
  {
    key: "(Emergency & Rebuild) Interior & Exterior",
    value: "E&R - Interior & Exterior",
    req: [16],
  },
  {
    key: "(Emergency & Rebuild) Interior Only & Separate Contents",
    value: "E&R - Interior Only & Separate Contents",
    req: [17],
  },
  {
    key: "(Emergency & Contents) Exterior Only & Separate Contents",
    value: "E&C - Exterior Only & Separate Contents",
    req: [18],
  },
  {
    key: "(Emergency & Rebuild & Contents) Interior, Exterior & Separate Contents",
    value: "E&R&C - Interior, Exterior & Separate Contents",
    req: [19],
  },
  {
    key: "(Emergency & Rebuild & Contents) Interior Only & Separate Contents",
    value: "E&R&C - Interior Only & Separate Contents",
    req: [61],
  },
  {
    key: "(Rebuild) - Interior & (Emergency&Rebuild)-Exterior",
    value: "R - Interior Only - E&R - Exterior Only",
    req: [62],
  },
  {
    key: "(Emergency & Rebuild & Contents) Interior Only & Separate Contents",
    value: "Work In Progress",
    req: [63],
  },
];

const questions = {
  1: {
    id: 1,
    title: "Choose Request Type ?",
    renderable_type: "select",
    options: q1Option,
  },
  2: {
    id: 2,
    title: "Room name ?",
    renderable_type: "text",
  },
  3: {
    id: 3,
    title: "Structure/Area Name ?",
    renderable_type: "text",
  },
  4: {
    id: 4,
    title: "Room name ?",
    renderable_type: "text",
  },
};

export default function EditIndividualRoom() {
  const location = useLocation();
  const dropdownData = location.state;
  const inputRef = useRef(null);
  const [showInput, setShowInput] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(questions[1]);
  const [showError, setShowError] = useState(false);
  const [currentReq, setCurrentReq] = useState(0);
  const [chat, setChat] = useState([]);
  const [payloadArray, setPayloadArray] = useState([]);
  const [showQuestion, setShowQuestion] = useState(false);
  const [mulVals, setMulVals] = useState([
    { val1: "", val2: "", valText1: "", valText2: "" },
  ]);

  const [singleVal1, setSingleVal1] = useState("");
  const [singleVal2, setSingleVal2] = useState("");
  const [singleValText1, setSingleValText1] = useState("");
  const [singleValText2, setSingleValText2] = useState("");

  const [mulVal1, setMulVal1] = useState("");
  const [mulVal2, setMulVal2] = useState("");
  const [mulVal3, setMulVal3] = useState("");
  const [mulValAddon, setMulValAddon] = useState("");
  const [loading, setLoading] = useState(false);

  const [mulValText1, setMulValText1] = useState("");
  const [mulValText2, setMulValText2] = useState("");
  const [mulValText3, setMulValText3] = useState("");
  const [mulValTextAddon, setMulValTextAddon] = useState("");

  const [currentReqValue, setCurrentReqValue] = useState("");
  const [room_id, setRoom_id] = useState(dropdownData?.roomId);
  const [currentInfoId, setCurrentInfoId] = useState("");
  const [req, setReq] = useState({});
  const [editStarted, setEditStarted] = useState(false);

  const baseUrl = process.env.REACT_APP_ROOT_URL;
  const { id } = useParams();
  const chatContainerRef = useRef(null);
  const navigate = useNavigate();
  const individualFileId = localStorage.getItem("individual_file_id");

  const [currentStep, setCurrentStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(14);

  const [showButton, setShowButton] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [editValue, setEditValue] = useState(""); // Store input value
  const [currentIndex, setCurrentIndex] = useState(null); // Store the index

  const resetState = (source) => {
    // console.log(`Resetting state from: ${source}`);
    setMulVals([
      {
        val1: "",
        val2: "",
        mulValAddon: "",
        valText1: "",
        valText2: "",
        mulValTextAddon: "",
      },
    ]);
    setMulVal1("");
    setMulVal2("");
    setMulVal3("");
    setMulValText1("");
    setMulValText2("");
    setMulValText3("");
    setMulValAddon("");
    setMulValTextAddon("");
  };

  const reqType = (val) => {
    setCurrentStep(1);
    const reqArr = val.req;
    setCurrentReqValue(val?.value);
    const firstVal = reqArr.shift();
    localStorage.setItem("req_arr", JSON.stringify(reqArr));
    setCurrentReq(firstVal);
    setReq(val);
    if (firstVal === 1) {
      setCurrentQuestion(questions[2]);
    } else if ([2, 3, 6, 9, 12, 15, 18, 61, 62].includes(firstVal)) {
      setCurrentQuestion(questions[3]);
    } else if ([1, 4, 5, 7, 8, 10, 11, 13, 14, 16, 17, 19].includes(firstVal)) {
      setCurrentQuestion(questions[4]);
    }
    setShowQuestion(true);

    createRoom();
    setShowInput(true);
  };
  useEffect(() => {
    const setChatBox = async () => {
      try {
        const selectedKey = dropdownData.requestType.title;
        const selectedOption = q1Option.find(
          (option) => option.key === selectedKey
        );
        const selectedReq = {
          key: selectedOption.key,
          req: selectedOption.req,
          value: selectedOption.value,
        };
        let nextQuestionId;
        if (
          [1, 4, 5, 7, 8, 10, 11, 13, 14, 16, 17, 19].includes(
            selectedOption.req[0]
          )
        ) {
          nextQuestionId = 4;
        } else if (
          [2, 3, 6, 9, 12, 15, 18, 61, 62].includes(selectedOption.req[0])
        ) {
          nextQuestionId = 3;
        } else {
          nextQuestionId = 2;
        }

        // Initial array obj1
        const obj1 = [
          {
            question: questions[1],
            payload: selectedReq.req[0],
            ans: selectedReq.value,
          },
          {
            question: questions[nextQuestionId],
            ans: dropdownData.roomName,
            payload: "",
          },
        ];
        const groupedAnswers = new Map();
        obj1.forEach((item) => {
          const questionId = item.question.id;
          groupedAnswers.set(questionId, {
            ans: item.ans,
            payload: [item.payload],
            question: item.question,
          });
        });
        dropdownData.room_information.forEach((roomInfo) => {
          if (roomInfo.answer) {
            const questionId = roomInfo.question_id;
            const existingEntry = groupedAnswers.get(questionId);

            if (existingEntry) {
              // Merge answers for questions with the same id
              existingEntry.ans += `, ${roomInfo.answer.value}`;
              existingEntry.payload.push(JSON.stringify(roomInfo.answer.id));
            } else {
              // Create a new entry for this question id
              groupedAnswers.set(questionId, {
                ans: roomInfo.answer.value,
                payload: [JSON.stringify(roomInfo.answer.id)],
                question: {
                  code: roomInfo.code,
                  id: roomInfo.question_id,
                  possible_answers: roomInfo.possible_answers,
                  prerequisite: roomInfo.prerequisite,
                  title: roomInfo.question_title,
                  renderable_type: roomInfo.renderable_type,
                },
                room_information_answer_id: roomInfo.id,
              });
            }
          }
        });

        // Step 4: Convert the map back to the desired array format
        const finalObj1 = Array.from(groupedAnswers.values()).map((entry) => ({
          ...entry,
          payload: entry.payload.join(", "),
        }));

        setChat((prevChat) => [...prevChat, ...finalObj1]);
        scrollToBottom();
        let latestQuestion = finalObj1[finalObj1?.length - 1];
        const payload = {
          question_id: latestQuestion?.question.id,
          answers: [
            {
              answer_id: latestQuestion?.payload,
              is_after_hours: null,
              quantity: null,
              number_of_days: null,
              room_information_answer_id:
                latestQuestion.room_information_answer_id,
              code: latestQuestion?.question?.code,
            },
          ],
        };

        const obj = {
          question: latestQuestion.question,
          ans: mulValText1,
          ans1: mulValText2,
          ans2: mulValText3,
          ans_id: mulVal1,
          quantity: mulVal2,
          number_of_days: mulVal3,
        };
        await postData(payload, obj);
      } catch (error) {
        console.error("Error in setEditChatBox:", error);
      }
    };
    setChatBox();
  }, []);

  const createRoom = async (e) => {
    e.preventDefault();
    const createRoomIds = [2, 3, 4];

    if (createRoomIds.includes(currentQuestion.id)) {
    } else {
      console.log("Post another text ans");
    }
  };

  useEffect(() => {
    if (
      currentQuestion?.renderable_type === "dropdown_and_quantity_and_days" ||
      currentQuestion?.renderable_type ===
        "dropdown_and_quantity_and_afterhours"
    ) {
      setMulVal1(currentQuestion?.possible_answers?.dropdown[0]?.id || "");
      setMulVal2(currentQuestion?.possible_answers?.second_values[0]?.id || "");
      setMulVal3(currentQuestion?.possible_answers?.third_values[0]?.id || "");

      setMulValText1(
        currentQuestion?.possible_answers?.dropdown[0]?.value || ""
      );
      setMulValText2(
        currentQuestion?.possible_answers?.second_values[0]?.value || ""
      );
      setMulValText3(
        currentQuestion?.possible_answers?.third_values[0]?.value || ""
      );
    }
    if (
      currentQuestion?.renderable_type ===
      "dropdown_multi_value_and_multi_value"
    ) {
      const firstDropdownValue = currentQuestion.possible_answers.dropdown[0];
      const secondDropdownValue =
        currentQuestion.possible_answers.second_values[0];
      setMulValText1(firstDropdownValue.value);
      setMulValText2(secondDropdownValue.value);
      handleMulValChange(
        0,
        "val1",
        "valText1",
        firstDropdownValue.value,
        firstDropdownValue.id
      );
      handleMulValChange(
        0,
        "val2",
        "valText2",
        secondDropdownValue.value,
        secondDropdownValue.id
      );
    }
  }, [currentQuestion]);

  const updateChatQuestions = (chat, data) => {
    // console.log("Data question code:", data.question.code);
    return chat.map((item) => {
      if (item.question.id === data.question.id) {
        return {
          ...item,
          question: data.question,
        };
      } else {
        console.log("No match for item with ID:", item.question.id);
      }
      return item;
    });
  };

  const fetchQuestion = async (roomId) => {
    try {
      setLoading(true);
      const { data } = await axiosAuth.get(
        `/room-information/questions/${roomId}`
      );
      setTotalSteps(data.total_questions_count + 2);
      setCurrentStep(2);
      setCurrentQuestion(data?.question);
      setCurrentInfoId(data?.room_information_answer_id);
      setLoading(false);

      data?.question?.renderable_type === "text" && setShowInput(true);
    } catch (error) {
      console.error("Error fetching question:", error);
    }
  };

  const handleMulValChange = (index, key, textKey, textValue, value) => {
    const updatedMulVals = [...mulVals];
    updatedMulVals[index][key] = value;
    updatedMulVals[index][textKey] = textValue;
    setMulVals(updatedMulVals);
  };

  const handleAddCircle = (index) => {
    const updatedMulVals = [
      ...mulVals,
      {
        val1: "",
        val2: "",
        valText1: "",
        valText2: "",
        mulValAddon: "",
        mulValTextAddon: "",
      },
    ];
    setMulVals(updatedMulVals);
  };

  const handleRemoveCircle = (index) => {
    const updatedMulVals = [...mulVals];
    updatedMulVals.splice(index, 1);
    setMulVals(updatedMulVals);
  };

  const postData = async (payload, obj) => {
    setEditStarted(true);
    setLoading(true);
    setShowInput(false);
    try {
      const { data } = await axiosAuth.post(
        `/room-information/questions/${room_id}`,
        payload
      );

      if (data.submitted) {
        setLoading(false);
        const reqArray = JSON.parse(localStorage.getItem("req_arr")) || [];

        if (!reqArray.length) {
          setShowButton(true);
          setCurrentQuestion(data.question);
        } else {
          setShowButton(false);
          console.log("in submitted else");

          setChat((prevChat) => [...prevChat, obj]);
          setPayloadArray([...payloadArray, payload]);
          scrollToBottom();
          reqType(req);
          setCurrentQuestion(data.question);
          setCurrentStep(totalSteps - data?.remaining_questions_count);
          setCurrentInfoId(data.room_information_answer_id);
          resetState("postData");
        }
      } else {
        console.log("in not  submitted ");

        setCurrentStep(totalSteps - data?.remaining_questions_count);
        setCurrentQuestion(data.question);
        setCurrentInfoId(data.room_information_answer_id);

        if (obj.ans) {
          // Assuming chat and data are defined
          const updatedChat = updateChatQuestions(chat, data);
          setChat(updatedChat);
          scrollToBottom();
        }

        setPayloadArray([...payloadArray, payload]);
        scrollToBottom();
        resetState();
      }
    } catch (error) {
      console.error("Error posting data:", error);
      resetState("errorPostData");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    setShowButton(false);
    navigate(`/individual-file/${individualFileId}`);
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionIds, setSelectedOptionIds] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [selectedRadioIds, setSelectedRadioIds] = useState(null);

  const options = [
    "Ceillings",
    "Walls",
    "Flooring",
    "Doors & Windows",
    "Decks",
    "Fences",
  ];

  const handleSelect = (option, ids) => {
    setSelectedOption(option);
    setSelectedOptionIds(ids);
  };

  const postSelectAns = async (e, val, ans, addon = "", addOnText = "") => {
    e.preventDefault();

    const obj = {
      question: currentQuestion,
      ans: addOnText ? ans + " , " + addOnText : ans,
      payload: val,
    };

    const finalOutput = {
      answers: [
        {
          answer_id: val,
          is_after_hours: null,
          quantity: null,
          number_of_days: null,
          add_on: addon,
          room_information_answer_id: Number(currentInfoId),
          code: currentQuestion.code,
        },
      ],
      question_id: currentQuestion.id,
    };

    await postData(finalOutput, obj);
  };

  const PostMulAns = async () => {
    // Check if any dropdowns are not selected
    const isAnyDropdownNotSelected = mulVals.some(
      (mulVal) => !mulVal.val1 || !mulVal.val2
    );

    if (isAnyDropdownNotSelected) {
      // Display error toast
      toast.error("Please select values for all dropdowns");
      setShowError(true);
      return;
    }

    const answersArray = mulVals?.map((mulVal, index) => {
      const updatedRoomInformationAnswerId = Number(currentInfoId) + index;

      return {
        answer_id: mulVal.val1,
        is_after_hours: null,
        quantity: mulVal.val2,
        number_of_days: null,
        add_on: mulVal?.mulValAddon || null,
        room_information_answer_id: updatedRoomInformationAnswerId,
        code: currentQuestion.code,
      };
    });

    const obj = {
      question: currentQuestion,
      ans: mulValText1,
      ans1: mulValText2,
      ans2: null,
      ans_id: mulVal1,
      quantity: mulVal2,
      number_of_days: mulVal3,
      multiAns: true,
      multiAnsVal: mulVals,
      addonAns: mulValTextAddon,
      add_on: mulValAddon,
    };

    const finalOutput = {
      answers: answersArray,
      question_id: currentQuestion.id,
    };

    await postData(finalOutput, obj);

    // Reset the error state
    setShowError(false);
  };

  const PostMulQtyAndDays = async () => {
    const isAnyDropdownNotSelected = !mulVal1 || !mulVal2 || !mulVal3;

    if (isAnyDropdownNotSelected) {
      // Display error toast
      toast.error("Please select values for all dropdowns");
      setShowError(true);
      return;
    }

    const payload = {
      question_id: currentQuestion.id,
      answers: [
        {
          answer_id: mulVal1,
          is_after_hours: null,
          quantity: mulVal2,
          add_on: mulValAddon,
          number_of_days: mulVal3,
          room_information_answer_id: Number(currentInfoId),
          code: currentQuestion.code,
        },
      ],
    };

    const obj = {
      question: currentQuestion,
      ans: mulValText1,
      ans1: mulValText2,
      ans2: mulValText3,
      ans_id: mulVal1,
      quantity: mulVal2,
      number_of_days: mulVal3,
      addonAns: mulValTextAddon,
      add_on: mulValAddon,
    };

    await postData(payload, obj);
  };

  const PostMulQtyAndAfterHours = async () => {
    const isAnyDropdownNotSelected = !mulVal1 || !mulVal2 || !mulVal3;

    if (isAnyDropdownNotSelected) {
      // Display error toast
      toast.error("Please select values for all dropdowns");
      setShowError(true);
      return;
    }
    const payload = {
      question_id: currentQuestion.id,
      answers: [
        {
          answer_id: mulVal1,
          is_after_hours: mulVal3,
          quantity: mulVal2,
          number_of_days: null,
          add_on: mulValAddon,
          room_information_answer_id: Number(currentInfoId),
          code: currentQuestion.code,
        },
      ],
    };

    const obj = {
      question: currentQuestion,
      ans: mulValText1,
      ans1: mulValText2,
      ans2: mulValText3,
      answer_id: mulVal1,
      is_after_hours: mulVal3,
      quantity: mulVal2,
      addonAns: mulValTextAddon,
      add_on: mulValAddon,
    };

    await postData(payload, obj);
  };

  const PostDate = async (formattedDate) => {
    setLoading(true);

    const payload = {
      question_id: currentQuestion.id,
      answers: [
        {
          answer_id: null,
          is_after_hours: null,
          quantity: null,
          number_of_days: null,
          room_information_answer_id: Number(currentInfoId),
          code: currentQuestion.code,
          date_time: formattedDate,
        },
      ],
    };
    const obj = {
      question: currentQuestion,
      ans: formattedDate,
      ans1: null,
      answer_id: null,
      quantity: null,
    };

    await postData(payload, obj);
  };

  const PostTable = async () => {
    setLoading(true);

    const payload = {
      question_id: currentQuestion.id,
      answers: [
        {
          answer_id: selectedOptionIds,
          is_after_hours: null,
          quantity: null,
          number_of_days: null,
          room_information_answer_id: Number(currentInfoId),
          code: currentQuestion.code,
        },
      ],
    };
    const obj = {
      question: currentQuestion,
      ans: selectedOption,
      ans1: null,
      answer_id: selectedOptionIds,
      quantity: null,
    };

    await postData(payload, obj);
  };

  const PostRadio = async () => {
    setLoading(true);

    const payload = {
      question_id: currentQuestion.id,
      answers: [
        {
          answer_id: selectedRadioIds,
          is_after_hours: null,
          quantity: null,
          number_of_days: null,
          room_information_answer_id: Number(currentInfoId),
          code: currentQuestion.code,
        },
      ],
    };
    const obj = {
      question: currentQuestion,
      ans: selectedRadio,
      ans1: null,
      answer_id: selectedRadioIds,
      quantity: null,
    };

    await postData(payload, obj);
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat.length]);

  const EditResponse = async (currentChat, index) => {
    setCurrentStep(index);

    const {
      question,
      ans,
      ans1,
      ans2,
      answer_id,
      quantity,
      number_of_days,
      is_after_hours,
    } = currentChat;

    if (index === 0) {
      const response = await axiosAuth.post(`/room-information/claim-data`, {
        room_id: room_id,
      });
      const claim_id = response.data.claim_information_id;
      navigate(`/individual-room/${claim_id}?type_of_request=7`);
    }

    if (question.id === 1 || question.id === 2) {
      setShowQuestion(false);
      setChat([]);
      setPayloadArray([]);
      return;
    }

    if (index === 1) {
      setCurrentIndex(index);
      setEditValue(currentChat?.ans || "");
      setShowPopup(true);
      return;
    }

    if (index === 2) {
      await fetchQuestion(room_id);
      const removeChat = chat.splice(2);
      setChat([...chat]);
      setPayloadArray([]);
      return;
    }
    const trimmedChat = chat.slice(0, index);
    if (index > 2 && trimmedChat.length > 0) {
      const lastChat = trimmedChat[trimmedChat.length - 1];
      setChat(trimmedChat);

      const payload = {
        question_id: lastChat?.question?.id,
        answers: [
          {
            answer_id: lastChat?.payload,
            is_after_hours: null,
            quantity: null,
            number_of_days: null,
            room_information_answer_id: lastChat?.room_information_answer_id,
            code: lastChat?.question?.code,
          },
        ],
      };

      await postData(payload, lastChat);
    }
    // Set dropdown values before resetting the state
    setCurrentQuestion(currentChat.question);
    setMulValText1(currentChat.ans);
    setMulValText2(currentChat.ans1);
    setMulValText3(currentChat.ans2);
    setMulVal1(currentChat.answer_id);
    setMulVal2(currentChat.quantity);
    setMulVal3(currentChat.number_of_days ?? currentChat.is_after_hours);

    setChat(chat.slice(0, index));
    setPayloadArray(payloadArray.slice(0, index - 2));
  };

  const handleSave = async () => {
    try {
      const updatedChat = [...chat];
      updatedChat[currentIndex].ans = editValue;
      setChat(updatedChat);

      const response = await axiosAuth.post(`/room-information/edit-room`, {
        room_id: room_id,
        room_name: editValue,
      });

      setShowPopup(false);
    } catch (error) {
      console.error("Error saving room:", error);

      if (error.response) {
        console.error("Server Error:", error.response.data);
        console.error("Status Code:", error.response.status);
      }
    }
  };

  const getMessage = (codePrefix) => {
    const messages = {
      CEI: "(Emergency) Interior Only",
      CEX: "(Emergency) Exterior Only",
      DEC: "(Emergency) Separate Contents Only",
      CRI: "(Rebuild) Interior Only",
      CRX: "(Rebuild) Exterior Only",
    };
    return messages[codePrefix] ? messages[codePrefix] + " Scenario " : "";
  };
  return (
    <Sidebar>
      <div className="flex h-[100vh]  justify-center   overflow-auto  items-end  ">
        <div className="xl:w-[70%] w-[95%]  my-2   min-w-[20rem]  align-center bg-slate-100 border rounded-md lg:mx-16  md:w-[90%]  ans-shadow  ">
          <div
            className={` relative lg:max-h-[94dvh] grid items-end max-h-[15vh] xs:max-h-[77dvh] sm:max-h-[82dvh] overflow-auto ${
              chat.length < 2 ? "sm:min-h-[75vh] min-h-[70vh]" : ""
            }`}
          >
            <div className="messages-container min-h-[75vh]  px-6 overflow-auto  border-dashed border-b-2 ">
              {chat &&
                chat?.map((ele, index) => (
                  <div key={index}>
                    {index > 0 &&
                      ele?.question?.code?.slice(0, 3) !==
                        chat[index - 1]?.question?.code?.slice(0, 3) && (
                        <div className="relative flex justify-center py-2">
                          <div className="p-2 px-4 bg-gray-300 text-center rounded-full absolute top-0 -mt-2">
                            <p className="text-sm font-thin text-grey-300">
                              <span className="text-violet-600 font-bold">
                                {getMessage(ele?.question?.code?.slice(0, 3))}
                              </span>
                            </p>
                          </div>
                        </div>
                      )}

                    <div key={index} className="my-6">
                      <div className="flex my-10">
                        <div>
                          <button className="border p-2.5 rounded-full">
                            <FaUserAlt
                              color="724CF9"
                              className="w-[15px] h-[15px]"
                            />
                          </button>
                        </div>
                        <div className="ml-2  py-3 px-2 bg-gradient-to-r from-slate-200 to-gray-200 rounded-br-3xl rounded-tr-3xl rounded-bl-3xl text-black shadow-xl">
                          <p className="mb-0 text-right pr-2 ">
                            {ele?.question?.title}
                          </p>
                        </div>
                      </div>
                      <div className="flex pl-5 pl- justify-end">
                        {index > 0 && (
                          <button onClick={() => EditResponse(ele, index)}>
                            <Edit2
                              size="20"
                              color="#724CF9"
                              className="mt-4 cursor-pointer"
                            />
                          </button>
                        )}

                        <div className=" py-3 flex">
                          {ele?.multiAns ? (
                            <div>
                              <div className="py-3 pr-5 pl-5 mb-2 bg-gradient-to-r from-indigo-500 to-violet-500 rounded-bl-3xl rounded-tl-3xl rounded-tr-3xl text-white shadow-xl">
                                <div>
                                  {ele?.multiAnsVal.map((multiAns, index) => (
                                    <React.Fragment key={index}>
                                      {`${multiAns.valText1} :: ${multiAns.valText2} `}
                                      <br />
                                      {multiAns.mulValTextAddon
                                        ? "" + multiAns.mulValTextAddon
                                        : ""}
                                    </React.Fragment>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="rounded-md lg:px-1 pr-0 flex flex-col items-end justify-start w-full">
                              {/* Answer 1 */}
                              <div className="py-3 pr-5 pl-5 mb-2 bg-gradient-to-r from-indigo-500 to-violet-500 rounded-bl-3xl rounded-tl-3xl rounded-tr-3xl text-white shadow-xl">
                                <div className="rounded-md sm:px-4 lg:pr-5 xl:pr-8 pl-0 flex flex-col items-start justify-start w-full">
                                  {/* {ele?.ans1 && ( */}
                                  <div className="flex items-start">
                                    {/* <label>Answer 1:</label> */}
                                    <p className="pl-1 rounded mb-0 ml-2">
                                      {Array.isArray(ele?.ans)
                                        ? ele.ans.join(", ")
                                        : ele?.ans}
                                    </p>
                                  </div>
                                  {/* )} */}
                                  {/* {!ele?.ans1 && (
                                    <p className="pl-1 mb-0">
                                      {Array.isArray(ele?.ans)
                                        ? ele.ans.join(", ")
                                        : ele?.ans}
                                    </p>
                                  )} */}
                                </div>
                                {/* {!ele?.ans1 && ( */}
                                {/* <p className="text-xs pt-2 pr-2 font-thin text-right text-white"> */}
                                {/* {currentTime} */}
                                {/* </p> */}
                                {/* )} */}
                              </div>

                              {/* Answer 2 */}
                              {ele?.ans1 && !ele?.ans2 && (
                                <div
                                  className={`mb-5 px-5 py-3 bg-gradient-to-r from-indigo-500 to-violet-500 rounded-bl-3xl rounded-tr-xl text-white shadow-xl ${
                                    ele?.ans2 ? "rounded-tr-3xl" : ""
                                  }`}
                                >
                                  <div className="rounded-md sm:px-4 lg:px-6 xl:px-8 flex flex-col items-start justify-start w-full">
                                    <div className="flex items-start">
                                      {/* <label>Answer 2:</label> */}
                                      <p
                                        className="pl-1 rounded mb-0 ml-2"
                                        style={{ overflowWrap: "break-word" }}
                                      >
                                        {Array.isArray(ele?.ans1)
                                          ? ele.ans1.join(", ")
                                          : ele?.ans1}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="text-xs pt-2 pr-2 font-thin text-right text-white">
                                    {/* {currentTime} */}
                                  </p>
                                </div>
                              )}

                              {ele?.ans1 && ele?.ans2 && (
                                <div className="mb-2 px-5 py-2 bg-gradient-to-r from-indigo-500 to-violet-500 rounded-bl-xl rounded-tr-xl text-white shadow-xl">
                                  <div className="rounded-md sm:px-4 lg:px-6 xl:px-8 flex flex-col items-start justify-start w-full">
                                    <div className="flex items-start">
                                      <p
                                        className="pl-1 rounded mb-0 ml-2"
                                        style={{ overflowWrap: "break-word" }}
                                      >
                                        {Array.isArray(ele?.ans1)
                                          ? ele.ans1.join(", ")
                                          : ele?.ans1}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Answer 3 */}
                              {ele?.ans2 && (
                                <div className="py-1 px-5 bg-gradient-to-r from-indigo-500 to-violet-500 rounded-bl-3xl rounded-tr-xl text-white shadow-xl">
                                  <div className="rounded-md sm:px-4 lg:px-6 xl:px-8 flex flex-col items-start justify-start w-full">
                                    <div className="flex items-start">
                                      {/* <label>Answer 3:</label> */}
                                      <p
                                        className="pl-1 rounded-b-lg mb-0 ml-2"
                                        style={{ overflowWrap: "break-word" }}
                                      >
                                        {Array.isArray(ele?.ans2)
                                          ? ele.ans2.join(", ")
                                          : ele?.ans2}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="text-xs pt-2 pr-2 font-thin text-right text-white">
                                    {/* {currentTime} */}
                                  </p>
                                </div>
                              )}
                            </div>
                          )}

                          <div className="flex  pl-3 flex-shrink-0 flex-col-reverse items-end">
                            <img
                              className="w-[30px] h-[30px] shadow-lg"
                              src={chat_icon}
                              alt="Chat Icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {!loading && (
              <div className="rounded-bl-3xl  relative rounded-br-3xl  w-full justify-center">
                {currentQuestion && (
                  <div className="questionContainer  py-4 flex items-center sm:pb-5 sm:mb-5">
                    <button className="border  rounded-full">
                      <FaUserAlt color="724CF9" className="w-[15px] h-[15px]" />
                    </button>
                    <p className="ml-2 px-5 py-3  bg-gradient-to-r from-slate-200 to-gray-200  rounded-br-3xl rounded-tr-2xl rounded-bl-3xl text-black shadow-xl">
                      {currentQuestion?.title}
                    </p>
                  </div>
                )}
                {/* user Interaction box */}
                {chat.length >= 2 && (
                  <div
                    ref={chatContainerRef}
                    className={`flex gap-3  justify-end bg-slate-100   py-4   w-full flex-wrap questionContainer      ${
                      currentQuestion?.renderable_type == "table"
                        ? "justify-center"
                        : " justify-end "
                    }`}
                  >
                    {/* {showInput && (
                      <form
                        onSubmit={createRoom}
                        ref={inputRef}
                        className=" relative  flex justify-end gap-2 bg-[#f2f2f200] p-2  w-full  "
                      >
                        <input
                          placeholder="Something ..."
                          onChange={(e) => setInputVal(e.target.value.trim())}
                          className="sm:w-[20rem] w-[15rem] pr-16 pl-3 rounded-md  py-2 outline-none border"
                          value={dropdownData?.roomName}                          
                        />
                        <button                          
                          className={` ${
                            inputVal ? "" : "opacity-100"
                          } bg-gray-100 border w-14 absolute  rounded-r-md flex justify-center items-center py-1`}
                        >
                          <Send
                            size="32"
                            color={inputVal ? "#724cf9" : "#EBD9FC"}
                            variant="Bold"
                          />
                        </button>
                      </form>
                    )} */}

                    {/* dropdowns */}
                    {currentQuestion?.renderable_type == "dropdown" ? (
                      // single dropdown
                      <div className="flex sm:gap-4 gap-1">
                        <div className="flex  flex-col   gap-4 sm:mr-10">
                          <div className="flex justify-center items-center relative">
                            <div>
                              <select
                                defaultValue={
                                  currentQuestion?.possible_answers
                                    ?.addOnDropdown.length > 0
                                    ? currentQuestion?.possible_answers
                                        ?.dropdown[0]
                                    : ""
                                }
                                className=" rounded-3xl"
                                onChange={(e) => {
                                  if (
                                    currentQuestion?.possible_answers
                                      ?.addOnDropdown.length > 0
                                  ) {
                                    setSingleVal1(e.target.value);
                                    setSingleValText1(
                                      e.target.options[e.target.selectedIndex]
                                        .text
                                    );
                                  } else {
                                    postSelectAns(
                                      e,
                                      e.target.value,
                                      e.target.options[e.target.selectedIndex]
                                        .text
                                    );
                                  }
                                }}
                              >
                                <option disabled value="">
                                  Select...
                                </option>
                                {currentQuestion?.possible_answers?.dropdown.map(
                                  (ele, i) => (
                                    <option value={ele.id} key={i}>
                                      {ele.value}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>

                            <ArrowSquareDown
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "5px",
                                transform: "translateY(-50%)",
                              }}
                              size="32"
                              color="#5957FF"
                              variant="Bold"
                            />
                          </div>
                          {currentQuestion?.possible_answers?.addOnDropdown
                            .length > 0 && (
                            <div className="relative">
                              <div className="flex  justify-center items-center relative">
                                <select
                                  defaultValue={""}
                                  onChange={(e) => {
                                    setSingleVal2(e.target.value);
                                    setSingleValText2(
                                      e.target.options[e.target.selectedIndex]
                                        .text
                                    );
                                  }}
                                >
                                  <option value="" disabled>
                                    Select Add ons
                                  </option>
                                  {currentQuestion.possible_answers?.addOnDropdown.map(
                                    (ele, i) => (
                                      <option value={ele.id} key={i}>
                                        {ele.value}
                                      </option>
                                    )
                                  )}
                                </select>
                                <ArrowSquareDown
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "5px",
                                    transform: "translateY(-50%)",
                                  }}
                                  size="32"
                                  color="#5957FF"
                                  variant="Bold"
                                />
                              </div>
                              <button
                                className="absolute right-0 py-2 "
                                onClick={(e) => {
                                  postSelectAns(
                                    e,
                                    singleVal1,
                                    singleValText1,
                                    singleVal2,
                                    singleValText2
                                  );
                                }}
                              >
                                <TickCircle size="32" color="#00cc00" />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : currentQuestion?.renderable_type ==
                      "dropdown_and_quantity" ? (
                      // single dropdown
                      <div className="flex sm:gap-4 gap-1">
                        <div className="flex  flex-col   gap-4 sm:mr-10">
                          <div className="flex justify-center items-center relative">
                            <div>
                              <select
                                defaultValue={
                                  currentQuestion?.possible_answers
                                    ?.addOnDropdown.length > 0
                                    ? currentQuestion?.possible_answers
                                        ?.dropdown[0]
                                    : ""
                                }
                                className=" rounded-3xl"
                                onChange={(e) => {
                                  if (
                                    currentQuestion?.possible_answers
                                      ?.addOnDropdown.length > 0
                                  ) {
                                    setSingleVal1(e.target.value);
                                    setSingleValText1(
                                      e.target.options[e.target.selectedIndex]
                                        .text
                                    );
                                  } else {
                                    postSelectAns(
                                      e,
                                      e.target.value,
                                      e.target.options[e.target.selectedIndex]
                                        .text
                                    );
                                  }
                                }}
                              >
                                <option disabled value="">
                                  Select...
                                </option>
                                {currentQuestion?.possible_answers?.dropdown.map(
                                  (ele, i) => (
                                    <option value={ele.id} key={i}>
                                      {ele.value}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>

                            <ArrowSquareDown
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "5px",
                                transform: "translateY(-50%)",
                              }}
                              size="32"
                              color="#5957FF"
                              variant="Bold"
                            />
                          </div>
                          {currentQuestion?.possible_answers?.addOnDropdown
                            .length > 0 && (
                            <div className="relative">
                              <div className="flex  justify-center items-center relative">
                                <select
                                  defaultValue={""}
                                  onChange={(e) => {
                                    setSingleVal2(e.target.value);
                                    setSingleValText2(
                                      e.target.options[e.target.selectedIndex]
                                        .text
                                    );
                                  }}
                                >
                                  <option value="" disabled>
                                    Select Add ons
                                  </option>
                                  {currentQuestion.possible_answers?.addOnDropdown.map(
                                    (ele, i) => (
                                      <option value={ele.id} key={i}>
                                        {ele.value}
                                      </option>
                                    )
                                  )}
                                </select>
                                <ArrowSquareDown
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "5px",
                                    transform: "translateY(-50%)",
                                  }}
                                  size="32"
                                  color="#5957FF"
                                  variant="Bold"
                                />
                              </div>
                              <button
                                className="absolute right-0 py-2 "
                                onClick={(e) => {
                                  postSelectAns(
                                    e,
                                    singleVal1,
                                    singleValText1,
                                    singleVal2,
                                    singleValText2
                                  );
                                }}
                              >
                                <TickCircle size="32" color="#00cc00" />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : currentQuestion?.renderable_type ==
                      "dropdown_multi_value_and_multi_value" ? (
                      // 2 dropdowns
                      <div className="  flex-col flex  md:gap-4  ">
                        {mulVals?.map((mulVal, index) => (
                          <div key={index} className="flex sm:gap-4 gap-1">
                            <div className=" flex  flex-col   gap-4 sm:mr-10   ">
                              {/* Dropdown 1 */}
                              <div className="flex justify-center items-center relative">
                                <select
                                  defaultValue={
                                    currentQuestion.possible_answers.dropdown[0]
                                  }
                                  onChange={(e) => {
                                    handleMulValChange(
                                      index,
                                      "val1",
                                      "valText1",
                                      JSON.parse(e.target.value).value,
                                      JSON.parse(e.target.value).id
                                    );
                                    setMulValText1(
                                      JSON.parse(e.target.value).value
                                    );
                                  }}
                                  className="bg-red-500"
                                >
                                  {currentQuestion.possible_answers.dropdown.map(
                                    (ele, i) => (
                                      <option
                                        value={JSON.stringify(ele)}
                                        key={i}
                                      >
                                        {ele.value}
                                      </option>
                                    )
                                  )}
                                </select>
                                <ArrowSquareDown
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "5px",
                                    transform: "translateY(-50%)",
                                  }}
                                  size="32"
                                  color="#5957FF"
                                  variant="Bold"
                                />
                              </div>

                              {/* Dropdown 2 */}
                              <div className="flex  justify-center items-center relative">
                                <select
                                  defaultValue={
                                    currentQuestion.possible_answers
                                      .second_values[0]
                                  }
                                  onChange={(e) => {
                                    handleMulValChange(
                                      index,
                                      "val2",
                                      "valText2",
                                      JSON.parse(e.target.value).value,
                                      JSON.parse(e.target.value).id
                                    );
                                    setMulValText2(
                                      JSON.parse(e.target.value).value
                                    );
                                  }}
                                >
                                  {currentQuestion.possible_answers.second_values.map(
                                    (ele, i) => (
                                      <option
                                        value={JSON.stringify(ele)}
                                        key={i}
                                      >
                                        {ele.value}
                                      </option>
                                    )
                                  )}{" "}
                                </select>
                                <ArrowSquareDown
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "5px",
                                    transform: "translateY(-50%)",
                                  }}
                                  size="32"
                                  color="#5957FF"
                                  variant="Bold"
                                />
                              </div>

                              {/* Add on Dropdown */}
                              {currentQuestion?.possible_answers?.addOnDropdown
                                .length > 0 && (
                                <div className="flex  justify-center items-center relative">
                                  <select
                                    defaultValue={""}
                                    onChange={(e) => {
                                      handleMulValChange(
                                        index,
                                        "mulValAddon",
                                        "mulValTextAddon",
                                        JSON.parse(e.target.value).value,
                                        JSON.parse(e.target.value).id
                                      );
                                      setMulValText2(
                                        JSON.parse(e.target.value).value
                                      );
                                    }}
                                  >
                                    <option value="" disabled>
                                      Select Add ons
                                    </option>
                                    {currentQuestion.possible_answers?.addOnDropdown.map(
                                      (ele, i) => (
                                        <option
                                          value={JSON.stringify(ele)}
                                          key={i}
                                        >
                                          {ele.value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <ArrowSquareDown
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "5px",
                                      transform: "translateY(-50%)",
                                    }}
                                    size="32"
                                    color="#5957FF"
                                    variant="Bold"
                                  />
                                </div>
                              )}
                            </div>
                            <div
                              className={`grid sm:w-[20%]  justify-center gap-7  ${
                                index > 0 ? "py-7" : "py-1 "
                              }`}
                            >
                              {index == 0 && (
                                <button onClick={() => handleAddCircle(index)}>
                                  <AddCircle size="32" color="#FF8A65" />
                                </button>
                              )}
                              {index > 0 && (
                                <button
                                  onClick={() => handleRemoveCircle(index)}
                                >
                                  <CloseCircle size="32" color="#ff0000" />
                                </button>
                              )}
                              {index == 0 && (
                                <button onClick={PostMulAns}>
                                  <TickCircle size="32" color="#00cc00" />
                                </button>
                              )}
                              <ToastContainer />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : currentQuestion?.renderable_type ==
                      "dropdown_and_quantity_and_days" ? (
                      // 3 dropdowns with days
                      <div className="flex justify-end flex-wrap gap-2">
                        <div className="flex flex-col  gap-2">
                          <div className="flex justify-center items-center relative">
                            <div>
                              <select
                                defaultValue={
                                  currentQuestion?.possible_answers?.dropdown[0]
                                }
                                onChange={(e) => {
                                  setMulValText1(
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  );
                                  setMulVal1(e.target.value);
                                }}
                              >
                                {currentQuestion.possible_answers.dropdown.map(
                                  (ele, i) => (
                                    <option value={ele.id} key={i}>
                                      {ele.value}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                            <ArrowSquareDown
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "5px",
                                transform: "translateY(-50%)",
                              }}
                              size="32"
                              color="#5957FF"
                              variant="Bold"
                            />
                          </div>

                          <div className="flex justify-center items-center relative">
                            <div>
                              <select
                                defaultValue={
                                  currentQuestion?.possible_answers
                                    ?.second_values[0]
                                }
                                onChange={(e) => {
                                  setMulValText2(
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  );
                                  setMulVal2(e.target.value);
                                }}
                              >
                                {currentQuestion.possible_answers.second_values.map(
                                  (ele, i) => (
                                    <option value={ele.id} key={i}>
                                      {ele.value}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                            <ArrowSquareDown
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "5px",
                                transform: "translateY(-50%)",
                              }}
                              size="32"
                              color="#5957FF"
                              variant="Bold"
                            />
                          </div>

                          <div className="flex justify-center items-center relative">
                            <div>
                              <select
                                defaultValue={
                                  currentQuestion?.possible_answers
                                    ?.third_values[0]
                                }
                                onChange={(e) => {
                                  setMulValText3(
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  );
                                  setMulVal3(e.target.value);
                                }}
                              >
                                {currentQuestion.possible_answers.third_values.map(
                                  (ele, i) => (
                                    <option value={ele.id} key={i}>
                                      {ele.value}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                            <ArrowSquareDown
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "5px",
                                transform: "translateY(-50%)",
                              }}
                              size="32"
                              color="#5957FF"
                              variant="Bold"
                            />
                          </div>

                          {/* Addon Dropdown */}
                          {currentQuestion?.possible_answers?.addOnDropdown
                            .length > 0 && (
                            <div className="flex justify-center items-center relative">
                              {
                                <div>
                                  <select
                                    defaultValue={""}
                                    onChange={(e) => {
                                      setMulValText2(
                                        e.target.options[e.target.selectedIndex]
                                          .text
                                      );
                                      // setMulVal2(e.target.value);
                                    }}
                                  >
                                    <option disabled value="">
                                      Select Addons
                                    </option>
                                    {currentQuestion.possible_answers.addOnDropdown.map(
                                      (ele, i) => (
                                        <option value={ele.id} key={i}>
                                          {ele.value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              }
                              <ArrowSquareDown
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "5px",
                                  transform: "translateY(-50%)",
                                }}
                                size="32"
                                color="#5957FF"
                                variant="Bold"
                              />
                            </div>
                          )}
                        </div>
                        <div className="flex flex-row    place-items-center">
                          <button onClick={PostMulQtyAndDays}>
                            <TickCircle size="32" color="#00cc00" />
                          </button>
                        </div>
                      </div>
                    ) : currentQuestion?.renderable_type ==
                      "dropdown_and_quantity_and_afterhours" ? (
                      // 3 dropdowns with hours
                      <div className="flex flex-wrap justify-end gap-2">
                        <div className="flex flex-col  gap-2">
                          <div className="flex justify-center items-center relative">
                            <div>
                              <select
                                defaultValue={
                                  currentQuestion?.possible_answers?.dropdown[0]
                                }
                                onChange={(e) => {
                                  setMulValText1(
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  );
                                  setMulVal1(e.target.value);
                                }}
                              >
                                {currentQuestion.possible_answers.dropdown.map(
                                  (ele, i) => (
                                    <option value={ele.id} key={i}>
                                      {ele.value}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                            <ArrowSquareDown
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "5px",
                                transform: "translateY(-50%)",
                              }}
                              size="32"
                              color="#5957FF"
                              variant="Bold"
                            />
                          </div>

                          <div className="flex justify-center items-center relative">
                            <div>
                              <select
                                defaultValue={
                                  currentQuestion?.possible_answers
                                    ?.second_values[0]
                                }
                                onChange={(e) => {
                                  setMulValText2(
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  );
                                  setMulVal2(e.target.value);
                                }}
                              >
                                <option disabled value="">
                                  Select quantities ...
                                </option>
                                {currentQuestion.possible_answers.second_values.map(
                                  (ele, i) => (
                                    <option value={ele.id} key={i}>
                                      {ele.value}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                            <ArrowSquareDown
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "5px",
                                transform: "translateY(-50%)",
                              }}
                              size="32"
                              color="#5957FF"
                              variant="Bold"
                            />
                          </div>

                          <div className="flex justify-center items-center relative">
                            <div>
                              <select
                                defaultValue={
                                  currentQuestion?.possible_answers
                                    ?.third_values[0]
                                }
                                onChange={(e) => {
                                  setMulValText3(
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  );
                                  setMulVal3(e.target.value);
                                }}
                              >
                                <option disabled value="">
                                  Select hours ...
                                </option>
                                {currentQuestion.possible_answers.third_values.map(
                                  (ele, i) => (
                                    <option value={ele.id} key={i}>
                                      {ele.label}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                            <ArrowSquareDown
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "5px",
                                transform: "translateY(-50%)",
                              }}
                              size="32"
                              color="#5957FF"
                              variant="Bold"
                            />
                          </div>

                          {/* Addon Dropdown */}
                          {currentQuestion?.possible_answers?.addOnDropdown
                            .length > 0 && (
                            <div className="flex justify-center items-center relative">
                              {
                                <div>
                                  <select
                                    defaultValue={""}
                                    onChange={(e) => {
                                      // setMulValTextAddon(
                                      //   e.target.options[e.target.selectedIndex]
                                      //     .text
                                      // );
                                      // setMulValAddon(e.target.value);
                                    }}
                                  >
                                    <option disabled value="">
                                      Select Addons
                                    </option>
                                    {currentQuestion.possible_answers.addOnDropdown.map(
                                      (ele, i) => (
                                        <option value={ele.id} key={i}>
                                          {ele.value}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              }
                              <ArrowSquareDown
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "5px",
                                  transform: "translateY(-50%)",
                                }}
                                size="32"
                                color="#5957FF"
                                variant="Bold"
                              />
                            </div>
                          )}
                        </div>
                        <div className="flex flex-row    place-items-center">
                          <button onClick={PostMulQtyAndAfterHours}>
                            <TickCircle size="32" color="#00cc00" />
                          </button>
                        </div>
                      </div>
                    ) : currentQuestion?.renderable_type == "date_time" ? (
                      <div className="flex justify-end">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDateTimePicker
                            className="bg-white"
                            defaultValue={dayjs(new Date())}
                            onAccept={(date) => {
                              // date is a Date object when the user picks a date so we need to convert it to a string
                              const formattedDate = dayjs(date).format(
                                "YYYY-MM-DD HH:mm:ss"
                              );
                              PostDate(formattedDate);
                            }}
                            slotProps={{
                              textField: {
                                inputProps: {
                                  "aria-hidden": "true", // Add aria-hidden attribute here
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    ) : currentQuestion?.renderable_type == "radio" ? (
                      // yes/no buttons
                      <div className="flex gap-4 flex-wrap justify-end">
                        {currentQuestion?.possible_answers.dropdown.map(
                          (ele, i) => (
                            <div key={i}>
                              <button
                                type="button"
                                value={ele.id}
                                className="hover:bg-[#6A4AF0] bg-white p-1  px-6 rounded-md text-[#6A4AF0] font-bold hover:shadow-xl shadow-sm  hover:text-white white"
                                onClick={(e) => {
                                  postSelectAns(e, e.target.value, ele.value);
                                }}
                              >
                                {ele.value}
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    ) : currentQuestion?.renderable_type == "table" ? (
                      <div className="flex justify-end flex-row flex-wrap">
                        <div className="flex justify-center">
                          <CheckboxButtonWrapper
                            options={currentQuestion.possible_answers.dropdown}
                            onSelectionChange={handleSelect}
                          />
                          <button
                            className={`bg-[#ffffff00]  w-14 p-3 flex mr-5 justify-center pr-5 items-center rounded-full`}
                            onClick={PostTable}
                            disabled={selectedOption?.length < 1}
                          >
                            <div className="bg-[#ffffff] rounded-full p-2">
                              <Send
                                size="32"
                                color={
                                  selectedOption?.length > 0
                                    ? "#724cf9"
                                    : "#EBD9FC"
                                }
                                variant="Bold"
                              />
                            </div>
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                <div>
                  {/* The Popup Modal */}
                  <Modal open={showPopup} onClose={() => setShowPopup(false)}>
                    <div
                      style={{
                        padding: "20px",
                        background: "#fff",
                        margin: "20% auto",
                        width: "300px",
                        textAlign: "center",
                        borderRadius: "8px",
                      }}
                    >
                      <h3>Edit Value</h3>
                      <TextField
                        fullWidth
                        label="Enter new value"
                        variant="outlined"
                        value={editValue}
                        onChange={(e) => setEditValue(e.target.value)}
                        margin="normal"
                      />
                      <div className="flex justify-center gap-2 mt-4">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSave}
                        >
                          Save
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => setShowPopup(false)}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            )}
            {loading && (
              <div ref={chatContainerRef} className="flex py-2  items-center">
                <ChatMessageSkeleton />
              </div>
            )}
          </div>
          {showButton && (
            <button
              style={{ marginLeft: "45%", marginBottom: "2%" }}
              className="relative flex items-center justify-center w-32 h-12 px-4 py-2 bg-[#4c0f78] text-white font-medium rounded-md"
              type="button"
              onClick={handleClick}
            >
              Submit
            </button>
          )}
          <div className=" flex items-center w-full  ">
            <div className="flex  w-full">
              {currentReqValue && (
                <Steps currentStep={currentStep} totalSteps={totalSteps} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
