import React, { useState, useEffect } from "react";
import {
  utils_data_for_form,
  convert_array_to_object_for_dropdown_data_for_dynamically_rendered_component,
  convert_array_to_object_for_dropdown_data_not_for_llama_form,
} from "../../lib/utils";
import Select from "react-select";
import "../ComponentTwo.css";
import { Player } from "@lottiefiles/react-lottie-player";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { StringContentForDropdown } from "../../lib/toolTipFunction";
import { Edit } from "iconsax-react";
import loader from "./loader.json";


const DoubleDropdown = ({
  id,
  label,
  type,
  options,
  response,
  setPreviousQuestion,
  previousQuestion,
  setInputResponse,
  setPresentQuestion,
  inputResponse,
  presentQuestion,
  option_groups,
  optionsChosenForCountry,
  setOptionChosenForCountry,
  last_question_for_part_one,
  loading,
  setLoading,
  setLastResponse,
  lastResponse,
}) => {
  const { dropdown, second_values } = option_groups;
  const [className, setClassName] = useState();
  const [main, setMain] = useState();
  const customStyles = utils_data_for_form.dropdown_style;

  const drop_down_option_main =
    convert_array_to_object_for_dropdown_data_not_for_llama_form(dropdown);
  const drop_down_option_secondary =
    convert_array_to_object_for_dropdown_data_not_for_llama_form(second_values);

  const handle_change_main = (e) => {
    let arr = inputResponse;

    arr = arr.map((val) => {
      setMain(e.label);
      if (val.id == id) {
        val.response = { main: e.value };
        return val;
      } else {
        return val;
      }
    });

    if (e.label == "No" || e.label == "None") {
      setPresentQuestion({
        ...previousQuestion[
          previousQuestion.indexOf(
            previousQuestion.find((val) => val.id == id)
          ) + 1
        ],
        response: null,
      });
    }

    setInputResponse([...arr]);
  };

  const handle_change_secondary = (e) => {
    let arr = inputResponse;

    arr = arr.map((val) => {
      if (val.id == id) {
        let secondaryLabel = e.label;
        let secondaryAnswerId = e.value;
        val.response = { ...val.response, done: true, secondary: secondaryAnswerId };
        return val;
      } else {
        return val;
      }
    });

    setPresentQuestion({
      ...previousQuestion[
        previousQuestion.indexOf(previousQuestion.find((val) => val.id == id)) +
          1
      ],
      response: null,
    });

    setInputResponse([...arr]);
  };

  const handleReset = () => {
    let arr = inputResponse;
    let index_search = arr.indexOf(arr.find((val) => val.id == id));
    arr = arr
      .map((val, index) => {
        if (index == index_search) {
          val.response = null;
          return val;
        } else if (index < index_search) {
          return val;
        }
      })
      .filter((val) => val !== undefined);
    setInputResponse([...arr]);
    setLastResponse(false);
  };

  return (
    <div className="A1-dropdown ">
      {(response?.main && (!main || main === "No" || main === "None")) ||
      (response?.main && response?.secondary) || 
      lastResponse ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: 5,
          }}
        >
          <div
            className={`A1-select-double-dropdown ${id}`}
            onMouseEnter={(e) => {
              setClassName(e.target.classList[1]);
            }}
          >
            <Tippy
              content="Do you want to edit the data? Doing so, you will lose all your stored data."
              onClick={handleReset}
            >
              <Edit
                size="20"
                color="#2ccce4"
                onClick={handleReset}
                style={{ padding: 4 }}
              />
            </Tippy>
            <Select
              options={drop_down_option_main}
              onChange={handle_change_main}
              styles={customStyles}
              value={{
                value: String(response.main).toLowerCase(),
                label: response.main,
              }}
              isDisabled={true}
            />
            {response && response.secondary && (
              <Select
                options={drop_down_option_secondary}
                onChange={handle_change_secondary}
                styles={customStyles}
                value={{
                  value: String(response.secondary).toLowerCase(),
                  label: response.secondary,
                }}
                isDisabled={true}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          {loading && (
            <div style={{ height: "4rem", width: "4rem" }}>
              <Player
                src={loader}
                className="player"
                loop
                autoplay
              />
            </div>
          )}
          {!loading && (
            <div className="A1-select-double-dropdown">
              <Select
                options={drop_down_option_main}
                onChange={handle_change_main}
                styles={customStyles}
              />
              {main && main !== "No" && main !== "None" && (
                <Select
                  options={drop_down_option_secondary}
                  onChange={handle_change_secondary}
                  styles={customStyles}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DoubleDropdown;
