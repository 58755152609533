import React from "react";
import "./ChatMessageSkeleton.css";

const ChatMessageSkeleton = () => {
  return (
    <div className="chat-message-skeleton">
      <div className="avatar-skeleton"></div>
      <div className="message-content-skeleton">
        <div className="text-skeleton ">
          <span className="shimmer-effect"></span>
        </div>
      </div>
    </div>
  );
};

export default ChatMessageSkeleton;
