

export const StringContentForDropdown = (inputResponse,className,setInputResponse) => {
    return (
        <div id="confirmation-div">
            <p>Do you want to edit the data?</p>
            <p>Doing so, you will lose all your stored data.</p>
        </div>
    )
};

export const StringContentForInput = (inputResponse,className,setInputResponse,setTextArea) => {
    return (
        <div id="confirmation-div">
            <p>Do you want to edit the data?</p>
            <p>Doing so, you will lose all your stored data.</p>
        </div>
    )
};

export const StringContentForDoubleDropdownMulti = (inputResponse,className,setInputResponse,setMain,setOk,ok) => {
    return (
        <div id="confirmation-div">
            <p>Do you want to edit the data?</p>
            <p>Doing so, you will lose all your stored data.</p>
            <button id="yes-button" onClick={() => {
                let arr = inputResponse
                let index_search = arr.indexOf(arr.find(val => val.id == className))
                arr = arr.map((val, index) => {
                    if (index == index_search) {
                        val.response = null
                        return val
                    } else if (index < index_search) {
                        return val
                    }
                }).filter(val => val !== undefined)
                setInputResponse([...arr])
                setMain("Yes")
                setOk(!ok)
            }}>Yes</button>
        </div>
    )
};

export const tool_tip = (setPresentQuestion,previousQuestion,setOk,ok,id,setLastResponse) => {
    return (
        <div id="confirmation-div">
        <p>Click on ok to save</p>
        <button id="yes-button" onClick={() => {
            if(previousQuestion[previousQuestion.length-1].id !== id){
                setPresentQuestion({ ...previousQuestion[previousQuestion.indexOf(previousQuestion.find((val) => val.id == id)) + 1], response: null })
                setOk(!ok)
            }else{
                setOk(!ok)
                setLastResponse(true)
            }
        }}>Ok</button>
        </div>
    )
}

export const tool_tip_for_multi_single = (setPresentQuestion,previousQuestion,setOk, ok,id,setLastResponse) => {
    return (
        <div id="confirmation-div">
        <p>Click on ok to save</p>
        <button id="yes-button" onClick={() => {
            if(previousQuestion[previousQuestion.length-1].id !== id){
                setPresentQuestion({ ...previousQuestion[previousQuestion.indexOf(previousQuestion.find((val) => val.id == id)) + 1], response: null })
                setOk(!ok)
            }else{
                setOk(!ok)
                setLastResponse(true)
            }
        }}>Ok</button>
        </div>
    )
}

export const tool_tip_multi_multi = (setPresentQuestion,previousQuestion,setOk,ok,id,setShow,setLastResponse) => {
    return (
        <div id="confirmation-div">
        <p>Click on ok to save</p>
        <button id="yes-button" onClick={() => { 
                if (previousQuestion[previousQuestion.length - 1].id !== id) {
                    setPresentQuestion({ ...previousQuestion[previousQuestion.indexOf(previousQuestion.find((val) => val.id == id)) + 1], response: null })
                    setOk(!ok)
                } else {
                    setOk(!ok)
                    setShow(true)
                    setLastResponse(true)
                }
        }}>Ok</button>
        </div>
    )
}
