import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHome } from "../../customHooks/useHome";
import { AddCircle, Edit2, CloseCircle } from "iconsax-react";
import Sidebar from "../Sidebar";
import ResultComponent from "../ResultComponent";
import { HashLoader } from "react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTools, faBoxes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import { axiosAuth } from "../../lib/axiosInstance";
import Modal from 'react-modal';

import throttle from 'lodash/throttle';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const AccordionSection = ({ title, content, defaultExpanded }) => {
  const [isOpen, setIsOpen] = useState(defaultExpanded);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border border-solid rounded-lg overflow-hidden mb-1 w-full mx-[1rem] ">
      <input type="checkbox" id={`accordion-${title}`} className="hidden" />
      <label
        htmlFor={`accordion-${title}`}
        className="accordion-header individual-file-accordion"
        onClick={toggleAccordion}
      >
        <span>{title}</span>
        <span
          className={`accordion-toggle w-6 h-6 flex items-center justify-center transform transition-transform duration-[.4s] ${isOpen ? "rotate-180" : ""
            }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </span>
      </label>
      <div
        className={`accordion-body bg-[#ffffff] overflow-hidden  w-full transition-all duration-[.4s] ${isOpen ? "max-h-[500px]  overflow-y-auto" : "max-h-0"
          }`}
        aria-hidden={!isOpen}
      >
        <div className="p-[1rem]">{content}</div>
      </div>
    </div>
  );
};

const RoomCard = ({ room, onRoomClick }) => {
  const getTimeDifferenceLabel = (timestamp) => {
    const creationDate = moment(timestamp).tz('UTC').format('MM/DD/YYYY');
    return creationDate;
  };

  return (
    <div
      onClick={onRoomClick}
      className="relative w-full bg-gray-100 rounded-lg overflow-hidden shadow-md hover:shadow-xl transition duration-300  md:w-[17rem] xl:w-80 cursor-pointer"
    >
      <div className="p-10 h-lg">
        <h5 className="text-[#7e84a3] text-lg font-semibold mb-2 text-center break-words">{room.room_name}</h5>

        <div className="text-gray-800 flex gap-1 text-xs text-center absolute bottom-0 right-0 mt-1 -mr-3  font-medium p-1 px-2 pr-4  rounded-tl-xl  ">
          {getTimeDifferenceLabel(room.created_at)}
        </div>
        {room.status == 'pending' && <div className="text-slate-100 font-medium capitalize italic flex gap-1 text-xs text-center absolute top-0 left-0  -mr-3 bg-[#4c0f78] p-1 px-2 pr-4  rounded-br-xl  ">
          {room.status}
        </div>}
      </div>
    </div>
  );
};

const IndivisualFile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showResultModal, setShowResultModal] = useState(false);
  const { loading, indivisualData, fetchIndivisualClaim } = useHome();
  const [roomId, setRoomId] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const [roomName, setRoomName] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [loader, setLoader] = useState(false);
  const [roomStatus, setRoomStatus] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [generalInformation, setGeneralInformation] = useState(indivisualData?.general_information || []);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setShowSpinner(false);
    }, 1000);

    return () => clearTimeout(loadingTimeout);
  }, []);

  useEffect(() => {
    fetchIndivisualClaim(id);
  }, []);

  useEffect(() => {
    if (indivisualData) {
      setGeneralInformation(indivisualData.general_information || []);
    }
  }, [indivisualData]);

  const renderIcon = (title) => {
    if (title.includes("Emergency") && title.includes("Rebuild") && title.includes("Contents")) {
      return <FontAwesomeIcon icon={faExclamationTriangle} />;
    } else if (title.includes("Emergency") && title.includes("Contents")) {
      return <FontAwesomeIcon icon={faBoxes} />;
    } else if (title.includes("Emergency") && title.includes("Rebuild")) {
      return <FontAwesomeIcon icon={faTools} />;
    } else if (title.includes("Rebuild")) {
      return <FontAwesomeIcon icon={faTools} />;
    } else if (title.includes("Emergency")) {
      return <FontAwesomeIcon icon={faExclamationCircle} />;
    } else {
      return null; // No icon matched
    }
  };

  const getBgColor = (title) => {
    if (title.includes("Emergency") && title.includes("Rebuild") && title.includes("Contents")) {
      return "bg-gradient-to-r from-red-200 to-green-200";
    } else if (title.includes("Emergency") && title.includes("Contents")) {
      return "bg-green-200";
    } else if (title.includes("Emergency") && title.includes("Rebuild")) {
      return "bg-yellow-200";
    } else if (title.includes("Rebuild")) {
      return "bg-yellow-200";
    } else if (title.includes("Emergency")) {
      return "bg-red-200";
    } else {
      return ""; // No background color matched
    }
  };

  const handleEmailSend = async () => {
    try {
      setLoader(true);
      const roomIds = Object.keys(indivisualData.room_information).map(key => {
        if (indivisualData.room_information[key].status === 'submitted') {
          return indivisualData.room_information[key].id;
        }
      }).filter(Boolean);

      const { data } = await axiosAuth.post('/send-room-emails', { roomIds: roomIds, claim_id: id });

      toast.success('Email sent successfully!');
    } catch (error) {
      toast.error('Failed to send emails.');
    } finally {
      setLoader(false);
    }
  };

  const throttledHandleEmailSend = useCallback(throttle(handleEmailSend, 2000, { leading: true, trailing: false }), [indivisualData]);

  const handleUpdateGeneralInfo = async (formData) => {
    try {
      const payload = {
        form_answers: formData.map((info) => ({
          question_id: info.question.id,
          answer: info.user_entered_answer,
        })),
      };

      const response = await axiosAuth.put(`/claims/${id}/general-information`, payload);
      toast.success('General information updated successfully');
    } catch (error) {
      toast.error('Failed to update general information');
      throw error;
    }
  };

  return (
    <Sidebar>
      {showResultModal && (
        <div
          onClick={() => setShowResultModal(false)}
          className="block w-[100vw] h-[100vh] fixed top-0 left-0 z-[1]"
        >
          <div className="individual-file-room-modal">
            <ResultComponent
              setShowResultModal={setShowResultModal}
              roomId={roomId}
              roomStatus={roomStatus}
              roomName={roomName}
              requestType={requestType}
              pending={isPending}
            />
          </div>
        </div>
      )}
      {showSpinner && (
        <div className="flex justify-center items-center h-screen">
          <HashLoader
            size={40}
            color="#1B2559"
            loading={showSpinner}
            css="margin: 0 auto;"
          />
        </div>
      )}
      <div className="flex flex-col items-start">
        {indivisualData && (
          <div className="individual-file-container">
            <div className="flex flex-col items-center flex-wrap justify-between">
              <div className="w-full flex flex-col justify-between gap-[2rem] items-center rounded-[5px]">
                <AccordionSection
                  title="Property and Owner Details"
                  defaultExpanded={false}
                  content={
                    <>
                      <div className="text-xs flex flex-wrap gap-1">
                        <strong>Client Name:</strong>
                        <span>{indivisualData.claim_information.full_name}</span>
                      </div>
                      <div className="text-xs flex flex-wrap gap-1">
                        <strong>Email:</strong>
                        <span>{indivisualData.claim_information.email}</span>
                      </div>
                      <div className="text-xs flex flex-wrap gap-1">
                        <strong>Loss Address:</strong>
                        <span>{indivisualData.claim_information.address}</span>
                      </div>
                      <div className="text-xs flex flex-wrap gap-1">
                        <strong>Postal Code:</strong>
                        <span>{indivisualData.claim_information.postal_code}</span>
                      </div>
                      <div className="text-xs flex flex-wrap gap-1">
                        <strong>City:</strong>
                        <span>{indivisualData.claim_information.city}</span>
                      </div>
                      <div className="text-xs flex flex-wrap gap-1">
                        <strong>State/Region:</strong>
                        <span>{indivisualData.claim_information.region}</span>
                      </div>
                    </>
                  }
                />

                <AccordionSection
                  title="Claim Information"
                  defaultExpanded={false}
                  content={
                    <>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Carrier Name:</strong>
                        <span>{indivisualData.claim_information.carrier_name}</span>
                      </div>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Carrier Adjuster:</strong>
                        <span>{indivisualData.claim_information.claim_adjuster}</span>
                      </div>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Claim Number:</strong>
                        <span>{indivisualData.claim_information.claim_number}</span>
                      </div>
                      <div className="flex text-xs flex-wrap gap-1">
                        <strong>Policy Number:</strong>
                        <span>{indivisualData.claim_information.policy_number}</span>
                      </div>
                    </>
                  }
                />

                <AccordionSection
                  title="General Information"
                  defaultExpanded={false}
                  content={
                    <>
                      <div className="flex justify-between items-start mb-4">
                        <h3 className="text-sm font-medium">General Information</h3>
                        <button
                          onClick={() => setShowEditModal(true)}
                          className="flex items-center text-[#4c0f78] hover:text-[#3a0b5c]"
                        >
                          <Edit2 size="20" className="mr-1" />
                          <span className="text-sm">Edit</span>
                        </button>
                      </div>
                      {generalInformation?.map((info, index) => (
                        <div key={index} className="mb-4">
                          <div className="flex text-xs flex-wrap gap-1">
                            <strong>{info.question?.title || ''}:</strong>
                            <span>{info.user_entered_answer.answer_1}</span>
                          </div>
                          {info.user_entered_answer.answer_2 && Array.isArray(info.user_entered_answer.answer_2) && (
                            <div className="flex text-xs flex-wrap gap-1">
                              <strong>Additional Answers:</strong>
                              <span>{info.user_entered_answer.answer_2.flat(2).join(', ')}</span>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  }
                />

                <Modal
                  isOpen={showEditModal}
                  onRequestClose={() => setShowEditModal(false)}
                  ariaHideApp={false}
                  style={{
                    content: {
                      top: "50%",
                      left: "50%",
                      right: "auto",
                      bottom: "auto",
                      marginRight: "-50%",
                      transform: "translate(-50%, -50%)",
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "5px",
                      width: "80%",
                      maxWidth: "800px",
                      overflow: "auto",
                      height: "auto",
                      maxHeight: "90vh",
                    },
                  }}
                >
                  <div className="flex bg-slate-500 text-white p-[0.6rem] text-center mb-4">
                    <div className="flex-1">Edit General Information</div>
                    <CloseCircle
                      className="cursor-pointer"
                      onClick={() => setShowEditModal(false)}
                      size="25"
                      color="#ff5c21"
                    />
                  </div>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      const updatedInfo = generalInformation?.map((info, index) => {
                        const answer_1 = e.target[`answer_1_${index}`]?.value || "";
                        const answer_2 =
                          e.target[`answer_2_${index}`]?.value
                            ?.split(",")
                            .map((item) => item.trim()) || null;

                        return {
                          ...info,
                          user_entered_answer: { answer_1, answer_2 },
                        };
                      });
                      handleUpdateGeneralInfo(updatedInfo);
                      setGeneralInformation(updatedInfo); // Update the state with new values
                      setShowEditModal(false);
                    }}
                    className="space-y-4"
                  >
                    {generalInformation?.map((info, index) => {
                      const { renderable_type, possible_answers } = info.question || {};
                      const { answer_1, answer_2 } = info.user_entered_answer || {};
                      const updatedSelectedOptions = Array.isArray(answer_2) ? answer_2.flat(Infinity) : [];

                      return (
                        <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div>
                            <label className="block text-sm font-medium mb-1">
                            {info.question?.title || ''}:
                            </label>
                            {renderable_type === "dropdown_single_value_and_single_value" ||
                              renderable_type === "dropdown_single_value_and_amount" ||
                              renderable_type === "dropdown" ||
                              renderable_type === "dropdown_single_value_and_multi_value" ? (
                              <select
                                name={`answer_1_${index}`}
                                defaultValue={answer_1 || ""}
                                className="w-full p-2 border rounded"
                                onChange={(e) => {
                                  const selectedValue = e.target.value;
                                  setGeneralInformation((prevInfo) => {
                                    const updatedInfo = [...prevInfo];
                                    updatedInfo[index].user_entered_answer.answer_1 = selectedValue;
                                    return updatedInfo;
                                  });
                                }}
                              >
                                {possible_answers.dropdown.map((option, optionIndex) => (
                                  <option key={optionIndex} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <input
                                name={`answer_1_${index}`}
                                type="text"
                                defaultValue={answer_1 || ""}
                                className="w-full p-2 border rounded"
                              />
                            )}
                          </div>

                          {renderable_type === "dropdown_single_value_and_multi_value" && (
                            answer_1 !== "No" &&
                            <div >
                              <label className="block text-sm font-medium mb-1">
                                Additional Answers
                              </label>
                              <div className="flex flex-wrap gap-2">
                                {possible_answers.second_values.map((option, optionIndex) => (
                                  <button
                                    type="button"
                                    key={optionIndex}
                                    className={`cursor-pointer ${Array.isArray(answer_2) && answer_2.includes(option)
                                        ? "bg-gray-500 text-white"
                                        : "bg-gray-200"
                                      } px-2 py-2 rounded-md`}
                                    onClick={() => {
                                      // Toggle the selection of the option
                                      const newAnswer2 = Array.isArray(answer_2) && answer_2.includes(option)
                                        ? answer_2.filter((item) => item !== option)
                                        : [...(answer_2 || []), option];

                                      // Update the hidden input value
                                      document.querySelector(`input[name="answer_2_${index}"]`).value = newAnswer2.join(", ");
                                      setGeneralInformation((prevInfo) => {
                                        const updatedInfo = [...prevInfo];
                                        updatedInfo[index].user_entered_answer.answer_2 = newAnswer2.length ? newAnswer2 : [null];
                                        return updatedInfo;
                                      });
                                    }}
                                  >
                                    {option}
                                  </button>
                                ))}
                              </div>
                              <input
                                name={`answer_2_${index}`}
                                type="hidden"
                                defaultValue={Array.isArray(answer_2) ? answer_2.join(", ") : ""}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}

                    <div className="flex justify-end gap-2 mt-4">
                      <button
                        type="button"
                        onClick={() => setShowEditModal(false)}
                        className="px-4 py-2 border rounded hover:bg-gray-100"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-[#4c0f78] text-white rounded hover:bg-[#3a0b5c]"
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                </Modal>
              </div>
            </div>
            <hr className="mt-8" />
            <header className="flex flex-wrap sm:flex-row justify-between items-center mt-[20px]">
              <p className="font-medium lg:text-[1.3rem] md:text-[1.2rem] text-[1.1rem] text-[#1B2559] mr-[1rem]">
                Room
              </p>
              <div>
                <button
                  onClick={throttledHandleEmailSend}
                  className="relative flex items-center justify-center w-32 h-12 px-4 py-2 bg-[#4c0f78] text-white font-medium rounded-md"
                  disabled={loading}
                >
                  {loader ? (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="w-6 h-6 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
                    </div>
                  ) : (
                    'Send Mail'
                  )}
                </button>
              </div>

              {/* <ToastContainer /> */}
              <div
                key={id}
                className="cursor-pointer py-2 "
                onClick={() => {
                  navigate(`/individual-room/${id}?type_of_request=${encodeURIComponent(indivisualData.claim_information.type_of_request)}`);
                }}
              >

                <div className="flex items-center text-sm font-medium">
                  Add a Room/Area
                  <AddCircle
                    size="32"
                    color="#4c0f78"
                    variant="Bold"
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              </div>
            </header>
            <div className="grid">
              <div className="grid 2xl:grid-cols-3 md:grid-cols-2  gap-4 bg-white-400 p-4  rounded-md ">

                {Object.keys(indivisualData.room_information).map((item, index) => (
                  <div>

                    <RoomCard
                      key={index}
                      room={indivisualData.room_information[item]}
                      onRoomClick={() => {
                        setShowResultModal(true);
                        setRoomId(indivisualData.room_information[item].id);
                        setRoomName(indivisualData.room_information[item].room_name);
                        setRoomStatus(indivisualData.room_information[item].status)
                        setRequestType(indivisualData.room_information[item].request_type);
                        setIsPending(indivisualData.room_information[item].status === 'pending');
                      }}

                    />
                  </div>
                ))}

              </div>
            </div>

          </div>
        )}
      </div>
    </Sidebar>
  );
};

export default IndivisualFile;